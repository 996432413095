/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from 'react';
import ReactSelect from 'react-select';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ErrorMessage } from '@hookform/error-message';
import styled, { css } from 'styled-components';
import { useScreenClass } from 'react-grid-system';
import FlexBox, { FlexGrow } from '../../components/FlexBox';
import { StyledText } from '../../components/Text';
import TextArea from '../../components/TextArea';
import { RightAlign } from '../../components/UtilsComponents';
import Status from '../../types/enum/Status';
import theme from '../../styles/theme';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Breadcrumb from '../../components/Breadcrumb';
import MinimalSelect from '../../components/MinimalSelect';
import Input from '../../components/Input';
import Modal from '../../components/Modal';

const sharedMinimalStyle = css`
  width: 100%;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 0px 12px;
  .react-select__menu {
    width: 100%;
    min-width: unset;
    margin-left: -12px;
  }
`;
const StyledMinimalSelect = styled(MinimalSelect)`
  ${sharedMinimalStyle}
`;
const StyledReactSelect = styled(ReactSelect) <{ isMobile?: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '86px' : '128px')};
`;
type BasicCourseInfoForm = {
  code: string | undefined;
  full_name: string | undefined;
  short_name: string | undefined;
  description: string | undefined;
  units: number | undefined;
  programs: { id: any }[] | undefined;
};
function CreateCourseMasterPage(props: any) {
  const test = [
    { name: 'Course Master', link: '/admin/courses/course/master' },
    { name: 'Create Course Master', link: '/admin/courses/course/master/add' },
  ];
  const validationSchema = Yup.object({
    code: Yup.string().required('Course code is required'),
    full_name: Yup.string().required('Course Full Name is required'),
    short_name: Yup.string().required('Course Short Name is required'),
    description: Yup.string().required('Course Description is required'),
    units: Yup.number()
      .required('Course Unit is required')
      .typeError('Course Unit is required')
      .min(0)
      .integer(),
    programs: Yup.array()
      .min(1, ' Course Programs are required')
      .required('Course Programs are required'),
  });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<BasicCourseInfoForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      code: '',
      full_name: '',
      short_name: '',
      description: '',
      units: 0,
      programs: [],
    },
  });
  const screenClass = useScreenClass();
  const [isOpenError, setOpenError] = useState<boolean>(false);
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const [loading, setLoading] = useState<boolean>(true);
  const handleProgramChange = (program: any, dirty: boolean) => {
    const data: { id: any }[] = [];
    if (program && program.length > 0) {
      program.forEach((lect: { value: any }) => data.push({ id: lect.value }));
    }
    setValue('programs', data, {
      shouldDirty: dirty,
    });
  };
  const watchAllFields = watch();
  // const termsReg = register('term', { required: true });
  const programsReg = register('programs');
  const [programs, setPrograms] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const history = useHistory();
  useEffect(() => {
    fetchPrograms();
    setLoading(false);
  }, []);
  const onSubmitClicked = (data: any) => {
    setLoading(true);
    axios
      .post('/api/v1/course-master', data)
      .then((res) => {
        setLoading(false);
        history.push('/admin/courses/course/master');
        console.log('create course', res.data.data);
      })
      .catch((error) => {
        setOpenError(true);
        console.log(error);
        setLoading(false);
      });
  };
  const fetchPrograms = () => {
    setLoading(true);
    axios
      .get('/api/v1/courses/programs')
      .then((res) => {
        if (res.data.data.programs) {
          // eslint-disable-next-line max-len
          const programList: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.programs.forEach(
            (element: { name: string; id: string }) => {
              const array = { label: '', value: '' };
              array.label = element.name;
              array.value = element.id;
              programList.push(array);
            },
          );
          setPrograms(programList);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const onError = (errors: any, e: any) => console.log('error', errors, e);
  const onErrorCloseModal = () => {
    setOpenError(false);
  };
  return (
    <>
      {console.log('course watch', watchAllFields)}
      <FlexBox>
        <FlexGrow>
          <Breadcrumb path={test} />
        </FlexGrow>
      </FlexBox>
      <FlexBox
        style={{
          marginBottom: theme.spacing.base * 3,
          marginTop: theme.spacing.base * 3,
        }}>
        <FlexGrow>
          <h2 style={{ marginRight: 'auto', marginBottom: 0 }}>
            Create Course Master
          </h2>
        </FlexGrow>
      </FlexBox>
      <Card style={{ margin: '24px 0px' }}>
        {!loading ? (
          <form onSubmit={handleSubmit(onSubmitClicked, onError)}>
            <FlexBox
              alignItems="flex-start"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow>
                <h3>Course Information</h3>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Course Code
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('code')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="code"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Short Name
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('short_name')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="short_name"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Full Name
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('full_name')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="full_name"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Description
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <TextArea
                    {...register('description')}
                    isFullWidth
                    wrap="hard"
                    rows={6}></TextArea>
                  <ErrorMessage
                    errors={errors}
                    name="description"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Program
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <StyledMinimalSelect
                  multi
                  placeholder="Find programs..."
                  options={programs}
                  onChange={(e: any) => {
                    handleProgramChange(e, true);
                  }}
                  onBlur={programsReg.onBlur}
                  innerRef={programsReg.ref}
                  style={{ width: 'inherit' }}
                />
                <ErrorMessage
                  errors={errors}
                  name="programs"
                  render={({ message }) => (
                    <p
                      style={{
                        marginTop: 0,
                        color: theme.color.danger,
                      }}>
                      {message}
                    </p>
                  )}
                />
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Units
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <Input type="number" isFullWidth {...register('units')}></Input>
                <ErrorMessage
                  errors={errors}
                  name="units"
                  render={({ message }) => (
                    <p
                      style={{
                        marginTop: 0,
                        color: theme.color.danger,
                      }}>
                      {message}
                    </p>
                  )}
                />
              </FlexGrow>
            </FlexBox>

            <FlexBox
              alignItems="center"
              justify="flex-end"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow>
                <RightAlign>
                  <Button
                    onClick={() => {
                      history.push('/admin/courses/course/master');
                    }}
                    isOutline
                    status={Status.primary}
                    style={{ padding: '4px 12px' }}>
                    <Icon name="x-s" mr={0.5} />
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    onClick={() => handleSubmit(onSubmitClicked)}
                    status={Status.primary}
                    backgroundColor="#CB092B"
                    style={{ padding: '4px 12px', marginLeft: '8px' }}>
                    <Icon name="floppy" mr={0.5} />
                    Save
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
          </form>
        ) : (
          <Icon mr={1} name="spinner" spin />
        )}
      </Card>
      <Modal
        isOpen={isOpenError}
        setOpen={setOpenError}
        onClose={onError}
        customStyle={{ content: { textAlign: 'center' } }}>
        <img
          src="/alert-warning.svg"
          alt="alert warning"
          style={{ margin: 'auto' }}
        />
        <h3>Cannot Create Course Master</h3>
        <p>
          An error occurred while creating the course.
          This error can occur if the same course code and unit already exist.
          Please try again or contact us if the problem persists.
        </p>
        <Button status={Status.primary} onClick={onErrorCloseModal}>
          Done
        </Button>
      </Modal>
    </>
  );
}
export default CreateCourseMasterPage;
