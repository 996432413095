import React, { useState, useEffect } from 'react';

import { useScreenClass } from 'react-grid-system';
import styled from 'styled-components';
import colorName from '../styles/colors';
import theme from '../styles/theme';
import Status from '../types/enum/Status';
import FlexBox, { FlexGrow } from './FlexBox';
import { ClickableIcon } from './Icon';
import Modal from './Modal';
import NullStateTR from './NullStateTr';
import { Col, Row } from './StyledGridSystem';
import Table from './Table';
import { StyledText, TextStatus } from './Text';

const MAX_DESCRIPTION_LENGTH = 280;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StatusStyled = styled.div`
  display: flex;
  flex-flow: column;
`;

const StatsItemStyled = styled.div`
  display: flex;
  flex-flow: row;
  vertical-align: baseline;
`;

const BlockStlyed = styled.h6`
  padding-right: ${({ theme }) => theme.spacing.x3};
  width: 100px;
  margin: 0px;
`;

type CourseSelectModalProps = {
  isOpen?: boolean;
  setOpen?: (x: boolean) => void;
  closeTest?: (courseId: any, section: any) => void;
  currentCourse: {
    [key: string]: any;
  };
  showSections?: boolean;
};
const CourseSelectModal = ({
  isOpen,
  setOpen,
  closeTest,
  currentCourse,
  showSections = true,
}: CourseSelectModalProps): JSX.Element => {
  const screenClass = useScreenClass();

  const [isDescriptionExpand, setDescriptionExpand] = useState<boolean>(false);

  const currentCourseSection = (
    <>
      {currentCourse?.sections?.map?.((data: any, i: any) => {
        let SeatLeft: JSX.Element = <>{data.seat}</>;
        if (data.capacity - data.seat === 0) {
          SeatLeft = (
            <StyledText status={TextStatus.danger}>
              {data.capacity - data.seat}/{data.capacity}
            </StyledText>
          );
        } else {
          SeatLeft = (
            <StyledText>
              {data.capacity - data.seat}/{data.capacity}
            </StyledText>
          );
        }
        return (
          <tr style={{ verticalAlign: 'baseline' }} key={i}>
            <td>{data.name}</td>
            <td>
              <div>
                {data.schedule?.map((time: any) => {
                  const Time: string = `${time.start} ${time.end}`;
                  const [start, startPeriod, end, endPeriod] = Time.split(' ');
                  return (
                    <>
                      <div>
                        {time.day.slice(0, 3)} {start}-{end}
                      </div>
                    </>
                  );
                })}
                {/* <div>
                  <StyledText status={TextStatus.muted}>{data.Room}</StyledText>
                </div> */}
                <div>
                  <StyledText status={TextStatus.muted}>
                    {data.room.name}
                  </StyledText>
                </div>
              </div>
              <div>
                <StyledText status={TextStatus.muted}>
                  {data?.lecturers
                    && data?.lecturers?.map((obj: any) => <div>{obj.name}</div>)}
                </StyledText>
              </div>
              {/* <StyledText status={TextStatus.muted}>
                {currentCourse.lecturers}
              </StyledText> */}
            </td>
            <td>{SeatLeft}</td>
            <td>
              {!currentCourse.isSelected && (
                <ClickableIcon
                  name="plus-s"
                  title="Select"
                  style={{
                    color: colorName.primary,
                    alignItems: 'center',
                  }}
                  status={Status.primary}
                  onClick={(e) => {
                    e.stopPropagation();
                    closeTest?.(currentCourse.id, data.name);
                    setOpen?.(false);
                  }}
                />
              )}
              {currentCourse.isSelected && (
                <ClickableIcon
                  name="x-s"
                  color={theme.color.textDisabled}
                  title="Deselect"
                  style={{
                    color: theme.color.textDisabled,
                    alignItems: 'center',
                  }}
                  status={Status.primary}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              )}
              {/* <Icon ml={0.5} size={1.5} name="plus-s" />Select */}
            </td>
          </tr>
        );
      })}
      {!currentCourse?.sections?.length && (
        <NullStateTR>No sections info.</NullStateTR>
      )}
    </>
  );

  useEffect(() => {
    if (!isOpen) setDescriptionExpand(false);
  }, [isOpen]);

  return (
    <Modal isOpen={!!isOpen} setOpen={setOpen} onClose={closeTest}>
      <FlexBox alignItems="flex-start">
        <FlexGrow>
          <StyledText status={TextStatus.muted}>
            {currentCourse?.code}
          </StyledText>
          <h3 style={{ margin: '0px' }}>{currentCourse?.short_name}</h3>
        </FlexGrow>
        <RightAlign>
          <ClickableIcon name="x-s" onClick={() => setOpen?.(false)} />
        </RightAlign>
      </FlexBox>
      <p>
        {/* eslint-disable-next-line no-nested-ternary */}
        {currentCourse?.description.length > MAX_DESCRIPTION_LENGTH ? (
          !isDescriptionExpand ? (
            <>
              {currentCourse?.description.substring(0, MAX_DESCRIPTION_LENGTH)}
              ...{' '}
              <a href="#" onClick={() => setDescriptionExpand(true)}>
                Show More
              </a>
            </>
          ) : (
            <>
              {currentCourse?.description}{' '}
              <a href="#" onClick={() => setDescriptionExpand(false)}>
                Show less
              </a>
            </>
          )
        ) : (
          currentCourse?.description
        )}
      </p>
      <StatusStyled>
        <StatsItemStyled>
          <BlockStlyed>Semester</BlockStlyed>
          <div style={{ fontSize: '16px' }}>{currentCourse?.term}</div>
        </StatsItemStyled>
        <StatsItemStyled>
          <BlockStlyed>Units</BlockStlyed>
          <div style={{ fontSize: '16px' }}>{currentCourse?.units}</div>
        </StatsItemStyled>
        <StatsItemStyled>
          <BlockStlyed>Prerequisites</BlockStlyed>
          {/* FIXEME : ADD LOGIC SHOW NONE OR NOT */}
          {currentCourse.condition.prerequisites
          && currentCourse.condition.prerequisites !== '' ? (
            <div style={{ fontSize: '16px' }}>
              {' '}
              {currentCourse.condition.prerequisites}
            </div>
            ) : (
            <StyledText
              status={TextStatus.muted}
              style={{ fontStyle: 'italic', fontSize: '16px' }}>
              None
            </StyledText>
            )}
        </StatsItemStyled>
        <StatsItemStyled>
          <BlockStlyed>Co-requisites</BlockStlyed>
          {/* {currentCourse?.coprerequisites} */}
          {currentCourse.condition.co_requisites
          && currentCourse.condition.co_requisites !== '' ? (
            <div style={{ fontSize: '16px' }}>
              {' '}
              {currentCourse.condition.co_requisites}
            </div>
            ) : (
            <StyledText
              status={TextStatus.muted}
              style={{ fontStyle: 'italic', fontSize: '16px' }}>
              None
            </StyledText>
            )}
        </StatsItemStyled>
      </StatusStyled>
      {showSections && (
        <>
          <h3>Sections</h3>
          <Table>
            <thead>
              <tr>
                <th>Sect.</th>
                <th>Time/Location</th>
                <th>Seats Left</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>
              {currentCourseSection}
              {!currentCourse.sections && (
                <NullStateTR>No sections.</NullStateTR>
              )}
            </tbody>
          </Table>
        </>
      )}
    </Modal>
  );
};

export default CourseSelectModal;
