/* eslint-disable no-nested-ternary */
import React, {
  HTMLProps, useEffect, useMemo, useState,
} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useScreenClass } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import { Col, Row } from '../components/StyledGridSystem';
import Breadcrumb from '../components/Breadcrumb';
import Card from '../components/Card';
import Table from '../components/Table';
import NullStateTR from '../components/NullStateTr';
import { StyledText, TextSize, TextStatus } from '../components/Text';
import FlexBox, { FlexGrow } from '../components/FlexBox';
import { RightAlign } from '../components/UtilsComponents';
import Button from '../components/Button';
import Icon from '../components/Icon';
import Status from '../types/enum/Status';
import theme from '../styles/theme';
import { EmptyProgramContent, ProgramContent } from '../filter/FilterValue';
import ProgramDropdown from '../components/ProgramDropDown';
import ProgramEditModal from '../components/ProgramEditModal';

const RowStyled = styled.div`
  display: flex;
  flex-flow: column;
`;
function ProgramsPage() {
  const history = useHistory();
  useEffect(() => {
    fetchPrograms();
  }, []);
  const screenClass = useScreenClass();
  const test = [{ name: 'Programs', link: '/programs' }];
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [currentProgram, setCurrentProgram] = useState<ProgramContent>(EmptyProgramContent);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = (data: any) => {
    if (data) {
      setCurrentProgram(data);
      setIsModalOpen(true);
    }
  };
  const openEditModal = (data: any) => {
    if (data) {
      setCurrentProgram(data);
      setIsEditOpen(true);
    }
  };
  const [loading, setLoading] = useState(true);
  const [isItem, setIsItem] = useState(false);
  const ColumnName = (isMobile: boolean) => {
    if (isMobile) {
      return (
        <>
          <th></th>
          <th></th>
        </>
      );
    }
    return (
      <>
        <th>Short Name</th>
        <th>Full Name</th>
        <th>Degree Type</th>
        <th>Degree Abbr</th>
        <th>Program Abbr</th>
        <th></th>
      </>
    );
  };
  const [programs, setPrograms] = useState([
    {
      id: '',
      name: '',
      program_name: '',
      degree_type: '',
      degree_abbr: '',
      program_abbr: '',
      description: '',
    },
  ]);
  const fetchPrograms = () => {
    setLoading(true);
    axios
      .get('/api/v1/courses/programs')
      .then((res) => {
        if (res.data.data.programs) {
          setPrograms(res.data.data.programs);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const createProgram = () => {
    const path = '/programs/create';
    history.push(path);
  };
  const closeEditModal = () => {
    setIsEditOpen(false);
    fetchPrograms();
  };
  return (
    <>
      {isEditOpen && (
        <ProgramEditModal
          isOpen={isEditOpen}
          setOpen={setIsEditOpen}
          currentProgram={currentProgram}
          onEditComplete={() => {
            closeEditModal();
          }}
        />
      )}
      <Row>
        <Col>
          <Breadcrumb path={test} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FlexBox space={2}>
            <h2 style={{ marginRight: 'auto', marginBottom: 0 }}>
              Programs
            </h2>
          </FlexBox>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ marginTop: '0px' }}>
            <FlexBox
              style={{
                alignItems: 'flex-start',
                marginBottom: theme.spacing.base * 2,
              }}>
              <FlexGrow ml-auto style={{ marginLeft: 'auto' }}>
                <RightAlign>
                  <Button
                    onClick={() => createProgram()}
                    isOutline
                    status={Status.primary}
                    style={{ padding: '4px 12px' }}>
                    <Icon name="plus-s" mr={0.5} />
                    Create New Program
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
            <Table hoverable>
              <thead>
                <tr>{ColumnName(isMobile)}</tr>
              </thead>
              <tbody>
                {!loading && programs ? (
                  programs.map((data) => {
                    if (!isMobile) {
                      return (
                        <tr key={data.id} onClick={() => openModal(data)}>
                          <td>
                            <StyledText
                              textSize={TextSize.default}
                            >
                              {data.name}
                            </StyledText>
                          </td>
                          <td>{data.program_name}</td>
                          <td>{data.degree_type}</td>
                          <td>{data.degree_abbr}</td>
                          <td>{data.program_abbr}</td>
                          <td
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            style={{ verticalAlign: 'middle' }}>
                            <ProgramDropdown
                              program={data}
                              openEditModal={openEditModal}
                            />
                          </td>
                        </tr>
                      );
                    }

                    return (
                      <tr key={data.id}>
                        <td>
                          <RowStyled>
                            <StyledText style={{ fontWeight: 'bold' }}>
                              {data.name}
                            </StyledText>
                            <StyledText status={TextStatus.muted}>
                              {data.program_name}
                            </StyledText>
                            <StyledText status={TextStatus.muted}>
                              {data.degree_type}
                            </StyledText>
                            <StyledText status={TextStatus.muted}>
                              {data.degree_abbr}
                            </StyledText>
                            <StyledText status={TextStatus.muted}>
                              {data.program_abbr}
                            </StyledText>
                          </RowStyled>
                        </td>
                        <td
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          style={{ verticalAlign: 'middle' }}>
                          <ProgramDropdown
                            program={data}
                            openEditModal={openEditModal}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <div></div>
                )}
                {isItem && !loading ? (
                  <NullStateTR>No ProgramEditModalProps found.</NullStateTR>
                ) : (
                  <div></div>
                )}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default ProgramsPage;
