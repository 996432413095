import { useState, useEffect } from 'react';

import { useScreenClass } from 'react-grid-system';
import styled from 'styled-components';
import { CourseContent, SectionContent } from '../filter/FilterValue';
import FlexBox, { FlexGrow } from './FlexBox';
import { ClickableIcon } from './Icon';
import Modal from './Modal';
import { StyledText, TextStatus } from './Text';

const MAX_DESCRIPTION_LENGTH = 280;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StatusStyled = styled.div`
  display: flex;
  flex-flow: column;
`;

const StatsItemStyled = styled.div`
  display: flex;
  flex-flow: row;
  vertical-align: baseline;
`;

const BlockStlyed = styled.h6`
  padding-right: ${({ theme }) => theme.spacing.x3};
  width: 100px;
  margin: 0px;
`;
const CustomRow = styled.tr`
  vertical-align: baseline;
`;
type CourseInfoModalProps = {
  isOpen?: boolean;
  setOpen?: (x: boolean) => void;
  currentCourse: any;
  showSections?: boolean;
};

const CourseCatalogModal = ({
  isOpen,
  setOpen,
  currentCourse,
  showSections = true,
}: CourseInfoModalProps): JSX.Element => {
  const screenClass = useScreenClass();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [currentCourseData, setCurrentCourseData] = useState<CourseContent>(currentCourse);
  const [currentSectionData, setCurrentSectionData] = useState<SectionContent>();
  const [isDescriptionExpand, setDescriptionExpand] = useState<boolean>(false);
  useEffect(() => {
    setCurrentCourseData(currentCourse);
  }, [currentCourse]);

  useEffect(() => {
    if (!isOpen) setDescriptionExpand(false);
  }, [isOpen]);

  return (
    <>

      <Modal
        isOpen={!!isOpen}
        setOpen={setOpen}
        customStyle={{ content: { paddingBottom: '20px' } }}>
        <FlexBox alignItems="flex-start">
          <FlexGrow>
            <StyledText status={TextStatus.muted}>
              {currentCourseData?.code}
            </StyledText>
            <h3 style={{ margin: '0px' }}>{currentCourseData?.short_name}</h3>
          </FlexGrow>
          <RightAlign>
            <ClickableIcon name="x-s" onClick={() => setOpen?.(false)} />
          </RightAlign>
        </FlexBox>
        <p>
          {/* eslint-disable-next-line no-nested-ternary */}
          {currentCourseData?.description}
        </p>
        <StatusStyled>
          <StatsItemStyled>
            <BlockStlyed>Units</BlockStlyed>
            <div style={{ fontSize: '16px' }}>{currentCourseData?.units}</div>
          </StatsItemStyled>
        </StatusStyled>
      </Modal>
    </>
  );
};

export default CourseCatalogModal;
