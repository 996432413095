import React, {
  HTMLProps,
  useEffect,
  useMemo,
  useState,
  useRef,
  useContext,
} from 'react';
import styled from 'styled-components';

import axios from 'axios';
import { useScreenClass } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import Icon, { ClickableIcon } from '../../components/Icon';
import { Col, Row } from '../../components/StyledGridSystem';
import Status from '../../types/enum/Status';
// import FlexBox from '../../components/FlexBox';
import FlexBox, { FlexGrow } from '../../components/FlexBox';
import Card from '../../components/Card';
import Input, {
  InputContainer,
  InputIconContainer,
} from '../../components/Input';
import Table from '../../components/Table';
import {
  StyledText,
  Subtitle,
  TextSize,
  TextStatus,
} from '../../components/Text';
import {
  CourseContent,
  FilterValue,
  EmptyCourseContent,
} from '../../filter/FilterValue';
import useDebounce from '../../components/use-debounce';
import theme from '../../styles/theme';
import Button from '../../components/Button';
import colorName from '../../styles/colors';

import ConfirmModal from '../../components/ConfirmModal';
import CourseSelectModal from '../../components/CourseSelectModal';
import { StudentContext } from '../../context/StudentBehalfContext';
import { UserContext } from '../../context/UserContext';

export interface Ioption {
  semester: any;
  program: any;
  time: any;
  day: any;
}
const TBody = styled.tbody`
  tr:last-child: {
    td: {
      border-bottom: none;
    }
  }
  tr:last-child td {
    border-bottom: none;
  }
`;
const MinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x4 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
`;

const MultiMinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x4 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
  align-items: center;
`;

const FilterStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing.x2};
`;

const RowStyled = styled.div`
  display: flex;
  flex-flow: column;
`;

function AddCoursesPage(props: any) {
  const { student, isBehalf } = useContext(StudentContext);
  const { user } = useContext(UserContext);
  const { termID } = props.location.state;
  const { termName } = props.location.state;
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const test = [
    { name: 'Courses', link: '/courses/myCourses' },
    { name: 'My Courses', link: '/courses/myCourses' },
    { name: 'Add Courses', link: '/courses/add-courses' },
  ];
  const [posts, setPosts] = useState<Array<any>>([]);
  const [selectedCourses, setSelectedCourses] = useState<Array<any>>([]);
  const [isItem, setIsItem] = useState(false);
  const [offset, setOffSet] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSelectionModalOpen, setIsSelectionModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [term, setTerm] = useState('');
  const [currentCourse, setCurrentCourse] = useState<CourseContent>(EmptyCourseContent);
  // const [courses, setCourses] = useState();
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, 300);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState({
    semester: [],
    program: [{ label: 'All', value: 'All' }],
    time: [],
    day: [],
  });
  const [filters, setfilters] = useState({
    semester: '',
    program: [],
    time: [],
    day: [],
    sort: 'Course Code',
  });

  const [latestSemester, setLatestSemester] = useState<FilterValue>({
    label: '',
    value: '',
  });

  const toggleSelects = (id: number, sectionName: String) => {
    // console.log(id);
    setPosts(
      posts.map((course: any) => (course.id === id
        ? {
          ...course,
          isSelected: !course.isSelected,
        }
        : course)),
    );
  };
  const toggleSelect = (id: number, sectionName: String) => {
    setPosts(updateObjectInArray(posts, id, sectionName));
  };
  const getSectionName = (course: any) => course.sections
    .filter((obj: any) => obj.isSelected && obj.isSelected === true)
    .map((obj: any) => obj.name);

  function updateObjectInArray(
    array: any[],
    courseId: any,
    sectionName: String,
  ) {
    return array.map((item: any) => {
      if (item.id !== courseId) {
        // This isn't the item we care about - keep it as-is
        return item;
      }
      // Otherwise, this is the one we want - return an updated value
      return {
        ...item,
        isSelected: !item.isSelected,
        sections: item.sections?.map((content: any) => (content.name === sectionName
          ? { ...content, isSelected: !content.isSelected }
          : content)),
      };
    });
  }
  const getShortDay = (day: String) => {
    switch (day) {
      case 'Monday':
        return 'Mon';
      case 'Tuesday':
        return 'Tue';
      case 'Wednesday':
        return 'Wed';
      case 'Thursday':
        return 'Thu';
      case 'Friday':
        return 'Fri';
      case 'Saturday':
        return 'Sat';
      case 'Sunday':
        return 'Sun';
      default:
        return day;
    }
  };
  const fetchTerm = () => {
    setLoading(true);
    setTerm('Fall 2021');
    // axios.get('/api/v1/terms/add-course-period').then((res) => {
    //   setTerm(res.data.data.name);
    //   setLoading(false);
    // }).catch((err) => {
    //   setLoading(false);
    // });
  };

  const isInitialRender = useRef(true);

  useEffect(() => {
    fetchTerm();
  }, []);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      setfilters({ ...filters, semester: term });
    }
  }, [term]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else if (posts != null) {
      setSelectedCourses(posts.filter((course) => course.isSelected === true));
    }
  }, [posts]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else if (user.role === 'admin' && isBehalf) {
      fetchPostbyPage(student.student_id);
    } else {
      fetchPostbyPage(user.student_id);
    }
  }, [filters, student, user]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      // console.log(selectedCourses);
    }
  }, [selectedCourses]);

  const handleSearchChange = (text: string) => {
    // eslint-disable-next-line max-len
    // const filtered = dummyData.data.courses.filter((data) => {
    //   // eslint-disable-next-line max-len
    //   if (
    //     data.title.toLowerCase().includes(text.toLowerCase())
    //     || data.code.toLowerCase().includes(text.toLowerCase())
    //   ) {
    //     return true;
    //   }
    //   return false;
    // });
    // setSearchValue(text);
    // setPosts(filtered);
  };

  const openModal = (data: any) => {
    console.log('open modal');
    if (data) {
      setCurrentCourse(data);
      setIsModalOpen(true);
    }
  };
  const openSelectionModal = (data: any) => {
    console.log('open selection modal', data);
    if (data) {
      setCurrentCourse(data);
      setIsSelectionModalOpen(true);
    }
  };
  const ColumnName = (isMobile: boolean) => {
    if (isMobile) {
      return (
        <>
          <th></th>
          <th></th>
        </>
      );
    }
    return (
      <>
        <th>Course Code</th>
        <th>Course Name</th>
        <th>Sections</th>
        <th>Units</th>
        <th></th>
      </>
    );
  };

  const history = useHistory();

  const addCourses = () => {
    setIsConfirmModalOpen(true);
  };
  const getIds = (array: any[], keys_to_keep = ['id']) => array.map((o) => keys_to_keep.reduce((acc: any, curr: any) => {
    acc[curr] = o[curr];
    return acc;
  }, {}));
  const confirmAddCourses = () => {
    if (
      user.role === 'admin'
      || isBehalf
      || student.student_id !== ''
      || selectedCourses.length > 0
    ) {
      let studentId = user.student_id;
      if (user.role === 'admin') {
        studentId = student.student_id;
      }
      setLoading(true);
      // eslint-disable-next-line camelcase
      const course_ids = getIds(selectedCourses);
      axios
        .post(`/api/v1/students/${studentId}/courses`, {
          courses: course_ids,
        })
        .then(() => {
          setIsConfirmModalOpen(false);
          const path = '/courses/myCourses';
          history.push(path);
          setLoading(false);
          // console.log(response);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const fetchPostbyPage = (studentId: string) => {
    setLoading(true);
    // let studentId = user.student_id;
    // if (user.role === 'admin') {
    //   studentId = student.student_id;
    // }
    axios
      // .get('/api/v1/courses')
      .get(`/api/v1/students/${studentId}/courses/offer?termId=${termID}`)
      .then((res) => {
        if (
          res.data.data.courses != null
          && res.data.data.courses.length != 0
        ) {
          res.data.data.courses.forEach((ele: any, index: number) => {
            res.data.data.courses[index].isSelected = false;
          });
        }
        setPosts(res.data.data.courses);
        setIsItem(false);
        if (!res.data.courses) {
          setIsItem(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    // if (dummyData.data.courses != null) {
    //   if (dummyData.data.courses.length != 0) {
    //     dummyData.data.courses.forEach((ele: any, index: number) => {
    //       dummyData.data.courses[index].isSelected = false;
    //     });
    //   }
    // }
    // setPosts(dummyData.data.courses);
    // console.log(posts);
    // setIsItem(false);
    // setLoading(false);
  };
  const closeTest = (courseId: any, section: any) => {
    toggleSelect(courseId, section);
  };
  return (
    <>
      <CourseSelectModal
        isOpen={isSelectionModalOpen}
        setOpen={setIsSelectionModalOpen}
        currentCourse={currentCourse}
        closeTest={closeTest}
      />
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        setOpen={setIsConfirmModalOpen}
        selectedCourses={selectedCourses}
        confirmAddCourses={confirmAddCourses}
      />
      <Row>
        <Col>
          <Breadcrumb path={test} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FlexBox space={2}>
            <h2 style={{ marginRight: 'auto', marginBottom: 0 }}>
              Enroll Courses
            </h2>
          </FlexBox>
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <Card style={{ marginTop: '0px' }}>
            <h3>Select Courses to Enroll</h3>
            <Subtitle style={{ marginBottom: '0px' }}>
              {termName || 'N/A'}
            </Subtitle>
            <InputContainer
              as="form"
              onSubmit={() => {}}
              style={{ width: '100%', margin: '16px 0px' }}>
              <Input
                id="search-input"
                name="searchInput"
                // eslint-disable-next-line max-len
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchChange(e.target.value)
                }
                placeholder="Find courses..."
                value={searchValue}
                style={{ width: 'inherit', padding: '6px 12px' }}
              />
              <InputIconContainer onClick={(e: any) => e.preventDefault()}>
                <Icon name="search" status={Status.default} />
              </InputIconContainer>
            </InputContainer>
            <Table hoverable>
              <thead>
                <tr>{ColumnName(isMobile)}</tr>
              </thead>
              <tbody>
                {!loading && posts ? (
                  posts.map((data, key) => {
                    if (!isMobile) {
                      return (
                        <tr
                          key={key}
                          style={
                            // eslint-disable-next-line max-len
                            data.isSelected
                              ? { backgroundColor: theme.color.primaryLight }
                              : { backgroundColor: '#FFFFFF' }
                          }
                          onClick={() => openSelectionModal(data)}>
                          <td>
                            <StyledText
                              textSize={TextSize.default}
                              // status={TextStatus.disabled}
                            >
                              {data.code}
                            </StyledText>
                          </td>
                          <td>
                            {data.short_name}
                            {data.isSelected && data.sections && (
                              <p style={{ color: colorName.primary }}>
                                Selected Section:{' '}
                                {data.sections.map((sect: any) => (sect.isSelected ? sect.name : ''))}
                              </p>
                            )}
                          </td>
                          <td>{data.sections ? data.sections.length : 0}</td>
                          <td>{data.units}</td>
                          <td>
                            {!data.isSelected && data.sections?.length === 1 && (
                              <ClickableIcon
                                name="plus-s"
                                title="Select"
                                style={{
                                  color: colorName.primary,
                                  alignItems: 'center',
                                }}
                                status={Status.primary}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleSelect(data.id, data.sections[0].name);
                                }}
                              />
                            )}
                            {!data.isSelected && !data.sections && (
                              <ClickableIcon
                                name="plus-s"
                                title="Select"
                                style={{
                                  color: colorName.primary,
                                  alignItems: 'center',
                                }}
                                status={Status.primary}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleSelect(data.id, '');
                                }}
                              />
                            )}
                            {!data.isSelected && data.sections?.length > 1 && (
                              <ClickableIcon
                                name="plus-s"
                                title="Select"
                                style={{
                                  color: colorName.primary,
                                  alignItems: 'center',
                                }}
                                status={Status.primary}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openSelectionModal(data);
                                }}
                              />
                            )}
                            {data.isSelected && (
                              <ClickableIcon
                                name="x-s"
                                color={theme.color.textDisabled}
                                title="Deselect"
                                style={{
                                  color: theme.color.textDisabled,
                                  alignItems: 'center',
                                }}
                                status={Status.primary}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleSelect(
                                    data.id,
                                    String(getSectionName(data)),
                                  );
                                }}
                              />
                            )}
                            {/* <Icon ml={0.5} size={1.5} name="plus-s" />Select */}
                          </td>
                        </tr>
                      );
                    }

                    return (
                      <tr key={data.id}>
                        <td onClick={() => openModal(data)}>
                          <RowStyled>
                            <StyledText style={{ fontWeight: 'bold' }}>
                              {data.short_name}
                            </StyledText>
                            <StyledText status={TextStatus.muted}>
                              {data.code}
                            </StyledText>
                            <StyledText status={TextStatus.muted}>
                              {data.units} Units ·{data.sections.length}{' '}
                              Sections
                            </StyledText>
                          </RowStyled>
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          {/* <ClickableIcon
                            name="plus-s"
                            status={Status.primary}
                            onClick={() => openModal(data)}
                          /> */}
                          {!data.isSelected && data.sections?.length === 1 && (
                            <ClickableIcon
                              name="plus-s"
                              title="Select"
                              style={{
                                color: colorName.primary,
                                alignItems: 'center',
                              }}
                              status={Status.primary}
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleSelect(data.id, data.sections[0].name);
                              }}
                            />
                          )}
                          {!data.isSelected && !data.sections && (
                            <ClickableIcon
                              name="plus-s"
                              title="Select"
                              style={{
                                color: colorName.primary,
                                alignItems: 'center',
                              }}
                              status={Status.primary}
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleSelect(data.id, '');
                              }}
                            />
                          )}
                          {!data.isSelected && data.sections?.length > 1 && (
                            <ClickableIcon
                              name="plus-s"
                              title="Select"
                              style={{
                                color: colorName.primary,
                                alignItems: 'center',
                              }}
                              status={Status.primary}
                              onClick={(e) => {
                                e.stopPropagation();
                                openSelectionModal(data);
                              }}
                            />
                          )}
                          {data.isSelected && (
                            <ClickableIcon
                              name="x-s"
                              color={theme.color.textDisabled}
                              title="Deselect"
                              style={{
                                color: theme.color.textDisabled,
                                alignItems: 'center',
                              }}
                              status={Status.primary}
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleSelect(
                                  data.id,
                                  String(getSectionName(data)),
                                );
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <div></div>
                )}
              </tbody>
            </Table>
            {!loading ? (
              <div></div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: theme.spacing.x2,
                }}>
                <Icon
                  name="spinner"
                  spin
                  size={4}
                  color={theme.color.textDisabled}
                />
                {/* <CircularProgress disableShrink /> */}
              </div>
            )}
          </Card>
        </Col>
        <Col sm={4}>
          <Card style={{ marginTop: '0px' }}>
            {/* <h5>No coursed selected</h5>
            <Subtitle>Units</Subtitle> */}
            {/* style={{ marginBottom: _theme.spacing.x2 }} */}

            <FlexBox
              alignItems="flex-start"
              style={
                selectedCourses.length !== 0
                  ? { paddingBottom: '16px' }
                  : { paddingBottom: '0px' }
              }>
              <FlexGrow grow={2}>
                {selectedCourses.length == 0 ? (
                  <h3>No Courses Selected</h3>
                ) : (
                  <h3>{selectedCourses.length} Course(s) Selected</h3>
                )}
                <Subtitle>
                  {selectedCourses.reduce((a, b) => a + b.units, 0)} Units
                </Subtitle>
              </FlexGrow>
              <FlexGrow
                grow={0}
                style={{
                  marginLeft: 'auto',
                  textAlign: 'right',
                  alignSelf: 'center',
                }}>
                <FlexBox direction="column" alignItems="flex-end">
                  <Button
                    disabled={selectedCourses.length < 1}
                    onClick={() => {
                      addCourses();
                    }}
                    status={Status.primary}>
                    Next
                    <Icon mr={1} name="chevron-right-s" />
                  </Button>
                </FlexBox>
              </FlexGrow>
            </FlexBox>

            <Table>
              <TBody>
                {selectedCourses.map((data, key) => {
                  if (!isMobile) {
                    return (
                      <tr key={key}>
                        <td
                          style={{ paddingBottom: '16px', paddingTop: '16px' }}>
                          {data.code}/{data.short_name}
                          <Subtitle style={{ margin: '0px' }}>
                            {data.sections
                              && data.sections[0].schedule
                              && data.sections[0].schedule.map((days: any) => [
                                `${getShortDay(days.day)} ${days.start}-${
                                  days.end
                                }`,
                                <br />,
                              ])}
                            <StyledText status={TextStatus.muted}>
                              {data.sections && data.sections[0].room.name} ·{' '}
                              {data.sections
                                && data.sections[0].lecturers
                                && data.sections[0].lecturers.map(
                                  (lecturer: any, i: any, arr: any) => {
                                    if (arr.length - 1 === i) {
                                      return [`${lecturer}`];
                                    }
                                    return [`${lecturer} · `];
                                  },
                                )}
                            </StyledText>
                          </Subtitle>
                          <Subtitle style={{ margin: '0px' }}>
                            {data.units} Units ·{' '}
                            {data.sections && data.sections[0].seat == 0 ? (
                              <>
                                <StyledText status={TextStatus.primary}>
                                  {data.sections[0].seat}/
                                  {data.sections[0].capacity} Seats Left
                                </StyledText>
                                <StyledText
                                  status={TextStatus.muted}
                                  style={{ paddingLeft: '8px' }}>
                                  (add to waitlist)
                                </StyledText>
                              </>
                            ) : (
                              <StyledText
                                status={TextStatus.muted}
                                style={{ paddingLeft: '8px' }}>
                                {data.sections ? data.sections[0].seat : 'N'}/
                                {data.sections
                                  ? data.sections[0].capacity
                                  : 'A'}{' '}
                                Seats Left
                              </StyledText>
                            )}
                          </Subtitle>
                        </td>
                        <td
                          style={{
                            paddingBottom: '16px',
                            paddingTop: '16px',
                            verticalAlign: 'middle',
                          }}>
                          <Icon
                            ml={0.5}
                            size={3}
                            name="x-s"
                            onClick={() => toggleSelect(data.id, '')}
                          />
                        </td>
                      </tr>
                    );
                  }

                  return (
                    <tr key={data.id}>
                      <td
                        style={{
                          paddingBottom: '16px',
                          paddingTop: '16px',
                          verticalAlign: 'middle',
                        }}>
                        <RowStyled>
                          <StyledText style={{ fontWeight: 'bold' }}>
                            {data.short_name}
                          </StyledText>
                          <StyledText status={TextStatus.muted}>
                            {data.code}
                          </StyledText>
                          <StyledText status={TextStatus.muted}>
                            {data.units} Units ·{data.sections.length} Sections
                          </StyledText>
                        </RowStyled>
                      </td>
                      <td
                        style={{
                          paddingBottom: '16px',
                          paddingTop: '16px',
                          verticalAlign: 'middle',
                        }}>
                        <Icon
                          ml={0.5}
                          size={3}
                          name="x-s"
                          onClick={() => toggleSelect(data.id, '')}
                        />
                      </td>
                    </tr>
                  );
                })}
              </TBody>
            </Table>
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default AddCoursesPage;
