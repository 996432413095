import axios from 'axios';
import React, { useMemo, useState, useEffect } from 'react';

import { useScreenClass } from 'react-grid-system';
import styled from 'styled-components';
import theme from '../styles/theme';
import Status from '../types/enum/Status';
import Button from './Button';
import FlexBox, { FlexGrow } from './FlexBox';
import Icon, { ClickableIcon } from './Icon';
import Input from './Input';
import Modal from './Modal';
import { Col, Row } from './StyledGridSystem';
import { RightAlign } from './UtilsComponents';

type StudentDeleteModalProps = {
  isOpen?: boolean;
  setOpen: (x: boolean) => void;
  currentStudent: { [key: string]: any; };
  sectionId: string | null;
  onDeleteComplete?: () => void
  onDeleteError?: (err: any) => void
}

const wait = async (ms: number) => new Promise((resolve, reject) => {
  setTimeout(resolve, ms);
});

const StudentDeleteModal = ({
  isOpen,
  setOpen,
  currentStudent,
  sectionId,
  onDeleteError,
  onDeleteComplete,
}: StudentDeleteModalProps): JSX.Element => {
  const screenClass = useScreenClass();
  const [deleteConfirmation, setDeleteconfirmation] = useState<boolean>(true);
  const [deletePasswordInput, setDeletePasswordInput] = useState<string>('');
  const [isOpenComplete, setOpenComplete] = useState<boolean>(false);
  const [isOpenError, setOpenError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const handleDeleteStudent = async (regId: string) => {
    try {
      setLoading(true);
      const res = await axios.delete(`/api/v1/students/${regId}/courses/${sectionId}`);
      // const res = await axios.delete(`/api/v1/students/${studentId}/Students/${regId}`);
      setOpenComplete(true);
    } catch (err) {
      setOpenError(true);
      console.error(err);
      setError(err);
    }
    setOpen(false);
    setLoading(false);
  };

  const onComplete = () => {
    setOpenComplete(false);
    onDeleteComplete?.();
  };

  const onError = () => {
    setOpenError(false);
    onDeleteError?.(error);
  };

  useEffect(() => {
    if (isOpen) {
      setDeletePasswordInput('');
    }
  }, [isOpen]);

  const deletePassword = useMemo(() => {
    const verb = 'remove';
    return `${verb} ${currentStudent.first_name}`;
  }, [currentStudent]);

  return (
    <>
      <Modal isOpen={isOpen || false} setOpen={setOpen}>
        <FlexBox>
          <FlexGrow>
              <h3>Remove Student</h3>
          </FlexGrow>
          <ClickableIcon name='x-s' onClick={() => setOpen(false)} />
        </FlexBox>
        {/* <p style={{ textAlign: 'center' }}>
          <img src="/alert-warning.svg" alt="alert warning"/>
        </p> */}
          <p>
            Are you sure you want to remove the student
            "<b>{currentStudent.first_name} {currentStudent.last_name}</b>" from section?
            This cannot be undone.
          </p>
        <p>
          To confirm, type "<b>{deletePassword}</b>" in this box.
        </p>
        <Input
          isFullWidth={true}
          value={deletePasswordInput}
          onInput={(e: any) => {
            setDeletePasswordInput(e.target.value);
          }}
        />
        <RightAlign style={{ marginTop: theme.spacing.x2 }}>
          <Button
            isOutline
            onClick={() => {
              setOpen(false);
            }}
            status={Status.default}>
            Cancel
          </Button>
          <Button
            disabled={isLoading || deletePasswordInput !== deletePassword}
            status={Status.primary}
            onClick={() => handleDeleteStudent(currentStudent.id)}
          >
            {isLoading && <>
              <Icon mr={1} name="spinner" spin />
              Removing...
            </>}
            {!isLoading && <>
              <Icon mr={1} name="trash" />
              Remove Student
            </>}
          </Button>
        </RightAlign>
      </Modal>

      <Modal isOpen={isOpenComplete} setOpen={setOpenComplete} onClose={onComplete} customStyle={{ content: { textAlign: 'center' } }}>
        <img src="/alert-success-soft.svg" alt="alert warning" style={{ margin: 'auto' }} />
        <h3>Student Deleting Complete</h3>
            <p>
              The student
              "<b>{currentStudent.first_name} {currentStudent.last_name}</b>" has been removed.
            </p>
        <Button status={Status.primary} onClick={onComplete}>Done</Button>
      </Modal>

      <Modal isOpen={isOpenError} setOpen={setOpenError} onClose={onError} customStyle={{ content: { textAlign: 'center' } }}>
        <img src="/alert-warning.svg" alt="alert warning" style={{ margin: 'auto' }} />
        <h3>Student Delete Error</h3>
        <p>
          An error occured while deleting the Student
          "<b>{currentStudent.first_name} {currentStudent.last_name}</b>".
          Please try again or contact us if the problem persists.
        </p>
        <Button status={Status.primary} onClick={onError}>Done</Button>
      </Modal>
    </>
  );
};

export default StudentDeleteModal;
