import ReactModal from 'react-modal';
import theme from '../styles/theme';

type Props = {
  isOpen: boolean;
  setOpen: any;
  onDismiss?: () => void;
  onClose?: (courseId: any, section: any) => void;
  children?: any;
  customStyle?: any;
  overlayClassName?: any;
};

function Modal({
  isOpen,
  setOpen,
  onDismiss,
  onClose,
  children = null,
  customStyle,
}: Props): JSX.Element {
  return (
    <>
      <ReactModal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={() => {
          setOpen(false);
          onClose?.('', '');
        }}
        closeTimeoutMS={500}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            // width: '100%',
            // height: '100%',
            zIndex: 100,
            paddingLeft: theme.spacing.x3,
            paddingRight: theme.spacing.x3,
            overflow: 'auto',
            ...customStyle?.overlay,
          },
          content: {
            maxWidth: 400,
            margin: `calc(${theme.navbarHeight} + ${theme.spacing.x3}) auto ${theme.spacing.x4}`,
            position: 'static',
            padding: theme.spacing.x2,
            borderRadius: theme.spacing.xs,
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
            ...customStyle?.content,
          },
        }}>
        {children}
      </ReactModal>
    </>
  );
}

export default Modal;
