import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Icon, { ClickableIcon } from './Icon';
import { NullStateText } from './Text';

const FontCourseNameStyled = styled.div`
  font-weight: bold;
  max-width: 121px;
  /* line-height: 18px; */
`;

const SecStyled = styled.div`
  color: ${({ theme }) => theme.color.textSecondary}!important;
  white-space: nowrap;
  /* line-height: 18px; */
`;

const TextStyled = styled.td`
  color: ${({ theme }) => theme.color.textSecondary}!important;
  vertical-align: baseline;
`;

const CanvasStyledMobile = styled.td`
  vertical-align: top;
  color: ${({ theme }) => theme.color.textSecondary}!important;
`;

type Props = {
  timestart: string;
  timeend: string;
  location: any;
  courseName: string;
  sec: string;
  instructor: any;
  link: string;
  isMobile: boolean;
};

const TimeTableItem = ({
  timestart,
  timeend,
  location,
  courseName,
  instructor,
  link,
  sec,
  isMobile,
}: Props): JSX.Element => {
  let courseTable: JSX.Element = <div />;
  const start = timestart.split(' ');
  const end = timeend.split(' ');
  if (!isMobile) {
    courseTable = (
      <>
        <tr>
          <TextStyled>
            {/* {start[0]}–{end[0]} */}
            {timestart !== '' ? (
              <div>
                {timestart}-{timeend}
              </div>
            ) : (
              <NullStateText>N/A</NullStateText>
            )}
          </TextStyled>
          <TextStyled>
            {location && location?.length > 0 ? (
              location.map((section: any) => <>{section.room.name}</>)
            ) : (
              <NullStateText>N/A</NullStateText>
            )}
          </TextStyled>
          <td>
            <FontCourseNameStyled>{courseName}</FontCourseNameStyled>
            <SecStyled>{sec}</SecStyled>
          </td>
          <TextStyled>
            {instructor
              && instructor?.length > 0
              && instructor.map((section: any) => (section.lecturers?.length > 0 ? (
                section.lecturers.map((lecturer: any) => (
                    <>{lecturer?.name}</>
                ))
              ) : (
                  <>
                    <NullStateText>N/A</NullStateText>
                  </>
              )))}
          </TextStyled>
          <TextStyled>
            {link ? (
              <a href={link} target="_blank" rel="noreferrer">
                Canvas
                <Icon ml={0.5} size={2} name="external-link" />
              </a>
            ) : (
              <NullStateText>N/A</NullStateText>
            )}
          </TextStyled>
        </tr>
      </>
    );
  } else {
    courseTable = (
      <>
        <tr>
          <TextStyled>
            <SecStyled>
              {timestart !== '' ? (
                <div>
                  {timestart}-{timeend}
                </div>
              ) : (
                <NullStateText>N/A</NullStateText>
              )}
            </SecStyled>
            {location && location?.length > 0 ? (
              location.map((section: any) => {
                <TextStyled>{section.room.name}</TextStyled>;
              })
            ) : (
              <NullStateText>N/A</NullStateText>
            )}
          </TextStyled>
          <td>
            <FontCourseNameStyled>{courseName}</FontCourseNameStyled>
            <SecStyled>{sec}</SecStyled>
            {/* <SecStyled>
              {instructor.map((name: string) => <div>{name}</div>)}
            </SecStyled> */}
          </td>
          <CanvasStyledMobile>
            <a href={link} target="_blank" rel="noreferrer">
              <ClickableIcon
                ml={0.5}
                size={2}
                name="canvas"
                style={{ opacity: link ? 1 : 0.2 }}
              />
              {/* Canvas
              <Icon ml={0.5} size={2} name="external-link" /> */}
            </a>
          </CanvasStyledMobile>
        </tr>
      </>
    );
  }
  return <>{courseTable}</>;
};

export default TimeTableItem;
