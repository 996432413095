import axios from 'axios';
import React, { useMemo, useState, useEffect } from 'react';

import { useScreenClass } from 'react-grid-system';
import styled from 'styled-components';
import theme from '../styles/theme';
import Status from '../types/enum/Status';
import Button from './Button';
import FlexBox, { FlexGrow } from './FlexBox';
import Icon, { ClickableIcon } from './Icon';
import Input from './Input';
import Modal from './Modal';
import { Col, Row } from './StyledGridSystem';
import { RightAlign } from './UtilsComponents';

type CourseDropModalProps = {
  isOpen?: boolean;
  setOpen: (x: boolean) => void;
  selectedCourses: Array<any>;
  // inDroppingPeriod: boolean;
  // currentCourse: { [key: string]: any; };
  // showSections?: boolean;
  // handleDropCourse: (regId: string) => void
  confirmAddCourses: () => void;
  // onDropError?: (err: any) => void
}

const wait = async (ms: number) => new Promise((resolve, reject) => {
  setTimeout(resolve, ms);
});

const ConfirmModal = ({
  isOpen,
  setOpen,
  selectedCourses,
  confirmAddCourses,
}: CourseDropModalProps): JSX.Element => {
  const screenClass = useScreenClass();
  const [dropConfirmation, setDropconfirmation] = useState<boolean>(true);
  const [dropPasswordInput, setDropPasswordInput] = useState<string>('');
  const [isOpenComplete, setOpenComplete] = useState<boolean>(false);
  const [isOpenError, setOpenError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const handleDropCourse = async (regId: string) => {
    try {
      setLoading(true);
      // alert(`/api/v1/grading/dropCourse?course_reg_id=${regId}`);
      // await wait(800);
      const res = await axios.post(`/api/v1/grading/dropCourse?course_reg_id=${regId}`);
      setOpenComplete(true);
    } catch (err) {
      setOpenError(true);
      console.error(err);
      setError(err);
    }
    setOpen(false);
    setLoading(false);
  };

  const onComplete = () => {
    setOpenComplete(false);
    // onDropComplete?.();
  };

  const onError = () => {
    setOpenError(false);
    // onDropError?.(error);
  };

  useEffect(() => {
    if (isOpen) {
      setDropPasswordInput('');
    }
  }, [isOpen]);

  // const dropPassword = useMemo(() => {
  //   const verb = inDroppingPeriod ? 'drop' : 'withdraw';
  //   return `${verb} ${currentCourse.code}`;
  // }, [currentCourse, inDroppingPeriod]);

  return (
    <>
      <Modal isOpen={isOpen || false} setOpen={setOpen}>
        <FlexBox>
          <FlexGrow>
            <h3>Enrolling Course(s)</h3>
          </FlexGrow>
          <ClickableIcon name='x-s' onClick={() => setOpen(false)} />
        </FlexBox>
        {/* <p style={{ textAlign: 'center' }}>
          <img src="/alert-warning.svg" alt="alert warning"/>
        </p> */}
          <p>
          Please review each enrolling course. This action cannot be undone.
            </p>
            {selectedCourses.map((c:any) => (
              <li>
                {`${c.code} ${c.short_name}`}
              </li>))}
            {/* "<b>{currentCourse.code} {currentCourse.title}</b>"? */}
            <p>
          </p>
        <p>
        To confirm, please click 'Enroll course' for enrollment.
        </p>
        <RightAlign style={{ marginTop: theme.spacing.x2 }}>
          <Button
            isOutline
            onClick={() => {
              setOpen(false);
            }}
            status={Status.default}>
            Cancel
          </Button>
          <Button
            // disabled={isLoading || dropPasswordInput !== dropPassword}
            status={Status.primary}
            onClick={() => { confirmAddCourses(); }}
          >
            {isLoading && <>
              <Icon mr={1} name="spinner" spin />
              Enrolling...
            </>}
            {!isLoading && <>
              Enroll course
            </>}
          </Button>
        </RightAlign>
      </Modal>

      <Modal isOpen={isOpenComplete} setOpen={setOpenComplete} onClose={onComplete} customStyle={{ content: { textAlign: 'center' } }}>
        <img src="/alert-success-soft.svg" alt="alert warning" style={{ margin: 'auto' }} />
        <h3>Course Enroll Complete</h3>
            <p>
              The courses has been enrolled.
            </p>
        <Button status={Status.primary} onClick={onComplete}>Done</Button>
      </Modal>

      <Modal isOpen={isOpenError} setOpen={setOpenError} onClose={onError} customStyle={{ content: { textAlign: 'center' } }}>
        <img src="/alert-warning.svg" alt="alert warning" style={{ margin: 'auto' }} />
        <h3>Course Enroll Error</h3>
        <p>
          An error occured while enrolling the course.
          Please try again or contact us if the problem persists.
        </p>
        <Button status={Status.primary} onClick={onError}>Done</Button>
      </Modal>
    </>
  );
};

export default ConfirmModal;
