// @ts-nocheck
// FIXME: typescript errors

import React from 'react';
import { darken } from 'polished';
import styled, { keyframes, css } from 'styled-components';
import Status from '../types/enum/Status';

type StyledIconProps = {
  size?: number;
  fixedWidth?: boolean;
  mr?: number;
  ml?: number;
  rotate?: number;
  color?: string;
  status?: Status;
  spin?: boolean;
} & React.HTMLProps<HTMLSpanElement>;

const spinAnim = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const StyledIcon = styled.span<StyledIconProps>`
  display: inline-block;
  ${({ fixedWidth }) => fixedWidth
    && css`
      text-align: center;
      width: 1em;
    `}

  font-size: ${({ theme, size }) => theme.spacing.base * (size || 2)}px;

  margin-right: ${({ theme, mr }) => theme.spacing.base * (mr || 0)}px;
  margin-left: ${({ theme, ml }) => theme.spacing.base * (ml || 0)}px;
  text-decoration: none !important;

  ${({ rotate }) => rotate
    && css`
      transform: rotate(${rotate}deg);
    `}

  ${({ theme, status, color }) => (color || status)
    && css`
      color: ${color || theme.color[status || Status.default]};
    `}

  ${({ spin }) => spin
    && css`
      animation: ${spinAnim} 1s linear infinite;
    `} /* &:hover {
    text-decoration: none !important;
  } */
`;

type Props = {
  name: string;
} & StyledIconProps;

const Icon = ({
  name = 'atom',
  size,
  fixedWidth,
  ml,
  mr,
  status,
  rotate,
  color,
  spin,
  ...props
}: Props): JSX.Element => (
  <StyledIcon
    className={`icon-${name}`}
    fixedWidth={fixedWidth}
    size={size}
    mr={mr}
    ml={ml}
    rotate={rotate}
    color={color}
    status={status}
    spin={spin}
    {...props}
  />
);

Icon.defaulltProps = {
  size: 2,
};

const StyledClickableIcon = styled.button`
  background: none;
  border: none;
  padding: ${({ theme }) => theme.spacing.xs};
  margin: -${({ theme }) => theme.spacing.xs};
  display: inline-flex;

  outline: none;
  cursor: pointer;
  color: inherit;

  margin-right: calc(
    -${({ theme }) => theme.spacing.xs} + ${({ theme, mr }) => theme.spacing.base * (mr || 0)}px
  );
  margin-left: calc(
    -${({ theme }) => theme.spacing.xs} + ${({ theme, ml }) => theme.spacing.base * (ml || 0)}px
  );

  &:hover {
    background-color: ${({ theme }) => theme.color.lightBackground};
    border-radius: 9999px;
  }

  &:active {
    background-color: ${({ theme }) => darken(0.05, theme.color.lightBackground)};
  }

  &:focus-visible {
    outline: 2px #000 solid;
    outline-style: auto;
  }
`;

export type ClickableIconProps = {
  style?: unknown;
  title?: string;
  onClick: (arg0: Event) => void;
} & Props;

export const ClickableIcon = ({
  ml,
  mr,
  title,
  onClick,
  style,
  ...props
}: Props): JSX.Element => (
  <StyledClickableIcon onClick={onClick} mr={mr} ml={ml} style={style}>
    <Icon style={{ margin: '0px 4px' }} {...props} />
    {title}
  </StyledClickableIcon>
);

ClickableIcon.defaulltProps = {
  title: '',
};

export default Icon;
