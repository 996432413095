/* eslint-disable camelcase */
import { useMemo, useState, useEffect } from 'react';
import { useScreenClass } from 'react-grid-system';

import styled from 'styled-components';
import axios from 'axios';
import _theme from '../styles/theme';
import Card from './Card';
import FlexBox, { FlexGrow } from './FlexBox';
import { RightAlign } from './UtilsComponents';
import Status from '../types/enum/Status';
import Button from './Button';
import Icon, { ClickableIcon } from './Icon';
import Table from './Table';
import NullStateTR from './NullStateTr';
import { StyledText, TextStatus } from './Text';
import EditSectionModal from './EditSectionModal';
import { SectionContent } from '../filter/FilterValue';
import SnackBar from './SnackBar';

const SubHeader = styled.div<{ isMobile?: boolean }>`
  font-size: ${({ isMobile }) => (isMobile ? '0.75rem' : '1rem')};
  display: flex;
  justify-content: flex-start;
`;
const Greytext = styled.div`
  color: ${({ theme }) => theme.color.textSecondary}!important;
  white-space: nowrap;
`;
type ViewMode = 'view' | 'edit';
type CourseSectionProps = {
  defViewMode: ViewMode;
  courseId: string;
  semester: string;
  reloadParent: any;
};
const CourseSectionInfo = ({
  defViewMode,
  courseId,
  semester,
  reloadParent,
}: CourseSectionProps) => {
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  // Loading text on "Enrolled Courses"
  const [loading, setLoading] = useState<boolean>(true);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<ViewMode>(defViewMode);
  const [mode, setMode] = useState<string>();
  const [currentSectionId, setCurrentSectionId] = useState<string>('');
  const [currentCourseId, setCurrentCourseId] = useState<string>('');
  const [currentTermId, setCurrentTermId] = useState<string>();
  const [allSections, setAllSections] = useState<Array<SectionContent>>([]);
  const [terms, setTerms] = useState<Array<{ id: string; name: string }>>([]);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [courseInfo, setCourseInfo] = useState({
    id: '',
    term: '',
  });
  useEffect(() => {
    fetchCourseInfo();
    fetchAllSections();
    fetchTerms();
  }, []);
  useEffect(() => {
    findTermId(semester);
  }, [semester, terms]);
  useEffect(() => {
    fetchAllSections();
  }, [currentTermId]);
  const fetchTerms = () => {
    setLoading(true);
    axios
      .get('/api/v1/courses/terms')
      .then((res) => {
        if (res.data.data.terms) {
          setTerms(res.data.data.terms);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const fetchAllSections = () => {
    setLoading(true);
    axios
      .get(`/api/v1/courses/${courseId}/semesters/${currentTermId}/sections`)
      .then((res) => {
        if (res.data.data.sections) {
          setAllSections(res.data.data.sections);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const fetchCourseInfo = () => {
    setLoading(true);
    axios
      .get(`/api/v1/courses/${courseId}`)
      .then((res) => {
        if (res.data.data.course) {
          setCourseInfo(res.data.data.course);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const openEditModal = (
    course_id: string,
    section_id: string,
    mode: string,
  ) => {
    setCurrentCourseId(course_id);
    setCurrentSectionId(section_id);
    setMode(mode);
    setIsEditModalOpen(true);
  };
  const findTermId = (semester: string) => {
    if (semester !== '' && terms && terms.length > 0) {
      setCurrentTermId(terms.find((elem) => elem.name === semester)?.id);
    }
  };
  return (
    <>
      <SnackBar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        type="success"
        message={`${
          mode === 'create' ? 'Created' : 'Edited'
        } section successfully!`}
      />
      {isEditModalOpen && (
        <EditSectionModal
          isOpen={isEditModalOpen}
          setOpen={setIsEditModalOpen}
          sectionId={currentSectionId}
          courseId={currentCourseId}
          termId={currentTermId}
          mode={mode}
          onEditComplete={() => {
            setSnackbarOpen(true);
            fetchAllSections();
          }}
          onEditError={() => {
            reloadParent.bind(this);
          }}
        />
      )}
      <Card style={{ margin: '24px 0px' }}>
        {/* <form onSubmit={handleSubmit(onSubmitClicked, onError)}> */}
        <FlexBox
          alignItems="flex-start"
          style={{
            marginBottom: _theme.spacing.x2,
          }}>
          <FlexGrow>
            <h3 style={{ margin: 0 }}>Sections</h3>
            <Greytext>
              <SubHeader isMobile={isMobile}>
                {semester != '' ? semester : 'N/A'}
              </SubHeader>
            </Greytext>
          </FlexGrow>
          <FlexGrow ml-auto style={{ marginLeft: 'auto' }}>
            <RightAlign>
              {viewMode === 'view' && (
                <>
                  <Button
                    onClick={() => {
                      setViewMode('edit');
                    }}
                    isOutline
                    status={Status.primary}
                    style={{ padding: '4px 12px' }}>
                    <Icon name="pencil-edit" mr={0.5} />
                    Edit
                  </Button>
                </>
              )}
              {viewMode === 'edit' && (
                <>
                  <Button
                    type="submit"
                    onClick={() => {
                      setViewMode('view');
                    }}
                    status={Status.primary}
                    backgroundColor="#CB092B"
                    style={{ padding: '4px 12px', marginLeft: '8px' }}>
                    <Icon name="check-mark" mr={0.5} />
                    Done
                  </Button>
                </>
              )}
            </RightAlign>
          </FlexGrow>
        </FlexBox>
        <FlexBox
          alignItems="center"
          style={{
            marginBottom: _theme.spacing.x2,
          }}>
          <FlexGrow>
            <h5>Description</h5>
          </FlexGrow>
        </FlexBox>
        <FlexBox
          alignItems="center"
          style={{
            marginBottom: _theme.spacing.x2,
          }}>
          <FlexGrow>
            <Table>
              <thead>
                <tr>
                  <th>Sect.</th>
                  <th>Time/Location</th>
                  <th>Seats Left</th>
                  {viewMode === 'edit' && <th></th>}
                </tr>
              </thead>
              <tbody>
                {allSections?.map?.((data: any, i: any) => {
                  let SeatLeft: JSX.Element = <>{data.seat}</>;
                  if (data.capacity - data.seat === 0) {
                    SeatLeft = (
                      <StyledText status={TextStatus.danger}>
                        {data.capacity - data.seat}/{data.capacity}
                      </StyledText>
                    );
                  } else {
                    SeatLeft = (
                      <StyledText>
                        {data.capacity - data.seat}/{data.capacity}
                      </StyledText>
                    );
                  }
                  return (
                    <tr style={{ verticalAlign: 'baseline' }} key={i}>
                      <td>{data.name}</td>
                      <td>
                        <div>
                          {data.schedule?.map((time: any) => {
                            const Time: string = `${time.start} ${time.end}`;
                            const [start, startPeriod, end, endPeriod] = Time.split(' ');
                            return (
                              <>
                                <div>
                                  {time.day.slice(0, 3)} {start}-{end}
                                </div>
                              </>
                            );
                          })}
                          <div>
                            <StyledText status={TextStatus.muted}>
                              {data.room.name}
                            </StyledText>
                          </div>
                        </div>
                        <div>
                          <StyledText status={TextStatus.muted}>
                            {data.lecturers
                              && data.lecturers.map((name: string) => (
                                <div>{name}</div>
                              ))}
                          </StyledText>
                        </div>
                      </td>
                      <td>{SeatLeft}</td>
                      {viewMode === 'edit' && (
                        <td>
                          <ClickableIcon
                            status={Status.primary}
                            name="pencil-edit"
                            onClick={() => openEditModal(courseId, data.id, 'edit')
                            }
                          />
                        </td>
                      )}
                    </tr>
                  );
                })}
                {(!allSections || allSections.length === 0) && (
                  <NullStateTR>No sections.</NullStateTR>
                )}
              </tbody>
            </Table>
          </FlexGrow>
        </FlexBox>
        {viewMode === 'edit' && (
          <FlexBox
            alignItems="center"
            style={{
              marginBottom: _theme.spacing.x2,
            }}>
            <FlexGrow>
              <ClickableIcon
                title="Add Section"
                style={{ alignItems: 'center', color: _theme.color.primary }}
                status={Status.primary}
                name="plus-s"
                onClick={() => openEditModal(courseId, '', 'create')}
              />
            </FlexGrow>
          </FlexBox>
        )}
        {/* </form> */}
      </Card>
    </>
  );
};

export default CourseSectionInfo;
