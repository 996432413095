export const convertToSelect = (
  items: Array<any>,
  labelStr: string,
  valueStr: string,
) => {
  const arrayMod: Array<any> = [];
  items.forEach((item: any) => {
    arrayMod.push({
      label: item[labelStr],
      value: item[valueStr],
      ...item,
    });
  });
  return arrayMod;
};
export default convertToSelect;
