/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from 'react';
import ReactSelect from 'react-select';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ErrorMessage } from '@hookform/error-message';
import styled, { css } from 'styled-components';
import { useScreenClass } from 'react-grid-system';
import MinimalSelect from './MinimalSelect';
import FlexBox, { FlexGrow } from './FlexBox';
import theme from '../styles/theme';
import Card from './Card';
import { StyledText } from './Text';
import Input from './Input';
import TextArea from './TextArea';
import Button from './Button';
import { RightAlign } from './UtilsComponents';
import Icon, { ClickableIcon } from './Icon';
import Status from '../types/enum/Status';
import Modal from './Modal';
import convertToSelect from '../hooks/convertToSelect';

const sharedMinimalStyle = css`å
  width: 100%;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 0px 12px;
  .react-select__menu {
    width: 100%;
    min-width: unset;
    margin-left: -12px;
  }
`;
const StyledMinimalSelect = styled(MinimalSelect)`
  ${sharedMinimalStyle}
`;
type ProgramForm = {
  name: string | undefined;
  program_name: string | undefined;
  degree_type: string | undefined;
  degree_abbr: string | undefined;
  program_abbr: string | undefined;
  description: string | undefined;
};
type ProgramEditModalProps = {
  isOpen?: boolean;
  setOpen?: (x: boolean) => void;
  currentProgram: {
    [key: string]: any;
  };
  onEditComplete?: () => void;
};

const ProgramEditModal = ({
  isOpen,
  setOpen,
  currentProgram,
  onEditComplete,
}: ProgramEditModalProps): JSX.Element => {
  const validationSchema = Yup.object({
    name: Yup.string().required('Short name is required'),
    program_name: Yup.string().required('Full name is required'),
    degree_type: Yup.string().required('Degree Type is required'),
    degree_abbr: Yup.string().required('Degree Abbr is required'),
    program_abbr: Yup.string().required('Program Abbr is required'),
    description: Yup.string().required('Description is required'),
  });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<ProgramForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      name: currentProgram.name,
      program_name: currentProgram.program_name,
      degree_type: currentProgram.degree_type,
      degree_abbr: currentProgram.degree_abbr,
      program_abbr: currentProgram.program_abbr,
      description: currentProgram.description,
    },
  });
  dayjs.extend(utc);
  dayjs.extend(tz);
  const screenClass = useScreenClass();
  const [loading, setLoading] = useState<boolean>(false);
  const watchAllFields = watch();
  const history = useHistory();
  const onSubmitClicked = (data: any) => {
    setLoading(true);
    const editData = {
    };
    axios
      .put(`/api/v1/courses/programs/${currentProgram.id}`, data)
      .then((res) => {
        setLoading(false);
        onEditComplete?.();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const onError = (errors: any, e: any) => console.log('error', errors, e);
  return (
    <>
      <Modal isOpen={!!isOpen} setOpen={setOpen}>
        <FlexBox
          style={{
            marginBottom: theme.spacing.base * 3,
          }}>
          <FlexGrow>
            <h3 style={{ marginRight: 'auto', marginBottom: 0 }}>
              Edit Program
            </h3>
          </FlexGrow>
          <RightAlign>
            <ClickableIcon name="x-s" onClick={() => setOpen?.(false)} />
          </RightAlign>
        </FlexBox>
        {!loading ? (
          <form onSubmit={handleSubmit(onSubmitClicked, onError)}>
            <FlexBox
              alignItems="flex-start"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow>
                <h3>Program Information</h3>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Short Name
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('name')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="name"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Full Name
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('program_name')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="program_name"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Degree Type
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('degree_type')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="degree_type"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Degree Abbr
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('degree_abbr')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="degree_abbr"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Program Abbr
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('program_abbr')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="program_abbr"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Description
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('description')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="description"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              justify="flex-end"
              style={{
                marginTop: theme.spacing.x4,
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow>
                <RightAlign>
                  <Button
                    onClick={() => { setOpen?.(false); onEditComplete?.(); }}
                    isOutline
                    status={Status.primary}
                    style={{ padding: '4px 12px' }}>
                    <Icon name="x-s" mr={0.5} />
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    onClick={() => handleSubmit(onSubmitClicked)}
                    status={Status.primary}
                    backgroundColor="#CB092B"
                    style={{ padding: '4px 12px', marginLeft: '8px' }}>
                    <Icon name="floppy" mr={0.5} />
                    Save
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
          </form>
        ) : (
          <Icon mr={1} name="spinner" spin />
        )}
      </Modal>
    </>
  );
};

export default ProgramEditModal;
