const FONT_PROXIMA_NOVA = 'proxima-nova';
const FONT_SOURCE_SANS_PRO = 'source-sans-pro';
const FONT_INCONSOLATA = 'Inconsolata';

const FONT_SIZE_DEFAULT = '1rem';
const FONT_SIZE_PARAGRAPH = '1rem';
const FONT_SIZE_SMALL = '0.75rem';

const DEFAULT_LINE_HEIGHT = 1.25;
const HEADING_LINE_HEIGHT = 1.25;
const PARAGRAPH_LINE_HEIGHT = 1.5;

const BASE_TYPOGRAPHY_HEADING = `
  font-family: ${FONT_PROXIMA_NOVA};
  font-weight: bold;
  line-height: ${HEADING_LINE_HEIGHT};
`;

const BASE_TYPOGRAPHY_BODY = `
  font-family: ${FONT_SOURCE_SANS_PRO};
  line-height: ${DEFAULT_LINE_HEIGHT};
  // font-weight: normal;
`;

const typography = {
  sizes: {
    default: FONT_SIZE_DEFAULT,
    paragraph: FONT_SIZE_PARAGRAPH,
    small: FONT_SIZE_SMALL,
  },
  headingBase: BASE_TYPOGRAPHY_HEADING,
  bodyBase: BASE_TYPOGRAPHY_BODY,

  heading1: `
    ${BASE_TYPOGRAPHY_HEADING}
    font-size: 2.5rem;
  `,
  heading2: `
    ${BASE_TYPOGRAPHY_HEADING}
    font-size: 1.75rem;
  `,
  heading3: `
    ${BASE_TYPOGRAPHY_HEADING}
    font-size: 1.25rem;
  `,
  heading4: `
    ${BASE_TYPOGRAPHY_HEADING}
    font-size: 1.125rem;
  `,
  heading5: `
    ${BASE_TYPOGRAPHY_HEADING}
    font-size: 1rem;
    text-transform: uppercase;
  `,
  heading6: `
    ${BASE_TYPOGRAPHY_HEADING}
    font-size: 1rem;
  `,
  UIText: `
    ${BASE_TYPOGRAPHY_BODY}
    font-size: ${FONT_SIZE_DEFAULT};    
  `,
  UITextSmall: `
    ${BASE_TYPOGRAPHY_BODY}
    font-size: ${FONT_SIZE_SMALL};
  `,
  paragraph: `
    ${BASE_TYPOGRAPHY_BODY}
    font-size: ${FONT_SIZE_PARAGRAPH};
    line-height: ${PARAGRAPH_LINE_HEIGHT};
  `,
  paragraphCode: `
    font-family: ${FONT_INCONSOLATA};
    font-size: ${FONT_SIZE_DEFAULT};
    // font-weight: normal;
  `,

  defaultLineHeight: DEFAULT_LINE_HEIGHT,
  paragraphLineHeight: PARAGRAPH_LINE_HEIGHT,
};

export default typography;
