import styled from 'styled-components';

type Props = {
  src?: string;
  size?: string;
}

export enum CircleImageSize {
    large = 'large',
    medium = 'medium',
    small = 'small',
 }

const CircleImage = styled.img.attrs<Props>(({
  src,
  size = CircleImageSize.large,
}) => ({
  src,
  size,
}))<Props>`
  border-radius: 9999px;
  overflow: hidden;
  ${({
    size,
  }) => {
    switch (size) {
      case CircleImageSize.large:
        return `
          width: 64px;
          height: 64px;
        `;
      case CircleImageSize.medium:
        return `
          width: 32px;
          height: 32px;
        `;
      case CircleImageSize.small:
        return `
          width: 20px;
          height: 20px;
        `;
      default:
        return `
          width: 64px;
          height: 64px;
        `;
    }
  }}
`;

export default CircleImage;
