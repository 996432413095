import React, { useState } from 'react';
import styled from 'styled-components';

import Dropdown, { DropdownContainer, DropdownItem } from './Dropdown';
import Icon, { ClickableIcon } from './Icon';
import _theme from '../styles/theme';

type ProgramDropdownProps = {
  program: { [key: string]: any };
  openEditModal: (x: any) => void;
};

const ProgramDropdown = ({
  program,
  openEditModal,
}: ProgramDropdownProps) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <DropdownContainer>
      <ClickableIcon
        ml={0.5}
        size={2}
        name="more-vertical"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!isOpen);
        }}
        style={{ color: _theme.color.primary }}
      />
      <Dropdown
        open={isOpen}
        style={{ whiteSpace: 'nowrap' }}
        setOpen={setOpen}
        align="right">
        <DropdownItem
          to="#"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
            openEditModal(program);
          }}>
          <Icon mr={1} name="pencil-edit" /> Edit Program
        </DropdownItem>
      </Dropdown>
    </DropdownContainer>
  );
};

export default ProgramDropdown;
