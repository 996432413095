/* eslint-disable camelcase */
import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import moment from 'moment';
import { useState, useEffect, useMemo } from 'react';
import { useScreenClass } from 'react-grid-system';

import { Controller, useFieldArray, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import styled, { css } from 'styled-components';
import { SectionContent } from '../filter/FilterValue';
import _theme from '../styles/theme';
import Status from '../types/enum/Status';
import ALink from './ALink';
import Button from './Button';
import FlexBox, { FlexGrow } from './FlexBox';
import Icon, { ClickableIcon } from './Icon';
import Input from './Input';
import MinimalSelect from './MinimalSelect';
import Modal from './Modal';
import ScheduleDeleteModal from './ScheduleDeleteModal';
import SectionDeleteModal from './SectionDeleteModal';
import SnackBar from './SnackBar';
import { StyledText } from './Text';
import TimeInput from './TimeInput';

const DayOptions = [
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  { label: 'Saturday', value: 'Saturday' },
  { label: 'Sunday', value: 'Sunday' },
];
const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    height: 32,
    minHeight: 32,
    padding: state.selectProps.isMobile ? '5px' : '5px 12px',
    cursor: 'pointer',
  }),
  input: () => ({
    display: 'none',
  }),
  singleValue: (base: any, state: any) => ({
    ...base,
    fontSize: state.selectProps.isMobile ? '0.85rem' : '1rem',
    top: state.selectProps.isMobile ? '67%' : '60%',
    maxWidth: state.selectProps.isMobile ? '46%' : '100%',
    height: 32,
    minHeight: 32,
  }),
  valueContainer: () => ({
    height: 32,
    minHeight: 32,
    marginTop: '-5px',
    'input[readonly]': {
      position: 'absolute',
      top: '-100px',
    },
  }),
  indicatorsContainer: () => ({
    height: 32,
    minHeight: 32,
  }),
  dropdownIndicator: () => ({
    color: 'gray',
  }),
};
const sharedMinimalStyle = css`
  width: 100%;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 0px 12px;
  .react-select__menu {
    width: 100%;
    min-width: unset;
    margin-left: -12px;
  }
`;

const StyledReactSelect = styled(ReactSelect)<{ isMobile?: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '86px' : '128px')};
`;
const StyledMinimalSelect = styled(MinimalSelect)`
  ${sharedMinimalStyle}
`;
const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;
interface SectionForm {
  capacity: number;
  instructors: Array<string>;
  room: {
    id: string;
  };
  seat: number;
  name: string;
  schedule: {
    id: string;
    TOD: string;
    day: string;
    end: string;
    start: string;
  }[];
}
type EditSectionModalProps = {
  isOpen?: boolean;
  setOpen: (x: boolean) => void;
  sectionId?: string;
  termId: string | undefined;
  courseId: string;
  mode: any;
  onEditComplete?: () => void;
  onEditError?: (err: any) => void;
};

const EditSectionModal = ({
  isOpen,
  setOpen,
  mode,
  sectionId,
  termId,
  onEditComplete,
  courseId,
}: EditSectionModalProps): JSX.Element => {
  const [defaultSecValues, setDefaultSecValues] = useState<SectionContent>({
    id: '',
    capacity: 0,
    instructors: [],
    room: {
      id: '',
      name: '',
    },
    seat: 0,
    name: '',
    schedule: [
      {
        id: '',
        TOD: '',
        day: '',
        end: '',
        start: '',
      },
    ],
  });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isScheduleOpen, setIsScheduleOpen] = useState<boolean>(false);
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const [currentSectionEdit, setCurrentSectionEdit] = useState({});
  const [currentSchedule, setCurrentSchedule] = useState({});
  const [currentIndex, setCurrentIndex] = useState<number>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState<boolean>(false);
  const [courseInfo, setCourseInfo] = useState({
    id: '',
    term: '',
    short_name: '',
  });
  const [instructors, setInstructors] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([{ label: '', value: '' }]);
  const [locations, setLocations] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm<SectionForm>({
    mode: 'onBlur',
  });
  const { fields, append, remove } = useFieldArray({
    name: 'schedule',
    control,
    keyName: 'key',
  });
  // const lecturersReg = register('instructors');
  function isEditSection() {
    if (mode === 'edit') {
      return true;
    }
    return false;
  }
  function mapData(array: any[]) {
    const sampleArray: { id: any; TOD: any; day: any; end: any; start: any }[] = [];
    array?.forEach((element) => {
      const obj = {
        id: element.id,
        TOD: element.TOD,
        day: element.day,
        end: element.end
          ? moment(element.end, ['h:mm A']).format('HH:mm')
          : element.end,
        start: element.start
          ? moment(element.start, ['h:mm A']).format('HH:mm')
          : element.start,
      };
      sampleArray.push(obj);
    });
    return sampleArray;
  }
  function getSubmitTime(time: string) {
    let convert = '';
    if (time !== '') {
      convert = moment(time, ['HH:mm']).format('h:mm A');
    }
    return convert;
  }
  useEffect(() => {
    fetchInstructor();
    fetchLocations();
    fetchCourseInfo();
    fetchCourseOfferInfo();
  }, []);
  useEffect(() => {
    if (isEditSection()) {
      fetchSectionInfo();
    }
  }, [locations, instructors]);
  const openDeleteModal = (section: any, e: MouseEvent) => {
    setCurrentSectionEdit(section);
    // setOpen(false);
    setIsModalOpen(true);
    e.stopPropagation();
  };
  const handleInstChange = (lecturer: any, dirty: boolean) => {
    // eslint-disable-next-line max-len
    const deletedInstr = defaultSecValues.instructors.filter(
      (elem) => !lecturer?.some(
        (elem2: { value: string | undefined }) => elem.id === elem2.value,
      ),
    );
    console.log('instructor', lecturer, defaultSecValues.instructors, deletedInstr);
    deletedInstr.forEach((element) => deleteInstructor(element.id));
    const data: Array<string> = [];
    if (lecturer && lecturer.length > 0) {
      lecturer.forEach((lect: { value: any }) => data.push(lect.value));
    }

    setValue('instructors', data, {
      shouldDirty: dirty,
    });
    // deleteInstructor()
  };
  const toggleDeleteSection = () => {
    setIsModalOpen(false);
  };
  const openParent = () => {
    setOpen(true);
  };
  const deleteInstructor = (id: any) => {
    axios
      .delete(`/api/v1/courses/sections/${sectionId}/instructors/${id}`)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchInstructor = () => {
    axios
      .get('/api/v1/courses/instructors')
      .then((res) => {
        if (res.data.data.instructors) {
          const lecturerList: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.instructors.forEach(
            (element: {
              first_name: string;
              last_name: string;
              id: string;
            }) => {
              const array = { label: '', value: '' };
              array.label = `${element.first_name} ${element.last_name}`;
              array.value = element.id;
              lecturerList.push(array);
            },
          );
          setInstructors(lecturerList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchLocations = () => {
    axios
      .get('/api/v1/courses/locations')
      .then((res) => {
        if (res.data.data.locations) {
          const locationList: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.locations.forEach(
            (element: { name: string; id: string }) => {
              const array = { label: '', value: '' };
              array.label = element.name;
              array.value = element.id;
              locationList.push(array);
            },
          );
          setLocations(locationList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchCourseInfo = () => {
    setLoading(true);
    axios
      .get(`/api/v1/courses/${courseId}`)
      .then((res) => {
        if (res.data.data.course) {
          setCourseInfo(res.data.data.course);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const fetchCourseOfferInfo = () => {
    setLoading(true);
    axios
      .get(`/api/v1/courses/course-offerings/${courseId}`)
      .then((res) => {
        if (res.data.data.courses) {
          setCourseInfo(res.data.data.courses[0]);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const fetchSectionInfo = () => {
    setLoading(true);
    axios
      .get(`/api/v1/courses/sections/${sectionId}`)
      .then((res) => {
        if (res.data.data.section) {
          mapSectionInfo(res.data.data.section);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const mapSectionInfo = (sectionData: any) => {
    setDefaultSecValues({
      ...defaultSecValues,
      id: sectionData.id,
      capacity: sectionData.capacity,
      instructors: sectionData.lecturers,
      room: sectionData.room,
      seat: sectionData.seat,
      name: sectionData.name,
      schedule: mapData(sectionData.schedule),
    });
    reset({
      capacity: sectionData.capacity,
      instructors: getLecturers(sectionData.lecturers),
      room: { id: sectionData.room.id },
      seat: Number(sectionData.seat),
      name: sectionData.name,
      schedule: mapData(sectionData.schedule),
    });
  };
  function getDefaultLecturers(list: any) {
    const sampleArray: Array<{ id: string | undefined }> = [];
    if (list && list.length > 0) {
      list.forEach(
        (inst: any) => inst.id! === ''
          && sampleArray.push({
            id: instructors.find((elem) => elem.value === inst.id)?.value,
          }),
      );
    }
    return sampleArray;
  }
  function getLecturers(list: any) {
    const sampleArray: Array<string | undefined> = [];
    if (list && list.length > 0 && instructors && instructors.length > 0) {
      list.forEach((inst: any) => {
        const found = instructors.find((elem) => elem.value === inst.id);
        return sampleArray.push(found?.value);
      });
    }
    return sampleArray;
  }
  const removeSchedule = (field: any, index: number) => {
    if (!field.id || field.id === '') {
      remove(index);
    } else {
      setCurrentIndex(index);
      setCurrentSchedule(field);
      setIsScheduleOpen(true);
    }
  };
  const removeCurrentIndex = () => {
    remove(currentIndex);
  };
  const onSubmit = (data: any) => {
    setLoading(true);
    data.schedule?.forEach((item: any) => {
      if (item.start && item.start !== '') {
        // eslint-disable-next-line no-param-reassign
        item.start = getSubmitTime(item.start);
      }
      if (item.end && item.end !== '') {
        // eslint-disable-next-line no-param-reassign
        item.end = getSubmitTime(item.end);
      }
    });
    if (isEditSection() && isDirty && courseId && sectionId) {
      if (data.instructors && data.instructors.length > 0) {
        data.instructors.forEach((element: any) => {
          axios
            .post(`/api/v1/courses/sections/${sectionId}/instructors`, {
              id: element,
            })
            .then(() => {
              setLoading(false);
            })
            .catch((error) => {
              setErrorSnackbarOpen(true);
              setLoading(false);
              console.log(error);
            });
        });
      }
      axios
        .put(`/api/v1/courses/sections/${sectionId}`, data)
        .then(() => {
          setLoading(false);
          onEditComplete?.();
          setOpen(false);
        })
        .catch((error) => {
          setErrorSnackbarOpen(true);
          setLoading(false);
          console.log(error);
        });
    } else {
      const values = {
        name: data.name,
        course_offer_id: courseId,
        capacity: data.capacity,
        // instructor_ids:
        //   data.instructors && data.instructors.length > 0
        //     ? data.instructors.map((item: any) => item.id)
        //     : [],
        instructors: data.instructors,
        room_id: data.room.id,
        term: {
          id: termId,
        },
        schedules: data.schedule,
      };
      console.log('instructors', data);
      axios
        .post('/api/v1/courses/sections', values)
        .then(() => {
          setLoading(false);
          onEditComplete?.();
          setOpen(false);
        })
        .catch((error) => {
          setErrorSnackbarOpen(true);
          setLoading(false);
          console.log(error);
        });
      // setOpen(false);
    }
  };
  return (
    <>
      <SnackBar
        isOpen={errorSnackbarOpen}
        setOpen={setErrorSnackbarOpen}
        type="error"
        message="Something went wrong. Please try again!"
      />
      {sectionId && (
        <ScheduleDeleteModal
          isOpen={isScheduleOpen}
          setOpen={setIsScheduleOpen}
          currentSchedule={currentSchedule}
          courseId={courseId}
          sectionId={sectionId}
          openParent={openParent.bind(this)}
          onDeleteComplete={() => {
            setOpen(false);
            onEditComplete?.();
          }}
          onDeleteError={() => {}}
        />
      )}
      <SectionDeleteModal
        isOpen={isModalOpen}
        setOpen={setIsModalOpen}
        currentSection={currentSectionEdit}
        openParent={openParent.bind(this)}
        onDeleteComplete={toggleDeleteSection}
        onDeleteError={() => {
          toggleDeleteSection();
        }}
      />
      <Modal
        isOpen={!!isOpen}
        setOpen={setOpen}
        customStyle={{ content: { maxWidth: '455px' } }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexBox style={{ margin: '16px 0px' }}>
            <FlexGrow>
              {isEditSection() ? (
                <h3>Edit Section</h3>
              ) : (
                <h3>Create New Section</h3>
              )}
            </FlexGrow>
            <ClickableIcon name="x-s" onClick={() => setOpen(false)} />
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px' }}>
            {isEditSection() ? (
              <FlexGrow>
                <StyledText>You are editing section “</StyledText>
                <StyledText fontWeight={'bold'}>
                  {defaultSecValues.name}
                </StyledText>
                <StyledText>” for the course “</StyledText>
                <StyledText fontWeight={'bold'}>
                  {courseInfo.short_name}”
                </StyledText>
              </FlexGrow>
            ) : (
              <FlexGrow>
                <StyledText>
                  You are creating a new section for the course “
                </StyledText>
                <StyledText fontWeight={'bold'}>
                  {courseInfo.short_name}”
                </StyledText>
              </FlexGrow>
            )}
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px' }}>
            <FlexGrow>
              <StyledText>Section Name</StyledText>
              {isEditSection() ? (
                <Input
                  isFullWidth
                  disabled
                  value={defaultSecValues.name}></Input>
              ) : (
                <>
                  <Input
                    isFullWidth
                    {...register('name', {
                      required: 'Section name is required',
                    })}></Input>
                  <ErrorMessage
                    errors={errors}
                    name="name"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: _theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              )}
            </FlexGrow>
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px 0px 0px' }}>
            <FlexGrow style={{ flex: '0 0 100%', marginBottom: '5px' }}>
              <StyledText>Schedule </StyledText>
            </FlexGrow>
          </FlexBox>
          {fields.map((field, index) => (
            <>
              <FlexBox
                justify="space-between"
                key={field.key}
                space={isMobile ? 0.5 : 1}
                style={{ margin: '6px 0px 0px 0px', flexWrap: 'nowrap' }}>
                <FlexGrow>
                  <Controller
                    control={control}
                    name={`schedule.${index}.day` as const}
                    defaultValue={field.day as any}
                    render={({
                      field: {
                        onChange, onBlur, name, ref, value,
                      },
                    }) => (
                      <StyledReactSelect
                        isMobile={isMobile}
                        name={name}
                        inputRef={ref}
                        value={DayOptions.find((c) => c.value === value)}
                        onChange={(val: { value: any }) => onChange(val.value)}
                        onBlur={onBlur}
                        isSearchable={false}
                        styles={customStyles}
                        components={{ IndicatorSeparator: null }}
                        options={DayOptions}
                      />
                    )}
                  />
                </FlexGrow>
                <FlexGrow>
                  <TimeInput
                    isMobile={isMobile}
                    {...register(
                      `schedule.${index}.start` as const,
                    )}></TimeInput>
                </FlexGrow>
                <FlexGrow>
                  <StyledText
                    style={{ display: 'flex', justifyContent: 'center' }}>
                    -
                  </StyledText>
                </FlexGrow>
                <FlexGrow>
                  <TimeInput
                    isMobile={isMobile}
                    {...register(`schedule.${index}.end` as const)}></TimeInput>
                </FlexGrow>
                <FlexGrow>
                  <RightAlign>
                    <ClickableIcon
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        removeSchedule(field, index);
                      }}
                      name="x-s"
                      style={{
                        color: '#7E7E7E',
                        verticalAlign: 'middle',
                      }}></ClickableIcon>
                  </RightAlign>
                </FlexGrow>
              </FlexBox>
            </>
          ))}
          <FlexBox style={{ marginTop: '5px' }}>
            <FlexGrow>
              <ClickableIcon
                type="button"
                title="Add Time"
                style={{ alignItems: 'center', color: _theme.color.primary }}
                status={Status.primary}
                name="plus-s"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  append({
                    id: '',
                    TOD: '',
                    day: '',
                    end: '',
                    start: '',
                  });
                }}
              />
            </FlexGrow>
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px 0px 0px' }}>
            <FlexGrow style={{ flex: '0 0 100%', marginBottom: '5px' }}>
              <StyledText>Location </StyledText>
            </FlexGrow>
            <FlexGrow>
              <Controller
                control={control}
                defaultValue={defaultSecValues.room?.id}
                rules={{ required: 'Please select the room' }}
                name="room.id"
                render={({
                  field: {
                    onChange, onBlur, name, ref, value,
                  },
                }) => (
                  <StyledMinimalSelect
                    multi={false}
                    placeholder="Find room..."
                    options={locations}
                    value={locations.find((c) => c.value === value)}
                    onChange={(val: { value: any }) => onChange(val.value)}
                    innerRef={ref}
                    style={{ width: 'inherit' }}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="room.id"
                render={({ message }) => (
                  <p
                    style={{
                      marginTop: 0,
                      color: _theme.color.danger,
                    }}>
                    {message}
                  </p>
                )}
              />
            </FlexGrow>
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px 0px 0px' }}>
            <FlexGrow style={{ flex: '0 0 100%', marginBottom: '5px' }}>
              <StyledText>Instructor(s) </StyledText>
            </FlexGrow>
            <FlexGrow>
              {/* <StyledMinimalSelect
                multi
                placeholder="Find instructors..."
                options={instructors}
                defaultValue={
                  defaultSecValues.instructors
                    ? defaultSecValues.instructors
                    : []
                }
                onChange={(e: any) => {
                  handleInstChange(e, true);
                }}
                onBlur={lecturersReg.onBlur}
                innerRef={lecturersReg.ref}
                style={{ width: 'inherit' }}
              /> */}
              <Controller
                control={control}
                defaultValue={defaultSecValues.instructors.map(
                  (sec: any) => sec.id,
                )}
                name="instructors"
                render={({ field: { onChange, value, ref } }) => (
                  <StyledMinimalSelect
                    inputRef={ref}
                    // eslint-disable-next-line max-len
                    value={instructors.filter((el) => value?.some((f) => f === el.value))}
                    onChange={(e: any) => {
                      onChange(e);
                      handleInstChange(e, true);
                    }}
                    options={instructors}
                    multi
                  />
                )}
              />
            </FlexGrow>
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px' }}>
            <FlexGrow>
              <StyledText>Maximum Students </StyledText>
              <Input
                type="number"
                isFullWidth
                {...register('capacity', {
                  valueAsNumber: true,
                })}
                defaultValue={defaultSecValues.capacity}></Input>
            </FlexGrow>
          </FlexBox>
          <FlexBox
            alignItems="center"
            justify="flex-end"
            style={{
              marginBottom: _theme.spacing.x2,
            }}>
            {isEditSection() ? (
              <>
                <FlexGrow grow={2}>
                  <ALink
                    onClick={(e: any) => {
                      openDeleteModal(defaultSecValues, e);
                    }}
                    style={{ textDecoration: 'underline' }}>
                    Delete Section
                  </ALink>
                </FlexGrow>
                <FlexGrow>
                  {isLoading && (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          padding: _theme.spacing.x2,
                        }}>
                        <Icon
                          name="spinner"
                          spin
                          size={4}
                          color={_theme.color.textDisabled}
                        />
                      </div>
                    </>
                  )}
                </FlexGrow>
                <FlexGrow>
                  <RightAlign>
                    <Button
                      type="submit"
                      status={Status.primary}
                      backgroundColor="#CB092B"
                      style={{ padding: '4px 12px', marginLeft: '8px' }}>
                      <Icon name="check-mark" mr={0.5} />
                      Save
                    </Button>
                  </RightAlign>
                </FlexGrow>
              </>
            ) : (
              <FlexGrow>
                <RightAlign>
                  <Button
                    type="submit"
                    status={Status.primary}
                    backgroundColor="#CB092B"
                    style={{ padding: '4px 12px', marginLeft: '8px' }}>
                    <Icon name="check-mark" mr={0.5} />
                    Create Section
                  </Button>
                </RightAlign>
              </FlexGrow>
            )}
          </FlexBox>
        </form>
      </Modal>
    </>
  );
};

export default EditSectionModal;
