import axios from 'axios';
import React, { useState, useEffect } from 'react';

import { useScreenClass } from 'react-grid-system';
import styled from 'styled-components';
import colorName from '../styles/colors';
import theme from '../styles/theme';
import Status from '../types/enum/Status';
import Button from './Button';
import FlexBox, { FlexGrow } from './FlexBox';
import { ClickableIcon } from './Icon';
import Modal from './Modal';
import NullStateTR from './NullStateTr';
import { Col, Row } from './StyledGridSystem';
import Table from './Table';
import { StyledText, TextStatus } from './Text';

const MAX_DESCRIPTION_LENGTH = 280;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StatusStyled = styled.div`
  display: flex;
  flex-flow: column;
`;

const StatsItemStyled = styled.div`
  display: flex;
  flex-flow: row;
  vertical-align: baseline;
`;

const BlockStlyed = styled.h6`
  padding-right: ${({ theme }) => theme.spacing.x3};
  width: 100px;
  margin: 0px;
`;

type ProgramSelectModalProps = {
  isOpen: boolean;
  setOpen: (x: boolean) => void;
  onSelect: (programId: string) => void;
};
const ProgramSelectModal = ({
  isOpen,
  setOpen,
  onSelect,
}: ProgramSelectModalProps): JSX.Element => {
  const screenClass = useScreenClass();

  const [programs, setPrograms] = useState<any>([]);

  useEffect(() => {
    if (isOpen) fetchPrograms();
  }, [isOpen]);
  const fetchPrograms = () => {
    axios
      .get('/api/v1/courses/programs')
      .then((res) => {
        console.log(res);
        setPrograms(res.data.data.programs);
      })
      .catch(() => {
        setPrograms([]);
      });
  };
  return (
    <Modal isOpen={!!isOpen} setOpen={setOpen}>
      <FlexBox alignItems="flex-start" style={{ marginBottom: '12px' }}>
        <FlexGrow>
          <h3 style={{ margin: '0px' }}>Select Program</h3>
        </FlexGrow>
        <RightAlign>
          <ClickableIcon name="x-s" onClick={() => setOpen?.(false)} />
        </RightAlign>
      </FlexBox>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Full Name</th>
            <th>Degree Type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {programs && programs.length > 0 ? (
            programs.map((program: any) => (
              <tr>
                <td>{program.name}</td>
                <td>{program.program_name}</td>
                <td>{program.degree_abbr}</td>
                <td>
                  <Button
                    isOutline
                    status={Status.primary}
                    onClick={() => {
                      onSelect(program.id);
                    }}>
                    Select
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <NullStateTR>No programs.</NullStateTR>
          )}
        </tbody>
      </Table>
    </Modal>
  );
};

export default ProgramSelectModal;
