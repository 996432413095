import styled, { css } from 'styled-components';
import Pill from './Pill';

const TabGroup = styled.nav`
  width: auto;
  border-bottom: 1px solid ${({
    theme,
  }) => theme.color.borderColor};
  display: flex;
  padding-left: ${({
    theme,
  }) => theme.spacing.x2};
  margin-bottom: ${({
    theme,
  }) => theme.spacing.x2};
`;

export const TabItem = styled.div<{ active?: boolean, disabled?: boolean }>`
  margin-right: ${({
    theme,
  }) => theme.spacing.x3};

  ${({ theme }) => theme.typography.UIText};
  /* ${({ active }) => active && css`font-weight: bold;`} */

  ${({ theme, disabled }) => disabled && css`color: ${theme.color.textMuted};`}
  
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: ${({
    theme,
  }) => theme.spacing.xs};
    margin-top: ${({
    theme,
  }) => theme.spacing.x1};
    background-color: ${({
    theme, active,
  }) => (active ? theme.color.primary : 'transparent')};
    /* border-top-left-radius: ${({
    theme,
  }) => theme.spacing.xs};
    border-top-right-radius: ${({
    theme,
  }) => theme.spacing.xs}; */
    opacity: ${({
    disabled,
  }) => (disabled ? 0.4 : 1)};
  }

  &:hover::after {
    ${({
    theme, active,
  }) => !active && `background-color: ${theme.color.lightBackground};`}
  }
`;

export const TabBadge = styled(Pill)`
  margin-left: ${({
    theme,
  }) => theme.spacing.x1};
`;

export default TabGroup;
