import { darken, transparentize } from 'polished';
import Status from '../types/enum/Status';
import colors from './colors';

// --drop--shadow -default -l-v 1:  4px 0px 2px rgba(0, 0, 0, 0.15);
// --drop--shadow -primary -l-v 1:  4px 0px 2px rgba(167, 16, 42, 0.25);
// --drop--shadow -secondary -l-v 1:  4px 0px 2px rgba(203, 50, 12, 0.25);
// --drop--shadow -success -l-v 1:  4px 0px 2px rgba(27, 143, 59, 0.25);
// --drop--shadow -danger -l-v 1:  4px 0px 2px rgba(171, 23, 14, 0.25);
// --drop--shadow -warning -l-v 1:  4px 0px 2px rgba(185, 133, 0, 0.25);
// --drop--shadow -info -l-v 1:  4px 0px 2px rgba(28, 155, 163, 0.25);
// --drop--shadow -blue -l-v 1:  4px 0px 2px rgba(28, 95, 157, 0.25);
// --drop--shadow -default -l-v 4:  16px 0px 8px rgba(0, 0, 0, 0.15);
// --drop--shadow -primary -l-v 4:  16px 0px 8px rgba(167, 16, 42, 0.25);
// --drop--shadow -secondary -l-v 4:  16px 0px 8px rgba(203, 50, 12, 0.25);
// --drop--shadow -success -l-v 4:  16px 0px 8px rgba(27, 143, 59, 0.25);
// --drop--shadow -danger -l-v 4:  16px 0px 8px rgba(171, 23, 14, 0.25);
// --drop--shadow -warning -l-v 4:  16px 0px 8px rgba(185, 133, 0, 0.25);
// --drop--shadow -info -l-v 4:  16px 0px 8px rgba(28, 155, 163, 0.25);
// --drop--shadow -blue -l-v 4:  16px 0px 8px rgba(28, 95, 157, 0.25);

const getShadow = (level: number, status: Status = Status.default) => {
  const y = level;
  const blur = level * 2;
  // FIXME: apply status to create colored shadow

  let color = '#000';
  if (status !== Status.default) {
    color = colors[status.toString()];
  }

  return `0px ${y}px ${blur}px ${transparentize(0.9, darken(0.1, color))}`;
  // return `0px ${y}px ${blur}px rgba(0, 0, 0, 0.15)`;
};

export default getShadow;
