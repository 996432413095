import React, { Children, HTMLProps } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Icon from './Icon';
import IconButton from './IconButton';
import Status from '../types/enum/Status';

const StyledText = styled.div`
  color: ${({ theme }) => theme.color.textColor};
  font-size: 1rem;
  padding-left: ${({ theme }) => theme.spacing.x2};

  align-self: center;
  line-height: 150%;
  /* width: 96px; */
  &:hover {
    text-decoration: underline;
  }

  * {
    font-size: 1rem;
  }
`;

const StyledQuickAccessIcon = styled(NavLink)`
  display: flex;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacing.x2};
  /* flex-grow: 1; */
  flex-basis: 0;
  text-decoration: none;
  box-sizing: border-box;
  padding-bottom: ${({ theme }) => theme.spacing.x2};

  ${IconButton} {
    margin-bottom: 0;
  }

  /* &:last-child {
    padding-right: 0;
  } */

  &:hover {
    ${StyledText} {
      text-decoration: underline;
    }

    ${IconButton} {
      text-decoration: none;
    }
  }
`;

export type QuickAccessContainerProps = {
  isMobile?: boolean;
  isVerticalOnMobile?: boolean;
} & HTMLProps<HTMLDivElement>;

type QuickAccessContainerFullProps = {
  iconGroupFlexBasis: string;
} & QuickAccessContainerProps;

export const QuickAccessContainer = styled.div.attrs(
  ({ isMobile, isVerticalOnMobile, ...props }: QuickAccessContainerProps) => {
    let iconGroupFlexBasis: string = '0';
    if (isMobile) {
      iconGroupFlexBasis = isVerticalOnMobile ? '48px' : '50%';
    }
    return {
      isMobile,
      isVerticalOnMobile,
      iconGroupFlexBasis,
      ...props,
    };
  },
)<QuickAccessContainerFullProps>`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: ${({ isMobile, isVerticalOnMobile }) => (isVerticalOnMobile && isMobile ? 'space-between' : 'flex-start')};
  text-align: ${({ isMobile, isVerticalOnMobile }) => (isVerticalOnMobile && isMobile ? 'center' : 'left')};
  margin-bottom: -${({ theme }) => theme.spacing.x2};
  /* padding-top: ${({ theme }) => theme.spacing.x1};  */

  ${StyledQuickAccessIcon} {
    ${({
    isMobile, isVerticalOnMobile, theme, iconGroupFlexBasis,
  }) => isMobile
      && css`
        flex-grow: 1;
        flex-basis: ${iconGroupFlexBasis};
        /* padding-bottom: ${isVerticalOnMobile ? '0' : theme.spacing.x2}; */
        ${isVerticalOnMobile
        && css`
          padding-right: 0;
        `}

        ${StyledText} {
          ${isVerticalOnMobile
          && css`
            padding: 0;
          `}
          ${isVerticalOnMobile
          && isMobile
          && css`
            padding-top: ${theme.spacing.x1};
          `};
        }
      `}

    ${({ isMobile, theme }) => !isMobile
      && css`
        max-width: 200px;
        padding-right: ${theme.spacing.x3};
      `}
    /* margin-bottom: ${({ theme }) => theme.spacing.x2};  */
    /* flex-basis: ${({ iconGroupFlexBasis }) => iconGroupFlexBasis}; */
    /* width: ${({ iconGroupFlexBasis }) => iconGroupFlexBasis}; */
    flex-direction: ${({ isMobile, isVerticalOnMobile }) => (isVerticalOnMobile && isMobile ? 'column' : 'row')};
    /* max-width: 200px; */
  }
`;

type Props = {
  to: string;
  onClick?: any;
  icon: string;
  children: React.ReactChild;
  isMobile?: boolean;
  isVerticalOnMobile?: boolean;
  size?: string;
  as?: string;
};

function QuickAccessIcon({
  to, icon, children, isMobile, size, as, onClick,
}: Props): JSX.Element {
  return (
    <StyledQuickAccessIcon onClick={onClick} to={to}>
      <IconButton status={Status.secondary} tabIndex={-1}>
        <Icon name={icon} size={3} status={Status.secondary} />
      </IconButton>
      <StyledText>{children}</StyledText>
    </StyledQuickAccessIcon>
  );
}
export default QuickAccessIcon;
