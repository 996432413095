import styled from 'styled-components';

import React, {
  useContext, useMemo, useRef, useState,
} from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Hidden, useScreenClass } from 'react-grid-system';
import Icon, { ClickableIcon } from './Icon';

import User from '../types/User';
import FlexBox, { FlexBoxProps, FlexGrow } from './FlexBox';
import _theme from '../styles/theme';
import Status from '../types/enum/Status';
import CircleImage, { CircleImageSize } from './CircleImage';
import { UserContext } from '../context/UserContext';
import Pill from './Pill';
import Dropdown, { DropdownContainer, DropdownItem } from './Dropdown';
import Student from '../types/Student';
import { StudentContext } from '../context/StudentBehalfContext';
import { StyledText, TextSize, TextStatus } from './Text';

const StyledNavbar = styled(FlexBox)<{ isMobile?: boolean } & FlexBoxProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: ${({ theme }) => theme.shadow(8, Status.default)};
  z-index: 100;

  display: flex;
  align-items: center;
  padding: ${({ theme, isMobile }) => `${theme.spacing.x2} ${isMobile ? theme.spacing.x2 : theme.spacing.x4}`};
  box-sizing: border-box;
`;
const StyledNavbarStudent = styled(FlexBox)<
  { isMobile?: boolean } & FlexBoxProps
>`
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  height: 60px;
  background-color: ${({ theme }) => theme.color.primary};
  box-shadow: ${({ theme }) => theme.shadow(8, Status.default)};
  z-index: 100;

  display: flex;
  align-items: center;
  padding: ${({ theme, isMobile }) => `${theme.spacing.x1} ${isMobile ? theme.spacing.x2 : theme.spacing.x4}`};
  box-sizing: border-box;
`;

const NavbarItem = styled.button`
  background: none;
  border: none;
  display: flex;
  flex-flow: row;
  cursor: pointer;
  justify-content: flex-end;
  outline: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ArrowStlyed = styled.div`
  text-decoration: none;
`;

const LogoImage = styled.img`
  &:hover {
    filter: brightness(80%);
  }
`;
const style = {
  textDecoration: 'none',
  color: _theme.color.white,
  textoverflow: 'clip',
  display: 'flex',
};
const ProfileTag = styled.button<
  { isMobile?: boolean } & React.HTMLProps<HTMLButtonElement>
>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.xs};
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  border-radius: 0;

  ${CircleImage} {
    margin-right: ${({ theme, isMobile }) => (isMobile ? theme.spacing.xs : theme.spacing.x1)};
  }

  &:hover {
    border-radius: 50px;
    background-color: ${({ theme }) => theme.color.lightBackground};
  }

  &:focus-visible {
    outline: 2px #000 solid;
    outline-style: auto;
  }
`;

export type NavbarProps = {
  children?: React.ReactNode;
  user?: User;
  onSidebarToggle?: (arg0: unknown) => void;
  showSignout?: boolean;
  studentBehalf?: Student;
};

function Navbar({
  user,
  children,
  onSidebarToggle,
  showSignout,
  studentBehalf,
}: NavbarProps): JSX.Element {
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const screenClass = useScreenClass();

  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );

  const currentUser = useContext(UserContext).user;
  const currentStudentBehalf = useContext(StudentContext).student;
  const { isSignedIn } = useContext(UserContext);
  const { isBehalf } = useContext(StudentContext);
  const student = useContext(StudentContext);
  const removeBehalf = () => {
    student.removeBehalf();
    window.localStorage.removeItem('behalfStudent');
  };
  return (
    <>
      <StyledNavbar space={2} isMobile={isMobile}>
        <FlexGrow>
          <FlexBox space={2} style={{ flexWrap: 'nowrap' }}>
            <ClickableIcon
              name="menu-s"
              onClick={onSidebarToggle}
              style={{ marginRight: `${_theme.spacing.base * 1.5}px` }}
            />
            <div
              style={{
                height: `calc(${_theme.navbarHeight} - 2 * ${_theme.spacing.x2})`,
              }}>
              <Link to="/" style={{ textDecoration: 'none' }}>
                <LogoImage
                  src="/images/logo_color.png"
                  style={{ height: '100%' }}
                  alt="hub logo"
                />
              </Link>
              <Pill style={{ marginLeft: `${_theme.spacing.xs}` }}>BETA</Pill>
            </div>
          </FlexBox>
        </FlexGrow>
        {isSignedIn && (
          <DropdownContainer>
            {/* <NavbarItem > */}
            <ProfileTag
              isMobile={isMobile}
              onClick={(e) => {
                e.stopPropagation();
                setIsProfileDropdownOpen(!isProfileDropdownOpen);
              }}>
              <CircleImage
                src={currentUser.picture}
                size={CircleImageSize.small}
              />
              <Hidden xs sm>
                <div>{currentUser.name}</div>
              </Hidden>
              <ArrowStlyed>
                <Icon size={2} name="caret" />
              </ArrowStlyed>
            </ProfileTag>
            {/* </NavbarItem> */}
            <Dropdown
              open={isProfileDropdownOpen}
              setOpen={setIsProfileDropdownOpen}
              align="right"
              style={{ minWidth: `${_theme.spacing.base * 25}px` }}>
              {/* <DropdownItem to="/personalInfo">
                <Icon mr={1} name="user" />
                Personal Information
              </DropdownItem> */}
              <DropdownItem to="/signout">
                <Icon mr={1} name="leave" />
                Sign out
              </DropdownItem>
            </Dropdown>
          </DropdownContainer>
        )}
        {isBehalf && (
          <StyledNavbarStudent space={2} isMobile={isMobile}>
            <FlexGrow>
              <FlexBox space={2} style={{ flexWrap: 'nowrap' }}>
                <CircleImage
                  src={currentUser.picture}
                  size={CircleImageSize.medium}
                />
                <FlexGrow>
                  <Hidden xs sm>
                    <div>
                      <StyledText style={{ color: 'white' }}>
                        Acting with admin rights on behalf of
                      </StyledText>
                    </div>
                    <div>
                      <StyledText
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          paddingRight: '4px',
                        }}>
                        {currentStudentBehalf.first_name}
                      </StyledText>
                      <StyledText
                        style={{ fontWeight: 'bold', color: 'white' }}>
                        {currentStudentBehalf.last_name}
                      </StyledText>
                    </div>
                  </Hidden>
                </FlexGrow>
              </FlexBox>
            </FlexGrow>
            <NavLink
              exact
              to={{
                pathname: '/students',
              }}
              onClick={() => removeBehalf()}
              style={style}>
              <StyledText textSize={TextSize.default} status={TextStatus.light}>
                View Students
              </StyledText>
              <Icon
                name="x-s"
                status={Status.light}
                style={{ alignSelf: 'center', paddingLeft: _theme.spacing.x1 }}
              />
            </NavLink>
          </StyledNavbarStudent>
        )}
      </StyledNavbar>
    </>
  );
}

export default Navbar;
