import { darken } from 'polished';
import styled from 'styled-components';
import theme from '../styles/theme';
import Status from '../types/enum/Status';

export type IconButtonProps = {
  theme: typeof theme;
  status?: Status;
} & React.HTMLProps<HTMLButtonElement>;

const IconButton = styled.button<IconButtonProps>`
  border: none;
  outline: none;

  width: ${({ theme }) => theme.spacing.x6};
  height: ${({ theme }) => theme.spacing.x6};
  min-width: ${({ theme }) => theme.spacing.x6};
  min-height: ${({ theme }) => theme.spacing.x6};
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;

  /* &, * {
    text-decoration: none !important;
    text-decoration-thickness: 0;
    text-decoration-color: rgba(0, 0, 0, 0) !important;
  } */

  background-color: ${({ theme, status }) => theme.color[`${status || Status.default}Light`]};
  color: ${({ theme, status }) => theme.color[`${status || Status.default}Dark`]};
  cursor: pointer;

  transition: all ${({ theme }) => theme.transitionTime.quick};
  
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: ${({ theme, status }) => darken(0.03, theme.color[`${status || Status.default}Light`])};
  }

  &:active:not(:disabled) {
    background-color: ${({ theme, status }) => darken(0.07, theme.color[`${status || Status.default}Light`])};
  }
`;

IconButton.defaultProps = {
  status: Status.default,
};

export default IconButton;
