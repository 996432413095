import styled, { css } from 'styled-components';
import theme from '../styles/theme';
import Status from '../types/enum/Status';

export type CardProps = {
  theme: typeof theme;
  status?: Status;
} & React.HTMLProps<HTMLDivElement>;

const Card = styled.div <CardProps>`
  width: auto;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.radius};
  padding: ${({ theme }) => theme.spacing.x2};

  ${({ theme, status }) => (status && status !== Status.default) && css`
    border: 1px solid ${theme.color[status]};
  `}

  box-shadow: ${({ theme, status }) => theme.shadow(2, Status.default)};
`;

Card.defaultProps = {
  status: Status.default,
};

export default Card;
