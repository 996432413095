/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import styled from 'styled-components';

import { useScreenClass } from 'react-grid-system';
import axios from 'axios';
import moment from 'moment';
import Card from '../components/Card';

import Table from '../components/Table';

import Icon from '../components/Icon';
import FlexBox, { FlexGrow } from '../components/FlexBox';
import Button from '../components/Button';
import Status from '../types/enum/Status';
import NullStateTR from '../components/NullStateTr';
import EditAnnouncement from '../components/EditAnnouncement';
import AnnouncementDropdown from '../components/AnnouncementDropdown';
import SnackBar from '../components/SnackBar';
import { UserContext } from '../context/UserContext';
import EditImportantDate from '../components/EditImportantDate';

const TextsecondayStyled = styled.td`
  color: ${({ theme }) => theme.color.textSecondary} !important;
  white-space: nowrap;
`;
const StyledCard = styled(Card)`
  margin-bottom: ${({ theme }) => theme.spacing.x3};
`;

const RowStyled = styled.tr`
  vertical-align: baseline;
`;

function ImportantDates() {
  const screenClass = useScreenClass();
  const { user } = useContext(UserContext);

  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const [importantDates, setImportantDates] = useState<Array<any>>([]);
  const [currentImportantDate, setCurrentImportantDate] = useState<{
    id: string;
    start_date: string;
    end_date: string;
    event_name: string;
  }>({
    id: '',
    start_date: '',
    end_date: '',
    event_name: '',
  });
  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState<boolean>(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  useEffect(() => {
    fecthImportantDates();
  }, []);
  const fecthImportantDates = () => {
    axios
      .get('/api/v1/upcoming-date')
      .then((res) => {
        if (res.data.data.upcoming_dates) {
          setImportantDates(res.data.data.upcoming_dates);
        }
      })
      .catch((err) => {
        setImportantDates([]);
      });
  };
  const deleteImportantDates = (id: string) => {
    axios
      .delete(`api/v1/upcoming-date/${id}`)
      .then((res) => {
        setSuccessMessage('Deleted important date successfully!');
        setSuccessSnackbarOpen(true);
        fecthImportantDates();
      })
      .catch((error) => {
        setErrorSnackbarOpen(true);
      });
  };
  return (
    <>
      <SnackBar
        isOpen={errorSnackbarOpen}
        setOpen={setErrorSnackbarOpen}
        type="error"
        message="Something went wrong. Please try again!"
      />
      <SnackBar
        isOpen={successSnackbarOpen}
        setOpen={setSuccessSnackbarOpen}
        type="success"
        message={successMessage}
      />
      <EditImportantDate
        isOpen={createOpen}
        setOpen={setCreateOpen}
        mode="create"
        info={{
          id: '', start_date: '', end_date: '', event_name: '',
        }}
        onEditComplete={() => {
          setSuccessMessage('Created important date successfully!');
          setSuccessSnackbarOpen(true);
          setEditOpen(false);
          fecthImportantDates();
        }}
      />
      {editOpen && currentImportantDate.event_name !== '' && (
        <EditImportantDate
          isOpen={editOpen}
          setOpen={setEditOpen}
          mode="edit"
          info={currentImportantDate}
          onEditComplete={() => {
            setSuccessMessage('Edited important date successfully!');
            setSuccessSnackbarOpen(true);
            setEditOpen(false);
            fecthImportantDates();
          }}
        />
      )}
      <StyledCard>
        <FlexBox style={{ marginBottom: '16px' }}>
          <FlexGrow grow={3}>
            <h3>Upcoming Important Dates</h3>
          </FlexGrow>
          {user.role === 'admin' && (
            <FlexGrow>
              <Button
                status={Status.primary}
                onClick={() => {
                  setCreateOpen(true);
                }}>
                <Icon name="plus-s" mr={0.5} />
                Add
              </Button>
            </FlexGrow>
          )}
        </FlexBox>
        <Table>
          <thead>
            <tr>
              <th style={{ width: '40%' }}>Event</th>
              <th style={{ width: '40%' }}>Date</th>
              <th style={{ width: '20%' }}></th>
            </tr>
          </thead>
          <tbody>
            {importantDates && importantDates.length > 0 ? (
              importantDates.map((data, i) => (
                <RowStyled key={`announcementrow-${i}`}>
                  <td>{data.event_name}</td>
                  <TextsecondayStyled>
                    {moment(data.start_date).format('DD-MM-YYYY')}
                  </TextsecondayStyled>
                  <td style={{ textAlign: 'end' }}>
                    {user.role === 'admin' && (
                      <AnnouncementDropdown
                        announcement={data}
                        openEditModal={(e) => {
                          setCurrentImportantDate(e);
                          setEditOpen(true);
                        }}
                        openDropModal={() => {
                          deleteImportantDates(data.id);
                        }}
                      />
                    )}
                  </td>
                </RowStyled>
              ))
            ) : (
              <NullStateTR>No Important Dates</NullStateTR>
            )}
          </tbody>
        </Table>
      </StyledCard>
    </>
  );
}
export default ImportantDates;
