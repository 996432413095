/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from 'react';
import ReactSelect from 'react-select';
import axios from 'axios';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ErrorMessage } from '@hookform/error-message';
import styled, { css } from 'styled-components';
import {
  Box, Step, StepLabel, Stepper,
} from '@mui/material';
import { useScreenClass } from 'react-grid-system';
import FlexBox, { FlexGrow } from '../../components/FlexBox';
import Input, {
  InputContainer,
  InputIconContainer,
} from '../../components/Input';
import { StyledText } from '../../components/Text';
import TextArea from '../../components/TextArea';
import { RightAlign } from '../../components/UtilsComponents';
import Status from '../../types/enum/Status';
import theme from '../../styles/theme';
import Icon, { ClickableIcon } from '../../components/Icon';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Breadcrumb from '../../components/Breadcrumb';
import MinimalSelect from '../../components/MinimalSelect';
import TimeInput from '../../components/TimeInput';
import useQuery from '../../hooks/useQuery';
import SnackBar from '../../components/SnackBar';

const DayOptions = [
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  { label: 'Saturday', value: 'Saturday' },
  { label: 'Sunday', value: 'Sunday' },
];
const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    height: 32,
    minHeight: 32,
    padding: state.selectProps.isMobile ? '5px' : '5px 12px',
    cursor: 'pointer',
  }),
  input: () => ({
    display: 'none',
  }),
  singleValue: (base: any, state: any) => ({
    ...base,
    fontSize: state.selectProps.isMobile ? '0.85rem' : '1rem',
    top: state.selectProps.isMobile ? '67%' : '60%',
    maxWidth: state.selectProps.isMobile ? '46%' : '100%',
    height: 32,
    minHeight: 32,
  }),
  valueContainer: () => ({
    height: 32,
    minHeight: 32,
    marginTop: '-5px',
    'input[readonly]': {
      position: 'absolute',
      top: '-100px',
    },
  }),
  indicatorsContainer: () => ({
    height: 32,
    minHeight: 32,
  }),
  dropdownIndicator: () => ({
    color: 'gray',
  }),
};
const sharedMinimalStyle = css`
  width: 100%;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 0px 12px;
  .react-select__menu {
    width: 100%;
    min-width: unset;
    margin-left: -12px;
  }
`;
const StyledMinimalSelect = styled(MinimalSelect)`
  ${sharedMinimalStyle}
`;
const StyledReactSelect = styled(ReactSelect)<{ isMobile?: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '86px' : '128px')};
`;
type BasicCourseInfoForm = {
  course_master_id: string | undefined;
  instructor_ids: Array<string>;
  schedules: {
    day: string;
    end_time: string;
    start_time: string;
  }[];
  room_id: string | undefined;
  name: string | undefined;
};
function CreateCourseOffering() {
  const query = useQuery();
  const semester = query.get('semester');
  useEffect(() => {
    fetchCourseMasters();
    fetchTerms();
    fetchLocations();
    fetchInstructor();
    setLoading(false);
  }, []);
  const [terms, setTerms] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  useEffect(() => {
    findTermId(semester);
  }, [terms]);
  const test = [
    { name: 'Courses', link: '/admin/courses' },
    { name: 'Create Course', link: '/admin/courses/add' },
  ];
  const validationSchema = Yup.object({
    course_master_id: Yup.string().required('Course master is required'),
    instructor_ids: Yup.array()
      .min(1, 'Course insctructor is required')
      .required('Course instructor is required'),
    room_id: Yup.string().required('Course location is required'),
    name: Yup.string().required('Course description is required'),
    schedules: Yup.array()
      .of(
        Yup.object({
          day: Yup.string().required('Schedule day is required'),
          end_time: Yup.string().required('Schedule end time is required'),
          start_time: Yup.string().required('Schedule start time is required'),
        }),
      )
      .required(),
  });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<BasicCourseInfoForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      name: 'A',
      course_master_id: '',
      instructor_ids: [],
      room_id: '',
      schedules: [{ day: '', start_time: '', end_time: '' }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    name: 'schedules',
    control,
    keyName: 'key',
  });
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const [loading, setLoading] = useState<boolean>(true);
  const handleInstChange = (lecturer: any, dirty: boolean) => {
    const data: Array<string> = [];
    if (lecturer && lecturer.length > 0) {
      lecturer.forEach((lect: { value: any }) => data.push(lect.value));
    }
    setValue('instructor_ids', data, {
      shouldDirty: dirty,
    });
  };
  const watchAllFields = watch();
  const [currentTermId, setCurrentTermId] = useState<string>();
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [instructors, setInstructors] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const [locations, setLocations] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const [courseMasters, setCourseMasters] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const history = useHistory();
  const findTermId = (semester: string | null) => {
    if (semester && semester !== '' && terms && terms.length > 0) {
      setCurrentTermId(terms.find((elem) => elem.label === semester)?.value);
    }
  };
  const fetchCourseMasters = () => {
    setLoading(true);
    axios
      .get('/api/v1/course-masters')
      .then((res) => {
        if (res.data.data.courses) {
          // eslint-disable-next-line max-len
          const termList: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.courses.forEach(
            (element: any) => {
              const array = { label: '', value: '' };
              array.label = `Code: [${element.code}] Name: ${element.short_name}  Unit: ${element.units}`;
              array.value = element.id;
              termList.push(array);
            },
          );
          setCourseMasters(termList);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const fetchTerms = () => {
    setLoading(true);
    axios
      .get('/api/v1/courses/terms')
      .then((res) => {
        if (res.data.data.terms) {
          // eslint-disable-next-line max-len
          const termList: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.terms.forEach(
            (element: { name: string; id: string }) => {
              const array = { label: '', value: '' };
              array.label = element.name;
              array.value = element.id;
              termList.push(array);
            },
          );
          setTerms(termList);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const fetchLocations = () => {
    axios
      .get('/api/v1/courses/locations')
      .then((res) => {
        if (res.data.data.locations) {
          const locationList: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.locations.forEach(
            (element: { name: string; id: string }) => {
              const array = { label: '', value: '' };
              array.label = element.name;
              array.value = element.id;
              locationList.push(array);
            },
          );
          setLocations(locationList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchInstructor = () => {
    axios
      .get('/api/v1/courses/instructors')
      .then((res) => {
        if (res.data.data.instructors) {
          const lecturerList: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.instructors.forEach(
            (element: {
              first_name: string;
              last_name: string;
              id: string;
            }) => {
              const array = { label: '', value: '' };
              array.label = `${element.first_name} ${element.last_name}`;
              array.value = element.id;
              lecturerList.push(array);
            },
          );
          setInstructors(lecturerList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onError = (errors: any, e: any) => console.log('error', errors, e);
  function getSubmitTime(time: string) {
    let convert = '';
    if (time !== '') {
      convert = moment(time, ['HH:mm']).format('h:mm A');
    }
    return convert;
  }
  const onSectionSubmit = (data: any) => {
    setLoading(true);
    data.schedule?.forEach((item: any) => {
      if (item.start && item.start !== '') {
        // eslint-disable-next-line no-param-reassign
        item.start = getSubmitTime(item.start);
      }
      if (item.end && item.end !== '') {
        // eslint-disable-next-line no-param-reassign
        item.end = getSubmitTime(item.end);
      }
    });
    console.log('section', data);
    const values = {
      name: data.name,
      capacity: data.capacity,
      instructors: data.instructors,
      room: data.room,
      term: data.term,
      schedule: data.schedule,
      seat: 0,
    };
  };
  const onSubmitClicked = (data: any) => {
    setLoading(true);
    axios
      .post(`/api/v1/courses/course-offerings/term/${currentTermId}`, data)
      .then((res) => {
        setLoading(false);
        setSnackbarOpen(true);
        setTimeout(() => {
          history.push('/admin/courses/course/offerings');
        }, 500);
      })
      .catch((error) => {
        console.log(error);
        setErrorSnackbarOpen(true);
        setLoading(false);
      });
  };
  return (
    <>
      <SnackBar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        type="success"
        message="Created course offer successfully!"
      />
      <SnackBar
        isOpen={errorSnackbarOpen}
        setOpen={setErrorSnackbarOpen}
        type="error"
        message="Something went wrong. Please try again!"
      />
      <FlexBox>
        <FlexGrow>
          <Breadcrumb path={test} />
        </FlexGrow>
      </FlexBox>
      <FlexBox
        style={{
          marginBottom: theme.spacing.base * 3,
          marginTop: theme.spacing.base * 3,
        }}>
        <FlexGrow>
          <h2 style={{ marginRight: 'auto', marginBottom: 0 }}>
            Create Course Offer for {semester}
          </h2>
        </FlexGrow>
      </FlexBox>
      <Card>
        <form onSubmit={handleSubmit(onSubmitClicked, onError)}>
          <FlexBox
            alignItems="flex-start"
            style={{
              marginBottom: theme.spacing.x2,
            }}>
            <FlexGrow>
              <h3>Course Information</h3>
            </FlexGrow>
          </FlexBox>
          <FlexBox
            alignItems="flex-start"
            style={{
              marginBottom: theme.spacing.x2,
            }}>
            <FlexGrow grow={1}>
              <StyledText color={'#000000'} fontWeight={'bold'}>
                Course Master
              </StyledText>
            </FlexGrow>
            <FlexGrow grow={3}>
              <Controller
                control={control}
                rules={{ required: 'Please select the course master' }}
                name="course_master_id"
                render={({
                  field: {
                    onChange, onBlur, name, ref, value,
                  },
                }) => (
                  <StyledMinimalSelect
                    multi={false}
                    placeholder="Select course master..."
                    options={courseMasters}
                    value={courseMasters.find((c) => c.value === value)}
                    onChange={(val: { value: any }) => onChange(val.value)}
                    innerRef={ref}
                    style={{ width: 'inherit' }}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="course_master_id"
                render={({ message }) => (
                  <p
                    style={{
                      marginTop: 0,
                      color: theme.color.danger,
                    }}>
                    {message}
                  </p>
                )}
              />
            </FlexGrow>
          </FlexBox>
          <FlexBox
            alignItems="flex-start"
            style={{
              marginBottom: theme.spacing.x2,
            }}>
            <FlexGrow grow={1}>
              <StyledText color={'#000000'} fontWeight={'bold'}>
                Instructor(s)
              </StyledText>
            </FlexGrow>
            <FlexGrow grow={3}>
              <Controller
                control={control}
                name="instructor_ids"
                render={({ field: { onChange, value, ref } }) => (
                  <StyledMinimalSelect
                    inputRef={ref}
                    // eslint-disable-next-line max-len
                    value={instructors.filter((el) => value.some((f) => f === el.value))}
                    onChange={(e: any) => {
                      onChange(e);
                      handleInstChange(e, true);
                    }}
                    options={instructors}
                    multi
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="instructor_ids"
                render={({ message }) => (
                  <p
                    style={{
                      marginTop: 0,
                      color: theme.color.danger,
                    }}>
                    {message}
                  </p>
                )}
              />
            </FlexGrow>
          </FlexBox>
          <FlexBox
            alignItems="flex-start"
            style={{
              marginBottom: theme.spacing.x2,
            }}>
            <FlexGrow grow={1}>
              <StyledText color={'#000000'} fontWeight={'bold'}>
                Location
              </StyledText>
            </FlexGrow>
            <FlexGrow grow={3}>
              <Controller
                control={control}
                rules={{ required: 'Please select the room' }}
                name="room_id"
                render={({
                  field: {
                    onChange, onBlur, name, ref, value,
                  },
                }) => (
                  <StyledMinimalSelect
                    multi={false}
                    placeholder="Find room..."
                    options={locations}
                    value={locations.find((c) => c.value === value)}
                    onChange={(val: { value: any }) => onChange(val.value)}
                    innerRef={ref}
                    style={{ width: 'inherit' }}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="room_id"
                render={({ message }) => (
                  <p
                    style={{
                      marginTop: 0,
                      color: theme.color.danger,
                    }}>
                    {message}
                  </p>
                )}
              />
            </FlexGrow>
          </FlexBox>
          <FlexBox
            alignItems="center"
            style={{
              marginBottom: theme.spacing.x2,
            }}>
            <FlexGrow grow={1}>
              <StyledText color={'#000000'} fontWeight={'bold'}>
                Section Name
              </StyledText>
            </FlexGrow>
            <FlexGrow grow={3}>
              <>
                <Input {...register('name')} isFullWidth />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) => (
                    <p
                      style={{
                        marginTop: 0,
                        color: theme.color.danger,
                      }}>
                      {message}
                    </p>
                  )}
                />
              </>
            </FlexGrow>
          </FlexBox>
          <FlexBox
            alignItems="flex-start"
            style={{
              marginBottom: theme.spacing.x2,
            }}>
            <FlexGrow grow={1}>
              <StyledText
                style={{ verticalAlign: 'top' }}
                color={'#000000'}
                fontWeight={'bold'}>
                Schedule{' '}
              </StyledText>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  append({
                    day: '',
                    end_time: '',
                    start_time: '',
                  });
                }}
                isOutline
                status={Status.primary}
                style={{ padding: '4px 12px' }}>
                <Icon name="plus-s" mr={0.5} />
                Add Time
              </Button>
            </FlexGrow>
            <FlexGrow grow={3}>
              {fields.map((field, index) => (
                <>
                  <FlexBox
                    justify="space-between"
                    key={field.key}
                    space={isMobile ? 0.5 : 1}
                    style={{ margin: '0px 0px 6px 0px', flexWrap: 'nowrap', alignItems: 'flex-start' }}>
                    <FlexGrow>
                      <Controller
                        control={control}
                        name={`schedules.${index}.day` as const}
                        defaultValue={field.day as any}
                        render={({
                          field: {
                            onChange, onBlur, name, ref, value,
                          },
                        }) => (
                          <StyledReactSelect
                            isMobile={isMobile}
                            name={name}
                            inputRef={ref}
                            value={DayOptions.find((c) => c.value === value)}
                            onChange={(val: { value: any }) => onChange(val.value)
                            }
                            onBlur={onBlur}
                            isSearchable={false}
                            styles={customStyles}
                            components={{ IndicatorSeparator: null }}
                            options={DayOptions}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`schedules.${index}.day`}
                        render={({ message }) => (
                          <p
                            style={{
                              marginTop: 0,
                              color: theme.color.danger,
                            }}>
                            {message}
                          </p>
                        )}
                      />
                    </FlexGrow>
                    <FlexGrow>
                      <TimeInput
                        isMobile={isMobile}
                        {...register(
                          `schedules.${index}.start_time` as const,
                        )}></TimeInput>
                      <ErrorMessage
                        errors={errors}
                        name={`schedules.${index}.start_time`}
                        render={({ message }) => (
                          <p
                            style={{
                              marginTop: 0,
                              color: theme.color.danger,
                            }}>
                            {message}
                          </p>
                        )}
                      />
                    </FlexGrow>
                    <FlexGrow>
                      <StyledText
                        style={{ display: 'flex', justifyContent: 'center' }}>
                        -
                      </StyledText>
                    </FlexGrow>
                    <FlexGrow>
                      <TimeInput
                        isMobile={isMobile}
                        {...register(
                          `schedules.${index}.end_time` as const,
                        )}></TimeInput>
                      <ErrorMessage
                        errors={errors}
                        name={`schedules.${index}.end_time`}
                        render={({ message }) => (
                          <p
                            style={{
                              marginTop: 0,
                              color: theme.color.danger,
                            }}>
                            {message}
                          </p>
                        )}
                      />
                    </FlexGrow>
                    <FlexGrow>
                      <RightAlign>
                        <ClickableIcon
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            remove(index);
                          }}
                          name="x-s"
                          style={{
                            color: '#7E7E7E',
                            verticalAlign: 'middle',
                          }}></ClickableIcon>
                      </RightAlign>
                    </FlexGrow>
                  </FlexBox>
                </>
              ))}
            </FlexGrow>
          </FlexBox>
          <FlexBox
            alignItems="flex-start"
            justify="flex-end"
            style={{
              marginBottom: theme.spacing.x2,
            }}>
            <FlexGrow>
              {loading && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: theme.spacing.x2,
                    }}>
                    <Icon
                      name="spinner"
                      spin
                      size={4}
                      color={theme.color.textDisabled}
                    />
                  </div>
                </>
              )}
            </FlexGrow>
            <FlexGrow>
              <RightAlign>
                <Button
                  onClick={() => {
                    history.push('/admin/courses/course/offerings');
                  }}
                  isOutline
                  status={Status.primary}
                  style={{ padding: '4px 12px' }}>
                  <Icon name="x-s" mr={0.5} />
                  Cancel
                </Button>
                <Button
                  type="submit"
                  status={Status.primary}
                  backgroundColor="#CB092B"
                  style={{ padding: '4px 12px', marginLeft: '8px' }}>
                  <Icon name="check-mark" mr={0.5} />
                  Save
                </Button>
              </RightAlign>
            </FlexGrow>
          </FlexBox>
        </form>
      </Card>
    </>
  );
}
export default CreateCourseOffering;
