import axios from 'axios';
import React, { useMemo, useState, useEffect } from 'react';
import { useScreenClass } from 'react-grid-system';
import theme from '../styles/theme';
import Status from '../types/enum/Status';
import Button from './Button';
import FlexBox, { FlexGrow } from './FlexBox';
import Icon, { ClickableIcon } from './Icon';
import Input from './Input';
import Modal from './Modal';
import { RightAlign } from './UtilsComponents';

type CourseOfferDeleteModalProps = {
  isOpen?: boolean;
  setOpen: (x: boolean) => void;
  courseOfferStatus: string;
  currentCourse: { [key: string]: any };
  showSections?: boolean;
  onDeleteComplete?: () => void;
  onDeleteError?: (err: any) => void;
};

const wait = async (ms: number) => new Promise((resolve, reject) => {
  setTimeout(resolve, ms);
});

const CourseOfferActiveModal = ({
  isOpen,
  setOpen,
  currentCourse,
  courseOfferStatus,
  onDeleteError,
  onDeleteComplete,
}: CourseOfferDeleteModalProps): JSX.Element => {
  const screenClass = useScreenClass();
  const [passwordInput, setPasswordInput] = useState<string>('');
  const [isOpenComplete, setOpenComplete] = useState<boolean>(false);
  const [isOpenError, setOpenError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const handleCourseOfferStatus = async (regId: string) => {
    setLoading(true);
    axios
      .put(`/api/v1/courses/course-offerings/${regId}/${courseOfferStatus}`)
      .then((res) => {
        setOpenComplete(true);
        console.log('open complete', isOpenComplete);
      })
      .catch((err) => {
        setOpenError(true);
        console.error(err);
        setError(err);
      });
    setLoading(false);
  };

  const onComplete = () => {
    setOpenComplete(false);
    setOpen(false);
    onDeleteComplete?.();
  };

  const onError = () => {
    setOpenError(false);
    setOpen(false);
    onDeleteError?.(error);
  };

  useEffect(() => {
    if (isOpen) {
      setPasswordInput('');
    }
  }, [isOpen]);

  const password = useMemo(() => {
    const verb = courseOfferStatus;
    return `${verb} ${currentCourse.code}`;
  }, [currentCourse]);

  return (
    <>
      {console.log('open complete2', isOpenComplete)}
      <Modal isOpen={isOpen || false} setOpen={setOpen}>
        <FlexBox>
          <FlexGrow>
            <h3>{courseOfferStatus} Course</h3>
          </FlexGrow>
          <ClickableIcon name="x-s" onClick={() => setOpen(false)} />
        </FlexBox>
        {/* <p style={{ textAlign: 'center' }}>
          <img src="/alert-warning.svg" alt="alert warning"/>
        </p> */}
        <p>
          Are you sure you want to {courseOfferStatus} the course "
          <b>
            {currentCourse.code} {currentCourse.short_name}
          </b>
          "? This cannot be undone.
        </p>
        <p>
          To confirm, type "<b>{password}</b>" in this box.
        </p>
        <Input
          isFullWidth={true}
          value={passwordInput}
          onInput={(e: any) => {
            setPasswordInput(e.target.value);
          }}
        />
        <RightAlign style={{ marginTop: theme.spacing.x2 }}>
          <Button
            isOutline
            onClick={() => {
              setOpen(false);
            }}
            status={Status.default}>
            Cancel
          </Button>
          <Button
            disabled={isLoading || passwordInput !== password}
            status={Status.primary}
            style={{ textTransform: 'capitalize' }}
            onClick={() => handleCourseOfferStatus(currentCourse.course_offer_id)
            }>
            {isLoading && (
              <>
                <Icon mr={1} name="spinner" spin />
                {courseOfferStatus}...
              </>
            )}
            {!isLoading && (
              <>
                <Icon mr={1} name="trash" />
                {courseOfferStatus} course
              </>
            )}
          </Button>
        </RightAlign>
      </Modal>
      <Modal
        isOpen={isOpenComplete}
        setOpen={setOpenComplete}
        onClose={onComplete}
        customStyle={{ content: { textAlign: 'center' } }}>
        <img
          src="/alert-success-soft.svg"
          alt="alert warning"
          style={{ margin: 'auto' }}
        />
        <h3>Course {courseOfferStatus} Complete</h3>
        <p>
          The course "
          <b>
            {currentCourse.code} {currentCourse.short_name}
          </b>
          " has been {courseOfferStatus}.
        </p>
        <Button status={Status.primary} onClick={onComplete}>
          Done
        </Button>
      </Modal>

      <Modal
        isOpen={isOpenError}
        setOpen={setOpenError}
        onClose={onError}
        customStyle={{ content: { textAlign: 'center' } }}>
        <img
          src="/alert-warning.svg"
          alt="alert warning"
          style={{ margin: 'auto' }}
        />
        <h3>Course {courseOfferStatus} Error</h3>
        <p>
          An error occured while {courseOfferStatus} the course "
          <b>
            {currentCourse.code} {currentCourse.short_name}
          </b>
          ". Please try again or contact us if the problem persists.
        </p>
        <Button status={Status.primary} onClick={onError}>
          Done
        </Button>
      </Modal>
    </>
  );
};

export default CourseOfferActiveModal;
