import React, { useState } from 'react';
import styled from 'styled-components';

import _theme from '../../styles/theme';
import Icon, { ClickableIcon } from '../../components/Icon';
import Dropdown, {
  DropdownContainer,
  DropdownItem,
} from '../../components/Dropdown';

const RemoveCourse = styled.div`
  color: ${({ theme }) => theme.color.primary};
`;

type StudentrDropdownProps = {
  currentUserRole: string;
  student: { [key: string]: any };
  openDeleteModal: (x: any, e?: any) => void;
};

const StudentDropDown = ({
  currentUserRole,
  student,
  openDeleteModal,
}: StudentrDropdownProps) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  return (
    <DropdownContainer>
      <ClickableIcon
        ml={0.5}
        size={2}
        name="more-vertical"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!isOpen);
        }}
        style={{ color: _theme.color.primary }}
      />
      <Dropdown
        open={isOpen}
        style={{ whiteSpace: 'nowrap' }}
        setOpen={setOpen}
        align="right">
        <DropdownItem
          exact
          to={{
            pathname: '/personalInfo',
            state: {
              userId: student.user_id,
              role: currentUserRole,
            },
          }}>
            <Icon mr={1} name="user" /> View Profile
        </DropdownItem>
        <DropdownItem
          to="#"
          onClick={(e: any) => {
            console.log('clicked');
            setOpen(false);
            openDeleteModal(student, e);
          }}>
          <RemoveCourse>
            <Icon mr={1} name="trash-empty" /> Delete student
          </RemoveCourse>
        </DropdownItem>
      </Dropdown>
    </DropdownContainer>
  );
};

export default StudentDropDown;
