/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from 'react';
import ReactSelect from 'react-select';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ErrorMessage } from '@hookform/error-message';
import styled, { css } from 'styled-components';
import { useScreenClass } from 'react-grid-system';
import MinimalSelect from './MinimalSelect';
import FlexBox, { FlexGrow } from './FlexBox';
import theme from '../styles/theme';
import Card from './Card';
import { StyledText } from './Text';
import Input from './Input';
import TextArea from './TextArea';
import Button from './Button';
import { RightAlign } from './UtilsComponents';
import Icon, { ClickableIcon } from './Icon';
import Status from '../types/enum/Status';
import Modal from './Modal';
import convertToSelect from '../hooks/convertToSelect';

const sharedMinimalStyle = css`å
  width: 100%;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 0px 12px;
  .react-select__menu {
    width: 100%;
    min-width: unset;
    margin-left: -12px;
  }
`;
const StyledMinimalSelect = styled(MinimalSelect)`
  ${sharedMinimalStyle}
`;
type TermForm = {
  name: string | undefined;
  term_start: string | undefined;
  term_end: string | undefined;
  add_course_start: string | undefined;
  add_course_end: string | undefined;
  withdraw_end: string | undefined;
};
type TermEditModalProps = {
  isOpen?: boolean;
  setOpen?: (x: boolean) => void;
  currentTerm: {
    [key: string]: any;
  };
  onEditComplete?: () => void;
};

const TermEditModal = ({
  isOpen,
  setOpen,
  currentTerm,
  onEditComplete,
}: TermEditModalProps): JSX.Element => {
  const validationSchema = Yup.object({
    name: Yup.string().required('Term name is required'),
  });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<TermForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      name: currentTerm.name,
    },
  });
  dayjs.extend(utc);
  dayjs.extend(tz);
  console.log('current term', currentTerm);
  const screenClass = useScreenClass();
  const [loading, setLoading] = useState<boolean>(false);
  const watchAllFields = watch();
  const history = useHistory();
  const [term_start, setTermStart] = useState<Dayjs | null>(dayjs(currentTerm.term_start));
  const [term_end, setTermEnd] = useState<Dayjs | null>(
    dayjs(currentTerm.term_end).utc(),
  );
  const [add_course_start, setAddCourseStart] = useState<Dayjs | null>(dayjs(
    currentTerm.add_course_start,
  ).utc());
  const [add_course_end, setAddCourseEnd] = useState<Dayjs | null>(dayjs(
    currentTerm.add_course_end,
  ).utc());
  const [withdraw_end, setDropCourseEnd] = useState<Dayjs | null>(dayjs(
    currentTerm.withdraw_end,
  ).utc());
  const [isOpenError, setOpenError] = useState<boolean>(false);
  const onSubmitClicked = (data: any) => {
    setLoading(true);
    const editData = {
      name: data.name,
      term_start,
      term_end,
      add_course_start,
      add_course_end,
      withdraw_end,
    };
    axios
      .put(`/api/v1/courses/terms/${currentTerm.id}`, editData)
      .then((res) => {
        setLoading(false);
        onEditComplete?.();
      })
      .catch((error) => {
        setOpenError(true);
        console.log(error);
        setLoading(false);
      });
  };
  const onErrorCloseModal = () => {
    setOpenError(false);
  };
  const onError = (errors: any, e: any) => console.log('error', errors, e);
  return (
    <>
      <Modal isOpen={!!isOpen} setOpen={setOpen}>
        <FlexBox
          style={{
            marginBottom: theme.spacing.base * 3,
          }}>
          <FlexGrow>
            <h3 style={{ marginRight: 'auto', marginBottom: 0 }}>
              Edit Term
            </h3>
          </FlexGrow>
          <RightAlign>
            <ClickableIcon name="x-s" onClick={() => setOpen?.(false)} />
          </RightAlign>
        </FlexBox>
        {!loading ? (
          <form onSubmit={handleSubmit(onSubmitClicked, onError)}>
            <FlexBox
              alignItems="flex-start"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow>
                <h3>Term Information</h3>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Term Name
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('name')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="name"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Term Start Date
                </StyledText>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    openTo="year"
                    views={['year', 'month', 'day']}
                    value={term_start}
                    onChange={(newValue) => {
                      setTermStart(newValue);
                    }}
                    renderInput={
                      (params) => <TextField {...params} helperText={null} />}
                  />
                </LocalizationProvider>
                <ErrorMessage
                  errors={errors}
                  name="term_start"
                  render={({ message }) => (
                    <p
                      style={{
                        marginTop: 0,
                        color: theme.color.danger,
                      }}>
                      {message}
                    </p>
                  )}
                />
              </FlexGrow>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Term End Date
                </StyledText>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    openTo="year"
                    views={['year', 'month', 'day']}
                    value={term_end}
                    onChange={(newValue) => {
                      setTermEnd(newValue);
                    }}
                    renderInput={
                      (params) => <TextField {...params} helperText={null} />}
                  />
                </LocalizationProvider>
                <ErrorMessage
                  errors={errors}
                  name="term_end"
                  render={({ message }) => (
                    <p
                      style={{
                        marginTop: 0,
                        color: theme.color.danger,
                      }}>
                      {message}å
                    </p>
                  )}
                />
              </FlexGrow>
            </FlexBox>
            <FlexGrow grow={1}>
              <StyledText color={'#000000'} fontWeight={'bold'}>
                Add/Drop Course Start Date
              </StyledText>
            </FlexGrow>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                openTo="year"
                value={add_course_start}
                onChange={(newValue) => {
                  setAddCourseStart(newValue);
                }}
                renderInput={
                  (params) => <TextField {...params} helperText={null} />}
              />
            </LocalizationProvider>
            <ErrorMessage
              errors={errors}
              name="add_course_start"
              render={({ message }) => (
                <p
                  style={{
                    marginTop: 0,
                    color: theme.color.danger,
                  }}>
                  {message}
                </p>
              )}
            />
            <FlexGrow grow={1}>
              <StyledText color={'#000000'} fontWeight={'bold'}>
                Add/Drop Course End Date
              </StyledText>
            </FlexGrow>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                openTo="year"
                value={add_course_end}
                onChange={(newValue) => {
                  setAddCourseEnd(newValue);
                }}
                renderInput={
                  (params) => <TextField {...params} helperText={null} />}
              />
            </LocalizationProvider>
            <ErrorMessage
              errors={errors}
              name="add_course_end"
              render={({ message }) => (
                <p
                  style={{
                    marginTop: 0,
                    color: theme.color.danger,
                  }}>
                  {message}
                </p>
              )}
            />
            <FlexGrow grow={1}>
              <StyledText color={'#000000'} fontWeight={'bold'}>
                Withdraw Course End Date
              </StyledText>
            </FlexGrow>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                openTo="year"
                value={withdraw_end}
                onChange={(newValue) => {
                  setDropCourseEnd(newValue);
                }}
                renderInput={
                  (params) => <TextField {...params} helperText={null} />}
              />
            </LocalizationProvider>
            <ErrorMessage
              errors={errors}
              name="withdraw_end"
              render={({ message }) => (
                <p
                  style={{
                    marginTop: 0,
                    color: theme.color.danger,
                  }}>
                  {message}
                </p>
              )}
            />
            <FlexBox
              alignItems="center"
              justify="flex-end"
              style={{
                marginTop: theme.spacing.x4,
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow>
                <RightAlign>
                  <Button
                    onClick={() => { setOpen?.(false); onEditComplete?.(); }}
                    isOutline
                    status={Status.primary}
                    style={{ padding: '4px 12px' }}>
                    <Icon name="x-s" mr={0.5} />
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    onClick={() => handleSubmit(onSubmitClicked)}
                    status={Status.primary}
                    backgroundColor="#CB092B"
                    style={{ padding: '4px 12px', marginLeft: '8px' }}>
                    <Icon name="floppy" mr={0.5} />
                    Save
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
          </form>
        ) : (
          <Icon mr={1} name="spinner" spin />
        )}
      </Modal>
      <Modal
        isOpen={isOpenError}
        setOpen={setOpenError}
        onClose={onError}
        customStyle={{ content: { textAlign: 'center' } }}>
        <img
          src="/alert-warning.svg"
          alt="alert warning"
          style={{ margin: 'auto' }}
        />
        <h3>Cannot Edit Term</h3>
        <p>
          An error occurred while editing the term.
          This error can occur if the same term name already exist.
          Please try again or contact us if the problem persists.
        </p>
        <Button status={Status.primary} onClick={onErrorCloseModal}>
          Done
        </Button>
      </Modal>
    </>
  );
};

export default TermEditModal;
