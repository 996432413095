// const { GetObjectCommand, ListObjectsCommand } = require('@aws-sdk/client-s3');
// const { writeFileSync } = require('fs');

import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';

// const { S3Client } = require('@aws-sdk/client-s3');
const s3Client = new S3Client({
  endpoint: 'https://sgp1.digitaloceanspaces.com',
  region: 'sgp1',
  credentials: {
    accessKeyId: 'QJCMLH6KQDS73X4XFVZX',
    secretAccessKey: 'aUqQpnqAfII6jcEpmEE/iLcZiIRabGYHVS6Bs9t1eZE',
  },
});
// Downloads your file and saves its contents to /tmp/local-file.ext.
// eslint-disable-next-line consistent-return
const getUrl = (keyStr: any) => s3Client
  .send(
    new GetObjectCommand({
      Bucket: 'omega-blob',
      Key: keyStr,
    }),
  )
  .then((response) => response.Body)
  .then((body) => {
    const reader = (body as any).getReader();

    return new ReadableStream({
      start(controller) {
        return pump();

        function pump() {
          return reader.read().then(({ done, value }: any) => {
            // When no more data needs to be consumed, close the stream
            if (done) {
              controller.close();
              return;
            }

            // Enqueue the next data chunk into our target stream
            controller.enqueue(value);
            // eslint-disable-next-line consistent-return
            return pump();
          });
        }
      },
    });
  })
  .then((stream) => new Response(stream))
  .then((response) => response.blob())
  .then((blob) => URL.createObjectURL(blob))
  .catch((err) => console.error(err));
export default getUrl;
