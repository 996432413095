import Icon from './Icon';

type UploadProps = {
  title: string;
  id: string;
  uploadChange: (e: any) => void;
};
const Upload = ({
  title,
  id,
  uploadChange,
}: UploadProps): JSX.Element => (
  <>
    <label
      style={{
        cursor: 'pointer',
        color: '#CB092B',
        border: '1px solid #CB092B',
        padding: '4px 12px',
        borderRadius: '4px',
        marginRight: '16px',
      }}
      htmlFor={id}>
      <>
        <Icon mr={0.5} name="cloud-upload"></Icon>
        Upload {title}
      </>
    </label>
    <input
      type="file"
      id={id}
      accept=".pdf"
      style={{ display: 'none' }}
      // eslint-disable-next-line no-return-assign
      onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        (e.target as HTMLInputElement).value = '';
      }}
      onChange={uploadChange}
    />
  </>
);

export default Upload;
