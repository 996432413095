import {
  useMemo, useState, useEffect, useContext,
} from 'react';
import { useScreenClass } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import styled, { css } from 'styled-components';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import moment from 'moment';
import _theme from '../styles/theme';
import { Col, Row } from './StyledGridSystem';
import Card from './Card';
import FlexBox, { FlexGrow } from './FlexBox';
import { RightAlign } from './UtilsComponents';
import Status from '../types/enum/Status';
import Button from './Button';
import Icon from './Icon';
import Table from './Table';
import NullStateTR from './NullStateTr';
import Input from './Input';
import { StudentContext } from '../context/StudentBehalfContext';
import Radio from './Radio';
import { StyledText } from './Text';
import getNationalityList from '../hooks/nationalityList';
import MinimalSelect from './MinimalSelect';

interface ScreenProps {
  readonly isMobile: boolean;
}

const StyledTR = styled.tr<ScreenProps>`
  display: ${(props) => (props.isMobile ? 'inline-grid' : 'table-row')};
  width: 100%;
`;
const StyledTH = styled.th<ScreenProps>`
  padding: ${(props) => (props.isMobile ? '8px 16px 0px 16px !important' : '8px 16px !important')};
`;
const StyledTD = styled.td<ScreenProps>`
  display: ${(props) => (props.isMobile ? 'inline-grid' : 'table-cell')};
  padding: ${(props) => (props.isMobile ? '0px 16px 8px 16px !important' : '8px 16px !important')};
`;
const StyledLabel = styled.label<ScreenProps>`
  width: ${(props) => (props.isMobile ? '100%' : 'auto')};
  white-space: nowrap;
`;
const sharedMinimalStyle = css`
  min-width: 230px;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 0px 12px;
  .react-select__menu {
    width: 100%;
    min-width: unset;
    margin-left: -12px;
  }
`;
const StyledMinimalSelect = styled(MinimalSelect)`
  ${sharedMinimalStyle}
`;
type Gender = 'male' | 'female' | 'prefer_not_to_say';
const GenderList = [
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'female',
    label: 'Female',
  },
  {
    value: '',
    label: 'Custom',
  },
  {
    value: 'prefer_not_to_say',
    label: 'Prefer Not to Say',
  },
];
type ViewMode = 'view' | 'edit';
type UserType = 'admin' | 'student';
/* eslint-disable camelcase */
interface User {
  user_id: string;
  personal_email: string;
  gender: Gender | string;
  phone_number: string;
  nationality: string;
  birthdate: string;
}
type PersonalInfoForm = {
  personal_email: string | undefined;
  gender: string | undefined;
  birthdate: string | undefined;
  nationality: string | undefined;
  phone_number: string | undefined;
};
type PersonalInfoProps = {
  defViewMode: ViewMode;
  userType: UserType;
  userInfo: User;
  information_confirm: boolean;
  reloadParent: any;
};
const PersonalInfo = ({
  defViewMode,
  userType,
  userInfo,
  information_confirm,
  reloadParent,
}: PersonalInfoProps) => {
  const validationSchema = Yup.object({
    personal_email: Yup.string().email('Personal email is invalid'),
    gender: Yup.string(),
    birthdate: Yup.string(),
    nationality: Yup.string(),
    phone_number: Yup.string(),
  });
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors, isDirty },
  } = useForm<PersonalInfoForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      personal_email: userInfo.personal_email,
      gender: userInfo.gender,
      birthdate: userInfo.birthdate,
      nationality: userInfo.nationality,
      phone_number: userInfo.phone_number,
    },
  });
  const { isBehalf } = useContext(StudentContext);
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  // Loading text on "Enrolled Courses"
  const [loading, setLoading] = useState<boolean>(true);
  const [viewMode, setViewMode] = useState<ViewMode>(defViewMode);
  // Enrolled Course Information for CourseInfoModal
  const [verified, setVerified] = useState<boolean>(false);
  const [nationalities, setNationalities] = useState(getNationalityList());
  const [personalInfo, setPersonalInfo] = useState({
    user_id: '',
    personal_email: '',
    birthdate: '',
    nationality: '',
    gender: '',
    phone_number: '',
  });
  const onSubmitClicked = (data: any) => {
    if (isDirty && userInfo.user_id && userInfo.user_id !== '') {
      console.log(data.birthdate, moment(data.birthdate).format());
      axios
        .put(`/api/v1/users/${userInfo.user_id}/personal-information`, {
          gender: data.gender,
          phone_number: data.phone_number,
          nationality: data.nationality,
          birthdate: moment(data.birthdate).format(),
          personal_email: data.personal_email,
        })
        .then(() => {
          history.replace({
            pathname: '/personalInfo',
            state: { userId: userInfo.user_id, role: userType },
          });
          reloadParent();
          setViewMode('view');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const onError = (errors: any, e: any) => console.log('error', errors, e);
  useEffect(() => {
    setPersonalInfo({
      user_id: userInfo.user_id,
      personal_email: userInfo.personal_email,
      birthdate: userInfo.birthdate,
      nationality: userInfo.nationality,
      gender: userInfo.gender,
      phone_number: userInfo.phone_number,
    });
    setVerified(information_confirm);
    setLoading(false);
  }, [userInfo, information_confirm]);
  // Redirect "Registration" alert and "Add Course/(s)" button to "Add Courses" Page
  const history = useHistory();
  const watchGender = watch('gender');
  function isCustomGender(watchStr: string | undefined) {
    if (
      watchStr
      && !['male', 'female', 'prefer_not_to_say'].includes(watchStr)
    ) {
      return true;
    }
    return false;
  }
  function checkEditing() {
    if (userType === 'admin') {
      return false;
    }
    if (userType === 'student' && !verified) {
      return false;
    }
    if (userType === 'student' && verified) {
      return true;
    }
    return false;
  }
  return (
    <>
      <Card style={{ margin: '24px 0px' }}>
        <form onSubmit={handleSubmit(onSubmitClicked, onError)}>
          <FlexBox
            alignItems="center"
            style={{
              marginBottom: _theme.spacing.x2,
            }}>
            <FlexGrow>
              <h3>Personal Information</h3>
            </FlexGrow>
            <FlexGrow ml-auto style={{ marginLeft: 'auto' }}>
              <RightAlign>
                {viewMode === 'view' && (
                  <>
                    <Button
                      onClick={() => {
                        setViewMode('edit');
                      }}
                      disabled={checkEditing()}
                      isOutline
                      status={Status.primary}
                      style={{ padding: '4px 12px' }}>
                      <Icon name="pencil-edit" mr={0.5} />
                      Edit
                    </Button>
                  </>
                )}
                {viewMode === 'edit' && !isMobile && (
                  <>
                    <Button
                      onClick={() => {
                        setViewMode('view');
                      }}
                      isOutline
                      status={Status.primary}
                      style={{ padding: '4px 12px' }}>
                      <Icon name="x-s" mr={0.5} />
                      Discard Changes
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => handleSubmit(onSubmitClicked)}
                      status={Status.primary}
                      backgroundColor="#CB092B"
                      style={{ padding: '4px 12px', margin: '0px 16px' }}>
                      <Icon name="check-mark" mr={0.5} />
                      Save Changes
                    </Button>
                  </>
                )}
              </RightAlign>
            </FlexGrow>
          </FlexBox>
          <Row>
            <Col md={12}>
              <Table>
                <tbody>
                  {!loading ? (
                    <>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Gender
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {personalInfo.gender !== ''
                                ? personalInfo.gender
                                : '-'}
                            </>
                          ) : (
                            <>
                              {GenderList.map((gender: any) => (
                                <StyledLabel
                                  isMobile={isMobile}
                                  htmlFor={gender.value}
                                  style={{
                                    marginRight: `${_theme.spacing.base * 3}px`,
                                  }}>
                                  <Input
                                    isFullWidth={false}
                                    type="radio"
                                    id={gender.value}
                                    {...register('gender')}
                                    className={`form-control ${errors.gender ? 'is-invalid' : ''}`}
                                    value={gender.value}
                                    // checked={gender.value === personalInfo.gender}
                                    style={{
                                      width: `${_theme.spacing.base * 2}px`,
                                      height: `${_theme.spacing.base * 2}px`,
                                      verticalAlign: 'middle',
                                      marginRight: _theme.spacing.base,
                                    }}
                                  />
                                  <StyledText
                                    style={{
                                      verticalAlign: 'middle',
                                    }}>
                                    {gender.label}
                                  </StyledText>
                                  {gender.value === '' && (
                                    <Input
                                      isFullWidth={false}
                                      type="text"
                                      // {...register('gender')}
                                      // className={`form-control ${
                                      //   errors.phone_number ? 'is-invalid' : ''
                                      // }`}
                                      style={{
                                        width: `${_theme.spacing.base * 11}px`,
                                        height: `${_theme.spacing.base * 4}px`,
                                        verticalAlign: 'middle',
                                        marginRight: _theme.spacing.base,
                                        marginLeft: _theme.spacing.base,
                                      }}
                                    />
                                  )}
                                </StyledLabel>
                              ))}
                              <ErrorMessage
                                errors={errors}
                                name="gender"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Date of birth
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {personalInfo.birthdate !== ''
                                && personalInfo.birthdate !== '0001-01-01T00:00:00Z'
                                ? moment(personalInfo.birthdate).format('DD/MM/YYYY')
                                : '-'}
                            </>
                          ) : (
                            <>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Controller
                                  control={control}
                                  defaultValue={personalInfo.birthdate}
                                  name="birthdate"
                                  render={({ field }) => (
                                    <DesktopDatePicker
                                      {...field}
                                      label="Select date of birth"
                                      inputFormat="DD/MM/YYYY"
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Nationality
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {personalInfo.nationality !== ''
                                ? personalInfo.nationality
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Controller
                                control={control}
                                defaultValue={personalInfo.nationality}
                                name="nationality"
                                render={({ field }) => (
                                  <StyledMinimalSelect
                                    {...field}
                                    value={{
                                      label: field.value ? field.value : '',
                                      value: field.value ? field.value : '',
                                    }}
                                    onChange={(data: { value: any }) => field.onChange(data?.value)
                                    }
                                    options={nationalities}
                                  />
                                )}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="phone_number"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Phone Number
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {personalInfo.phone_number !== ''
                                ? personalInfo.phone_number
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Input
                                isFullWidth
                                type="text"
                                {...register('phone_number')}
                                className={`form-control ${errors.phone_number ? 'is-invalid' : ''}`}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="phone_number"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Personal Email
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {personalInfo.personal_email !== ''
                                ? personalInfo.personal_email
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Input
                                isFullWidth
                                type="text"
                                {...register('personal_email')}
                                className={`form-control ${errors.personal_email ? 'is-invalid' : ''}`}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="personal_email"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                    </>
                  ) : (
                    <NullStateTR>
                      <Icon mr={1} name="spinner" spin />
                      Loading...
                    </NullStateTR>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
          {viewMode === 'edit' && isMobile && (
            <FlexBox
              direction={'row'}
              justify="space-between"
              alignItems="center">
              <FlexGrow>
                <Button
                  onClick={() => {
                    setViewMode('view');
                  }}
                  isOutline
                  status={Status.primary}
                  style={{ padding: '0px 5px', border: 'none' }}>
                  <Icon name="x-s" mr={0.5} />
                  Cancel
                </Button>
              </FlexGrow>
              <FlexGrow>
                <RightAlign>
                  <Button
                    type="submit"
                    onClick={() => handleSubmit(onSubmitClicked)}
                    status={Status.primary}
                    backgroundColor="#CB092B"
                    style={{ padding: '0px 10px' }}>
                    <Icon name="check-mark" mr={0.5} />
                    Save
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
          )}
        </form>
      </Card>
    </>
  );
};

export default PersonalInfo;
