// import React, { useEffect } from 'react';

// export const useOutsideClick = (
//   ref: React.MutableRefObject<any>,
//   fn: (e: Event) => void,
//   outSideOnly = true,
// ): void => {
//   // REFERENCE: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component

//   useEffect(() => {
//     function handleClickOutside(event: Event) {
//       if (
//         !outSideOnly
//         || (ref.current && !ref.current.contains(event.target))
//       ) {
//         fn(event);
//       }
//     }
//     // Bind the event listener
//     document.addEventListener('click', handleClickOutside);
//     return () => {
//       // Unbind the event listener on clean up
//       document.removeEventListener('click', handleClickOutside);
//     };
//   }, [ref, fn, outSideOnly]);
// };

// export default {
//   useOutsideClick,
// };
import { useEffect, RefObject } from 'react';

/**
 * Hook that handles outside click event of the passed refs
 *
 * @param refs array of refs
 * @param handler a handler function to be called when clicked outside
 */
export function useOutsideClick(
  refs: React.MutableRefObject<any>,
  handler?: () => void,
  outSideOnly = true,
) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (!handler) return;

      // Clicked browser's scrollbar
      if (
        event.target === document.getElementsByTagName('html')[0]
        && event.clientX >= document.documentElement.offsetWidth
      ) return;

      let containedToAnyRefs = false;
      // for (const rf of refs) {
      if (refs && refs.current && refs.current.contains(event.target)) {
        containedToAnyRefs = true;
      }
      // }

      // Not contained to any given refs
      if (!containedToAnyRefs) {
        handler();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, handler]);
}
export default {
  useOutsideClick,
};
