/* eslint-disable max-len */
import { useMemo, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import axios from 'axios';
import { useScreenClass } from 'react-grid-system';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Breadcrumb from '../../components/Breadcrumb';
import MinimalSelect from '../../components/MinimalSelect';
import _theme from '../../styles/theme';
import FlexBox, { FlexGrow } from '../../components/FlexBox';
import TabGroup, { TabItem } from '../../components/TabGroup';
import BasicCourseInfo from '../../components/BasicCourseInfo';
import Pill from '../../components/Pill';
import Status from '../../types/enum/Status';
import CourseSectionInfo from '../../components/CourseSectionInfo';
import CourseStudentsInfo from '../../components/CourseStudentsInfo';
import { RightAlign } from '../../components/UtilsComponents';
import { CourseContent, EmptyCourseContent } from '../../filter/FilterValue';

const RightAlignBold = styled.div`
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
`;

const Header = styled.h2<{ isMobile?: boolean }>`
  font-size: ${({ isMobile }) => (isMobile ? '1.25rem' : '1.75rem')};
  margin-bottom: 0;
`;
const SubHeader = styled.div<{ isMobile?: boolean }>`
  font-size: ${({ isMobile }) => (isMobile ? '0.75rem' : '1rem')};
  display: flex;
  justify-content: flex-start;
`;
const Greytext = styled.div`
  color: ${({ theme }) => theme.color.textSecondary}!important;
  white-space: nowrap;import Label from '../../components/Label';

`;
const Boldtext = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  white-space: nowrap;
`;
const sharedMinimalStyle = css`
  width: 100%;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 0px 12px;
  .react-select__menu {
    width: 100%;
    min-width: unset;
    margin-left: -12px;
  }
`;
const StyledMinimalSelect = styled(MinimalSelect)`
  ${sharedMinimalStyle}
`;
const GreytextDate = styled.div<{ isMobile?: boolean }>`
  white-space: nowrap;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ isMobile }) => (isMobile ? '0.6rem' : '0.75rem')};
  line-height: 15px;
  color: #000000;
`;
interface SectionForm {
  capacity: number;
  instructors: {
    id: string | undefined;
  }[];
  room: {
    id: string;
  };
  term: {
    id: string;
  };
  seat: number;
  name: string;
  schedule: {
    id: string;
    TOD: string;
    day: string;
    end: string;
    start: string;
  }[];
}

function CourseInfoPage(this: any, props: any) {
  const { courseId } = props.location.state || {};
  const { semester } = props.location.state || '';
  const [tab, setTab] = useState(0);
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [courseInfo, setCourseInfo] = useState<CourseContent>(EmptyCourseContent);
  const {
    register: registerSection,
    control: controlSection,
    handleSubmit: handleSubmitSection,
    setValue: setValueSection,
    reset: resetSection,
    watch: watchSection,
    formState: { errors: errorSection, isDirty: isDirtySection },
  } = useForm<SectionForm>({
    // resolver: yupResolver(validationSchemaSection),
    mode: 'onBlur',
    defaultValues: {
      capacity: 0,
      instructors: [],
      room: {},
      term: {},
      seat: 0,
      name: '',
      schedule: [],
    },
  });
  const test = [
    { name: 'Courses', link: '/admin/courses' },
    { name: courseInfo.short_name, link: '/admin/courses/info' },
  ];
  useEffect(() => {
    // fetchStage();
    fetchCourseInfo();
    fetchTerms();
  }, []);
  const fetchCourseInfo = () => {
    setLoading(true);
    axios
      .get(`/api/v1/courses/${courseId}`)
      .then((res) => {
        if (res.data.data.course) {
          // setUserInfo(res.data.data);
          mapCourseInfo(res.data.data.course);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const mapCourseInfo = (course: CourseContent) => {
    setCourseInfo({
      ...courseInfo,
      id: course.id,
      code: course.code,
      full_name: course.full_name,
      short_name: course.short_name,
      description: course.description,
      units: course.units,
      condition: {
        prerequisites: course.condition.prerequisites,
        co_requisites: course.condition.co_requisites,
      },
      programs: course.programs,
    });
  };
  const [terms, setTerms] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const [term, setTerm] = useState({
    label: '',
    value: '',
  });
  const [value, setValue] = useState({});
  const refresh = () => {
    setValue({});
  };
  const handleChangeSemester = (obj: any) => {
    setTerm({
      label: obj.label,
      value: obj.value,
    });
    console.log('Semester: ', term);
    refresh();
  };
  const fetchTerms = () => {
    setLoading(true);
    axios
      .get('/api/v1/courses/terms')
      .then((res) => {
        if (res.data.data.terms) {
          // eslint-disable-next-line max-len
          const termList: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.terms.forEach(
            (element: { name: string; id: string }) => {
              const array = { label: '', value: '' };
              array.label = element.name;
              array.value = element.id;
              termList.push(array);
            },
          );
          setTerms(termList);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <>
      {console.log(courseInfo, courseId)}
      <FlexBox>
        <FlexGrow>
          <Breadcrumb path={test} />
        </FlexGrow>
      </FlexBox>
      <FlexBox
        alignItems="center"
        style={{
          marginTop: _theme.spacing.base * 3,
          marginBottom: _theme.spacing.base * 3,
        }}>
        <FlexGrow>
          <Greytext>
            <SubHeader isMobile={isMobile}>{courseInfo.code}</SubHeader>
          </Greytext>
          <Header style={{ marginTop: 0 }} isMobile={isMobile}>
            {courseInfo.short_name}
          </Header>
          <FlexBox>
            <Greytext style={{ display: 'flex' }}>
              <SubHeader isMobile={isMobile}>Semester:</SubHeader>
            </Greytext>
            {/* <RightAlignBold>
              {semester !== '' ? semester : 'N/A'} */}
            <FlexGrow>
              <Controller
                control={controlSection}
                rules={{ required: 'Please select the term' }}
                name="term.id"
                render={({
                  field: {
                    onChange, onBlur, name, ref, value,
                  },
                }) => (
                  <StyledMinimalSelect
                    multi={false}
                    placeholder='Find term...'
                    options={terms}
                    value={terms.find((c) => c.value === value)}
                    onChange={(val: { value: any }) => {
                      onChange(val.value);
                      handleChangeSemester(val);
                    }}
                    innerRef={ref}
                    style={{ width: 'inherit' }}
                  />
                )}
              />
            </FlexGrow>
            {/* </RightAlignBold> */}
          </FlexBox>
        </FlexGrow>
        <FlexGrow grow={isMobile ? 2 : 1}>
          <RightAlign style={{ flexDirection: 'column' }}>
            <FlexBox style={{ marginBottom: '8px' }}>
              <FlexGrow>
                <RightAlignBold>Status</RightAlignBold>
              </FlexGrow>
              <FlexGrow>
                <Pill status={Status.success}>Course Progress</Pill>
              </FlexGrow>
            </FlexBox>
            <FlexBox>
              <FlexGrow>
                <RightAlignBold>Teaching Date</RightAlignBold>
              </FlexGrow>
              <FlexGrow>
                <GreytextDate isMobile={isMobile}>
                  14 Sep 2021 - 31 Dec 2021
                </GreytextDate>
              </FlexGrow>
            </FlexBox>
          </RightAlign>
        </FlexGrow>
      </FlexBox>
      {/* <UserInfo></UserInfo> */}
      {!loading ? (
        <>
          <TabGroup>
            <TabItem active={tab === 0} onClick={() => setTab(0)}>
              Course Information
            </TabItem>
            <TabItem active={tab === 1} onClick={() => setTab(1)}>
              Students & Grades
            </TabItem>
            <TabItem active={tab === 2} onClick={() => setTab(2)}>
              Activity
            </TabItem>
          </TabGroup>
          {tab === 0 && (
            <>
              <FlexBox
                alignItems="flex-start"
                style={{
                  marginBottom: _theme.spacing.x2,
                }}>
                <FlexGrow grow={2}>
                  <BasicCourseInfo
                    defViewMode={'view'}
                    courseInfo={courseInfo}
                    reloadParent={fetchCourseInfo.bind(this)}></BasicCourseInfo>
                </FlexGrow>
                <FlexGrow>
                  <CourseSectionInfo
                    defViewMode={'view'}
                    courseId={courseId}
                    semester={term.label}
                    reloadParent={fetchCourseInfo.bind(
                      this,
                    )}></CourseSectionInfo>
                </FlexGrow>
              </FlexBox>
            </>
          )}
          {tab === 1 && (
            <>
              <FlexBox
                alignItems="flex-start"
                style={{
                  marginBottom: _theme.spacing.x2,
                }}>
                <FlexGrow>
                  <CourseStudentsInfo
                    courseId={courseInfo.id} term={term}></CourseStudentsInfo>
                </FlexGrow>
              </FlexBox>
            </>
          )}
          {tab === 2 && (
            <>
              <FlexBox
                alignItems="flex-start"
                style={{
                  marginBottom: _theme.spacing.x2,
                }}>
                <FlexGrow></FlexGrow>
              </FlexBox>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
export default CourseInfoPage;
