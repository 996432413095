/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import {
  useEffect, useMemo, useState, useContext, Key,
} from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useScreenClass } from 'react-grid-system';
import Card from '../../components/Card';
import Breadcrumb from '../../components/Breadcrumb';
import { Col, Row } from '../../components/StyledGridSystem';
import FlexBox, { FlexGrow } from '../../components/FlexBox';
import { NullStateText } from '../../components/Text';
import { StudentContext } from '../../context/StudentBehalfContext';
import Table from '../../components/Table';
import DocumentDropdown from '../../components/DocumentDropdown';
import SnackBar from '../../components/SnackBar';
import Upload from '../../components/Upload';
import ProgramSelectModal from '../../components/ProgramSelectModal';
import { UserContext } from '../../context/UserContext';
import EditDocument from '../../components/EditDocument';
import TypeSelectModal from '../../components/TypeSelectModal';

const ZeroCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing.x3};
  margin-top: ${({ theme }) => theme.spacing.x1};
  text-align: center;
`;

interface Document {
  created_at: string;
  description: string;
  file_name: string;
  id: string;
  program_id: string;
  type: string;
  url: string;
}
function DocumentsPage(this: any, props: any) {
  const test = [{ name: 'Forms & Documents', link: '/documents' }];
  const { student, isBehalf } = useContext(StudentContext);
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState<boolean>(false);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<any>();
  const [openProgramSelect, setOpenProgramSelect] = useState<boolean>(false);
  const [openTypeSelect, setOpenTypeSelect] = useState<boolean>(false);
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const [documents, setDocuments] = useState<Array<Document>>([]);
  const [currentDocument, setCurrentDocument] = useState<Document>();
  // <-------- fetch data ---------->
  useEffect(() => {
    console.log(user, student, isBehalf);
    if (isBehalf || user.role === 'student') {
      if (student.program && student.program.id) {
        fetchStudentDocuments(student.program.id);
      } else {
        axios
          .get(`/api/v1/users/${student.user_id}`)
          .then((res) => {
            if (res.data.data.user) {
              fetchStudentDocuments(res.data.data.user.program.id);
            }
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    }
    if (!isBehalf && user.role === 'admin') {
      fetchDocuments();
    }
  }, [user, student, isBehalf]);
  const fetchStudentDocuments = (prog: string) => {
    setLoading(true);
    axios
      .get(`/api/v1/document?programId=${prog}`)
      .then((res) => {
        setDocuments(res.data.data.documents);
        setLoading(false);
      })
      .catch(() => {
        setDocuments([]);
        setLoading(false);
      });
  };
  const fetchDocuments = () => {
    setLoading(true);
    axios
      .get('/api/v1/document')
      .then((res) => {
        setDocuments(res.data.data.documents);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const downloadDocument = (urlDocumtent: string, name: string) => {
    const link = document.createElement('a');
    link.href = urlDocumtent;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  };
  const deleteDocument = (docId: string) => {
    setLoading(true);
    axios
      .delete(`/api/v1/document/${docId}`)
      .then(() => {
        setIsSuccessOpen(true);
        setLoading(false);
        fetchDocuments();
      })
      .catch(() => {
        setIsErrorOpen(true);
        setLoading(false);
      });
  };
  const uploadHandbook = (file: any, progId: string) => {
    setLoading(true);
    console.log(file);
    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios
      .post(`/api/v1/document/handbook/${progId}`, formData, config)
      .then((res) => {
        setLoading(false);
        setOpenProgramSelect(false);
        setUploadSuccess(true);
        fetchDocuments();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setIsErrorOpen(true);
      });
  };
  const uploadForm = (file: any, type: string) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios
      .post('/api/v1/document', formData, config)
      .then((res) => {
        setLoading(false);
        setOpenTypeSelect(false);
        setOpenProgramSelect(false);
        setUploadSuccess(true);
        fetchDocuments();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setIsErrorOpen(true);
      });
  };
  return (
    <>
      {editOpen && currentDocument && (
        <EditDocument
          isOpen={editOpen}
          setOpen={setEditOpen}
          info={currentDocument}
          onEditComplete={() => {
            setEditOpen(false);
            fetchDocuments();
          }}
        />
      )}
      <SnackBar
        isOpen={isSuccessOpen}
        setOpen={setIsSuccessOpen}
        message="Document deleted successfully!"
        type="success"
      />
      <SnackBar
        isOpen={uploadSuccess}
        setOpen={setUploadSuccess}
        message="Document uploaded successfully!"
        type="success"
      />
      <SnackBar
        isOpen={isErrorOpen}
        setOpen={setIsErrorOpen}
        message="Something went wrong. Please try again!"
        type="error"
      />
      <ProgramSelectModal
        isOpen={openProgramSelect}
        setOpen={setOpenProgramSelect}
        onSelect={(id: string) => {
          uploadHandbook(uploadedFile, id);
        }}
      />
      <TypeSelectModal
        isOpen={openTypeSelect}
        setOpen={setOpenTypeSelect}
        onSelect={(id: string) => {
          uploadForm(uploadedFile, id);
        }}
      />
      <Row>
        <Col>
          <Breadcrumb path={test} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FlexBox space={2} direction={isMobile ? 'column' : 'row'}>
            <FlexGrow>
              <h2 style={{ marginRight: 'auto', marginBottom: 0 }}>
                Forms &amp; Documents
              </h2>
            </FlexGrow>

            {(user.role === 'admin' || isBehalf) && (
              <>
                <FlexGrow style={{ textAlign: 'end' }}>
                  <Upload
                    title={'Form'}
                    id={'form'}
                    uploadChange={(e: any) => {
                      setUploadedFile(e.target.files[0]);
                      setOpenTypeSelect(true);
                    }}
                  />
                  <Upload
                    title={'Handbook'}
                    id={'handbook'}
                    uploadChange={(e: any) => {
                      setUploadedFile(e.target.files[0]);
                      setOpenProgramSelect(true);
                    }}
                  />
                </FlexGrow>
              </>
            )}
          </FlexBox>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Table>
              {!isMobile ? (
                <>
                  <thead>
                    <tr>
                      <th style={{ width: '40%' }}>File Name</th>
                      <th style={{ width: '30%' }}>Description</th>
                      <th style={{ width: '20%' }}>Type</th>
                      <th style={{ width: '10%' }}></th>
                    </tr>
                  </thead>
                  {documents && documents.length > 0 ? (
                    documents?.map(
                      (doc: Document, index: Key | null | undefined) => (
                        <>
                          <tr key={index}>
                            <td style={{ overflowWrap: 'anywhere' }}>
                              {doc.file_name}
                            </td>
                            <td style={{ overflowWrap: 'anywhere' }}>
                              {doc.description}
                            </td>
                            <td>
                              {doc.type === 'form'
                                ? 'Form'
                                : doc.type === 'academic_calendar'
                                  ? 'Academic Calendar'
                                  : doc.type}
                            </td>
                            <td style={{ textAlign: 'end' }}>
                              <DocumentDropdown
                                user={user}
                                downloadDocument={() => {
                                  downloadDocument(doc.url, doc.file_name);
                                }}
                                editDocument={() => {
                                  setCurrentDocument(doc);
                                  setEditOpen(true);
                                }}
                                deleteDocument={() => {
                                  deleteDocument(doc.id);
                                }}
                              />
                            </td>
                          </tr>
                        </>
                      ),
                    )
                  ) : (
                    <tr>
                      <td style={{ border: 'none' }} colSpan={4}>
                        <ZeroCard>
                          <NullStateText>No document</NullStateText>
                        </ZeroCard>
                      </td>
                    </tr>
                  )}
                </>
              ) : (
                <>
                  <thead>
                    <tr>
                      <th style={{ width: '40%' }}>File Name</th>
                      <th style={{ width: '30%' }}>Description</th>
                      <th style={{ width: '20%' }}>Type</th>
                      <th style={{ width: '10%' }}></th>
                    </tr>
                  </thead>
                  {documents && documents.length > 0 ? (
                    documents?.map(
                      (doc: Document, index: Key | null | undefined) => (
                        <>
                          <tr key={index}>
                            <td style={{ overflowWrap: 'anywhere' }}>
                              {doc.file_name}
                            </td>
                            <th style={{ overflowWrap: 'anywhere' }}>
                              {doc.description}
                            </th>
                            <td>
                              {doc.type === 'form'
                                ? 'Form'
                                : doc.type === 'academic_calendar'
                                  ? 'Academic Calendar'
                                  : doc.type}
                            </td>
                            <td style={{ textAlign: 'end' }}>
                              <DocumentDropdown
                                user={user}
                                downloadDocument={() => {
                                  downloadDocument(doc.url, doc.file_name);
                                }}
                                editDocument={() => {
                                  setCurrentDocument(doc);
                                  setEditOpen(true);
                                }}
                                deleteDocument={() => {
                                  deleteDocument(doc.id);
                                }}
                              />
                            </td>
                          </tr>
                        </>
                      ),
                    )
                  ) : (
                    <tr>
                      <td style={{ border: 'none' }} colSpan={4}>
                        <ZeroCard>
                          <NullStateText>No document</NullStateText>
                        </ZeroCard>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </Table>
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default DocumentsPage;
