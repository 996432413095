/* eslint-disable camelcase */
import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { useScreenClass } from 'react-grid-system';

import {
  Controller, useFieldArray, useForm, useWatch,
} from 'react-hook-form';
import Select from 'react-select';
import styled from 'styled-components';
import _theme from '../styles/theme';
import Status from '../types/enum/Status';
import Button from './Button';
import FlexBox, { FlexGrow } from './FlexBox';
import { ClickableIcon } from './Icon';
import Input from './Input';
import Modal from './Modal';
import Table from './Table';
import { StyledText } from './Text';

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;
interface SectionForm {
  name: string;
  description: string;
  total_amount: number;
  financial_plan_id: string;
  program_id: string;
  academic_year_id: string;
  items: {
    name: string;
    amount: number;
    type: string;
  }[];
}
type AddTemplateModalProps = {
  isOpen?: boolean;
  setOpen: (x: boolean) => void;
  onCreateComplete?: () => void;
  onEditError?: (err: any) => void;
};

const AddTemplateModal = ({
  isOpen,
  setOpen,
  onCreateComplete,
}: AddTemplateModalProps): JSX.Element => {
  const screenClass = useScreenClass();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [academicYears, setAcademicYears] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const [plans, setPlans] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const [programs, setPrograms] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SectionForm>({
    defaultValues: {
      items: [{ name: '', amount: 0, type: 'Fee' }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    name: 'items',
    control,
    keyName: 'key',
  });
  useEffect(() => {
    fetchAcademicYears();
    fetchPlans();
    fetchPrograms();
  }, []);
  const value = useWatch({
    control,
    name: 'items',
  });
  useEffect(() => {
    let totalAmount = 0;
    value.forEach((item) => {
      totalAmount += Number(item.amount);
    });
    setValue('total_amount', totalAmount);
  }, [value]);
  const calculateTotal = () => {};
  const fetchPrograms = () => {
    setLoading(true);
    axios
      .get('/api/v1/courses/programs')
      .then((res) => {
        if (res.data.data.programs) {
          // eslint-disable-next-line max-len
          const programList: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.programs.forEach(
            (element: { name: string; id: string }) => {
              const array = { label: '', value: '' };
              array.label = element.name;
              array.value = element.id;
              programList.push(array);
            },
          );
          setPrograms(programList);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const fetchAcademicYears = () => {
    axios
      .get('/api/v1/courses/academic-years')
      .then((res) => {
        if (res.data.data.academic_years) {
          const lecturerList: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.academic_years.forEach(
            (element: { name: string; id: string }) => {
              const array = { label: '', value: '' };
              array.label = element.name;
              array.value = element.id;
              lecturerList.push(array);
            },
          );
          setAcademicYears(lecturerList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchPlans = () => {
    axios
      .get('/api/v1/finances/plans')
      .then((res) => {
        if (res.data.data.plans) {
          const locationList: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.plans.forEach(
            (element: { name: string; id: string }) => {
              const array = { label: '', value: '' };
              array.label = element.name;
              array.value = element.id;
              locationList.push(array);
            },
          );
          setPlans(locationList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onSubmit = (data: any) => {
    setLoading(true);
    const editItems: { name: string; amount: number; type: string }[] = [];
    if (data.items && data.items.length > 0) {
      data.items.forEach((element: any) => {
        editItems.push({
          name: element.name,
          amount: Number(element.amount),
          type: element.type,
        });
      });
    }
    const values = {
      name: data.name,
      description: data.description,
      total_amount: Number(data.total_amount),
      financial_plan_id: data.financial_plan_id,
      program_id: data.program_id,
      academic_year_id: data.academic_year_id,
      items: editItems,
    };
    axios
      .post('/api/v1/templates', values)
      .then(() => {
        setLoading(false);
        onCreateComplete?.();
        setOpen(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  return (
    <>
      <Modal
        isOpen={!!isOpen}
        setOpen={setOpen}
        customStyle={{ content: { maxWidth: '655px' } }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexBox style={{ margin: '16px 0px' }}>
            <FlexGrow>
              <h3>Create New Template</h3>
            </FlexGrow>
            <ClickableIcon name="x-s" onClick={() => setOpen(false)} />
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px' }}>
            <FlexGrow>
              <StyledText>Template Name</StyledText>
              <Input
                isFullWidth
                {...register('name', {
                  required: 'Template name is required',
                })}></Input>
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => (
                  <p
                    style={{
                      marginTop: 0,
                      color: _theme.color.danger,
                    }}>
                    {message}
                  </p>
                )}
              />
            </FlexGrow>
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px' }}>
            <FlexGrow>
              <StyledText>Description</StyledText>
              <Input isFullWidth {...register('description')}></Input>
            </FlexGrow>
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px' }}>
            <FlexGrow>
              <StyledText>Total Amount(THB)</StyledText>
              <Input
                type="number"
                readOnly
                isFullWidth
                {...register('total_amount', {
                  required: 'Total Amount is required',
                })}></Input>
              <ErrorMessage
                errors={errors}
                name="total_amount"
                render={({ message }) => (
                  <p
                    style={{
                      marginTop: 0,
                      color: _theme.color.danger,
                    }}>
                    {message}
                  </p>
                )}
              />
            </FlexGrow>
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px 0px 0px' }}>
            <FlexGrow style={{ flex: '0 0 100%', marginBottom: '5px' }}>
              <StyledText>Financial Plan</StyledText>
              <Select
                isMulti={false}
                {...register('financial_plan_id', {
                  required: 'Financial Plan is required',
                })}
                options={plans}
                onChange={(e: any) => {
                  setValue('financial_plan_id', e.value);
                }}
              />
              <ErrorMessage
                errors={errors}
                name="financial_plan_id"
                render={({ message }) => (
                  <p
                    style={{
                      marginTop: 0,
                      color: _theme.color.danger,
                    }}>
                    {message}
                  </p>
                )}
              />
            </FlexGrow>
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px 0px 0px' }}>
            <FlexGrow style={{ flex: '0 0 100%', marginBottom: '5px' }}>
              <StyledText>Academic Year</StyledText>
              <Select
                isMulti={false}
                {...register('academic_year_id', {
                  required: 'Academic Year is required',
                })}
                options={academicYears}
                onChange={(e: any) => {
                  setValue('academic_year_id', e.value);
                }}
              />
              <ErrorMessage
                errors={errors}
                name="academic_year_id"
                render={({ message }) => (
                  <p
                    style={{
                      marginTop: 0,
                      color: _theme.color.danger,
                    }}>
                    {message}
                  </p>
                )}
              />
            </FlexGrow>
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px 0px 0px' }}>
            <FlexGrow style={{ flex: '0 0 100%', marginBottom: '5px' }}>
              <StyledText>Program</StyledText>
              <Select
                isMulti={false}
                {...register('program_id', {
                  required: 'Program is required',
                })}
                options={programs}
                onChange={(e: any) => {
                  setValue('program_id', e.value);
                }}
              />
              <ErrorMessage
                errors={errors}
                name="program_id"
                render={({ message }) => (
                  <p
                    style={{
                      marginTop: 0,
                      color: _theme.color.danger,
                    }}>
                    {message}
                  </p>
                )}
              />
            </FlexGrow>
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px 0px 0px' }}>
            <FlexGrow style={{ flex: '0 0 100%', marginBottom: '5px' }}>
              <Table>
                <thead>
                  <tr>
                    <th>Line Item Type</th>
                    <th>Line Item Name</th>
                    <th>Amount(THB)</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((field, index) => (
                    <>
                      <tr>
                        <td style={{ border: 'none' }}>
                          <Input
                            isFullWidth
                            {...register(`items.${index}.type` as const, {
                              required: 'Line Item type is required',
                            })}></Input>
                          <p
                            style={{
                              marginTop: 0,
                              color: _theme.color.danger,
                            }}>
                            {errors?.items?.[index]?.type}
                          </p>
                        </td>
                        <td style={{ border: 'none' }}>
                          <Input
                            isFullWidth
                            {...register(`items.${index}.name` as const, {
                              required: 'Line Item name is required',
                            })}></Input>
                          <p
                            style={{
                              marginTop: 0,
                              color: _theme.color.danger,
                            }}>
                            {errors?.items?.[index]?.name?.message}
                          </p>
                        </td>
                        <td style={{ border: 'none' }}>
                          <Input
                            type="number"
                            step="0.01"
                            isFullWidth
                            {...register(`items.${index}.amount` as const, {
                              required: 'Line Item amount is required',
                            })}></Input>
                          <p
                            style={{
                              marginTop: 0,
                              color: _theme.color.danger,
                            }}>
                            {errors?.items?.[index]?.amount?.message}
                          </p>
                        </td>
                        <td style={{ border: 'none' }}>
                          <RightAlign>
                            <ClickableIcon
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                remove(index);
                              }}
                              name="x-s"
                              style={{
                                color: '#7E7E7E',
                                verticalAlign: 'middle',
                              }}></ClickableIcon>
                          </RightAlign>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
            </FlexGrow>
          </FlexBox>
          <FlexBox style={{ marginTop: '5px' }}>
            <FlexGrow>
              <ClickableIcon
                type="button"
                title="Add Item"
                style={{ alignItems: 'center', color: _theme.color.primary }}
                status={Status.primary}
                name="plus-s"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  append({
                    name: '',
                    amount: 0,
                    type: 'Fee',
                  });
                }}
              />
            </FlexGrow>
          </FlexBox>
          <FlexBox
            alignItems="center"
            justify="flex-end"
            style={{
              marginBottom: _theme.spacing.x2,
            }}>
            <FlexGrow>
              <RightAlign>
                <Button
                  type="submit"
                  status={Status.primary}
                  backgroundColor="#CB092B"
                  style={{ padding: '4px 12px', marginLeft: '8px' }}>
                  {/* <Icon name="check-mark" mr={0.5} /> */}
                  Create
                </Button>
              </RightAlign>
            </FlexGrow>
          </FlexBox>
        </form>
      </Modal>
    </>
  );
};

export default AddTemplateModal;
