import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import Status from '../types/enum/Status';
import _theme from '../styles/theme';

export type ButtonProps = {
  theme: {
    [key: string]: any
  },
  children?: React.ReactNode;
  status?: Status;
  isFullwidth?: boolean;
  isOutline?: boolean;
  disabled?: boolean;
}

type ButtonFullProps = {
  backgroundColor?: string;
  borderColor?: string;
  fontColor?: string;
} & ButtonProps;

const Button = styled.button.attrs(({
  theme,
  children,
  status = Status.default,
  isOutline,
  disabled,
  isFullwidth = false,
  // backgroundColor = _theme.color.lightBackground,
  // borderColor,
  // fontColor,
}: ButtonProps) => {
  let backgroundColor: string;
  let borderColor: string;
  let fontColor: string;
  if (status === Status.default) {
    backgroundColor = isOutline ? 'transparent' : _theme.color.lightBackground;
    borderColor = isOutline ? _theme.color.lightBackground : 'transparent';
    fontColor = _theme.color.textColor;
  } else {
    backgroundColor = isOutline ? 'transparent' : _theme.color[status];
    borderColor = isOutline ? _theme.color[status] : 'transparent';
    fontColor = isOutline ? _theme.color[status] : _theme.color.white;
  }
  return ({
    theme,
    backgroundColor,
    borderColor,
    fontColor,
  });
}) <ButtonFullProps>`
  justify-content: center;
  align-items: center;
  display: inline-flex;
  box-sizing: border-box;
  min-height: ${({ theme }) => _theme.spacing.x4};
  ${({ isFullwidth }) => (isFullwidth ? 'width: 100%;' : '')}
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ fontColor }) => fontColor};
  ${({ theme }) => _theme.typography.paragraph};
  padding: 3px 12px;
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 4px;
  line-height: 150%;
  outline: none;
  cursor: pointer;
  transition: 0.1s all;
  text-decoration:none;

  &:hover:not(:disabled) {
    background-color: ${({
    theme,
    backgroundColor,
    isOutline,
    borderColor,
  }) => (isOutline ? borderColor : darken(0.05, backgroundColor || _theme.color.lightBackground))};
    text-decoration:none;
    color: ${({
    isOutline, theme, fontColor,
  }) => (isOutline ? _theme.color.white : fontColor)};
  }
  &:active:not(:disabled) {
    background-color: ${({
    theme,
    backgroundColor,
    isOutline,
    borderColor,
  }) => (isOutline ? darken(0.10, borderColor || _theme.color.borderColor) : darken(0.10, backgroundColor || _theme.color.lightBackground))};
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export default Button;
