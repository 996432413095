/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from 'react';
import ReactSelect from 'react-select';
import axios from 'axios';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ErrorMessage } from '@hookform/error-message';
import styled, { css } from 'styled-components';
import {
  Box,
  Step,
  StepLabel,
  StepLabelProps,
  Stepper,
  StepProps,
  Typography,
} from '@mui/material';
import { useScreenClass } from 'react-grid-system';
import { CourseContent } from '../../filter/FilterValue';
import FlexBox, { FlexGrow } from '../../components/FlexBox';
import Input, {
  InputContainer,
  InputIconContainer,
} from '../../components/Input';
import { StyledText } from '../../components/Text';
import TextArea from '../../components/TextArea';
import { RightAlign } from '../../components/UtilsComponents';
import Status from '../../types/enum/Status';
import theme from '../../styles/theme';
import Icon, { ClickableIcon } from '../../components/Icon';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Breadcrumb from '../../components/Breadcrumb';
import MinimalSelect from '../../components/MinimalSelect';
import TimeInput from '../../components/TimeInput';

const DayOptions = [
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  { label: 'Saturday', value: 'Saturday' },
  { label: 'Sunday', value: 'Sunday' },
];
const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    height: 32,
    minHeight: 32,
    padding: state.selectProps.isMobile ? '5px' : '5px 12px',
    cursor: 'pointer',
  }),
  input: () => ({
    display: 'none',
  }),
  singleValue: (base: any, state: any) => ({
    ...base,
    fontSize: state.selectProps.isMobile ? '0.85rem' : '1rem',
    top: state.selectProps.isMobile ? '67%' : '60%',
    maxWidth: state.selectProps.isMobile ? '46%' : '100%',
    height: 32,
    minHeight: 32,
  }),
  valueContainer: () => ({
    height: 32,
    minHeight: 32,
    marginTop: '-5px',
    'input[readonly]': {
      position: 'absolute',
      top: '-100px',
    },
  }),
  indicatorsContainer: () => ({
    height: 32,
    minHeight: 32,
  }),
  dropdownIndicator: () => ({
    color: 'gray',
  }),
};
const sharedMinimalStyle = css`
  width: 100%;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 0px 12px;
  .react-select__menu {
    width: 100%;
    min-width: unset;
    margin-left: -12px;
  }
`;
const StyledMinimalSelect = styled(MinimalSelect)`
  ${sharedMinimalStyle}
`;
const StyledReactSelect = styled(ReactSelect)<{ isMobile?: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '86px' : '128px')};
`;
type BasicCourseInfoForm = {
  code: string | undefined;
  full_name: string | undefined;
  short_name: string | undefined;
  description: string | undefined;
  units: number | undefined;
  prerequisites: string | undefined;
  co_requisites: string | undefined;
  programs: { id: any }[] | undefined;
};
interface SectionForm {
  capacity: number;
  instructors: {
    id: string | undefined;
  }[];
  room: {
    id: string;
  };
  term: {
    id: string;
  };
  seat: number;
  name: string;
  schedule: {
    id: string;
    TOD: string;
    day: string;
    end: string;
    start: string;
  }[];
}
const steps = ['Course Information', 'Section Information'];
function CreateCoursePage(props: any) {
  const { semester } = props.location.state;
  useEffect(() => {
    fetchPrograms();
    fetchTerms();
    fetchLocations();
    fetchInstructor();
    setLoading(false);
  }, []);
  const [terms, setTerms] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  useEffect(() => {
    findTermId(semester);
  }, [terms]);
  const [courseIdfromCreate, setCourseId] = useState('');
  const test = [
    { name: 'Courses', link: '/admin/courses' },
    { name: 'Create Course', link: '/admin/courses/add' },
  ];
  const { courseId } = props.location.state;
  console.log('courseId', courseId);
  console.log('semesterId', semester);
  const validationSchema = Yup.object({
    code: Yup.string().required('Course code is required'),
    full_name: Yup.string().required('Course Full Name is required'),
    short_name: Yup.string().required('Course Short Name is required'),
    description: Yup.string().required('Course description is required'),
    // term: Yup.array().of(
    //   Yup.object({ id: Yup.string() }),
    // ).length(1, 'Term is required').required('Term is required'),
    units: Yup.number(),
    prerequisites: Yup.string().nullable(),
    co_requisites: Yup.string().nullable(),
    programs: Yup.array(),
  });
  const validationSchemaSection = Yup.object({
    capacity: Yup.number().positive().integer(),
    // seat: Yup.number().positive().integer(),
    room: Yup.object({ id: Yup.string() }),
    term: Yup.object({ id: Yup.string().required('Term is required') }),
    name: Yup.string().required('Section Name is required'),
    schedule: Yup.array().of(
      Yup.object({
        id: Yup.string(),
        TOD: Yup.string(),
        day: Yup.string(),
        end: Yup.string(),
        start: Yup.string(),
      }),
    ),
    instructors: Yup.array().of(Yup.object({ id: Yup.string() })),
  });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<BasicCourseInfoForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      code: '',
      full_name: '',
      short_name: '',
      description: '',
      units: 0,
      prerequisites: '',
      co_requisites: '',
      programs: [],
    },
  });
  const {
    register: registerSection,
    control: controlSection,
    handleSubmit: handleSubmitSection,
    setValue: setValueSection,
    reset: resetSection,
    watch: watchSection,
    formState: { errors: errorSection, isDirty: isDirtySection },
  } = useForm<SectionForm>({
    resolver: yupResolver(validationSchemaSection),
    mode: 'onBlur',
    defaultValues: {
      capacity: 0,
      instructors: [],
      room: {},
      term: {},
      seat: 0,
      name: '',
      schedule: [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    name: 'schedule',
    control: controlSection,
    keyName: 'key',
  });
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const [loading, setLoading] = useState<boolean>(true);
  const handleInstChange = (lecturer: any, dirty: boolean) => {
    const data: {
      id: string;
    }[] = [];
    if (lecturer && lecturer.length > 0) {
      lecturer.forEach((lect: { value: any }) => data.push({ id: lect.value }));
    }
    setValueSection('instructors', data, {
      shouldDirty: dirty,
    });
  };
  const handleProgramChange = (program: any, dirty: boolean) => {
    const data: { id: any }[] = [];
    if (program && program.length > 0) {
      program.forEach((lect: { value: any }) => data.push({ id: lect.value }));
    }
    setValue('programs', data, {
      shouldDirty: dirty,
    });
  };
  const watchAllFields = watch();
  // const termsReg = register('term', { required: true });
  const programsReg = register('programs');
  const [currentTermId, setCurrentTermId] = useState<string>();
  const [basicCourseInfo, setBasicCourseInfo] = useState({
    id: '',
    code: '',
    full_name: '',
    short_name: '',
    description: '',
    units: 0,
    condition: {
      prerequisites: '',
      co_requisites: '',
    },
    term: { id: currentTermId, name: semester },
  });
  const [programs, setPrograms] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const [instructors, setInstructors] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const [locations, setLocations] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const history = useHistory();
  const findTermId = (semester: string) => {
    console.log(semester, terms);
    if (semester !== '' && terms && terms.length > 0) {
      setCurrentTermId(terms.find((elem) => elem.label === semester)?.value);
    }
    console.log('current term id: ', currentTermId);
  };
  const onSubmitClicked = (data: any) => {
    console.log('test', data);
    console.log('current term id2: ', currentTermId);
    axios
      .post(`/api/v1/courses/semester/${currentTermId}`, data)
      .then((res) => {
        console.log('create course', res.data.data);
        setCourseId(res.data.data.id);
        handleNext();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    // if (isDirty) {
    //   axios
    //     .post('/api/v1/courses', data)
    //     .then(() => {
    //       history.replace({
    //         pathname: '/admin/courses',
    //         state: {},
    //       });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
  };
  const fetchCourseInfo = (id: any) => {
    setLoading(true);
    axios
      .get(`/api/v1/courses/${id}`)
      .then((res) => {
        if (res.data.data.course) {
          setBasicCourseInfo(res.data.data.course);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const fetchPrograms = () => {
    setLoading(true);
    axios
      .get('/api/v1/courses/programs')
      .then((res) => {
        if (res.data.data.programs) {
          // eslint-disable-next-line max-len
          const programList: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.programs.forEach(
            (element: { name: string; id: string }) => {
              const array = { label: '', value: '' };
              array.label = element.name;
              array.value = element.id;
              programList.push(array);
            },
          );
          setPrograms(programList);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const fetchTerms = () => {
    setLoading(true);
    axios
      .get('/api/v1/courses/terms')
      .then((res) => {
        if (res.data.data.terms) {
          // eslint-disable-next-line max-len
          const termList: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.terms.forEach(
            (element: { name: string; id: string }) => {
              const array = { label: '', value: '' };
              array.label = element.name;
              array.value = element.id;
              termList.push(array);
            },
          );
          setTerms(termList);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const fetchLocations = () => {
    axios
      .get('/api/v1/courses/locations')
      .then((res) => {
        if (res.data.data.locations) {
          const locationList: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.locations.forEach(
            (element: { name: string; id: string }) => {
              const array = { label: '', value: '' };
              array.label = element.name;
              array.value = element.id;
              locationList.push(array);
            },
          );
          setLocations(locationList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchInstructor = () => {
    axios
      .get('/api/v1/courses/instructors')
      .then((res) => {
        if (res.data.data.instructors) {
          const lecturerList: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.instructors.forEach(
            (element: {
              first_name: string;
              last_name: string;
              id: string;
            }) => {
              const array = { label: '', value: '' };
              array.label = `${element.first_name} ${element.last_name}`;
              array.value = element.id;
              lecturerList.push(array);
            },
          );
          setInstructors(lecturerList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onError = (errors: any, e: any) => console.log('error', errors, e);
  useEffect(() => {
    if (courseId !== '') {
      fetchCourseInfo(courseId);
      setActiveStep(1);
    }
  }, [courseId]);
  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  function getSubmitTime(time: string) {
    let convert = '';
    if (time !== '') {
      convert = moment(time, ['HH:mm']).format('h:mm A');
    }
    return convert;
  }
  const onSectionSubmit = (data: any) => {
    setLoading(true);
    data.schedule?.forEach((item: any) => {
      if (item.start && item.start !== '') {
        // eslint-disable-next-line no-param-reassign
        item.start = getSubmitTime(item.start);
      }
      if (item.end && item.end !== '') {
        // eslint-disable-next-line no-param-reassign
        item.end = getSubmitTime(item.end);
      }
    });
    console.log('section', data);
    const values = {
      name: data.name,
      capacity: data.capacity,
      instructors: data.instructors,
      room: data.room,
      term: data.term,
      schedule: data.schedule,
      seat: 0,
    };
    axios
      .post(`/api/v1/courses/${courseIdfromCreate}/sections`, values)
      .then(() => {
        setLoading(false);
        const path = '/admin/courses';
        history.push(path);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        alert(error);
      });
    // setOpen(false);
  };
  return (
    <>
      {console.log('course watch', watchAllFields)}
      <FlexBox>
        <FlexGrow>
          <Breadcrumb path={test} />
        </FlexGrow>
      </FlexBox>
      <FlexBox
        style={{
          marginBottom: theme.spacing.base * 3,
          marginTop: theme.spacing.base * 3,
        }}>
        <FlexGrow>
          <h2 style={{ marginRight: 'auto', marginBottom: 0 }}>
            Create Course for {semester}
          </h2>
        </FlexGrow>
      </FlexBox>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {activeStep === 0 && (
        <>
          <Card style={{ margin: '24px 0px' }}>
            <form onSubmit={handleSubmit(onSubmitClicked, onError)}>
              <FlexBox
                alignItems="flex-start"
                style={{
                  marginBottom: theme.spacing.x2,
                }}>
                <FlexGrow>
                  <h3>Course Information</h3>
                </FlexGrow>
              </FlexBox>
              <FlexBox
                alignItems="center"
                style={{
                  marginBottom: theme.spacing.x2,
                }}>
                <FlexGrow grow={1}>
                  <StyledText color={'#000000'} fontWeight={'bold'}>
                    Course Code
                  </StyledText>
                </FlexGrow>
                <FlexGrow grow={3}>
                  <>
                    <Input
                      {...register('code')}
                      isFullWidth
                      defaultValue={basicCourseInfo?.description}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="code"
                      render={({ message }) => (
                        <p
                          style={{
                            marginTop: 0,
                            color: theme.color.danger,
                          }}>
                          {message}
                        </p>
                      )}
                    />
                  </>
                </FlexGrow>
              </FlexBox>
              <FlexBox
                alignItems="center"
                style={{
                  marginBottom: theme.spacing.x2,
                }}>
                <FlexGrow grow={1}>
                  <StyledText color={'#000000'} fontWeight={'bold'}>
                    Short Name
                  </StyledText>
                </FlexGrow>
                <FlexGrow grow={3}>
                  <>
                    <Input
                      {...register('short_name')}
                      isFullWidth
                      defaultValue={basicCourseInfo?.description}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="short_name"
                      render={({ message }) => (
                        <p
                          style={{
                            marginTop: 0,
                            color: theme.color.danger,
                          }}>
                          {message}
                        </p>
                      )}
                    />
                  </>
                </FlexGrow>
              </FlexBox>
              <FlexBox
                alignItems="center"
                style={{
                  marginBottom: theme.spacing.x2,
                }}>
                <FlexGrow grow={1}>
                  <StyledText color={'#000000'} fontWeight={'bold'}>
                    Full Name
                  </StyledText>
                </FlexGrow>
                <FlexGrow grow={3}>
                  <>
                    <Input
                      {...register('full_name')}
                      isFullWidth
                      defaultValue={basicCourseInfo?.description}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="full_name"
                      render={({ message }) => (
                        <p
                          style={{
                            marginTop: 0,
                            color: theme.color.danger,
                          }}>
                          {message}
                        </p>
                      )}
                    />
                  </>
                </FlexGrow>
              </FlexBox>
              <FlexBox
                alignItems="center"
                style={{
                  marginBottom: theme.spacing.x2,
                }}>
                <FlexGrow grow={1}>
                  <StyledText color={'#000000'} fontWeight={'bold'}>
                    Description
                  </StyledText>
                </FlexGrow>
                <FlexGrow grow={3}>
                  <>
                    <TextArea
                      {...register('description')}
                      isFullWidth
                      defaultValue={basicCourseInfo?.description}
                      wrap="hard"
                      rows={6}></TextArea>
                    <ErrorMessage
                      errors={errors}
                      name="description"
                      render={({ message }) => (
                        <p
                          style={{
                            marginTop: 0,
                            color: theme.color.danger,
                          }}>
                          {message}
                        </p>
                      )}
                    />
                  </>
                </FlexGrow>
              </FlexBox>
              {/* <FlexBox
            alignItems="center"
            style={{
              marginBottom: theme.spacing.x2,
            }}>
            <FlexGrow grow={1}>
              <StyledText color={'#000000'} fontWeight={'bold'}>
                Terms
              </StyledText>
            </FlexGrow>
            <FlexGrow grow={3}>
              <StyledMinimalSelect
                multi={false}
                placeholder="Find term..."
                options={terms}
                onChange={(e: any) => {
                  handleTermChange(e, true);
                }}
                onBlur={termsReg.onBlur}
                ref={termsReg.ref}
                style={{ width: 'inherit' }}
              />
              <ErrorMessage
                errors={errors}
                name="term"
                render={({ message }) => (
                  <p
                    style={{
                      marginTop: 0,
                      color: theme.color.danger,
                    }}>
                    {message}
                  </p>
                )}
              />
            </FlexGrow>
          </FlexBox> */}
              <FlexBox
                alignItems="center"
                style={{
                  marginBottom: theme.spacing.x2,
                }}>
                <FlexGrow grow={1}>
                  <StyledText color={'#000000'} fontWeight={'bold'}>
                    Units
                  </StyledText>
                </FlexGrow>
                <FlexGrow grow={3}>
                  <Input
                    type="number"
                    isFullWidth
                    {...register('units')}
                    defaultValue={basicCourseInfo.units}></Input>
                </FlexGrow>
              </FlexBox>
              <FlexBox
                alignItems="center"
                style={{
                  marginBottom: theme.spacing.x2,
                }}>
                <FlexGrow grow={1}>
                  <StyledText color={'#000000'} fontWeight={'bold'}>
                    Prerequisites
                  </StyledText>
                </FlexGrow>
                <FlexGrow grow={3}>
                  <InputContainer style={{ width: '100%' }}>
                    <Input
                      id="search-input"
                      isFullWidth
                      {...register('prerequisites')}
                      // eslint-disable-next-line max-len
                      // onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchChange(e.target.value)
                      // }
                      placeholder="Find prerequisites..."
                      defaultValue={basicCourseInfo.condition.prerequisites}
                      style={{ width: 'inherit' }}
                    />
                    <InputIconContainer
                      onClick={(e: any) => e.preventDefault()}>
                      <Icon name="search" status={Status.default} />
                    </InputIconContainer>
                  </InputContainer>
                </FlexGrow>
              </FlexBox>
              <FlexBox
                alignItems="center"
                style={{
                  marginBottom: theme.spacing.x2,
                }}>
                <FlexGrow grow={1}>
                  <StyledText color={'#000000'} fontWeight={'bold'}>
                    Co-requisites
                  </StyledText>
                </FlexGrow>
                <FlexGrow grow={3}>
                  <InputContainer style={{ width: '100%' }}>
                    <Input
                      id="search-input"
                      {...register('co_requisites')}
                      isFullWidth
                      // eslint-disable-next-line max-len
                      // onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchChange(e.target.value)
                      // }
                      placeholder="Find co-requisites..."
                      defaultValue={basicCourseInfo.condition.co_requisites}
                      style={{ width: 'inherit' }}
                    />
                    <InputIconContainer
                      onClick={(e: any) => e.preventDefault()}>
                      <Icon name="search" status={Status.default} />
                    </InputIconContainer>
                  </InputContainer>
                </FlexGrow>
              </FlexBox>
              <FlexBox
                alignItems="center"
                style={{
                  marginBottom: theme.spacing.x2,
                }}>
                <FlexGrow grow={1}>
                  <StyledText color={'#000000'} fontWeight={'bold'}>
                    Program
                  </StyledText>
                </FlexGrow>
                <FlexGrow grow={3}>
                  <StyledMinimalSelect
                    multi
                    placeholder="Find programs..."
                    options={programs}
                    onChange={(e: any) => {
                      handleProgramChange(e, true);
                    }}
                    onBlur={programsReg.onBlur}
                    innerRef={programsReg.ref}
                    style={{ width: 'inherit' }}
                  />
                </FlexGrow>
              </FlexBox>
              <FlexBox
                alignItems="center"
                justify="flex-end"
                style={{
                  marginBottom: theme.spacing.x2,
                }}>
                <FlexGrow>
                  <RightAlign>
                    <Button
                      onClick={() => {
                        history.push('/admin/courses');
                      }}
                      isOutline
                      status={Status.primary}
                      style={{ padding: '4px 12px' }}>
                      <Icon name="x-s" mr={0.5} />
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => handleSubmit(onSubmitClicked)}
                      status={Status.primary}
                      backgroundColor="#CB092B"
                      style={{ padding: '4px 12px', marginLeft: '8px' }}>
                      Next Step
                      <Icon name="chevron-right" mr={0.5} />
                    </Button>
                  </RightAlign>
                </FlexGrow>
              </FlexBox>
            </form>
          </Card>
        </>
      )}
      {activeStep === 1 && (
        <form onSubmit={handleSubmitSection(onSectionSubmit, onError)}>
          <FlexBox style={{ margin: '16px 0px' }}>
            <FlexGrow>
              <h3>Create New Section</h3>
            </FlexGrow>
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px' }}>
            <FlexGrow>
              <StyledText>
                You are creating a new section for the course “
              </StyledText>
              <StyledText fontWeight={'bold'}>
                {basicCourseInfo.short_name}”
              </StyledText>
            </FlexGrow>
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px' }}>
            <FlexGrow>
              <StyledText>Section </StyledText>
              <Input
                isFullWidth
                {...registerSection('name', {
                  required: 'Section name is required',
                })}></Input>
              <ErrorMessage
                errors={errorSection}
                name="name"
                render={({ message }) => (
                  <p
                    style={{
                      marginTop: 0,
                      color: theme.color.danger,
                    }}>
                    {message}
                  </p>
                )}
              />
            </FlexGrow>
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px 0px 0px' }}>
            <FlexGrow style={{ flex: '0 0 100%', marginBottom: '5px' }}>
              <StyledText>Schedule </StyledText>
            </FlexGrow>
          </FlexBox>
          {fields.map((field, index) => (
            <>
              <FlexBox
                justify="space-between"
                key={field.key}
                space={isMobile ? 0.5 : 1}
                style={{ margin: '6px 0px 0px 0px', flexWrap: 'nowrap' }}>
                <FlexGrow>
                  <Controller
                    control={controlSection}
                    name={`schedule.${index}.day` as const}
                    defaultValue={field.day as any}
                    render={({
                      field: {
                        onChange, onBlur, name, ref, value,
                      },
                    }) => (
                      <StyledReactSelect
                        isMobile={isMobile}
                        name={name}
                        inputRef={ref}
                        value={DayOptions.find((c) => c.value === value)}
                        onChange={(val: { value: any }) => onChange(val.value)}
                        onBlur={onBlur}
                        isSearchable={false}
                        styles={customStyles}
                        components={{ IndicatorSeparator: null }}
                        options={DayOptions}
                      />
                    )}
                  />
                </FlexGrow>
                <FlexGrow>
                  <TimeInput
                    isMobile={isMobile}
                    {...registerSection(
                      `schedule.${index}.start` as const,
                    )}></TimeInput>
                </FlexGrow>
                <FlexGrow>
                  <StyledText
                    style={{ display: 'flex', justifyContent: 'center' }}>
                    -
                  </StyledText>
                </FlexGrow>
                <FlexGrow>
                  <TimeInput
                    isMobile={isMobile}
                    {...registerSection(`schedule.${index}.end` as const)}></TimeInput>
                </FlexGrow>
                <FlexGrow>
                  <RightAlign>
                    <ClickableIcon
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        remove(index);
                      }}
                      name="x-s"
                      style={{
                        color: '#7E7E7E',
                        verticalAlign: 'middle',
                      }}></ClickableIcon>
                  </RightAlign>
                </FlexGrow>
              </FlexBox>
            </>
          ))}
          <FlexBox style={{ marginTop: '5px' }}>
            <FlexGrow>
              <ClickableIcon
                type="button"
                title="Add Time"
                style={{ alignItems: 'center', color: theme.color.primary }}
                status={Status.primary}
                name="plus-s"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  append({
                    id: '',
                    TOD: '',
                    day: '',
                    end: '',
                    start: '',
                  });
                }}
              />
            </FlexGrow>
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px 0px 0px' }}>
            <FlexGrow style={{ flex: '0 0 100%', marginBottom: '5px' }}>
              <StyledText>Location </StyledText>
            </FlexGrow>
            <FlexGrow>
              <Controller
                control={controlSection}
                rules={{ required: 'Please select the room' }}
                name="room.id"
                render={({
                  field: {
                    onChange, onBlur, name, ref, value,
                  },
                }) => (
                  <StyledMinimalSelect
                    multi={false}
                    placeholder="Find room..."
                    options={locations}
                    value={locations.find((c) => c.value === value)}
                    onChange={(val: { value: any }) => onChange(val.value)}
                    innerRef={ref}
                    style={{ width: 'inherit' }}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="room.id"
                render={({ message }) => (
                  <p
                    style={{
                      marginTop: 0,
                      color: theme.color.danger,
                    }}>
                    {message}
                  </p>
                )}
              />
            </FlexGrow>
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px 0px 0px' }}>
            <FlexGrow style={{ flex: '0 0 100%', marginBottom: '5px' }}>
              <StyledText>Term </StyledText>
            </FlexGrow>
            <FlexGrow>
              <Controller
                control={controlSection}
                rules={{ required: 'Please select the term' }}
                name="term.id"
                render={({
                  field: {
                    onChange, onBlur, name, ref, value,
                  },
                }) => (
                  <StyledMinimalSelect
                    multi={false}
                    placeholder="Find term..."
                    options={terms}
                    value={terms.find((c) => c.value === value)}
                    onChange={(val: { value: any }) => onChange(val.value)}
                    innerRef={ref}
                    style={{ width: 'inherit' }}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="term.id"
                render={({ message }) => (
                  <p
                    style={{
                      marginTop: 0,
                      color: theme.color.danger,
                    }}>
                    {message}
                  </p>
                )}
              />
            </FlexGrow>
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px 0px 0px' }}>
            <FlexGrow style={{ flex: '0 0 100%', marginBottom: '5px' }}>
              <StyledText>Instructor(s) </StyledText>
            </FlexGrow>
            <FlexGrow>
              {/* <StyledMinimalSelect
              multi
              placeholder="Find instructors..."
              options={instructors}
              defaultValue={
                defaultSecValues.instructors
                  ? defaultSecValues.instructors
                  : []
              }
              onChange={(e: any) => {
                handleInstChange(e, true);
              }}
              onBlur={lecturersReg.onBlur}
              innerRef={lecturersReg.ref}
              style={{ width: 'inherit' }}
            /> */}
              <Controller
                control={controlSection}
                name="instructors"
                render={({ field: { onChange, value, ref } }) => (
                  <StyledMinimalSelect
                    inputRef={ref}
                    // eslint-disable-next-line max-len
                    value={instructors.filter((el) => value.some((f) => f.id === el.value))}
                    onChange={(e: any) => {
                      onChange(e);
                      handleInstChange(e, true);
                    }}
                    options={instructors}
                    multi
                  />
                )}
              />
            </FlexGrow>
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px' }}>
            <FlexGrow>
              <StyledText>Maximum Students </StyledText>
              <Input
                type="number"
                isFullWidth
                {...registerSection('capacity', {
                  valueAsNumber: true,
                })}></Input>
            </FlexGrow>
          </FlexBox>
          <FlexBox
            alignItems="center"
            justify="flex-end"
            style={{
              marginBottom: theme.spacing.x2,
            }}>
            <FlexGrow>
              {loading && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: theme.spacing.x2,
                    }}>
                    <Icon
                      name="spinner"
                      spin
                      size={4}
                      color={theme.color.textDisabled}
                    />
                  </div>
                </>
              )}
            </FlexGrow>
            <FlexGrow>
              <RightAlign>
                <Button
                  type="submit"
                  status={Status.primary}
                  backgroundColor="#CB092B"
                  style={{ padding: '4px 12px', marginLeft: '8px' }}>
                  <Icon name="check-mark" mr={0.5} />
                  Create Section
                </Button>
              </RightAlign>
            </FlexGrow>
          </FlexBox>
        </form>
      )}
    </>
  );
}
export default CreateCoursePage;
