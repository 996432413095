/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from 'react';
import ReactSelect from 'react-select';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ErrorMessage } from '@hookform/error-message';
import styled, { css } from 'styled-components';
import { useScreenClass } from 'react-grid-system';
import MinimalSelect from './MinimalSelect';
import FlexBox, { FlexGrow } from './FlexBox';
import theme from '../styles/theme';
import Card from './Card';
import { StyledText } from './Text';
import Input from './Input';
import TextArea from './TextArea';
import Button from './Button';
import { RightAlign } from './UtilsComponents';
import Icon, { ClickableIcon } from './Icon';
import Status from '../types/enum/Status';
import Modal from './Modal';
import convertToSelect from '../hooks/convertToSelect';
import SnackBar from './SnackBar';

const sharedMinimalStyle = css`
  width: 100%;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 0px 12px;
  .react-select__menu {
    width: 100%;
    min-width: unset;
    margin-left: -12px;
  }
`;
const StyledMinimalSelect = styled(MinimalSelect)`
  ${sharedMinimalStyle}
`;
type BasicCourseInfoForm = {
  code: string | undefined;
  full_name: string | undefined;
  short_name: string | undefined;
  description: string | undefined;
  units: number | undefined;
  programs: { id: any }[] | undefined;
};
type CourseEditModalProps = {
  isOpen?: boolean;
  setOpen?: (x: boolean) => void;
  currentCourse: {
    [key: string]: any;
  };
  onEditComplete?: () => void;
};

const CourseEditModal = ({
  isOpen,
  setOpen,
  currentCourse,
  onEditComplete,
}: CourseEditModalProps): JSX.Element => {
  const validationSchema = Yup.object({
    code: Yup.string().required('Course code is required'),
    full_name: Yup.string().required('Course Full Name is required'),
    short_name: Yup.string().required('Course Short Name is required'),
    description: Yup.string(),
    units: Yup.number()
      .required('Course Unit is required')
      .typeError('Course Unit is required')
      .min(0)
      .integer(),
    programs: Yup.array()
      .min(1, ' Course Programs are required')
      .required('Course Programs are required'),
  });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<BasicCourseInfoForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      code: currentCourse.code,
      full_name: currentCourse.full_name,
      short_name: currentCourse.short_name,
      description: currentCourse.description,
      units: Number(currentCourse.units),
      programs: convertToSelect(currentCourse.programs, 'name', 'id'),
    },
  });
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const [loading, setLoading] = useState<boolean>(true);
  const watchAllFields = watch();
  const history = useHistory();
  const programsReg = register('programs');
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState<boolean>(false);
  const [programs, setPrograms] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const [isOpenError, setOpenError] = useState<boolean>(false);
  useEffect(() => {
    fetchPrograms();
  }, []);
  const handleProgramChange = (program: any, dirty: boolean) => {
    axios
      .get(`/api/v1/course-master/${currentCourse.id}`)
      .then((res) => {
        console.log(res.data.data.course);
        let addedElement = [];
        let removedElement = [];
        const lastestCourseInfo = res.data.data.course;
        if (program) {
          const programsValue = program.map(({ value }: any) => value);
          console.log('values', program, programsValue, lastestCourseInfo);
          addedElement = programsValue.filter(
            (id1: any) => !lastestCourseInfo.programs.some(
              ({ id: id2 }: any) => id2 === id1,
            ),
          );
          console.log('addedElement', addedElement);
          removedElement = lastestCourseInfo.programs.filter(
            (id1: any) => !program.some(({ value: id2 }: any) => id2 === id1.id),
          );
          console.log('removedElement', removedElement);
        } else {
          removedElement = lastestCourseInfo.programs;
        }
        if (removedElement.length > 0) {
          removedElement.forEach((removed: any) => {
            axios
              .delete(
                `/api/v1/course-master/${currentCourse.id}/programs/${removed.id}`,
              )
              .then((res) => {
                console.log(res);
                const data: { id: any }[] = [];
                if (program && program.length > 0) {
                  program.forEach((lect: { value: any }) => data.push({ id: lect.value }));
                }
                setValue('programs', data, {
                  shouldDirty: dirty,
                });
              })
              .catch((err) => {
                console.log(err);
              });
          });
        }
        if (addedElement.length > 0) {
          addedElement.forEach((added: any) => {
            axios
              .post(
                `/api/v1/course-master/${currentCourse.id}/programs/${added}`,
              )
              .then((res) => {
                console.log(res);
                const data: { id: any }[] = [];
                if (program && program.length > 0) {
                  program.forEach((lect: { value: any }) => data.push({ id: lect.value }));
                }
                setValue('programs', data, {
                  shouldDirty: dirty,
                });
              })
              .catch((err) => {
                console.log(err);
              });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // const data: { id: any }[] = [];
    // if (program && program.length > 0) {
    //   program.forEach((lect: { value: any }) => data.push({ id: lect.value }));
    // }
    // setValue('programs', data, {
    //   shouldDirty: dirty,
    // });
  };
  const fetchPrograms = () => {
    setLoading(true);
    axios
      .get('/api/v1/courses/programs')
      .then((res) => {
        if (res.data.data.programs) {
          // eslint-disable-next-line max-len
          const programList: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.programs.forEach(
            (element: { name: string; id: string }) => {
              const array = { label: '', value: '' };
              array.label = element.name;
              array.value = element.id;
              programList.push(array);
            },
          );
          setPrograms(programList);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const onSubmitClicked = (data: any) => {
    setLoading(true);
    axios
      .put(`/api/v1/course-master/${currentCourse.id}`, data)
      .then((res) => {
        setLoading(false);
        onEditComplete?.();
      })
      .catch((error) => {
        setOpenError(true);
        console.log(error);
        setErrorSnackbarOpen(true);
        setLoading(false);
      });
  };
  const onError = (errors: any, e: any) => console.log('error', errors, e);
  const onErrorCloseModal = () => {
    setOpenError(false);
  };
  return (
    <>
      <SnackBar
        isOpen={errorSnackbarOpen}
        setOpen={setErrorSnackbarOpen}
        type="error"
        message="Something went wrong. Please try again!"
      />
      <Modal isOpen={!!isOpen} setOpen={setOpen}>
        <FlexBox
          style={{
            marginBottom: theme.spacing.base * 3,
          }}>
          <FlexGrow>
            <h3 style={{ marginRight: 'auto', marginBottom: 0 }}>
              Edit Course Master
            </h3>
          </FlexGrow>
          <RightAlign>
            <ClickableIcon name="x-s" onClick={() => setOpen?.(false)} />
          </RightAlign>
        </FlexBox>
        {!loading ? (
          <form onSubmit={handleSubmit(onSubmitClicked, onError)}>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Course Code
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('code')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="code"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Short Name
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('short_name')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="short_name"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Full Name
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('full_name')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="full_name"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Description
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <TextArea
                    {...register('description')}
                    isFullWidth
                    wrap="hard"
                    rows={6}></TextArea>
                  <ErrorMessage
                    errors={errors}
                    name="description"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Program
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <StyledMinimalSelect
                  multi
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                  }}
                  placeholder="Find programs..."
                  options={programs}
                  onChange={(e: any) => {
                    handleProgramChange(e, true);
                  }}
                  defaultValue={watchAllFields.programs}
                  onBlur={programsReg.onBlur}
                  innerRef={programsReg.ref}
                  style={{ width: 'inherit' }}
                />
                <ErrorMessage
                  errors={errors}
                  name="programs"
                  render={({ message }) => (
                    <p
                      style={{
                        marginTop: 0,
                        color: theme.color.danger,
                      }}>
                      {message}
                    </p>
                  )}
                />
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Units
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <Input type="number" isFullWidth {...register('units')}></Input>
                <ErrorMessage
                  errors={errors}
                  name="units"
                  render={({ message }) => (
                    <p
                      style={{
                        marginTop: 0,
                        color: theme.color.danger,
                      }}>
                      {message}
                    </p>
                  )}
                />
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              justify="flex-end"
              style={{
                marginTop: theme.spacing.x4,
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow>
                <RightAlign>
                  <Button
                    onClick={() => {
                      setOpen?.(false);
                    }}
                    isOutline
                    status={Status.primary}
                    style={{ padding: '4px 12px' }}>
                    <Icon name="x-s" mr={0.5} />
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={!isDirty}
                    onClick={() => handleSubmit(onSubmitClicked)}
                    status={Status.primary}
                    backgroundColor="#CB092B"
                    style={{ padding: '4px 12px', marginLeft: '8px' }}>
                    <Icon name="floppy" mr={0.5} />
                    Save
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
          </form>
        ) : (
          <Icon mr={1} name="spinner" spin />
        )}
      </Modal>
      <Modal
        isOpen={isOpenError}
        setOpen={setOpenError}
        onClose={onError}
        customStyle={{ content: { textAlign: 'center' } }}>
        <img
          src="/alert-warning.svg"
          alt="alert warning"
          style={{ margin: 'auto' }}
        />
        <h3>Cannot Edit Course Master</h3>
        <p>
          An error occurred while editing the course.
          This error can occur if the same course code and unit already exist.
          Please try again or contact us if the problem persists.
        </p>
        <Button status={Status.primary} onClick={onErrorCloseModal}>
          Done
        </Button>
      </Modal>
    </>
  );
};

export default CourseEditModal;
