import axios from 'axios';
import React, { useState, useEffect } from 'react';

import { useScreenClass } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { CourseContent, SectionContent } from '../filter/FilterValue';
import CourseSectionDropdown from './CourseSectionDropdown';
import EditSectionModal from './EditSectionModal';
import FlexBox, { FlexGrow } from './FlexBox';
import Icon, { ClickableIcon } from './Icon';
import Modal from './Modal';
import NullStateTR from './NullStateTr';
import SnackBar from './SnackBar';
import { Col, Row } from './StyledGridSystem';
import Table from './Table';
import { StyledText, TextStatus } from './Text';

const MAX_DESCRIPTION_LENGTH = 280;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StatusStyled = styled.div`
  display: flex;
  flex-flow: column;
`;

const StatsItemStyled = styled.div`
  display: flex;
  flex-flow: row;
  vertical-align: baseline;
`;

const BlockStlyed = styled.h6`
  padding-right: ${({ theme }) => theme.spacing.x3};
  width: 100px;
  margin: 0px;
`;
const CustomRow = styled.tr`
  vertical-align: baseline;
`;
type CourseInfoModalProps = {
  isOpen?: boolean;
  setOpen?: (x: boolean) => void;
  redirectStudents?: (x: string) => void;
  currentCourse: any;
  showSections?: boolean;
};

const CourseInfoModal = ({
  isOpen,
  setOpen,
  currentCourse,
  showSections = true,
  redirectStudents,
}: CourseInfoModalProps): JSX.Element => {
  const screenClass = useScreenClass();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [currentCourseData, setCurrentCourseData] = useState<CourseContent>(currentCourse);
  const [currentSectionData, setCurrentSectionData] = useState<SectionContent>();
  const [isDescriptionExpand, setDescriptionExpand] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [deleteSnackbarOpen, setDeleteSnackbarOpen] = useState<boolean>(false);
  useEffect(() => {
    setCurrentCourseData(currentCourse);
  }, [currentCourse]);
  const openEditModal = (sectionData: any) => {
    setCurrentSectionData(sectionData);
    setIsEditModalOpen(true);
  };
  const openDropModal = (sectionData: any, e: any) => {
    setIsLoading(true);
    axios
      .delete(`/api/v1/courses/sections/${sectionData.id}`)
      .then((res) => {
        setDeleteSnackbarOpen(true);
        setTimeout(() => {
          fetchCurrentCourse();
        }, 500);
      })
      .catch((err) => {
        console.log('error', err);
        setIsLoading(false);
      });
  };
  const openStudentsModal = (data: any) => {
    setOpen?.(false);
    redirectStudents?.(data.id);
  };
  const fetchCurrentCourse = () => {
    setIsLoading(true);
    axios
      .get(
        `/api/v1/courses/course-offerings/${currentCourseData.course_offer_id}`,
      )
      .then((res) => {
        console.log(res);
        if (res.data.data.courses && res.data.data.courses[0]) {
          setCurrentCourseData(res.data.data.courses[0]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const currentCourseSection = (
    <>
      {currentCourseData?.sections?.map?.((data: any, i: any) => {
        let SeatLeft: JSX.Element = <>{data.seat}</>;
        if (data.capacity - data.seat === 0) {
          SeatLeft = (
            <StyledText status={TextStatus.danger} key={i}>
              {data.capacity - data.seat}/{data.capacity}
            </StyledText>
          );
        } else {
          SeatLeft = (
            <StyledText>
              {data.capacity - data.seat}/{data.capacity}
            </StyledText>
          );
        }
        return (
          <CustomRow key={i}>
            <td>{data.name}</td>
            <td>
              <div>
                {data.schedule?.map((time: any) => {
                  const Time: string = `${time.start} ${time.end}`;
                  const [start, startPeriod, end, endPeriod] = Time.split(' ');
                  return (
                    <>
                      <div>
                        {time.day.slice(0, 3)} {start}-{end}
                      </div>
                    </>
                  );
                })}
                {/* <div>
                  <StyledText status={TextStatus.muted}>{data.Room}</StyledText>
                </div> */}
                <div>
                  <StyledText status={TextStatus.muted}>
                    {data.room?.name}
                  </StyledText>
                </div>
              </div>
              <div>
                <StyledText status={TextStatus.muted}>
                  {data.instructors
                    && data.instructors.map((inst: any) => <div>{inst.name}</div>)}
                </StyledText>
              </div>
              {/* <StyledText status={TextStatus.muted}>
                {currentCourse.lecturers}
              </StyledText> */}
            </td>
            <td>{SeatLeft}</td>
            <td>
              <CourseSectionDropdown
                section={data}
                openStudentsModal={openStudentsModal}
                openEditModal={openEditModal}
                openDropModal={openDropModal}
              />
            </td>
          </CustomRow>
        );
      })}
      {!currentCourseData?.sections?.length && (
        <NullStateTR>No sections info.</NullStateTR>
      )}
    </>
  );

  useEffect(() => {
    if (!isOpen) setDescriptionExpand(false);
  }, [isOpen]);

  return (
    <>
      <SnackBar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        type="success"
        message="Edited section successfully!"
      />
      <SnackBar
        isOpen={deleteSnackbarOpen}
        setOpen={setDeleteSnackbarOpen}
        type="success"
        message="Deleted section successfully!"
      />
      {isEditModalOpen && (
        <EditSectionModal
          isOpen={isEditModalOpen}
          setOpen={setIsEditModalOpen}
          sectionId={currentSectionData?.id}
          courseId={currentCourseData.course_offer_id}
          termId={currentCourseData.term_obj.id}
          mode="edit"
          onEditComplete={() => {
            setSnackbarOpen(true);
            fetchCurrentCourse();
          }}
          onEditError={() => {}}
        />
      )}
      <Modal
        isOpen={!!isOpen}
        setOpen={setOpen}
        customStyle={{ content: { paddingBottom: '130px' } }}>
        <FlexBox alignItems="flex-start">
          <FlexGrow>
            <StyledText status={TextStatus.muted}>
              {currentCourseData?.code}
            </StyledText>
            <h3 style={{ margin: '0px' }}>{currentCourseData?.short_name}</h3>
          </FlexGrow>
          <RightAlign>
            <ClickableIcon name="x-s" onClick={() => setOpen?.(false)} />
          </RightAlign>
        </FlexBox>
        <p>
          {/* eslint-disable-next-line no-nested-ternary */}
          {currentCourseData?.description
          && currentCourseData?.description.length > MAX_DESCRIPTION_LENGTH ? (
              !isDescriptionExpand ? (
              <>
                {currentCourseData?.description.substring(
                  0,
                  MAX_DESCRIPTION_LENGTH,
                )}
                ...{' '}
                <a href="#" onClick={() => setDescriptionExpand(true)}>
                  Show More
                </a>
              </>
              ) : (
              <>
                {currentCourseData?.description}{' '}
                <a href="#" onClick={() => setDescriptionExpand(false)}>
                  Show less
                </a>
              </>
              )
            ) : (
              currentCourseData?.description
            )}
        </p>
        <StatusStyled>
          <StatsItemStyled>
            <BlockStlyed>Semester</BlockStlyed>
            <div style={{ fontSize: '16px' }}>{currentCourseData?.term}</div>
          </StatsItemStyled>
          <StatsItemStyled>
            <BlockStlyed>Units</BlockStlyed>
            <div style={{ fontSize: '16px' }}>{currentCourseData?.units}</div>
          </StatsItemStyled>
          <StatsItemStyled>
            <BlockStlyed>Prerequisites</BlockStlyed>
            {/* FIXEME : ADD LOGIC SHOW NONE OR NOT */}
            {currentCourseData.prerequisites !== '' ? (
              <div style={{ fontSize: '16px' }}>
                {' '}
                {currentCourseData.prerequisites}
              </div>
            ) : (
              <StyledText
                status={TextStatus.muted}
                style={{ fontStyle: 'italic', fontSize: '16px' }}>
                None
              </StyledText>
            )}
          </StatsItemStyled>
          <StatsItemStyled>
            <BlockStlyed>Co-requisites</BlockStlyed>
            {/* {currentCourseData?.coprerequisites} */}
            {currentCourseData.coprerequisites !== '' ? (
              <div style={{ fontSize: '16px' }}>
                {' '}
                {currentCourseData.coprerequisites}
              </div>
            ) : (
              <StyledText
                status={TextStatus.muted}
                style={{ fontStyle: 'italic', fontSize: '16px' }}>
                None
              </StyledText>
            )}
          </StatsItemStyled>
        </StatusStyled>
        {showSections && (
          <>
            <h3>Sections</h3>
            <Table>
              <thead>
                <tr>
                  <th>Sect.</th>
                  <th>Time/Room</th>
                  <th>Seats Left</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <>
                    <Icon mr={1} name="spinner" spin />
                    Loading...
                  </>
                ) : (
                  <>
                    {currentCourseSection}
                    {!currentCourseData.sections && (
                      <NullStateTR>No sections.</NullStateTR>
                    )}
                  </>
                )}
              </tbody>
            </Table>
          </>
        )}
      </Modal>
    </>
  );
};

export default CourseInfoModal;
