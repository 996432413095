import {
  useMemo, useState, useEffect, useContext,
} from 'react';
import { useScreenClass } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import styled, { css } from 'styled-components';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import _theme from '../styles/theme';
import { Col, Row } from './StyledGridSystem';
import Card from './Card';
import FlexBox, { FlexGrow } from './FlexBox';
import { RightAlign } from './UtilsComponents';
import Status from '../types/enum/Status';
import Button from './Button';
import Icon from './Icon';
import Table from './Table';
import NullStateTR from './NullStateTr';
import Input from './Input';
import { StudentContext } from '../context/StudentBehalfContext';
import Radio from './Radio';
import { StyledText } from './Text';
import getNationalityList from '../hooks/nationalityList';
import MinimalSelect from './MinimalSelect';

interface ScreenProps {
  readonly isMobile: boolean;
}

const StyledTR = styled.tr<ScreenProps>`
  display: ${(props) => (props.isMobile ? 'inline-grid' : 'table-row')};
  width: 100%;
`;
const StyledTH = styled.th<ScreenProps>`
  padding: ${(props) => (props.isMobile ? '8px 16px 0px 16px !important' : '8px 16px !important')};
`;
const StyledTD = styled.td<ScreenProps>`
  display: ${(props) => (props.isMobile ? 'inline-grid' : 'table-cell')};
  padding: ${(props) => (props.isMobile ? '0px 16px 8px 16px !important' : '8px 16px !important')};
`;
const StyledLabel = styled.label<ScreenProps>`
  width: ${(props) => (props.isMobile ? '100%' : 'auto')};
  white-space: nowrap;
`;
const sharedMinimalStyle = css`
  min-width: 230px;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 0px 12px;
  .react-select__menu {
    width: 100%;
    min-width: unset;
    margin-left: -12px;
  }
`;
const StyledMinimalSelect = styled(MinimalSelect)`
  ${sharedMinimalStyle}
`;
type Gender = 'male' | 'female' | 'prefer_not_to_say';
const GenderList = [
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'female',
    label: 'Female',
  },
  {
    value: '',
    label: 'Custom',
  },
  {
    value: 'prefer_not_to_say',
    label: 'Prefer Not to Say',
  },
];
type ViewMode = 'view' | 'edit';
type UserType = 'admin' | 'student';
/* eslint-disable camelcase */
interface User {
  user_id: string;
  address: {
    id: string;
    address: string;
    city: string;
    region: string;
    country: string;
    zip_code: string;
  };
}
type LocalAddressForm = {
  address: string | undefined;
  city: string | undefined;
  region: string | undefined;
  country: string | undefined;
  zip_code: string | undefined;
};
type LocalAddressProps = {
  defViewMode: ViewMode;
  userType: UserType;
  userInfo: User;
  information_confirm: boolean;
  reloadParent: any;
};
const HomeAddress = ({
  defViewMode,
  userType,
  userInfo,
  information_confirm,
  reloadParent,
}: LocalAddressProps) => {
  const validationSchema = Yup.object({
    address: Yup.string(),
    city: Yup.string(),
    region: Yup.string(),
    country: Yup.string(),
    zip_code: Yup.string(),
  });
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors, isDirty },
  } = useForm<LocalAddressForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      address: userInfo.address.address,
      city: userInfo.address.city,
      region: userInfo.address.region,
      country: userInfo.address.country,
      zip_code: userInfo.address.zip_code,
    },
  });
  const { isBehalf } = useContext(StudentContext);
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  // Loading text on "Enrolled Courses"
  const [loading, setLoading] = useState<boolean>(true);
  const [viewMode, setViewMode] = useState<ViewMode>(defViewMode);
  // Enrolled Course Information for CourseInfoModal
  const [verified, setVerified] = useState<boolean>(false);
  const [localAddress, setLocalAddress] = useState({
    user_id: '',
    address: {
      id: '',
      address: '',
      city: '',
      region: '',
      country: '',
      zip_code: '',
    },
  });
  const onSubmitClicked = (data: any) => {
    if (isDirty && userInfo.user_id && userInfo.user_id !== '') {
      axios
        .put(`/api/v1/users/${userInfo.user_id}/home-address`, {
          address: data.address,
          city: data.city,
          region: data.region,
          country: data.country,
          zip_code: data.zip_code,
        })
        .then(() => {
          history.replace({
            pathname: '/personalInfo',
            state: { userId: userInfo.user_id, role: userType },
          });
          reloadParent();
          setViewMode('view');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const onError = (errors: any, e: any) => console.log('error', errors, e);
  useEffect(() => {
    setLocalAddress({
      user_id: userInfo.user_id,
      address: {
        id: userInfo.address.id,
        address: userInfo.address.address,
        city: userInfo.address.city,
        region: userInfo.address.region,
        country: userInfo.address.country,
        zip_code: userInfo.address.zip_code,
      },
    });
    setVerified(information_confirm);
    setLoading(false);
  }, [userInfo, information_confirm]);
  // Redirect "Registration" alert and "Add Course/(s)" button to "Add Courses" Page
  const history = useHistory();
  function checkEditing() {
    if (userType === 'admin') {
      return false;
    }
    if (userType === 'student' && !verified) {
      return false;
    }
    if (userType === 'student' && verified) {
      return true;
    }
    return false;
  }
  return (
    <>
      <Card style={{ margin: '24px 0px' }}>
        <form onSubmit={handleSubmit(onSubmitClicked, onError)}>
          <FlexBox
            alignItems="center"
            style={{
              marginBottom: _theme.spacing.x2,
            }}>
            <FlexGrow>
              <h3>Home Address</h3>
            </FlexGrow>
            <FlexGrow ml-auto style={{ marginLeft: 'auto' }}>
              <RightAlign>
                {viewMode === 'view' && (
                  <>
                    <Button
                      onClick={() => {
                        setViewMode('edit');
                      }}
                      disabled={checkEditing()}
                      isOutline
                      status={Status.primary}
                      style={{ padding: '4px 12px' }}>
                      <Icon name="pencil-edit" mr={0.5} />
                      Edit
                    </Button>
                  </>
                )}
                {viewMode === 'edit' && !isMobile && (
                  <>
                    <Button
                      onClick={() => {
                        setViewMode('view');
                      }}
                      isOutline
                      status={Status.primary}
                      style={{ padding: '4px 12px' }}>
                      <Icon name="x-s" mr={0.5} />
                      Discard Changes
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => handleSubmit(onSubmitClicked)}
                      status={Status.primary}
                      backgroundColor="#CB092B"
                      style={{ padding: '4px 12px', margin: '0px 16px' }}>
                      <Icon name="check-mark" mr={0.5} />
                      Save Changes
                    </Button>
                  </>
                )}
              </RightAlign>
            </FlexGrow>
          </FlexBox>
          <Row>
            <Col md={12}>
              <Table>
                <tbody>
                  {!loading ? (
                    <>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Address
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {localAddress.address.address !== ''
                                ? localAddress.address.address
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Input
                                isFullWidth
                                type="text"
                                {...register('address')}
                                className={`form-control ${
                                  errors.address ? 'is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="address"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          City
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {localAddress.address.city !== ''
                                ? localAddress.address.city
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Input
                                isFullWidth
                                type="text"
                                {...register('city')}
                                className={`form-control ${
                                  errors.city ? 'is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="city"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Region/Province
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {localAddress.address.region !== ''
                                ? localAddress.address.region
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Input
                                isFullWidth
                                type="text"
                                {...register('region')}
                                className={`form-control ${
                                  errors.region ? 'is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="region"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Country
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {localAddress.address.country !== ''
                                ? localAddress.address.country
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Input
                                isFullWidth
                                type="text"
                                {...register('country')}
                                className={`form-control ${
                                  errors.address ? 'is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="country"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          ZIP/Postal Code
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {localAddress.address.zip_code !== ''
                                ? localAddress.address.zip_code
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Input
                                isFullWidth
                                type="text"
                                {...register('zip_code')}
                                className={`form-control ${
                                  errors.zip_code ? 'is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="zip_code"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                    </>
                  ) : (
                    <NullStateTR>
                      <Icon mr={1} name="spinner" spin />
                      Loading...
                    </NullStateTR>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
          {viewMode === 'edit' && isMobile && (
            <FlexBox
              direction={'row'}
              justify="space-between"
              alignItems="center">
              <FlexGrow>
                <Button
                  onClick={() => {
                    setViewMode('view');
                  }}
                  isOutline
                  status={Status.primary}
                  style={{ padding: '0px 5px', border: 'none' }}>
                  <Icon name="x-s" mr={0.5} />
                  Cancel
                </Button>
              </FlexGrow>
              <FlexGrow>
                <RightAlign>
                  <Button
                    type="submit"
                    onClick={() => handleSubmit(onSubmitClicked)}
                    status={Status.primary}
                    backgroundColor="#CB092B"
                    style={{ padding: '0px 10px' }}>
                    <Icon name="check-mark" mr={0.5} />
                    Save
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
          )}
        </form>
      </Card>
    </>
  );
};

export default HomeAddress;
