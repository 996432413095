import React from 'react';
import styled, { css } from 'styled-components';

type TableProps = {
  isFullWidth?: boolean;
  bordered?: boolean;
  highlightFirstColumn?: boolean;
  hoverable?: boolean
}

const Table = styled.table<TableProps & React.HTMLProps<HTMLTableElement>>`
  overflow: auto;
  ${({ isFullWidth }) => (isFullWidth ? 'width: 100%;' : 'width: 0px;')};
  border-spacing:0;
  border-collapse: collapse;

  & > thead > tr:first-child {
    td, th {
      padding-top: 0
    }
  }

  & > tbody:first-child > tr:first-child {
    td, th {
      padding-top: 0
    }
  }

  td {
    vertical-align: baseline;
  }

  th {
    vertical-align: bottom;
  }

  td, th {
    padding: ${({ theme }) => theme.spacing.x1} ${({ theme }) => theme.spacing.x1};
    margin: 0;
    text-align: left;

    ${({ theme, isFullWidth }) => !isFullWidth && `
    min-width: ${theme.spacing.base * 8}px;
    white-space: nowrap;
    `};

    color: ${({ theme }) => theme.color.textColor};
    ${({ theme, bordered }) => (bordered ? `border: 1px solid ${theme.color.borderColor};`
    : `border-bottom: 1px solid ${theme.color.borderColor};`)}

    &:first-child {
      ${({ theme, highlightFirstColumn }) => highlightFirstColumn && `background-color: ${theme.color.lightBackground};`}
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  thead th {
    color: ${({ theme }) => theme.color.textColor};
    ${({ theme, bordered }) => (bordered ? `border: 1px solid ${theme.color.borderColor};`
    : `border-bottom: 1px solid ${theme.color.primary};`)}
  }

  td.nowrap {white-space: nowrap;}

  tbody tr:hover {
    ${({ hoverable, theme }) => hoverable && css`
      background-color: ${theme.color.lightBackground};
      cursor: pointer;
    `};
  }
`;

Table.defaultProps = {
  isFullWidth: true,
};

export default Table;
