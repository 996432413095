/* eslint-disable camelcase */
import internal from 'events';

export interface TermContent {
  id: string;
  name: string;
  term_start: string;
  term_end: string;
  add_course_end: string;
  add_course_start: string;
  drop_course_without_w_end: string;
  drop_course_end: string;
}

export const EmptyTermContent: TermContent = {
  id: '',
  name: '',
  term_start: '',
  term_end: '',
  add_course_start: '',
  add_course_end: '',
  drop_course_without_w_end: '',
  drop_course_end: '',
};
export interface CourseContent {
  id: string;
  code: string;
  short_name: string;
  full_name: string;
  description: string;
  units: number;
  programs: Array<ProgramContent>;
  condition: ConditionContent;
  sections: Array<SectionContent>;
  term: string;
  term_obj: TermContent;
  is_active: boolean;
  prerequisites: any;
  coprerequisites: any;
  course_offer_id: string;
}
export const EmptyCourseContent: CourseContent = {
  id: '',
  code: '',
  short_name: '',
  full_name: '',
  description: '',
  units: 0,
  condition: {
    prerequisites: [],
    co_requisites: [],
  },
  sections: [],
  is_active: false,
  term: '',
  term_obj: EmptyTermContent,
  prerequisites: '',
  coprerequisites: '',
  programs: [],
  course_offer_id: '',
};
export interface SectionContent {
  capacity: number;
  id: string;
  instructors: Array<{
    id: string | undefined;
  }>;
  room: RoomContent;
  schedule: Array<ScheduleContent>;
  seat: number;
  name: string;
}

export interface RoomContent {
  id: string;
  name: string;
}
export interface ConditionContent {
  prerequisites: Array<string>;
  co_requisites: Array<string>;
}
export interface ScheduleContent {
  TOD: string;
  day: string;
  end: string;
  id: string;
  start: string;
}
export interface FilterValue {
  label: string;
  value: string;
}

export const semesterValue: FilterValue = {
  label: 'Spring 2021',
  value: 'Spring 2021',
};

export const sortValue: FilterValue = {
  label: 'Course Code',
  value: 'Course Code',
};
export const CourseOptions: FilterValue[] = [
  { label: 'ECE', value: 'ECE' },
  { label: 'EIC', value: 'EIC' },
];
export const TimeOptions: FilterValue[] = [
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  { label: 'Saturday', value: 'Saturday' },
  { label: 'Sunday', value: 'Sunday' },
  { label: 'Morning', value: 'Morning' },
  { label: 'Afternoon', value: 'Afternoon' },
  { label: 'Evening', value: 'Evening' },
  { label: 'Night', value: 'Night' },
];

export const semesterOption: FilterValue[] = [
  {
    label: 'Spring 2021',
    value: 'Spring 2021',
  },
  {
    label: 'Any',
    value: 'Any',
  },
];

export const SortOption: FilterValue[] = [
  {
    label: 'Course Code',
    value: 'Course Code',
  },
  {
    label: 'Course Name',
    value: 'Course Name',
  },
  {
    label: 'Units (Low to High)',
    value: 'Low to High',
  },
  {
    label: 'Units (High to Low)',
    value: 'High to Low',
  },
];
export const OfferSortOption: FilterValue[] = [
  {
    label: 'Term Name ASC',
    value: 'Term Name ASC',
  },
  {
    label: 'Term Name DESC',
    value: 'Term Name DESC',
  },
  {
    label: 'Term Start ASC',
    value: 'Term Start ASC',
  },
  {
    label: 'Term Start DESC',
    value: 'Term Start DESC',
  },
];

export interface ProgramContent {
  id: string;
  name: string;
  program_name: string
  degree_type: string,
  degree_abbr: string,
  program_abbr: string,
  description: string,
}

export const EmptyProgramContent: ProgramContent = {
  id: '',
  name: '',
  program_name: '',
  degree_type: '',
  degree_abbr: '',
  program_abbr: '',
  description: '',
};
