import React, { Component, HTMLProps, useState } from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';
import Icon, { ClickableIcon } from './Icon';

type Props = {
  totalPages: number;
  currentPage: number;
  onPageChange: any;
};

const PaginationStyled = styled.div`
  display : flex;
  flex-flow : row;
  align-items : center;
  justify-content : center;
  padding-top :  ${({ theme }) => theme.spacing.x2};
`;

const PageStyled = styled.a < { isCurrentPage?: boolean } & HTMLProps<HTMLDivElement>>`
  padding :  ${({ theme }) => theme.spacing.x1};
  color : ${({ isCurrentPage }) => (isCurrentPage ? theme.color.primary : theme.color.textColor)};
  font-weight : ${({ isCurrentPage }) => (isCurrentPage ? 'bold' : 'normal')};
  &:hover{
    text-decoration : underline;
    cursor : pointer;
  }
`;
const ArrowStyled = styled.div`
  &:hover{
    cursor : pointer;
  }
`;

// ${({ isCurrentPage }) => (isCurrentPage ? theme.color.primary : theme.color.textColor)};
// prevPage , currentPage , nextPage , lastPage
function pagination({
  totalPages, currentPage, onPageChange,
}: Props): JSX.Element {
  const prevPage = currentPage - 1;
  const nextPage = currentPage + 1;

  const p = [];

  const hasPrevPage = prevPage >= 1;
  const hasNextPage = currentPage < totalPages;

  const gopreviouspage = () => {
    if (currentPage !== 1) {
      onPageChange(currentPage - 1);
    }
  };

  const gonextpage = () => {
    if (currentPage !== totalPages) {
      onPageChange(currentPage + 1);
    }
  };
  if (totalPages <= 6) {
    for (let i = 1; i <= totalPages; i += 1) {
      p.push(i);
    }
  } else {
    if (currentPage !== 1 && prevPage !== 1) {
      p.push(1);
      if (currentPage === 4) {
        p.push(2);
      } else if (currentPage > 3) {
        // p.push('lll');
      }
    }

    if (currentPage === totalPages) {
      p.push(totalPages - 2);
    }

    if (hasPrevPage) {
      p.push(prevPage);
    }

    p.push(currentPage);

    if (hasNextPage) {
      p.push(nextPage);
      if (nextPage === (totalPages - 2)) {
        p.push(nextPage + 1);
      }
      if ((nextPage < totalPages) && (nextPage !== 2) && (nextPage < totalPages - 2)) {
        // p.push('aaa');
      }
    }

    if (currentPage === 1) {
      p.push(3);
      // p.push('rrr');
    }

    if (currentPage !== totalPages && nextPage !== totalPages) {
      p.push(totalPages);
    }
  }

  const display = p.map((page, key) => {
    // console.log('cur : ', currentPage);
    // console.log('page : ', page);
    if (page == (currentPage)) {
      return <PageStyled key={key} onClick={() => onPageChange(page)}
        isCurrentPage={true}>{page}</PageStyled>;
    }
    if (typeof page !== 'number') {
      return (<div key={key} style={{ padding: theme.spacing.x1 }}>...</div>);
    }
    return (<>
      <PageStyled key={key} onClick={() => onPageChange(page)}>{page}</PageStyled>
    </>);
  });

  // console.log(totalPages, currentPage, onPageChange);
  return (
    <>
      <PaginationStyled>
        <ArrowStyled onClick={gopreviouspage}>
          <ClickableIcon name="chevron-left" />
        </ArrowStyled>
        {display}
        <ArrowStyled onClick={gonextpage}>
          <ClickableIcon name="chevron-right" />
        </ArrowStyled>
      </PaginationStyled>
    </>
  );
}
export default pagination;
