import axios from 'axios';
import React, { useEffect, useContext, useState } from 'react';
import { Row, Col } from 'react-grid-system';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../components/Button';
import Card from '../components/Card';
import FlexBox from '../components/FlexBox';
import Icon from '../components/Icon';
import { UserContext } from '../context/UserContext';
import Status from '../types/enum/Status';

const SignOutCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing.x6};
  margin-top: ${({ theme }) => theme.spacing.x6};
  text-align: center;
`;

function SignOutPage() {
  const [isSignOutComplete, setSignoutComplete] = useState(false);
  const { isSignedIn, signOut } = useContext(UserContext);

  useEffect(() => {
    if (isSignedIn) {
      axios.get('/oauth/logout')
        .then((res) => {
          signOut();
          setSignoutComplete(true);
        }).catch((err) => console.error(err));
    } else {
      setSignoutComplete(true);
    }
  }, [isSignedIn, signOut]);

  return (
    <>
      <Row>
        <Col>
          {isSignOutComplete
            && <SignOutCard>
              <FlexBox direction="column" space={2} alignItems="center">
                <h2>You are now signed out.</h2>
                <p>Thank you for using our services. Have a good day!</p>
                <p>Need to get back in? <Link to="/signin">Sign in again</Link>.</p>
              </FlexBox>
            </SignOutCard>
          }
        </Col>
      </Row>
      {/* <Redirect to="/" /> */}
    </>
  );
}

export default SignOutPage;
