import React from 'react';
import {
  Alert,
  AlertTitle,
  Slide,
  SlideProps,
  Snackbar,
  Stack,
} from '@mui/material';

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}
type SnackBarProps = {
  isOpen: boolean;
  setOpen: (x: boolean) => void;
  message: string;
  header?: string;
  type: 'error' | 'success' | 'warning' | 'info';
};

function SnackBar({
  isOpen,
  setOpen,
  header,
  message,
  type,
}: SnackBarProps): JSX.Element {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  return (
    <React.Fragment>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          style={{ zIndex: 1000000, maxWidth: '600px' }}
          onClose={handleClose}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          TransitionComponent={SlideTransition}
          open={isOpen}
          key={'topright'}>
          <Alert action={<></>} onClose={handleClose} variant="filled" severity={type} sx={{ width: '100%' }}>
            {header && header !== '' && (
              <AlertTitle
                style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                {header}
              </AlertTitle>
            )}
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </React.Fragment>
  );
}
export default SnackBar;
