/* eslint-disable max-len */
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import styled from 'styled-components';

import { useScreenClass } from 'react-grid-system';
import axios from 'axios';
import moment from 'moment';
import Card from '../components/Card';

import Table from '../components/Table';

import Icon from '../components/Icon';
import FlexBox, { FlexGrow } from '../components/FlexBox';
import Button from '../components/Button';
import Status from '../types/enum/Status';
import NullStateTR from '../components/NullStateTr';
import EditAnnouncement from '../components/EditAnnouncement';
import AnnouncementDropdown from '../components/AnnouncementDropdown';
import SnackBar from '../components/SnackBar';
import { UserContext } from '../context/UserContext';

const TextsecondayStyled = styled.td`
  color: ${({ theme }) => theme.color.textSecondary}!important;
  white-space: nowrap;
`;
const StyledCard = styled(Card)`
  margin-bottom: ${({ theme }) => theme.spacing.x3};
`;

const RowStyled = styled.tr`
  vertical-align: baseline;
`;

function AnnouncementPage() {
  const screenClass = useScreenClass();
  const { user } = useContext(UserContext);

  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const [announcements, setAnnouncements] = useState<Array<any>>([]);
  const [currentAnnouncement, setCurrentAnnouncement] = useState<{
    id: string;
    announcement: string;
  }>({
    id: '',
    announcement: '',
  });
  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState<boolean>(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  useEffect(() => {
    fecthAnnouncment();
  }, []);
  const fecthAnnouncment = () => {
    axios
      .get('/api/v1/announcement')
      .then((res) => {
        if (res.data.data.announcements) {
          setAnnouncements(res.data.data.announcements);
        }
      })
      .catch((err) => {
        setAnnouncements([]);
      });
  };
  const deleteAnnouncment = (id: string) => {
    axios
      .delete(`api/v1/announcement/${id}`)
      .then((res) => {
        setSuccessMessage('Deleted announcement successfully!');
        setSuccessSnackbarOpen(true);
        fecthAnnouncment();
      })
      .catch((error) => {
        setErrorSnackbarOpen(true);
      });
  };
  return (
    <>
      <SnackBar
        isOpen={errorSnackbarOpen}
        setOpen={setErrorSnackbarOpen}
        type="error"
        message="Something went wrong. Please try again!"
      />
      <SnackBar
        isOpen={successSnackbarOpen}
        setOpen={setSuccessSnackbarOpen}
        type="success"
        message={successMessage}
      />
      <EditAnnouncement
        isOpen={createOpen}
        setOpen={setCreateOpen}
        mode="create"
        info={{ id: '', announcement: '' }}
        onEditComplete={() => {
          setSuccessMessage('Created announcement successfully!');
          setSuccessSnackbarOpen(true);
          setEditOpen(false);
          fecthAnnouncment();
        }}
      />
      {editOpen && currentAnnouncement.announcement !== '' && (
        <EditAnnouncement
          isOpen={editOpen}
          setOpen={setEditOpen}
          mode="edit"
          info={currentAnnouncement}
          onEditComplete={() => {
            setSuccessMessage('Edited announcement successfully!');
            setSuccessSnackbarOpen(true);
            setEditOpen(false);
            fecthAnnouncment();
          }}
        />
      )}
      <StyledCard>
        <FlexBox style={{ marginBottom: '16px' }}>
          <FlexGrow grow={3}>
            <h3>Annoucements</h3>
          </FlexGrow>
          {user.role === 'admin' && (
            <FlexGrow>
              <Button
                status={Status.primary}
                onClick={() => {
                  setCreateOpen(true);
                }}>
                <Icon name="plus-s" mr={0.5} />
                Publish
              </Button>
            </FlexGrow>
          )}
        </FlexBox>
        <FlexBox>
          <FlexGrow>
            <Table style={{ overflowWrap: 'anywhere' }}>
              <thead>
                <tr>
                  <th style={{ width: '40%' }}></th>
                  <th style={{ width: '40%' }}></th>
                  <th style={{ width: '20%' }}></th>
                </tr>
              </thead>
              <tbody>
                {announcements && announcements.length > 0 ? (
                  announcements.map((data, i) => (
                    <RowStyled key={`announcementrow-${i}`}>
                      <td>{data.announcement}</td>
                      <TextsecondayStyled>
                        {moment(data.CreatedAt).format('DD-MM-YYYY')}
                      </TextsecondayStyled>
                      <td style={{ textAlign: 'end' }}>
                        {user.role === 'admin' && (
                          <AnnouncementDropdown
                            announcement={data}
                            openEditModal={(e) => {
                              setCurrentAnnouncement(e);
                              setEditOpen(true);
                            }}
                            openDropModal={() => {
                              deleteAnnouncment(data.id);
                            }}
                          />
                        )}
                      </td>
                    </RowStyled>
                  ))
                ) : (
                  <NullStateTR>No announcements</NullStateTR>
                )}
              </tbody>
            </Table>
          </FlexGrow>
        </FlexBox>
      </StyledCard>
    </>
  );
}
export default AnnouncementPage;
