import {
  HTMLProps, useEffect, useRef, useState,
} from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useOutsideClick } from '../hooks/useOutsideClick';
import Status from '../types/enum/Status';

export const DropdownItem = styled(NavLink)`
  text-decoration: none !important;
  padding: ${({ theme }) => theme.spacing.x1}
    ${({ theme }) => `${theme.spacing.base * 1.5}px`};
  color: ${({ theme }) => theme.color.textColor};
  /* font-size : 14px; */
  text-align: left;
  &:hover {
    background-color: ${({ theme }) => theme.color.lightBackground};
  }
`;

type StlyedDropdownProps = {
  align?: 'left' | 'right' | 'stretch';
} & React.HTMLProps<HTMLDivElement>;

export const StyledDropdown = styled.div<StlyedDropdownProps>`
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  flex-flow: column;
  padding: ${({ theme }) => theme.spacing.x1} 0;
  box-shadow: ${({ theme }) => theme.shadow(8, Status.default)};

  border: 1px solid ${({ theme }) => theme.color.borderColor};
  border-radius: 4px;
  position: absolute;
  top: calc(100% + ${({ theme }) => theme.spacing.x1});

  ${({ align }) => (!align || align === 'left' || align === 'stretch')
    && css`
      left: 0;
    `}
  ${({ align }) => (align === 'right' || align === 'stretch')
    && css`
      right: 0;
    `}

  z-index: 100;
`;

export const DropdownContainer = styled.div<
  { open?: boolean } & HTMLProps<HTMLDivElement>
>`
  position: relative;
  display: inline-block;
  /* z-index: ${({ open }) => (open ? -1 : 0)}; */

  /* &>* {
    z-index: 0;
  } */
`;

export type DropdownProps = {
  open: boolean;
  setOpen: (arg0: boolean) => void;
  align?: 'left' | 'right' | 'stretch';
  outsideClickOnly?: boolean;
  children: React.ReactNode;
  [key: string]: any;
};

const Dropdown = ({
  open,
  setOpen,
  align,
  children,
  outsideClickOnly,
  ...props
}: DropdownProps): JSX.Element => {
  const dropdownRef = useRef(null);
  useOutsideClick(
    dropdownRef,
    () => {
      setOpen(false);
    },
    !!outsideClickOnly,
  );
  return (
    <>
      {open && (
        <StyledDropdown ref={dropdownRef} align={align} {...props}>
          {children}
        </StyledDropdown>
      )}
    </>
  );
};

export default Dropdown;
