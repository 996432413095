/* eslint-disable camelcase */
import {
  useState, useMemo, HTMLProps, useEffect,
} from 'react';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useScreenClass } from 'react-grid-system';
import { ErrorMessage } from '@hookform/error-message';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import FlexBox, { FlexGrow } from './FlexBox';
import theme from '../styles/theme';
import { StyledText } from './Text';
import Button from './Button';
import { RightAlign } from './UtilsComponents';
import Icon, { ClickableIcon } from './Icon';
import Status from '../types/enum/Status';
import Modal from './Modal';
import Input from './Input';

const TextArea = styled.textarea`
  width: 100%;
  padding: 8px 4px;
  min-height: 100px;
}
`;
type AnnouncementForm = {
  start_date: string;
  end_date: string;
  event_name: string;
};
type Document = {
  start_date: string;
  end_date: string;
  event_name: string;
  id: string;
};

type EditImportantDateProps = {
  isOpen?: boolean;
  setOpen?: (x: boolean) => void;
  onEditComplete?: () => void;
  info: Document;
  mode: 'edit' | 'create';
};

const EditImportantDate = ({
  isOpen,
  setOpen,
  onEditComplete,
  info,
  mode,
}: EditImportantDateProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm<AnnouncementForm>({
    mode: 'onChange',
    defaultValues: {
      start_date: info.start_date,
      end_date: info.end_date,
      event_name: info.event_name,
    },
  });
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const watchAllFields = watch();
  const onSubmitClicked = (data: any) => {
    setLoading(true);
    console.log('value', data);
    if (mode === 'create') {
      axios
        .post('/api/v1/upcoming-date', data)
        .then((res) => {
          setLoading(false);
          setOpen?.(false);
          onEditComplete?.();
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
    if (mode === 'edit') {
      axios
        .put(`api/v1/upcoming-date/${info.id}`, data)
        .then((res) => {
          setLoading(false);
          onEditComplete?.();
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    reset();
  }, [isOpen]);
  const onError = (errors: any, e: any) => console.log('error', errors, e);
  return (
    <>
      <Modal isOpen={!!isOpen} setOpen={setOpen}>
        <FlexBox
          style={{
            marginBottom: theme.spacing.base * 3,
          }}>
          <FlexGrow>
            <h3
              style={{
                marginRight: 'auto',
                marginBottom: 0,
                textTransform: 'capitalize',
              }}>
              {mode} Important Date
            </h3>
          </FlexGrow>
          <RightAlign>
            <ClickableIcon name="x-s" onClick={() => setOpen?.(false)} />
          </RightAlign>
        </FlexBox>
        {!loading ? (
          <form onSubmit={handleSubmit(onSubmitClicked, onError)}>
            <FlexBox
              alignItems="flex-start"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>Event Name</FlexGrow>
              <FlexGrow grow={3}>
                <Input
                  isFullWidth
                  {...register('event_name', { required: true })}
                />
                <ErrorMessage
                  errors={errors}
                  name="event_name"
                  render={({ message }) => (
                    <p
                      style={{
                        marginTop: 0,
                        color: theme.color.danger,
                      }}>
                      Event name is required!
                    </p>
                  )}
                />
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="flex-start"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>Event Start Date</FlexGrow>
              <FlexGrow grow={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name={'start_date'}
                    control={control}
                    rules={{ required: 'Event Start Date is required!' }}
                    render={({ field }) => (
                      <DateTimePicker
                        openTo="year"
                        inputFormat='DD-MM-YYYY HH:mm'
                        {...field}
                        // value={value}
                        // onChange={onChange}
                        renderInput={(params) => (
                          <TextField sx={{ width: '100%' }} placeholder={'Select Date and Time'} {...params} helperText={null} />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
                <ErrorMessage
                  errors={errors}
                  name="start_date"
                  render={({ message }) => (
                    <p
                      style={{
                        marginTop: 0,
                        color: theme.color.danger,
                      }}>
                      {message}
                    </p>
                  )}
                />
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="flex-start"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>Event End Date</FlexGrow>
              <FlexGrow grow={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name={'end_date'}
                    control={control}
                    rules={{ required: 'Event End Date is required!' }}
                    render={({ field }) => (
                      <DateTimePicker
                        openTo="year"
                        inputFormat='DD-MM-YYYY HH:mm'
                        {...field}
                        // value={value}
                        // onChange={onChange}
                        renderInput={(params) => (
                          <TextField sx={{ width: '100%' }} placeholder={'Select Date and Time'} {...params} helperText={null} />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
                <ErrorMessage
                  errors={errors}
                  name="end_date"
                  render={({ message }) => (
                    <p
                      style={{
                        marginTop: 0,
                        color: theme.color.danger,
                      }}>
                      {message}
                    </p>
                  )}
                />
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              justify="flex-end"
              style={{
                marginTop: theme.spacing.x4,
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow>
                <RightAlign>
                  <Button
                    onClick={() => {
                      setOpen?.(false);
                    }}
                    isOutline
                    status={Status.primary}
                    style={{ padding: '4px 12px' }}>
                    <Icon name="x-s" mr={0.5} />
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    // disabled={!isDirty}
                    onClick={() => handleSubmit(onSubmitClicked)}
                    status={Status.primary}
                    backgroundColor="#CB092B"
                    style={{ padding: '4px 12px', marginLeft: '8px' }}>
                    <Icon name="floppy" mr={0.5} />
                    Save
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
          </form>
        ) : (
          <Icon mr={1} name="spinner" spin />
        )}
      </Modal>
    </>
  );
};

export default EditImportantDate;
