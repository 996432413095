import React, { useState } from 'react';
import styled from 'styled-components';

import { createPortal } from 'react-dom';
import Dropdown, { DropdownContainer, DropdownItem } from './Dropdown';
import Icon, { ClickableIcon } from './Icon';
import _theme from '../styles/theme';

const RemoveCourse = styled.div`
  color: ${({ theme }) => theme.color.primary};
`;

type CourseSectionDropdownProps = {
  section: { [key: string]: any };
  openEditModal: (x: any) => void;
  openStudentsModal: (x: any) => void;
  openDropModal: (x: any, e?: any) => void;
};

const CourseSectionDropdown = ({
  section,
  openEditModal,
  openStudentsModal,
  openDropModal,
}: CourseSectionDropdownProps) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <DropdownContainer>
      <ClickableIcon
        ml={0.5}
        size={2}
        name="more-vertical"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!isOpen);
        }}
        style={{ color: _theme.color.primary }}
      />

      <Dropdown
        open={isOpen}
        style={{ whiteSpace: 'nowrap' }}
        setOpen={setOpen}
        align="right">
        <DropdownItem
          to="#"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
            openStudentsModal(section);
          }}>
          <Icon mr={1} name="user" /> View Students
        </DropdownItem>
        <DropdownItem
          to="#"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
            openEditModal(section);
          }}>
          <Icon mr={1} name="pencil-edit" /> Edit Section
        </DropdownItem>
        <DropdownItem
          to="#"
          onClick={(e: any) => {
            console.log('clicked');
            setOpen(false);
            openDropModal(section, e);
          }}>
          <RemoveCourse>
            <Icon mr={1} name="trash-empty" /> Delete Section
          </RemoveCourse>
        </DropdownItem>
      </Dropdown>
    </DropdownContainer>
  );
};

export default CourseSectionDropdown;
