import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '../components/Button';
import Card from '../components/Card';
import { Col, Row } from '../components/StyledGridSystem';
import Status from '../types/enum/Status';
import Icon from '../components/Icon';
import FlexBox from '../components/FlexBox';

const Item = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: auto;
  align-items: center;
`;

const SignInCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing.x6};
  margin-top: ${({ theme }) => theme.spacing.x6};
  text-align: center;
`;

function NotFoundPage() {
  return (
    <>
      <Row>
        <Col>
          <SignInCard>
            <FlexBox direction="column" space={2}>
              <h2>Sorry, the page you requested was not found.</h2>
              <p>Error 404: Page Not Found</p>
              <Link to="/">Back to Home Page</Link>
            </FlexBox>
          </SignInCard>
        </Col>
      </Row>
    </>
  );
}

export default NotFoundPage;
