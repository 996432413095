import styled from 'styled-components';
import Status from '../types/enum/Status';

export type AlertProps = {
  status?: Status;
}

type AlertInnerProps = {
  backgroundColor?: string;
  borderColor?: string;
  fontColor?: string;
}

const Alert = styled.div.attrs<AlertProps>(({
  theme,
  status = Status.default,
}) => {
  let backgroundColor: string;
  let borderColor: string;
  let fontColor: string;

  if (status === Status.default) {
    backgroundColor = theme.color.white;
    borderColor = theme.color.borderColor;
    fontColor = theme.color.textColor;
  } else {
    backgroundColor = theme.color[`${status.toString()}Light`];
    borderColor = theme.color[`${status.toString()}Dark`];
    fontColor = theme.color[`${status.toString()}Dark`];
  }

  return ({
    theme,
    backgroundColor,
    borderColor,
    fontColor,
  });
}) <AlertInnerProps>`
  width: auto;
  padding: ${({ theme }) => theme.spacing.x2};
  
  border-radius: ${({ theme }) => theme.radius};
  color: ${({ fontColor }) => fontColor};
  border: 1px solid ${({ borderColor }) => borderColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export default Alert;
