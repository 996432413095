import axios from 'axios';
import moment from 'moment';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import Button from '../../components/Button';
import Card from '../../components/Card';
import FlexBox, { FlexGrow } from '../../components/FlexBox';
import Icon from '../../components/Icon';
import NullStateTR from '../../components/NullStateTr';
import { Col, Row } from '../../components/StyledGridSystem';
import StyledSnackbar from '../../components/StyledSnackbar';
import Table from '../../components/Table';
import { StyledText, Subtitle } from '../../components/Text';
import { StudentContext } from '../../context/StudentBehalfContext';
import { UserContext } from '../../context/UserContext';
import formatDecimal from '../../helpers/formatNumber';
import theme from '../../styles/theme';
import Status from '../../types/enum/Status';

function FinanceCardPage(this: any, props: any) {
  const { info } = props.location.state || [];
  const { isMobile } = props.location.state || false;
  const { semester } = props.location.state || '';
  const { student, isBehalf } = useContext(StudentContext);
  const fileRef = useRef<any>();
  const { user } = useContext(UserContext);
  const [invoice, setInvoice] = useState<any>({});
  const test = [
    { name: 'Finance', link: '/finance' },
    { name: 'Invoice Detail', link: '/finance/detail' },
  ];
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  useEffect(() => {
    fetchInvoice();
  }, [student, user, info]);
  const fetchInvoice = () => {
    setLoading(true);
    axios
      .get(
        `/api/v1/students/${
          isBehalf ? student.student_id : user.student_id
        }/invoices/${info}`,
      )
      .then((res) => {
        // console.log('dataHistory : ', res.data);
        setInvoice(res.data.data.invoice);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const uploadPayslips = (event: any) => {
    setLoading(true);
    const items = Array.from(event.target.files);
    const data = new FormData();
    items.forEach((img: any) => {
      data.append('files', img);
    });
    axios
      .post(
        `/api/v1/students/${
          isBehalf ? student.student_id : user.student_id
        }/invoices/${info}/payslips`,
        data,
      )
      .then((res) => {
        setShowMessage(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(true);
      });
  };
  const fetchPDF = () => {
    axios
      .get(
        `/api/v1/students/${
          isBehalf ? student.student_id : user.student_id
        }/invoices/${info}/download`,
        { responseType: 'blob' },
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'invoice.pdf');
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        setLoading(true);
      });
  };
  const fetchReceiptPDF = () => {
    axios
      .get(
        `/api/v1/students/${
          isBehalf ? student.student_id : user.student_id
        }/invoices/${info}/receipts/download`,
        { responseType: 'blob' },
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'receipt.pdf');
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        setLoading(true);
      });
  };
  return (
    <>
      <StyledSnackbar
        isOpen={showMessage}
        setOpen={setShowMessage}
        message="Payslip Uploaded Successfully!"
      />
      {loading && (
        <>
          <div
            style={{
              padding: theme.spacing.x2,
              position: 'absolute',
              top: '50%',
              left: '50%',
            }}>
            <Icon
              name="spinner"
              spin
              size={6}
              color={theme.color.textDisabled}
            />
            {/* <CircularProgress disableShrink /> */}
          </div>
        </>
      )}
      <Row>
        <Col>
          <Breadcrumb path={test} />
        </Col>
      </Row>
      <Card>
        <Row>
          <Col>
            <FlexBox space={2}>
              <FlexGrow>
                <h3>{invoice.invoice_number}</h3>
                <Subtitle>Invoice Details</Subtitle>
              </FlexGrow>
              {isMobile ? (
                <>
                  <div>
                    <br />
                    <Button
                      isOutline={false}
                      status={Status.primary}
                      onClick={() => fetchPDF()}>
                      <Icon mr={1} name="download" /> Upload Payslip
                    </Button>
                  </div>
                  <div>
                    <br />
                    <Button
                      isOutline
                      status={Status.primary}
                      onClick={() => fetchPDF()}>
                      <Icon mr={1} name="download" /> Download Invoice (.pdf)
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <input
                    ref={fileRef}
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="file"
                    multiple={true}
                    type="file"
                    onChange={uploadPayslips}
                  />
                  <Button
                    isOutline={false}
                    status={Status.primary}
                    onClick={() => fileRef.current.click()}
                    style={{ marginLeft: 'auto' }}>
                    <Icon mr={1} name="cloud-upload" /> Upload Payslip
                  </Button>
                  <Button
                    isOutline
                    status={Status.primary}
                    style={{ marginLeft: 'auto' }}
                    onClick={() => fetchPDF()}>
                    <Icon mr={1} name="download" /> Download Invoice (.pdf)
                  </Button>
                </>
              )}
            </FlexBox>
          </Col>
        </Row>
        {!isMobile ? (
          <>
            <Table>
              <thead>
                <tr>
                  <th>Item title</th>
                  <th>Amount(THB)</th>
                </tr>
              </thead>
              <tbody>
                {invoice?.items
                  && invoice.items.map((data: any) => (
                    <>
                      <tr>
                        <td style={{ border: 'none' }}>{data.name || 'N/A'}</td>
                        <td style={{ border: 'none' }}>
                          {data.type === 'scholarship' && <>-</>}
                          {formatDecimal(data.amount)}
                        </td>
                      </tr>
                    </>
                  ))}
              </tbody>
              <tfoot>
                {invoice.total ? (
                  <tr>
                    <td
                      style={{
                        textAlign: 'end',
                        borderTop: '1px solid #C1C7CF',
                      }}>
                      {'Amount:'}
                    </td>
                    <td style={{ borderTop: '1px solid #C1C7CF' }}>
                      {formatDecimal(invoice?.total)}
                    </td>
                  </tr>
                ) : (
                  <NullStateTR />
                )}
                {invoice?.confirmation_admin && (
                  <>
                    <tr>
                      <td
                        style={{
                          borderTop: '1px solid #C1C7CF',
                        }}>
                        {'Confirmed By:'}
                        <StyledText style={{ marginLeft: '10px' }}>
                          {invoice?.confirmation_admin}
                        </StyledText>
                      </td>
                      <td style={{ borderTop: '1px solid #C1C7CF' }}></td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          borderTop: '1px solid #C1C7CF',
                        }}>
                        {'Confirmed Date:'}
                        <StyledText style={{ marginLeft: '10px' }}>
                          {moment(invoice?.confirmation_date).format(
                            'DD/MM/YYYY',
                          )}
                        </StyledText>
                      </td>
                      <td style={{ borderTop: '1px solid #C1C7CF' }}></td>
                    </tr>
                  </>
                )}
                {invoice?.financial_evidence && (
                  <tr>
                    <td
                      style={{
                        borderTop: '1px solid #C1C7CF',
                      }}>
                      {'Evidence:'}
                      <StyledText style={{ marginLeft: '10px' }}>
                        {invoice?.financial_evidence}
                      </StyledText>
                    </td>
                    <td style={{ borderTop: '1px solid #C1C7CF' }}></td>
                  </tr>
                )}
                {invoice && invoice.status === 'completed' && (
                  <tr>
                    <td
                      style={{
                        borderTop: '1px solid #C1C7CF',
                      }}>
                      <Button
                        isOutline
                        status={Status.primary}
                        style={{ marginLeft: 'auto' }}
                        onClick={() => fetchReceiptPDF()}>
                        <Icon mr={1} name="download" /> Download Receipt (.pdf)
                      </Button>
                    </td>
                    <td style={{ borderTop: '1px solid #C1C7CF' }}></td>
                  </tr>
                )}
              </tfoot>
            </Table>
          </>
        ) : (
          <>
            <Table>
              <thead>
                <tr>
                  <th />
                  <th>Name</th>
                  <th>Amount</th>
                  <th>Academic Year</th>
                </tr>
              </thead>
              {invoice.items.map((data: any) => (
                <>
                  <tr>
                    <th colSpan={6}>{data.name}</th>
                  </tr>
                  <tr>
                    <td />
                    <td>{data.name || 'X'}</td>
                    <td>{data.amount || 'X'}</td>
                    <td>{data.academic_year || 'N/A'}</td>
                  </tr>
                </>
              ))}
            </Table>
          </>
        )}
      </Card>
    </>
  );
}
export default FinanceCardPage;
