import React, {
  HTMLProps, useEffect, useMemo, useState,
} from 'react';
import styled from 'styled-components';

import axios from 'axios';
import { useScreenClass } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import Icon from '../../components/Icon';
import { Col, Row } from '../../components/StyledGridSystem';
import Status from '../../types/enum/Status';
import FlexBox, { FlexGrow } from '../../components/FlexBox';
import Card from '../../components/Card';
import Input, {
  InputContainer,
  InputIconContainer,
} from '../../components/Input';
import MinimalSelect from '../../components/MinimalSelect';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import { StyledText, TextSize, TextStatus } from '../../components/Text';
import {
  CourseContent,
  SortOption,
  sortValue,
  EmptyCourseContent,
} from '../../filter/FilterValue';
import useDebounce from '../../components/use-debounce';
import NullStateTR from '../../components/NullStateTr';
import theme from '../../styles/theme';
import { RightAlign } from '../../components/UtilsComponents';
import Button from '../../components/Button';
import CourseInfoModal from '../../components/CourseInfoModal';
import CourseMasterDropdown from '../../components/CourseMasterDropdown';
import CourseEditModal from '../../components/CourseEditModal';
import CourseMasterDeleteModal from '../../components/CourseMasterDeleteModal';
import CourseCatalogModal from '../../components/CourseCatalogModal';
import SnackBar from '../../components/SnackBar';

export interface Ioption {
  semester: any;
  program: any;
  time: any;
  day: any;
}

const MinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x4 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
`;

const MultiMinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x4 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
  align-items: center;
`;

const FilterStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing.x2};
`;

const RowStyled = styled.div`
  display: flex;
  flex-flow: column;
`;

function CourseMasterPage() {
  const screenClass = useScreenClass();
  const history = useHistory();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const test = [
    { name: 'Courses', link: '/admin/courses' },
    { name: 'Course Master', link: '/admin/courses/course/master' },
  ];

  const [posts, setPosts] = useState<Array<CourseContent>>([]);
  const [isItem, setIsItem] = useState(false);
  const [offset, setOffSet] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [currentCourse, setCurrentCourse] = useState<CourseContent>(EmptyCourseContent);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, 300);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState({
    semester: [],
    program: [{ label: 'All', value: 'All' }],
    time: [],
    day: [],
  });
  const [filters, setfilters] = useState({
    semester: '',
    program: [],
    time: [],
    day: [],
    sort: 'Course Code',
  });

  const handleChangeSort = (obj: any) => {
    setfilters({ ...filters, sort: obj.value });
  };

  const handleChangeProgram = (obj: any) => {
    console.log('obj in program', obj);
    const program: any = [];
    if (obj) {
      obj.forEach((element: any) => {
        program.push(element.value);
      });
      setfilters({ ...filters, program });
    } else {
      setfilters({ ...filters, program: [] });
    }
  };

  const handleSearchChange = (text: string) => {
    setSearchValue(text);
  };

  const ColumnName = (isMobile: boolean) => {
    if (isMobile) {
      return (
        <>
          <th></th>
          <th></th>
        </>
      );
    }
    return (
      <>
        <th>Course Code</th>
        <th>Course Name</th>
        <th>Units</th>
        <th>Programs</th>
        <th></th>
      </>
    );
  };
  useEffect(() => {
    fetchOptions();
  }, []);
  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchPostbyPage(offset, limit);
    } else {
      fetchPostbyPage(offset, limit);
    }
  }, [filters, debouncedSearchTerm]);

  const fetchPostbyPage = (offset: number, limit: number) => {
    console.log(offset, limit);
    const params = {
      query: searchValue,
      filters,
      offSet: offset,
      perpage: limit,
    };
    setLoading(true);
    axios
      .get('/api/v1/course-masters', { params })
      .then((res) => {
        if (res.data.data.courses) {
          setPosts(res.data.data.courses);
          setTotalPage(res.data.data.total_pages);
          setOffSet(res.data.data.offset);
          setLimit(res.data.data.limit);
          setLoading(false);
        } else {
          setPosts([]);
          setTotalPage(0);
          setOffSet(0);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const fetchOptions = () => {
    setLoading(true);
    const programVals: any = [];
    const termsVals: any = [];
    axios
      .get('/api/v1/courses/terms')
      .then((res) => {
        if (res.data.data.terms) {
          res.data.data.terms.forEach((term: any) => {
            termsVals.push({ label: term.name, value: term.name });
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    axios
      .get('/api/v1/courses/programs')
      .then((res) => {
        if (res.data.data.programs) {
          res.data.data.programs.forEach((prog: any) => {
            programVals.push({ label: prog.name, value: prog.name });
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setOptions({ ...options, semester: termsVals, program: programVals });
  };
  const addCourse = () => {
    const path = '/admin/courses/course/master/add';
    history.push(path);
  };
  const openModal = (data: any) => {
    if (data) {
      setCurrentCourse(data);
      setIsModalOpen(true);
    }
  };
  const closeEditModal = () => {
    setSnackbarOpen(true);
    setIsEditOpen(false);
    fetchPostbyPage(offset, limit);
  };
  const openEditModal = (data: any) => {
    if (data) {
      setCurrentCourse(data);
      setIsEditOpen(true);
    }
  };

  // Drop Course Modal
  const openDropModal = (course: any, e: MouseEvent) => {
    setCurrentCourse(course);
    setIsDeleteOpen(true);
    e.stopPropagation();
  };
  return (
    <>
      <SnackBar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        type="success"
        message="Course master edited successfully!"
      />
      <CourseCatalogModal
        isOpen={isModalOpen}
        setOpen={setIsModalOpen}
        currentCourse={currentCourse}
      />
      {isEditOpen && (
        <CourseEditModal
          isOpen={isEditOpen}
          setOpen={setIsEditOpen}
          currentCourse={currentCourse}
          onEditComplete={() => {
            closeEditModal();
          }}
        />
      )}
      <CourseMasterDeleteModal
        isOpen={isDeleteOpen}
        setOpen={setIsDeleteOpen}
        currentCourse={currentCourse}
        // handleDeleteCourse={handleDeleteCourse}
        onDeleteComplete={() => {
          fetchPostbyPage(offset, limit);
        }}
        onDeleteError={() => {
          fetchPostbyPage(offset, limit);
        }}
      />
      <Row>
        <Col>
          <Breadcrumb path={test} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FlexBox space={2}>
            <h2 style={{ marginRight: 'auto', marginBottom: 0 }}>
              Course Master
            </h2>
          </FlexBox>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ marginTop: '0px' }}>
            <FlexBox
              style={{
                alignItems: 'flex-start',
                marginBottom: theme.spacing.base * 2,
              }}>
              <FlexGrow>
                <h3>Look Up Course Information</h3>
              </FlexGrow>
              <FlexGrow ml-auto style={{ marginLeft: 'auto' }}>
                <RightAlign>
                  <Button
                    onClick={() => addCourse()}
                    isOutline
                    status={Status.primary}
                    style={{ padding: '4px 12px' }}>
                    <Icon name="plus-s" mr={0.5} />
                    Create Course Master
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
            <InputContainer
              as="form"
              onSubmit={() => {}}
              style={{ width: '100%' }}>
              <Input
                id="search-input"
                name="searchInput"
                // eslint-disable-next-line max-len
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchChange(e.target.value)
                }
                placeholder="Find courses..."
                value={searchValue}
                style={{ width: 'inherit' }}
              />
              <InputIconContainer onClick={(e: any) => e.preventDefault()}>
                <Icon name="search" status={Status.default} />
              </InputIconContainer>
            </InputContainer>
            <FilterStyled isMobile={isMobile}>
              <MinimalSelectStyled isMobile={isMobile}>
                <StyledText status={TextStatus.default}>Programs:</StyledText>
                <MinimalSelect
                  multi
                  placeholder="All"
                  options={options.program}
                  onChange={handleChangeProgram}
                />
              </MinimalSelectStyled>
              <MinimalSelectStyled isMobile={isMobile}>
                <StyledText status={TextStatus.default}>Sort:</StyledText>
                <MinimalSelect
                  defaultValue={sortValue}
                  options={SortOption}
                  onChange={handleChangeSort}
                />
              </MinimalSelectStyled>
            </FilterStyled>
            <Table hoverable>
              <thead>
                <tr>{ColumnName(isMobile)}</tr>
              </thead>
              <tbody>
                {!loading && posts ? (
                  posts.map((data) => {
                    if (!isMobile) {
                      return (
                        <tr key={data.id} onClick={() => openModal(data)}>
                          <td>
                            <StyledText
                              textSize={TextSize.default}
                              // status={TextStatus.disabled}
                            >
                              {data.code}
                            </StyledText>
                          </td>
                          <td>{data.short_name}</td>
                          <td>{data.units}</td>
                          <td>
                            {data.programs
                              && data.programs.map((program: any) => (
                                <div>·{program.name}</div>
                              ))}
                          </td>
                          <td
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            style={{ verticalAlign: 'middle' }}>
                            <CourseMasterDropdown
                              course={data}
                              openEditModal={openEditModal}
                              openDropModal={openDropModal}
                            />
                          </td>
                        </tr>
                      );
                    }

                    return (
                      <tr key={data.id}>
                        <td>
                          <RowStyled>
                            <StyledText style={{ fontWeight: 'bold' }}>
                              {data.short_name}
                            </StyledText>
                            <StyledText status={TextStatus.muted}>
                              {data.code}
                            </StyledText>
                            <StyledText status={TextStatus.muted}>
                              {data.units} Units ·{data.sections.length}{' '}
                              Sections
                            </StyledText>
                            <StyledText status={TextStatus.muted}>
                              {data.programs
                                && data.programs.map((program: any) => (
                                  <>
                                    {program.name}
                                    {data.programs.length > 1 && '-'}
                                  </>
                                ))}
                            </StyledText>
                          </RowStyled>
                        </td>
                        <td
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          style={{ verticalAlign: 'middle' }}>
                          <CourseMasterDropdown
                            course={data}
                            openEditModal={openEditModal}
                            openDropModal={openDropModal}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <div></div>
                )}
                {isItem && !loading ? (
                  <NullStateTR>No courses found.</NullStateTR>
                ) : (
                  <div></div>
                )}
              </tbody>
            </Table>
            {!loading ? (
              <div></div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: theme.spacing.x2,
                }}>
                <Icon
                  name="spinner"
                  spin
                  size={4}
                  color={theme.color.textDisabled}
                />
                {/* <CircularProgress disableShrink /> */}
              </div>
            )}
            <Pagination
              totalPages={totalPage}
              currentPage={Math.floor((offset + limit) / limit)}
              onPageChange={(goingToPage: number) => {
                fetchPostbyPage((goingToPage - 1) * limit, limit);
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default CourseMasterPage;
