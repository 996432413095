/* eslint-disable */

import { normalize } from 'polished'
import { createGlobalStyle } from 'styled-components'
import { Subtitle } from '../components/Text'
import theme from './theme'
import typography from './typography'

const BaseStyle = createGlobalStyle`
  ${normalize()}

  html {
    font-size: 16px;
  }

  body {
    background-color: #EEF1F5;
  }

  *:not(body, html) {
    ${typography.UIText} 
    transition: all ${theme.transitionTime.quick};
    line-height: ${theme.typography.paragraphLineHeight};
  }

  h1 { ${typography.heading1} }
  h2 { ${typography.heading2} }
  h3 { ${typography.heading3} }
  h4 { ${typography.heading4} }
  h5 { ${typography.heading5} }
  h6 { ${typography.heading6} }

  p, p * { ${typography.paragraph} }

  h1, h2, h3, h4, h5, h6, p {
    margin-bottom: ${theme.spacing.x2};
    margin-top: ${theme.spacing.x2};

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: ${theme.color.secondaryDark};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  // accessibility options
  *:focus-visible{
    outline: 2px #000 solid;
    outline-style: auto;
  }

  // react modal
  .ReactModal__Overlay {
    opacity: 0;
    transition: all 300ms ease-in-out;
  }

  .ReactModal__Content {
    opacity: 0;
    transform: translateY(-100px);
    transition: all 300ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
  
  .ReactModal__Content--after-open {
    opacity: 1;
    transform: translateY(0px);
  }

  .ReactModal__Content--before-close {
    opacity: 0;
    transform: translateY(-100px);
  }
`;

export default BaseStyle;