import React from 'react';
import styled, { css } from 'styled-components';

export const FlexGrow = styled.div<{ grow?: number } & React.HTMLProps<HTMLDivElement>>`
  flex-grow: ${({
    grow,
  }) => grow || 1};
  flex-basis: 0;
`;

export type FlexBoxProps = {
  direction?: string;
  justify?: string;
  alignItems?: string;
  space?: number;
  inline?: boolean;
}

const FlexBox = styled.div<FlexBoxProps & React.HTMLProps<HTMLDivElement>>`
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  flex-wrap: wrap;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  ${({ direction }) => direction && css`flex-direction: ${direction}`};

  &>* {
    margin: 0;

    &:not(:last-child) {
      ${({ theme, direction, space }) => direction === 'column' && css`
        margin-bottom: ${theme.spacing.base * (space !== undefined ? space : 1)}px;
      `}

      ${({ theme, direction, space }) => (!direction || direction === 'row') && css`
        margin-right: ${theme.spacing.base * (space !== undefined ? space : 1)}px;
      `}
    }
  }
`;

export default FlexBox;
