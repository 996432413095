import {
  darken,
} from 'polished';
import styled from 'styled-components';

type Props = {
  size?: string,
  color?: string
}

const ALink = styled.a<Props>`
  cursor: pointer;
  outline: none;
  text-decoration: none;
  color: ${({ theme, color }) => color || theme.color.primary};
  &:hover { color: ${({ theme, color }) => darken(0.05, color || theme.color.primary)};text-decoration: none;}
  &:active {color: ${({ theme, color }) => darken(0.10, color || theme.color.primary)};text-decoration: none;}
  &.disabled { opacity: 0.4; cursor: not-allowed;}
  &.disabled:hover {text-decoration: none;color: ${({ theme, color }) => color || theme.color.primary};}
`;

export default ALink;
