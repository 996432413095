import { darken } from 'polished';
import styled, { css } from 'styled-components';
import theme from '../styles/theme';
import Status from '../types/enum/Status';

export type TimeInputProps = {
  isMobile?: boolean;
  isFullWidth?: boolean;
  disabled?: boolean;
  status?: Status;
} & React.HTMLProps<HTMLInputElement>;

const TimeInput = styled.input.attrs({
  type: 'time',
})<TimeInputProps>`
  box-sizing: border-box;
  padding: 5px 12px;
  position: relative;
  height: ${({ theme }) => `${theme.spacing.base * 4}px`};
  width: ${({ isMobile }) => (isMobile ? '86px' : '128px')};
  font-size: ${({ isMobile }) => (isMobile ? '0.85rem' : '1rem')};
  border-radius: ${({ theme }) => theme.radius};
  border: 1px solid
    ${({ theme, status }) => (status === Status.default
    ? theme.color.borderColor
    : theme.color[status || Status.default])};
  ::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    padding-left: 0px;
    padding-right: 0px;
    z-index: 1;
  }
  ::placeholder {
    color: ${({ theme }) => theme.color.textMuted};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.color.lightBackground};
  }

  &:hover {
    border-color: ${({ theme }) => theme.color.primaryLight};
  }

  &:focus {
    outline-color: ${({ theme }) => theme.color.primaryLight};
    outline-width: 2px;
  }
  &:before {
    background: none;
    display: block;
    font-family: 'FontAwesome';
    content: url('data:image/svg+xml;utf8,<svg width="13" height="8" viewBox="0 0 13 8" aria-hidden="true" focusable="false" fill="none" xmlns="http://www.w3.org/2000/svg" style="display: inline-block;line-height: 1;"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.293 0.292908L13.7072 1.70712L7.00008 8.41423L0.292969 1.70712L1.70718 0.292908L7.00008 5.5858L12.293 0.292908Z" fill="gray"/></svg>');
    position: absolute;
    top: 3px;
    right: 15px;
    color: #7E7E7E;
  }
`;
TimeInput.defaultProps = {
  status: Status.default,
  isFullWidth: false,
};

export type TimeInputContainerProps = {
  theme?: typeof theme;
  isFullWidth?: boolean;
} & React.HTMLProps<HTMLDivElement>;

export const InputContainer = styled.div<TimeInputContainerProps>`
  position: relative;
  display: inline-flex;
  ${({ isFullWidth }) => isFullWidth
    && css`
      width: 100%;
    `}
`;

export const InputIconContainer = styled.button<TimeInputContainerProps>`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.color.primary};
  width: ${({ theme, isFullWidth }) => (isFullWidth ? '100%' : `${theme.spacing.base * 20}px`)};
  display: flex;
  padding: 0;
  width: ${({ theme }) => theme.spacing.x3};

  align-items: center;
  justify-content: center;
  position: absolute;
  right: ${({ theme }) => theme.spacing.x1};
  top: ${({ theme }) => theme.spacing.xs};
  bottom: ${({ theme }) => theme.spacing.xs};
  transition: 0.2s all;
  border-radius: ${({ theme }) => theme.radius};
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: ${({ theme }) => theme.color.lightBackground};
    border-radius: 50%;
  }

  &:active {
    background-color: ${({ theme }) => darken(0.05, theme.color.lightBackground)};
  }
`;

export default TimeInput;
