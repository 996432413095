import React, {
  HTMLProps, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Card from './Card';
import Table from './Table';
import { Subtitle } from './Text';
import _theme from '../styles/theme';
import FlexBox, { FlexGrow } from './FlexBox';
import { RightAlign } from './UtilsComponents';
import Button from './Button';
import Icon from './Icon';
import Status from '../types/enum/Status';
import EditStudentGrade from './EditStudentGrade';
import SnackBar from './SnackBar';

type GradeItemProps = {
  // eslint-disable-next-line camelcase
  course_code: string;
  id: string;
  section: string;
  title: string;
  units: number;
  // eslint-disable-next-line camelcase
  final_score: string;
  points: string;
  semester: string;
  isCurrentSemester: boolean;
};

type GradeProps = {
  info: Array<GradeItemProps>;
  semester: string;
  termId: string;
  summary: any;
  cumulative: any;
  isMobile: boolean;
  screensize?: string;
  isBehalf: boolean;
  studentId: string;
};
const GradeCard = ({
  info,
  semester,
  termId,
  isMobile,
  summary,
  cumulative,
  isBehalf,
  studentId,
}: GradeProps): JSX.Element => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [currentTermId, setCurrentTermId] = useState<string>(termId);
  const closeEditModal = () => {
    setIsEditOpen(false);
    window.location.reload();
  };
  const importGrades = (e: any) => {
    console.log('current term', currentTermId);
    setLoading(true);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios
      .post(
        `/api/v1/courses/sections/students/${studentId}/term/${currentTermId}/uploadGrade`,
        formData,
        config,
      )
      .then((res) => {
        setLoading(false);
        window.location.reload();
        setSnackbarOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setErrorSnackbarOpen(true);
      });
  };
  useEffect(() => {
    setCurrentTermId(termId);
  }, [termId]);

  function getScore(user: GradeItemProps): string {
    if (user.final_score) {
      return user.final_score;
    }

    if (user.isCurrentSemester) {
      return 'TBA';
    } else {
      return 'I';
    }
  }

  return (
    <>
      <SnackBar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        type="success"
        message="Grades uploaded successfully !"
      />
      <SnackBar
        isOpen={errorSnackbarOpen}
        setOpen={setErrorSnackbarOpen}
        type="error"
        message="Something went wrong. Please try again!"
      />
      <Card>
        <FlexBox>
          <FlexGrow>
            <h3>{semester}</h3>
            <Subtitle>Course Result</Subtitle>
          </FlexGrow>
          {isEditOpen && (
            <EditStudentGrade
              isOpen={isEditOpen}
              setOpen={setIsEditOpen}
              onEditComplete={() => {
                closeEditModal();
              }}
              info={info}
              studentId={studentId}
            />
          )}
          {isBehalf && currentTermId ? (
            <>
              {console.log('semester', semester, termId, currentTermId)}
              <FlexGrow ml-auto style={{ marginLeft: 'auto' }}>
                <RightAlign>
                  <label
                    style={{
                      cursor: 'pointer',
                      color: '#CB092B',
                      border: '1px solid #CB092B',
                      padding: '4px 12px',
                      borderRadius: '4px',
                      marginRight: '16px',
                    }}
                    htmlFor={currentTermId}>
                    <>
                      <Icon mr={0.5} name="cloud-upload"></Icon>
                      Import Grades
                    </>
                  </label>
                  <input
                    type="file"
                    id={currentTermId}
                    accept=".csv"
                    style={{ display: 'none' }}
                    // eslint-disable-next-line no-return-assign
                    onClick={(
                      e: React.MouseEvent<HTMLInputElement, MouseEvent>,
                    ) => {
                      (e.target as HTMLInputElement).value = '';
                      console.log(currentTermId, semester);
                    }}
                    onChange={importGrades}
                  />
                  <Button
                    onClick={() => setIsEditOpen(true)}
                    isOutline
                    status={Status.primary}
                    style={{ padding: '4px 12px' }}>
                    <Icon name="pencil-s" mr={0.5} />
                    Edit Grade
                  </Button>
                </RightAlign>
              </FlexGrow>
            </>
          ) : (
            <></>
          )}
        </FlexBox>
        {!isMobile ? (
          <>
            <Table>
              <thead>
                <tr>
                  <th>Course/Sect.</th>
                  <th>Course Name</th>
                  <th>Units</th>
                  <th>Final Grade</th>
                  <th>Grade Point</th>
                </tr>
              </thead>
              {info?.map((user, index) => (
                <>
                  <tr key={index}>
                    <td>
                      {user.course_code} / {user.section || 'A'}
                    </td>
                    <th style={{ width: '50%' }}>{user.title || 'X'}</th>
                    <td>{user.units || 'X'}</td>
                    <td>{getScore(user)}</td>
                    <td>{user.points ? (+user.points).toFixed(2) : 'N/A'}</td>
                  </tr>
                </>
              ))}
              <tr
                style={{
                  background: _theme.color.lightBackground,
                  fontWeight: 'bold',
                }}>
                <td />
                <td>Term Summary</td>
                <td>{summary?.units || 'N/A'}</td>
                <td />
                <td>GPA: {summary?.qpa ? (+summary.qpa).toFixed(2) : 'N/A'}</td>
              </tr>
              <tr
                style={{
                  background: _theme.color.primary,
                  fontWeight: 'bold',
                }}>
                <td />
                <td style={{ color: 'white' }}>Accumulated</td>
                <td style={{ color: 'white' }}>{cumulative.units || 'N/A'}</td>
                <td />
                <td style={{ color: 'white' }}>
                  QPA: {cumulative.qpa ? cumulative.qpa.toFixed(2) : 'N/A'}
                </td>
              </tr>
            </Table>
          </>
        ) : (
          <>
            <Table>
              <thead>
                <tr>
                  <th />
                  <th>Units</th>
                  <th>Final Grade</th>
                  <th>Grade Point</th>
                </tr>
              </thead>
              {info.map((user, index) => (
                <>
                  <tr key={index}>
                    <th colSpan={6}>
                      {user.course_code} - {user.title}
                    </th>
                  </tr>
                  <tr>
                    <td />
                    <td>{user.units || 'X'}</td>
                    <td>{user.final_score || 'I'}</td>
                    <td>{user.points ? (+user.points).toFixed(2) : 'N/A'}</td>
                  </tr>
                </>
              ))}
              <tr
                style={{
                  background: _theme.color.lightBackground,
                  fontWeight: 'bold',
                }}>
                <td>Term Summary</td>
                <td>{summary.units || 'N/A'}</td>
                <td />
                <td>GPA: {summary.qpa ? (+summary.qpa).toFixed(2) : 'N/A'}</td>
              </tr>
              <tr
                style={{
                  background: _theme.color.primary,
                  color: 'white',
                  fontWeight: 'bold',
                }}>
                <td style={{ color: 'white' }}>Accumulated</td>
                <td style={{ color: 'white' }}>{cumulative.units || 'N/A'}</td>
                <td />
                <td style={{ color: 'white' }}>
                  QPA: {cumulative.qpa ? cumulative.qpa.toFixed(2) : 'N/A'}{' '}
                </td>
              </tr>
            </Table>
          </>
        )}
      </Card>
    </>
  );
};

export default GradeCard;
