export const formatDecimal = (number: any) => {
  if (number === 0) {
    return number;
  }
  if (number) {
    return `${number?.toLocaleString(undefined, { maximumFractionDigits: 2 })}.00`;
  }
  return '';
};
export default formatDecimal;
