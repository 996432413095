import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Icon from './Icon';
import theme from '../styles/theme';

type Props = {
  name : string;
  to: any;
  margin: number;
  icon: string;
  customStyle? : Object;
  children?:any;
  isMain?: boolean;
  fontSize: string;
  click?: any;
  isDropDown?: boolean,
  isSignOut?: boolean,
};

const ActiveStyled = styled.div`
  margin: 0 -${({ theme }) => theme.spacing.x2};
  margin-bottom: 0px;
  width : calc(100% + 2 * ${({ theme }) => theme.spacing.x1});

  &:hover {
    background-color: ${({ theme }) => theme.color.lightBackground};
    border-radius: ${({ theme }) => theme.radius};
  }
  `;

function SidebarMenuItem({
  name,
  to,
  margin,
  icon,
  children = null,
  fontSize,
  isMain = false,
  click,
  isDropDown = false,
  isSignOut,
}: Props): JSX.Element {
  let style = {
    textDecoration: 'none',
    paddingLeft: theme.spacing.x2,
    paddingTop: theme.spacing.x1,
    paddingBottom: theme.spacing.x1,
    paddingRight: theme.spacing.x2,
    color: theme.color.textColor,
    alignContent: 'center',
    fontSize,
    textoverflow: 'clip',
    display: 'flex',
  };
  const appendStyle = { justifyContent: 'space-between' };

  if (isDropDown) {
    style = { ...style, ...appendStyle };
  }

  if (!isMain) {
    style.paddingLeft = `calc(${theme.spacing.x4} + ${theme.spacing.x1})`;
  }
  return (
    <ActiveStyled onClick = {click}>
      <NavLink
        to={to}
        style={style}
        exact
        activeStyle={{ fontWeight: 'bold', whiteSpace: 'nowrap', color: theme.color.primaryDark }}
      >
        <div style = {{ fontSize: '16px' }}>
        <Icon mr={margin} name={icon} />
        {name}
        </div>
        {children}

      </NavLink>
    </ActiveStyled>
  );
}

export default React.memo(SidebarMenuItem);
