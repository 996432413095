import React from 'react';
import { NullStateText } from './Text';

const NullStateTR = ({ children }: { children?: React.ReactNode }) => <tr>
  <td style={{ textAlign: 'center' }} colSpan={999}>
    <NullStateText>
      {children || 'No data.'}
    </NullStateText>
  </td>
</tr>;

export default NullStateTR;
