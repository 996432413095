/* eslint-disable no-nested-ternary */
import React, {
  HTMLProps, useEffect, useMemo, useState,
} from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import axios from 'axios';
import { useScreenClass } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import { Col, Row } from '../components/StyledGridSystem';
import Breadcrumb from '../components/Breadcrumb';
import Card from '../components/Card';
import Table from '../components/Table';
import NullStateTR from '../components/NullStateTr';
import { StyledText, TextSize, TextStatus } from '../components/Text';
import FlexBox, { FlexGrow } from '../components/FlexBox';
import { RightAlign } from '../components/UtilsComponents';
import Button from '../components/Button';
import Icon from '../components/Icon';
import Status from '../types/enum/Status';
import theme from '../styles/theme';
import TermDropdown from '../components/TermDropDown';
import TermEditModal from '../components/TermEditModal';
import { EmptyTermContent, TermContent } from '../filter/FilterValue';

const RowStyled = styled.div`
  display: flex;
  flex-flow: column;
`;
function TermsPage() {
  const history = useHistory();
  useEffect(() => {
    fetchTerms();
  }, []);
  const screenClass = useScreenClass();
  const test = [{ name: 'Terms', link: '/terms' }];
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [currentTerm, setCurrentTerm] = useState<TermContent>(EmptyTermContent);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = (data: any) => {
    if (data) {
      setCurrentTerm(data);
      setIsModalOpen(true);
    }
  };
  const openEditModal = (data: any) => {
    if (data) {
      console.log('current term in edit', data);
      setCurrentTerm(data);
      setIsEditOpen(true);
    }
  };
  const [loading, setLoading] = useState(true);
  const [isItem, setIsItem] = useState(false);
  const ColumnName = (isMobile: boolean) => {
    if (isMobile) {
      return (
        <>
          <th></th>
          <th></th>
        </>
      );
    }
    return (
      <>
        <th>Term Name</th>
        <th>Start</th>
        <th>End</th>
        <th>Add/Drop course start</th>
        <th>Add course end</th>
        <th>Withdraw course end</th>
        <th></th>
      </>
    );
  };
  const [terms, setTerms] = useState([
    {
      id: '',
      name: '',
      term_start: '',
      term_end: '',
      add_course_start: '',
      add_course_end: '',
      withdraw_end: '',
    },
  ]);
  const fetchTerms = () => {
    setLoading(true);
    axios
      .get('/api/v1/courses/terms')
      .then((res) => {
        if (res.data.data.terms) {
          setTerms(res.data.data.terms);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const createTerm = () => {
    const path = '/terms/create';
    history.push(path);
  };
  const closeEditModal = () => {
    setIsEditOpen(false);
    fetchTerms();
  };
  return (
    <>
      {isEditOpen && (
        <TermEditModal
          isOpen={isEditOpen}
          setOpen={setIsEditOpen}
          currentTerm={currentTerm}
          onEditComplete={() => {
            closeEditModal();
          }}
        />
      )}
      <Row>
        <Col>
          <Breadcrumb path={test} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FlexBox space={2}>
            <h2 style={{ marginRight: 'auto', marginBottom: 0 }}>Terms</h2>
          </FlexBox>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ marginTop: '0px' }}>
            <FlexBox
              style={{
                alignItems: 'flex-start',
                marginBottom: theme.spacing.base * 2,
              }}>
              <FlexGrow ml-auto style={{ marginLeft: 'auto' }}>
                <RightAlign>
                  <Button
                    onClick={() => createTerm()}
                    isOutline
                    status={Status.primary}
                    style={{ padding: '4px 12px' }}>
                    <Icon name="plus-s" mr={0.5} />
                    Create New Term
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
            <Table hoverable>
              <thead>
                <tr>{ColumnName(isMobile)}</tr>
              </thead>
              <tbody>
                {!loading && terms ? (
                  terms.map((data) => {
                    if (!isMobile) {
                      return (
                        <tr key={data.id} onClick={() => openModal(data)}>
                          <td>
                            <StyledText textSize={TextSize.default}>
                              {data.name}
                            </StyledText>
                          </td>
                          <td>{data.term_start ? new Date(data.term_start).toDateString() : '-'}</td>
                          <td>{data.term_end ? new Date(data.term_end).toDateString() : '-'}</td>
                          <td>
                            {data.add_course_start === '0001-01-01T00:00:00Z'
                              ? '-'
                              : moment(
                                new Date(data.add_course_start).toISOString(),
                              )
                                .utc()
                                .format('DD MM YYYY HH:mm:ss')}
                          </td>
                          <td>
                            {data.add_course_end === '0001-01-01T00:00:00Z' ? '-' : moment(new Date(data.add_course_end).toISOString())
                              .utc()
                              .format('DD MM YYYY HH:mm:ss')}
                          </td>
                          <td>
                            {data.withdraw_end === '0001-01-01T00:00:00Z' ? '-' : moment(new Date(data.withdraw_end).toISOString())
                              .utc()
                              .format('DD MM YYYY HH:mm:ss')}
                          </td>
                          <td
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            style={{ verticalAlign: 'middle' }}>
                            <TermDropdown
                              term={data}
                              openEditModal={openEditModal}
                            />
                          </td>
                        </tr>
                      );
                    }

                    return (
                      <tr key={data.id}>
                        <td>
                          <RowStyled>
                            <StyledText style={{ fontWeight: 'bold' }}>
                              {data.name}
                            </StyledText>
                            <StyledText status={TextStatus.muted}>
                              {data.term_start}
                            </StyledText>
                            <StyledText status={TextStatus.muted}>
                              {data.term_end}
                            </StyledText>
                            <StyledText status={TextStatus.muted}>
                              {data.add_course_start}
                            </StyledText>
                            <StyledText status={TextStatus.muted}>
                              {data.add_course_end}
                            </StyledText>
                            <StyledText status={TextStatus.muted}>
                              {data.withdraw_end}
                            </StyledText>
                          </RowStyled>
                        </td>
                        <td
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          style={{ verticalAlign: 'middle' }}>
                          <TermDropdown
                            term={data}
                            openEditModal={openEditModal}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <div></div>
                )}
                {isItem && !loading ? (
                  <NullStateTR>No term found.</NullStateTR>
                ) : (
                  <div></div>
                )}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default TermsPage;
