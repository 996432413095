import {
  useMemo, useState, useEffect, useContext,
} from 'react';
import { useScreenClass } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import styled from 'styled-components';
import _theme from '../styles/theme';
import { Col, Row } from './StyledGridSystem';
import Card from './Card';
import FlexBox, { FlexGrow } from './FlexBox';
import { RightAlign } from './UtilsComponents';
import Status from '../types/enum/Status';
import Button from './Button';
import Icon from './Icon';
import Table from './Table';
import NullStateTR from './NullStateTr';
import Input from './Input';
import { StudentContext } from '../context/StudentBehalfContext';

interface ScreenProps {
  readonly isMobile: boolean;
}

const StyledTR = styled.tr<ScreenProps>`
  display: ${(props) => (props.isMobile ? 'inline-grid' : 'table-row')};
  width: 100%;
`;
const StyledTH = styled.th<ScreenProps>`
  padding: ${(props) => (props.isMobile ? '8px 16px 0px 16px !important' : '8px 16px !important')};
`;
const StyledTD = styled.td<ScreenProps>`
  display: ${(props) => (props.isMobile ? 'inline-grid' : 'table-cell')};
  padding: ${(props) => (props.isMobile ? '0px 16px 8px 16px !important' : '8px 16px !important')};
`;
type ViewMode = 'view' | 'edit';
type UserType = 'admin' | 'student';
/* eslint-disable camelcase */
interface User {
  user_id: string;
  student_id: string;
  second_emergency_contact: {
    id: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    relationship: string;
    phone_number: string;
    personal_email: string;
  };
}
type SecondEmergencyContactForm = {
  id: string | undefined;
  first_name: string | undefined;
  middle_name: string | undefined;
  last_name: string | undefined;
  relationship: string | undefined;
  phone_number: string | undefined;
  personal_email: string | undefined;
};
type SecondEmergencyContactProps = {
  defViewMode: ViewMode;
  userType: UserType;
  userInfo: User;
  information_confirm: boolean;
  reloadParent: any;
};
const SecondEmergencyContact = ({
  defViewMode,
  userType,
  userInfo,
  information_confirm,
  reloadParent,
}: SecondEmergencyContactProps) => {
  const validationSchema = Yup.object({
    id: Yup.string(),
    personal_email: Yup.string().email('Personal email is invalid'),
    phone_number: Yup.string(),
    first_name: Yup.string(),
    middle_name: Yup.string(),
    last_name: Yup.string(),
    relationship: Yup.string(),
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<SecondEmergencyContactForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: userInfo.second_emergency_contact.id,
      first_name: userInfo.second_emergency_contact.first_name,
      middle_name: userInfo.second_emergency_contact.middle_name,
      last_name: userInfo.second_emergency_contact.last_name,
      relationship: userInfo.second_emergency_contact.relationship,
      phone_number: userInfo.second_emergency_contact.phone_number,
      personal_email: userInfo.second_emergency_contact.personal_email,
    },
  });
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  // Loading text on "Enrolled Courses"
  const [loading, setLoading] = useState<boolean>(true);
  const [viewMode, setViewMode] = useState<ViewMode>(defViewMode);
  const { isBehalf } = useContext(StudentContext);
  const [verified, setVerified] = useState<boolean>(false);
  const [secondEmergencyContact, setSecondEmergencyContact] = useState({
    user_id: '',
    second_emergency_contact: {
      id: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      relationship: '',
      phone_number: '',
      personal_email: '',
    },
  });
  const onSubmit = (data: any, e: any) => console.log('success', data, e);
  const onSubmitClicked = (data: any, e: any) => {
    if (isDirty && userInfo.user_id && userInfo.user_id !== '') {
      axios
        .put(`/api/v1/students/${userInfo.student_id}/second-emergency-contact`, {
          first_name: data.first_name,
          middle_name: data.middle_name,
          last_name: data.last_name,
          relationship: data.relationship,
          phone_number: data.phone_number,
          personal_email: data.personal_email,
        })
        .then(() => {
          history.replace({
            pathname: '/personalInfo',
            state: { userId: userInfo.user_id, role: userType },
          });
          reloadParent();
          setViewMode('view');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const onError = (errors: any, e: any) => console.log('error', errors, e);
  useEffect(() => {
    setSecondEmergencyContact({
      user_id: userInfo.user_id,
      second_emergency_contact: {
        id: userInfo.second_emergency_contact.id,
        first_name: userInfo.second_emergency_contact.first_name,
        middle_name: userInfo.second_emergency_contact.middle_name,
        last_name: userInfo.second_emergency_contact.last_name,
        relationship: userInfo.second_emergency_contact.relationship,
        phone_number: userInfo.second_emergency_contact.phone_number,
        personal_email: userInfo.second_emergency_contact.personal_email,
      },
    });
    setVerified(information_confirm);
    setLoading(false);
  }, [userInfo, information_confirm]);

  const history = useHistory();
  function checkEditing() {
    if (userType === 'admin') {
      return false;
    }
    if (userType === 'student' && !verified) {
      return false;
    }
    if (userType === 'student' && verified) {
      return true;
    }
    return false;
  }
  return (
    <>
      <Card style={{ margin: '24px 0px' }}>
        <form onSubmit={handleSubmit(onSubmitClicked, onError)}>
          <FlexBox
            alignItems="center"
            style={{
              marginBottom: _theme.spacing.x2,
            }}>
            <FlexGrow>
              <h3>Second Emergency Contact</h3>
            </FlexGrow>
            <FlexGrow ml-auto style={{ marginLeft: 'auto' }}>
              <RightAlign>
                {viewMode === 'view' && (
                  <>
                    <Button
                      onClick={() => {
                        setViewMode('edit');
                      }}
                      isOutline
                      disabled={checkEditing()}
                      status={Status.primary}
                      style={{ padding: '4px 12px' }}>
                      <Icon name="pencil-edit" mr={0.5} />
                      Edit
                    </Button>
                  </>
                )}
                {viewMode === 'edit' && !isMobile && (
                  <>
                    <Button
                      onClick={() => {
                        setViewMode('view');
                      }}
                      isOutline
                      status={Status.primary}
                      style={{ padding: '4px 12px' }}>
                      <Icon name="x-s" mr={0.5} />
                      Discard Changes
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => handleSubmit(onSubmitClicked)}
                      status={Status.primary}
                      backgroundColor="#CB092B"
                      style={{ padding: '4px 12px', margin: '0px 16px' }}>
                      <Icon name="check-mark" mr={0.5} />
                      Save Changes
                    </Button>
                  </>
                )}
              </RightAlign>
            </FlexGrow>
          </FlexBox>
          <Row>
            <Col md={12}>
              <Table>
                <tbody>
                  {!loading ? (
                    <>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          First Name
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {secondEmergencyContact.second_emergency_contact.first_name
                              !== ''
                                ? secondEmergencyContact.second_emergency_contact.first_name
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Input
                                isFullWidth
                                type="text"
                                {...register('first_name')}
                                className={`form-control ${
                                  errors.first_name ? 'is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="first_name"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Middle Name
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {secondEmergencyContact.second_emergency_contact
                                .middle_name !== ''
                                ? secondEmergencyContact.second_emergency_contact.middle_name
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Input
                                isFullWidth
                                type="text"
                                {...register('middle_name')}
                                className={`form-control ${
                                  errors.middle_name ? 'is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="middle_name"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Last name
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {secondEmergencyContact.second_emergency_contact.last_name
                              !== ''
                                ? secondEmergencyContact.second_emergency_contact.last_name
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Input
                                isFullWidth
                                type="text"
                                {...register('last_name')}
                                className={`form-control ${
                                  errors.last_name ? 'is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="last_name"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Relationship With You
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {secondEmergencyContact.second_emergency_contact
                                .relationship !== ''
                                ? secondEmergencyContact.second_emergency_contact
                                  .relationship
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Input
                                isFullWidth
                                type="text"
                                {...register('relationship')}
                                className={`form-control ${
                                  errors.relationship ? 'is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="relationship"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Phone Number
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {secondEmergencyContact.second_emergency_contact
                                .phone_number !== ''
                                ? secondEmergencyContact.second_emergency_contact
                                  .phone_number
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Input
                                isFullWidth
                                type="text"
                                {...register('phone_number')}
                                className={`form-control ${
                                  errors.phone_number ? 'is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="phone_number"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Personal Email
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {secondEmergencyContact.second_emergency_contact
                                .personal_email !== ''
                                ? secondEmergencyContact.second_emergency_contact
                                  .personal_email
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Input
                                isFullWidth
                                type="text"
                                {...register('personal_email')}
                                className={`form-control ${
                                  errors.personal_email ? 'is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="personal_email"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                    </>
                  ) : (
                    <NullStateTR>
                      <Icon mr={1} name="spinner" spin />
                      Loading...
                    </NullStateTR>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
          {viewMode === 'edit' && isMobile && (
            <FlexBox
              direction={'row'}
              justify="space-between"
              alignItems="center">
              <FlexGrow>
                <Button
                  onClick={() => {
                    setViewMode('view');
                  }}
                  isOutline
                  status={Status.primary}
                  style={{ padding: '0px 5px', border: 'none' }}>
                  <Icon name="x-s" mr={0.5} />
                  Cancel
                </Button>
              </FlexGrow>
              <FlexGrow>
                <RightAlign>
                  <Button
                    type="submit"
                    onClick={() => handleSubmit(onSubmitClicked)}
                    status={Status.primary}
                    backgroundColor="#CB092B"
                    style={{ padding: '0px 10px' }}>
                    <Icon name="check-mark" mr={0.5} />
                    Save
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
          )}
        </form>
      </Card>
    </>
  );
};

export default SecondEmergencyContact;
