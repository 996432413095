/* eslint-disable camelcase */
import { useState, useMemo, HTMLProps } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useScreenClass } from 'react-grid-system';
import FlexBox, { FlexGrow } from './FlexBox';
import theme from '../styles/theme';
import { StyledText } from './Text';
import Button from './Button';
import { RightAlign } from './UtilsComponents';
import Icon, { ClickableIcon } from './Icon';
import Status from '../types/enum/Status';
import Modal from './Modal';

const TextArea = styled.textarea`
  width: 100%;
  padding: 8px 4px;
  min-height: 100px;
}
`;
type GradeForm = {
  description: string;
};
type Document = {
  created_at: string;
  description: string;
  file_name: string;
  id: string;
  program_id: string;
  type: string;
  url: string;
};

type EditDocumentProps = {
  isOpen?: boolean;
  setOpen?: (x: boolean) => void;
  onEditComplete?: () => void;
  info: Document;
};

const EditDocument = ({
  isOpen,
  setOpen,
  onEditComplete,
  info,
}: EditDocumentProps): JSX.Element => {
  console.log('grade info in edit page', info);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<GradeForm>({
    mode: 'onChange',
    defaultValues: {
      description: info.description,
    },
  });
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const [letter_grade, setLetterGrade] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const watchAllFields = watch();
  const onSubmitClicked = (data: any) => {
    setLoading(true);
    console.log('value', data);
    axios
      .put(`/api/v1/document/${info.id}`, data)
      .then((res) => {
        setLoading(false);
        onEditComplete?.();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const onError = (errors: any, e: any) => console.log('error', errors, e);
  return (
    <>
      <Modal isOpen={!!isOpen} setOpen={setOpen}>
        <FlexBox
          style={{
            marginBottom: theme.spacing.base * 3,
          }}>
          <FlexGrow>
            <h3 style={{ marginRight: 'auto', marginBottom: 0 }}>
              Edit Document
            </h3>
          </FlexGrow>
          <RightAlign>
            <ClickableIcon name="x-s" onClick={() => setOpen?.(false)} />
          </RightAlign>
        </FlexBox>
        {!loading ? (
          <form onSubmit={handleSubmit(onSubmitClicked, onError)}>
            <FlexBox
              alignItems="flex-start"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>File Name</FlexGrow>
              <FlexGrow grow={3}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  {info.file_name}
                </StyledText>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="flex-start"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>File Type</FlexGrow>
              <FlexGrow grow={3}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  {info.type}
                </StyledText>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="flex-start"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>Description</FlexGrow>
              <FlexGrow grow={3}>
                <TextArea {...register('description')} />
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              justify="flex-end"
              style={{
                marginTop: theme.spacing.x4,
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow>
                <RightAlign>
                  <Button
                    onClick={() => {
                      setOpen?.(false);
                      onEditComplete?.();
                    }}
                    isOutline
                    status={Status.primary}
                    style={{ padding: '4px 12px' }}>
                    <Icon name="x-s" mr={0.5} />
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    // disabled={!isDirty}
                    onClick={() => handleSubmit(onSubmitClicked)}
                    status={Status.primary}
                    backgroundColor="#CB092B"
                    style={{ padding: '4px 12px', marginLeft: '8px' }}>
                    <Icon name="floppy" mr={0.5} />
                    Save
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
          </form>
        ) : (
          <Icon mr={1} name="spinner" spin />
        )}
      </Modal>
    </>
  );
};

export default EditDocument;
