/* eslint-disable camelcase */
import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { useScreenClass } from 'react-grid-system';

import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Select from 'react-select';
import styled from 'styled-components';
import _theme from '../styles/theme';
import Status from '../types/enum/Status';
import Button from './Button';
import FlexBox, { FlexGrow } from './FlexBox';
import { ClickableIcon } from './Icon';
import Input from './Input';
import Modal from './Modal';
import NullStateTR from './NullStateTr';
import Table from './Table';
import { StyledText } from './Text';

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;
interface SectionForm {
  student_id: string;
  template: {
    id: string;
  };
  additional_items: {
    name: string;
    amount: number;
    type: string;
  }[];
  extra_items: {
    name: string;
    amount: number;
    type: string;
  }[];
}
type AddInvoiceModalProps = {
  isOpen?: boolean;
  setOpen: (x: boolean) => void;
  onCreateComplete?: () => void;
  onEditError?: (err: any) => void;
};

const AddInvoiceModal = ({
  isOpen,
  setOpen,
  onCreateComplete,
}: AddInvoiceModalProps): JSX.Element => {
  const screenClass = useScreenClass();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [students, setStudents] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const [templates, setTemplates] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>({});
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SectionForm>({});
  const { fields, append, remove } = useFieldArray({
    name: 'extra_items',
    control,
    keyName: 'key',
  });
  useEffect(() => {
    fetchTemplates();
    fetchStudents();
  }, []);
  const getTemplate = (id: string) => {
    axios
      .get(`/api/v1/templates/${id}`)
      .then((res) => {
        if (res.data.data.template) {
          setSelectedTemplate(res.data.data.template);
        }
        if (
          res.data.data.template.items
          && res.data.data.template.items.length > 0
        ) {
          const temp: { name: string; amount: number; type: string }[] = [];
          res.data.data.template.items.forEach((item: any) => {
            temp.push({
              name: item.name,
              amount: item.amount,
              type: item.type,
            });
          });
          setValue('additional_items', temp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchTemplates = () => {
    setLoading(true);
    axios
      .get('/api/v1/templates')
      .then((res) => {
        if (res.data.data.templates) {
          // eslint-disable-next-line max-len
          const programList: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.templates.forEach(
            (element: { name: string; id: string }) => {
              const array = { label: '', value: '' };
              array.label = element.name;
              array.value = element.id;
              programList.push(array);
            },
          );
          setTemplates(programList);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const fetchStudents = () => {
    axios
      .get('/api/v1/students')
      .then((res) => {
        if (res.data.data.students) {
          const lecturerList: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.students.forEach(
            (element: {
              first_name: string;
              last_name: string;
              student_id: string;
            }) => {
              const array = { label: '', value: '' };
              array.label = `${element.first_name}${' '}${element.last_name}`;
              array.value = element.student_id;
              lecturerList.push(array);
            },
          );
          setStudents(lecturerList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onSubmit = (data: any) => {
    setLoading(true);
    const payload: {
      additional_items: Array<any>;
      student_id: string;
      template: any;
    } = {
      additional_items: [],
      student_id: '',
      template: {},
    };
    data.extra_items.forEach((item2: any) => {
      payload.additional_items.push({
        amount: Number(item2.amount),
        name: item2.name,
        type: item2.type,
      });
    });
    payload.student_id = data.student_id;
    payload.template = data.template;
    axios
      .post(`/api/v1/students/${data.student_id}/invoices`, payload)
      .then(() => {
        setLoading(false);
        onCreateComplete?.();
        setOpen(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  return (
    <>
      <Modal
        isOpen={!!isOpen}
        setOpen={setOpen}
        customStyle={{ content: { maxWidth: '700px' } }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexBox style={{ margin: '16px 0px' }}>
            <FlexGrow>
              <h3>Create New Invoice</h3>
            </FlexGrow>
            <ClickableIcon name="x-s" onClick={() => setOpen(false)} />
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px 0px 0px' }}>
            <FlexGrow style={{ flex: '0 0 100%', marginBottom: '5px' }}>
              <StyledText>Student</StyledText>
              <Select
                isMulti={false}
                {...register('student_id', {
                  required: 'Select the student',
                })}
                options={students}
                onChange={(e: any) => {
                  setValue('student_id', e.value);
                }}
              />
              <ErrorMessage
                errors={errors}
                name="student_id"
                render={({ message }) => (
                  <p
                    style={{
                      marginTop: 0,
                      color: _theme.color.danger,
                    }}>
                    {message}
                  </p>
                )}
              />
            </FlexGrow>
          </FlexBox>
          <FlexBox style={{ margin: '16px 0px 0px 0px' }}>
            <FlexGrow style={{ flex: '0 0 100%', marginBottom: '5px' }}>
              <StyledText>Template</StyledText>
              <Select
                isMulti={false}
                {...register('template.id', {
                  required: 'Academic Year is required',
                })}
                options={templates}
                onChange={(e: any) => {
                  setValue('template.id', e.value);
                  getTemplate(e.value);
                }}
              />
              <ErrorMessage
                errors={errors}
                name="template.id"
                render={({ message }) => (
                  <p
                    style={{
                      marginTop: 0,
                      color: _theme.color.danger,
                    }}>
                    {message}
                  </p>
                )}
              />
            </FlexGrow>
          </FlexBox>
          <FlexBox
            style={{
              margin: '16px 0px 0px 0px',
              minHeight: '400px',
              alignItems: 'flex-start',
            }}>
            <FlexGrow style={{ flex: '0 0 100%', marginBottom: '5px' }}>
              <Table>
                <thead>
                  <tr>
                    <th>Line Item Type</th>
                    <th>Line Item Name</th>
                    <th>Amount(THB)</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {selectedTemplate.items ? (
                    <>
                      {selectedTemplate.items?.map((item: any) => (
                        <>
                          <tr>
                            <td style={{ border: 'none' }}>
                              <StyledText>{item.type || '-'}</StyledText>
                            </td>
                            <td style={{ border: 'none' }}>
                              <StyledText>{item.name}</StyledText>
                            </td>
                            <td style={{ border: 'none' }}>
                              <StyledText>{item.amount}</StyledText>
                            </td>
                          </tr>
                        </>
                      ))}
                      {fields.map((field, index) => (
                        <>
                          <tr>
                            <td style={{ border: 'none' }}>
                              <Input
                                isFullWidth
                                {...register(
                                  `extra_items.${index}.type` as const,
                                  {
                                    required: 'Line Item type is required',
                                  },
                                )}></Input>
                              <p
                                style={{
                                  marginTop: 0,
                                  color: _theme.color.danger,
                                }}>
                                {errors?.extra_items?.[index]?.type}
                              </p>
                            </td>
                            <td style={{ border: 'none' }}>
                              <Input
                                isFullWidth
                                {...register(
                                  `extra_items.${index}.name` as const,
                                  {
                                    required: 'Line Item name is required',
                                  },
                                )}></Input>
                              <p
                                style={{
                                  marginTop: 0,
                                  color: _theme.color.danger,
                                }}>
                                {errors?.extra_items?.[index]?.name?.message}
                              </p>
                            </td>
                            <td style={{ border: 'none' }}>
                              <Input
                                type="number"
                                step="0.01"
                                isFullWidth={false}
                                {...register(
                                  `extra_items.${index}.amount` as const,
                                  {
                                    required: 'Line Item amount is required',
                                  },
                                )}></Input>
                              <p
                                style={{
                                  marginTop: 0,
                                  color: _theme.color.danger,
                                }}>
                                {errors?.extra_items?.[index]?.amount?.message}
                              </p>
                            </td>
                            <td style={{ border: 'none' }}>
                              <RightAlign>
                                <ClickableIcon
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    remove(index);
                                  }}
                                  name="x-s"
                                  style={{
                                    color: '#7E7E7E',
                                    verticalAlign: 'middle',
                                  }}></ClickableIcon>
                              </RightAlign>
                            </td>
                          </tr>
                        </>
                      ))}
                      <FlexBox style={{ marginTop: '5px' }}>
                        <FlexGrow>
                          <ClickableIcon
                            type="button"
                            title="Add Item"
                            style={{
                              alignItems: 'center',
                              color: _theme.color.primary,
                            }}
                            status={Status.primary}
                            name="plus-s"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              append({
                                name: '',
                                amount: 0,
                                type: 'Fee',
                              });
                            }}
                          />
                        </FlexGrow>
                      </FlexBox>
                    </>
                  ) : (
                    <NullStateTR>Select Template</NullStateTR>
                  )}
                </tbody>
              </Table>
            </FlexGrow>
          </FlexBox>
          <FlexBox
            alignItems="center"
            justify="flex-end"
            style={{
              marginBottom: _theme.spacing.x2,
            }}>
            <FlexGrow>
              <RightAlign>
                <Button
                  type="submit"
                  status={Status.primary}
                  backgroundColor="#CB092B"
                  style={{ padding: '4px 12px', marginLeft: '8px' }}>
                  {/* <Icon name="check-mark" mr={0.5} /> */}
                  Create
                </Button>
              </RightAlign>
            </FlexGrow>
          </FlexBox>
        </form>
      </Modal>
    </>
  );
};

export default AddInvoiceModal;
