import axios from 'axios';
import React, { useMemo, useState, useEffect } from 'react';

import { useScreenClass } from 'react-grid-system';
import styled from 'styled-components';
import theme from '../styles/theme';
import Status from '../types/enum/Status';
import Button from './Button';
import FlexBox, { FlexGrow } from './FlexBox';
import Icon, { ClickableIcon } from './Icon';
import Input from './Input';
import Modal from './Modal';
import { Col, Row } from './StyledGridSystem';
import { RightAlign } from './UtilsComponents';

type CourseWithdrawModalProps = {
  isOpen?: boolean;
  setOpen: (x: boolean) => void;
  inWithdrawPeriod: boolean;
  currentCourse: { [key: string]: any };
  showSections?: boolean;
  studentId: string;
  // handleWithdrawCourse: (regId: string) => void
  onDropComplete?: () => void;
  onDropError?: (err: any) => void;
};

const wait = async (ms: number) => new Promise((resolve, reject) => {
  setTimeout(resolve, ms);
});

const CourseWithdrawModal = ({
  isOpen,
  setOpen,
  currentCourse,
  inWithdrawPeriod,
  studentId,
  // handleWithdrawCourse,
  onDropError,
  onDropComplete,
}: CourseWithdrawModalProps): JSX.Element => {
  const screenClass = useScreenClass();
  const [dropConfirmation, setDropconfirmation] = useState<boolean>(true);
  const [dropPasswordInput, setDropPasswordInput] = useState<string>('');
  const [isOpenComplete, setOpenComplete] = useState<boolean>(false);
  const [isOpenError, setOpenError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const handleWithdrawCourse = async (regId: string) => {
    try {
      setLoading(true);
      // alert(`/api/v1/grading/dropCourse?course_reg_id=${regId}`);
      // await wait(800);
      // const res = await axios.delete(`/api/v1/grading/dropCourse?course_reg_id=${regId}`);
      const res = await axios.put(
        `/api/v1/students/${studentId}/courses/${regId}`,
      );
      setOpenComplete(true);
    } catch (err) {
      setOpenError(true);
      console.error(err);
      setError(err);
    }
    setOpen(false);
    setLoading(false);
  };

  const onComplete = () => {
    setOpenComplete(false);
    onDropComplete?.();
  };

  const onError = () => {
    setOpenError(false);
    onDropError?.(error);
  };

  useEffect(() => {
    if (isOpen) {
      setDropPasswordInput('');
    }
  }, [isOpen]);

  const dropPassword = useMemo(() => {
    const verb = 'withdraw';
    return `${verb} ${currentCourse.code}`;
  }, [currentCourse]);

  return (
    <>
      <Modal isOpen={isOpen || false} setOpen={setOpen}>
        <FlexBox>
          <FlexGrow>
            <h3>Withdrawing Course</h3>
          </FlexGrow>
          <ClickableIcon name="x-s" onClick={() => setOpen(false)} />
        </FlexBox>
        <p>
          Are you sure you want to withdraw the course "
          <b>
            {currentCourse.code} {currentCourse.short_name}
          </b>
          "?
        </p>
        <p>
          Please note that:
          <ul>
            <li>This cannot be undone.</li>
            <li>
              You will get a “
              <span style={{ color: theme.color.danger }}>W</span>” on your
              transcript for dropping this course.
            </li>
          </ul>
        </p>
        <p>
          To confirm, type "<b>{dropPassword}</b>" in this box.
        </p>
        <Input
          isFullWidth={true}
          value={dropPasswordInput}
          onInput={(e: any) => {
            setDropPasswordInput(e.target.value);
          }}
        />
        <RightAlign style={{ marginTop: theme.spacing.x2 }}>
          <Button
            isOutline
            onClick={() => {
              setOpen(false);
            }}
            status={Status.default}>
            Cancel
          </Button>
          <Button
            disabled={isLoading || dropPasswordInput !== dropPassword}
            status={Status.primary}
            onClick={() => handleWithdrawCourse(currentCourse.id)}>
            {isLoading && (
              <>
                <Icon mr={1} name="spinner" spin />
                Withdrawing...
              </>
            )}
            {!isLoading && (
              <>
                <Icon mr={1} name="trash" />
                Withdraw course
              </>
            )}
          </Button>
        </RightAlign>
      </Modal>

      <Modal
        isOpen={isOpenComplete}
        setOpen={setOpenComplete}
        onClose={onComplete}
        customStyle={{ content: { textAlign: 'center' } }}>
        <img
          src="/alert-success-soft.svg"
          alt="alert warning"
          style={{ margin: 'auto' }}
        />
        <h3>Course Withrawal Complete</h3>
        <p>
          The course "
          <b>
            {currentCourse.code} {currentCourse.short_name}
          </b>
          " has been withdrawn. This course will be marked as "W" on your
          transcript.
        </p>
        <Button status={Status.primary} onClick={onComplete}>
          Done
        </Button>
      </Modal>

      <Modal
        isOpen={isOpenError}
        setOpen={setOpenError}
        onClose={onError}
        customStyle={{ content: { textAlign: 'center' } }}>
        <img
          src="/alert-warning.svg"
          alt="alert warning"
          style={{ margin: 'auto' }}
        />
        <h3>Course Withdrawal Error</h3>
        <p>
          An error occured while withdrawing the course "
          <b>
            {currentCourse.code} {currentCourse.short_name}
          </b>
          ". Please try again or contact us if the problem persists.
        </p>
        <Button status={Status.primary} onClick={onError}>
          Done
        </Button>
      </Modal>
    </>
  );
};

export default CourseWithdrawModal;
