import { Link } from 'react-router-dom';
import React, {
  Fragment,
} from 'react';
import styled from 'styled-components';
import FlexBox from './FlexBox';
import Icon from './Icon';
import ALink from './ALink';

const UIText = styled.h4`
  ${({ theme }) => theme.typography.UIText}
  margin : 0px;
  color : ${({ theme }) => theme.color.textSecondary};
  font-weight: bold;
`;

type BreadcrumbItemProps = {
  name: string,
  link?: string,
}

type BreadcrumbProps = {
  path: Array<BreadcrumbItemProps>;
}

const Breadcrumb = ({ path, ...props }
  : BreadcrumbProps & React.HTMLProps<HTMLDivElement>): JSX.Element => (
    <FlexBox>
      <Link to='/' style = {{ textDecoration: 'none' }}>
        {/* <ALink> */}
          Home
        {/* </ALink> */}
      </Link>
      {path.map((page: BreadcrumbItemProps, index: number) => <Fragment
        key={`breaddrumb-item-${index}`}>
        {/* <UIText>&raquo;</UIText> */}
        <Icon name='chevron-right'/>
          {(index < path.length - 1) ? <Link to={page.link || ''} style = {{ textDecoration: 'none' }}>
              <ALink>
                {page.name}
              </ALink>
            </Link>
            : <UIText>{page.name}</UIText>
          }
        </Fragment>)}
    </FlexBox>
);

export default Breadcrumb;
