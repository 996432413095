import React, { useState } from 'react';
import styled from 'styled-components';

import Dropdown, { DropdownContainer, DropdownItem } from './Dropdown';
import Icon, { ClickableIcon } from './Icon';
import _theme from '../styles/theme';

const RemoveCourse = styled.div`
  color: ${({ theme }) => theme.color.primary};
`;

type CourseMasterDropdownProps = {
  course: { [key: string]: any };
  openAddSection?: (x: any) => void;
  openActiveModal: (x: any) => void;
  openInactiveModal: (x: any, e?: any) => void;
};

const CourseOfferDropdown = ({
  course,
  openAddSection,
  openActiveModal,
  openInactiveModal,
}: CourseMasterDropdownProps) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <DropdownContainer>
      <ClickableIcon
        ml={0.5}
        size={2}
        name="more-vertical"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!isOpen);
        }}
        style={{ color: _theme.color.primary }}
      />
      <Dropdown
        open={isOpen}
        style={{ whiteSpace: 'nowrap' }}
        setOpen={setOpen}
        align="right">
        {openAddSection && (
          <DropdownItem
            to="#"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
              openAddSection?.(course);
            }}>
            <Icon mr={1} name="plus-s" /> Add Section
          </DropdownItem>
        )}
        {course.is_active === false ? (
          <DropdownItem
            to="#"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
              openActiveModal(course);
            }}>
            <Icon mr={1} name="pencil-edit" /> Activate
          </DropdownItem>
        ) : (
          <DropdownItem
            to="#"
            onClick={(e: any) => {
              console.log('clicked');
              setOpen(false);
              openInactiveModal(course, e);
            }}>
            <RemoveCourse>
              <Icon mr={1} name="trash-empty" /> Inactivate
            </RemoveCourse>
          </DropdownItem>
        )}
      </Dropdown>
    </DropdownContainer>
  );
};

export default CourseOfferDropdown;
