import axios from 'axios';
import React, { useMemo, useState, useEffect } from 'react';

import { useScreenClass } from 'react-grid-system';
import styled from 'styled-components';
import theme from '../styles/theme';
import Status from '../types/enum/Status';
import Button from './Button';
import FlexBox, { FlexGrow } from './FlexBox';
import Icon, { ClickableIcon } from './Icon';
import Input from './Input';
import Modal from './Modal';
import { Col, Row } from './StyledGridSystem';
import { RightAlign } from './UtilsComponents';

type CourseMasterDeleteModalProps = {
  isOpen?: boolean;
  setOpen: (x: boolean) => void;
  currentCourse: { [key: string]: any };
  showSections?: boolean;
  // handleDeleteCourse: (regId: string) => void
  onDeleteComplete?: () => void;
  onDeleteError?: (err: any) => void;
};

const wait = async (ms: number) => new Promise((resolve, reject) => {
  setTimeout(resolve, ms);
});

const CourseMasterDeleteModal = ({
  isOpen,
  setOpen,
  currentCourse,
  // handleDeleteCourse,
  onDeleteError,
  onDeleteComplete,
}: CourseMasterDeleteModalProps): JSX.Element => {
  const screenClass = useScreenClass();
  const [deleteConfirmation, setDeleteconfirmation] = useState<boolean>(true);
  const [deletePasswordInput, setDeletePasswordInput] = useState<string>('');
  const [isOpenComplete, setOpenComplete] = useState<boolean>(false);
  const [isOpenError, setOpenError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const handleDeleteCourse = async (regId: string) => {
    setLoading(true);
    axios
      .delete(`/api/v1/course-master/${regId}`)
      .then((res) => {
        setOpenComplete(true);
      })
      .catch((err) => {
        setOpenError(true);
        console.error(err);
        setError(err);
      });
    setOpen(false);
    setLoading(false);
  };

  const onComplete = () => {
    setOpenComplete(false);
    onDeleteComplete?.();
  };

  const onError = () => {
    setOpenError(false);
    onDeleteError?.(error);
  };

  useEffect(() => {
    if (isOpen) {
      setDeletePasswordInput('');
    }
  }, [isOpen]);

  const deletePassword = useMemo(() => {
    const verb = 'delete';
    return `${verb} ${currentCourse.code}`;
  }, [currentCourse]);

  return (
    <>
      <Modal isOpen={isOpen || false} setOpen={setOpen}>
        <FlexBox>
          <FlexGrow>
            <h3>Deleting Course</h3>
          </FlexGrow>
          <ClickableIcon name="x-s" onClick={() => setOpen(false)} />
        </FlexBox>
        {/* <p style={{ textAlign: 'center' }}>
          <img src="/alert-warning.svg" alt="alert warning"/>
        </p> */}
        <p>
          Are you sure you want to delete the course "
          <b>
            {currentCourse.code} {currentCourse.short_name}
          </b>
          "? This cannot be undone.
        </p>
        <p>
          To confirm, type "<b>{deletePassword}</b>" in this box.
        </p>
        <Input
          isFullWidth={true}
          value={deletePasswordInput}
          onInput={(e: any) => {
            setDeletePasswordInput(e.target.value);
          }}
        />
        <RightAlign style={{ marginTop: theme.spacing.x2 }}>
          <Button
            isOutline
            onClick={() => {
              setOpen(false);
            }}
            status={Status.default}>
            Cancel
          </Button>
          <Button
            disabled={isLoading || deletePasswordInput !== deletePassword}
            status={Status.primary}
            onClick={() => handleDeleteCourse(currentCourse.id)}>
            {isLoading && (
              <>
                <Icon mr={1} name="spinner" spin />
                Deleting...
              </>
            )}
            {!isLoading && (
              <>
                <Icon mr={1} name="trash" />
                Delete course
              </>
            )}
          </Button>
        </RightAlign>
      </Modal>

      <Modal
        isOpen={isOpenComplete}
        setOpen={setOpenComplete}
        onClose={onComplete}
        customStyle={{ content: { textAlign: 'center' } }}>
        <img
          src="/alert-success-soft.svg"
          alt="alert warning"
          style={{ margin: 'auto' }}
        />
        <h3>Course Deleting Complete</h3>
        <p>
          The course "
          <b>
            {currentCourse.code} {currentCourse.short_name}
          </b>
          " has been removed.
        </p>
        <Button status={Status.primary} onClick={onComplete}>
          Done
        </Button>
      </Modal>

      <Modal
        isOpen={isOpenError}
        setOpen={setOpenError}
        onClose={onError}
        customStyle={{ content: { textAlign: 'center' } }}>
        <img
          src="/alert-warning.svg"
          alt="alert warning"
          style={{ margin: 'auto' }}
        />
        <h3>Course Delete Error</h3>
        <p>
          An error occured while deleting the course "
          <b>
            {currentCourse.code} {currentCourse.short_name}
          </b>
          ". Please try again or contact us if the problem persists.
        </p>
        <Button status={Status.primary} onClick={onError}>
          Done
        </Button>
      </Modal>
    </>
  );
};

export default CourseMasterDeleteModal;
