import React, { useState } from 'react';
import {
  Alert, Slide, SlideProps, Snackbar,
} from '@mui/material';

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}
type SnackBarProps = {
  isOpen: boolean;
  setOpen: (x: boolean) => void;
  message: string;
};

function StyledSnackbar({
  isOpen,
  setOpen,
  message,
}: SnackBarProps): JSX.Element {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  return (
    <React.Fragment>
      <Snackbar
        onClose={handleClose}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={SlideTransition}
        open={isOpen}
        key={'topright'}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
export default StyledSnackbar;
