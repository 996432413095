// /* Text-size styles */
// --heading-1: 35px;
// --heading-2: 28px;
// --heading-3: 21px;
// --heading-4: 18px;
// --heading-5: 14px;
// --heading-6: 14px;
// --u-i--text: 14px;
// --body: 16px;
// --u-i--text - -small: 12px;
// --body-- -code: 14px;

// /* Effect styles */
// --drop--shadow -default -l-v 1:  4px 0px 2px rgba(0, 0, 0, 0.15);
// --drop--shadow -primary -l-v 1:  4px 0px 2px rgba(167, 16, 42, 0.25);
// --drop--shadow -secondary -l-v 1:  4px 0px 2px rgba(203, 50, 12, 0.25);
// --drop--shadow -success -l-v 1:  4px 0px 2px rgba(27, 143, 59, 0.25);
// --drop--shadow -danger -l-v 1:  4px 0px 2px rgba(171, 23, 14, 0.25);
// --drop--shadow -warning -l-v 1:  4px 0px 2px rgba(185, 133, 0, 0.25);
// --drop--shadow -info -l-v 1:  4px 0px 2px rgba(28, 155, 163, 0.25);
// --drop--shadow -blue -l-v 1:  4px 0px 2px rgba(28, 95, 157, 0.25);
// --drop--shadow -default -l-v 4:  16px 0px 8px rgba(0, 0, 0, 0.15);
// --drop--shadow -primary -l-v 4:  16px 0px 8px rgba(167, 16, 42, 0.25);
// --drop--shadow -secondary -l-v 4:  16px 0px 8px rgba(203, 50, 12, 0.25);
// --drop--shadow -success -l-v 4:  16px 0px 8px rgba(27, 143, 59, 0.25);
// --drop--shadow -danger -l-v 4:  16px 0px 8px rgba(171, 23, 14, 0.25);
// --drop--shadow -warning -l-v 4:  16px 0px 8px rgba(185, 133, 0, 0.25);
// --drop--shadow -info -l-v 4:  16px 0px 8px rgba(28, 155, 163, 0.25);
// --drop--shadow -blue -l-v 4:  16px 0px 8px rgba(28, 95, 157, 0.25);

const colorName: { [key: string]: string } = {
  primaryLight: '#FAD3DA',
  primary: '#CB092B',
  primaryDark: '#A7102A',
  secondaryLight: '#FEDDD4',
  secondary: '#FF562B',
  secondaryDark: '#CB320C',
  textColor: '#333333',
  textSecondary: '#7E7E7E',
  textDisabled: '#AAAAAA',
  white: '#FFFFFF',
  lightBackground: '#EEF1F5',
  darkBackgroundShaded: '#3B3435',
  darkBackground: '#271D1F',
  successLight: '#D1F0D9',
  success: '#12BA41',
  successDark: '#1B8F3B',
  dangerLight: '#FBE2E1',
  danger: '#DC2317',
  dangerDark: '#AB170E',
  warningLight: '#FBECC8',
  warning: '#EFB829',
  warningDark: '#B98500',
  infoLight: '#D5FAFC',
  info: '#20D4DF',
  infoDark: '#1C9BA3',
  blueLight: '#E3F1FD',
  blue: '#1F82DE',
  blueDark: '#1C5F9D',
  borderColor: '#C1C7CF',
  borderColorDarkBg: '#676767',

  defaultLight: '#EEF1F5',
  default: '#AAAAAA',
  defaultDark: '#7E7E7E',

  // primary: '#647BCE',
  // primaryLight: '#E2E9FF',
  // primaryHalfLight: '#B4C4FD',
  // primaryHalfDark: '#4F64B4',
  // primaryDark: '#485586',

  // secondary: '#545767',
  // accent: '#F17F67',

  // textColor: '#333333',
  // textColorWhite: 'white',
  // textSecondary: '#828282',

  // lightBackground: '#ECEDF1',
  // darkBackground: '#434552',

  // borderColor: '#d3d4da',
  // textMuted: '#cccccc',

  // successLight: '#C9E8CE',
  // success: '#78C584',
  // successDark: '#40944D',
  // warningLight: '#F9EFD4',
  // warning: '#EAC35E',
  // warningDark: '#C19319',
  // dangerLight: '#FAD7D7',
  // danger: '#ED6868',
  // dangerDark: '#DA3535',
  // infoLight: '#CEEFF6',
  // info: '#85D7E9',
  // infoDark: '#229FBA',
  // link: '#8BA3F9',

  // muted: '#828282',
  // disabled: '#cccccc',
  // light: '#ffffff',
};

export default colorName;
