/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from 'react';
import ReactSelect from 'react-select';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ErrorMessage } from '@hookform/error-message';
import styled, { css } from 'styled-components';
import { useScreenClass } from 'react-grid-system';
import FlexBox, { FlexGrow } from '../components/FlexBox';
import { StyledText } from '../components/Text';
import TextArea from '../components/TextArea';
import { RightAlign } from '../components/UtilsComponents';
import Status from '../types/enum/Status';
import theme from '../styles/theme';
import Icon from '../components/Icon';
import Button from '../components/Button';
import Card from '../components/Card';
import Breadcrumb from '../components/Breadcrumb';
import MinimalSelect from '../components/MinimalSelect';
import Input from '../components/Input';
import Modal from '../components/Modal';

const sharedMinimalStyle = css`
  width: 100%;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 0px 12px;
  .react-select__menu {
    width: 100%;
    min-width: unset;
    margin-left: -12px;
  }
`;
const CustomGrow = styled(FlexGrow)`
  flex-grow: 0;
  white-space: nowrap;
`;
const StyledMinimalSelect = styled(MinimalSelect)`
  ${sharedMinimalStyle}
`;
const StyledReactSelect = styled(ReactSelect)<{ isMobile?: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '86px' : '128px')};
`;
type TermForm = {
  name: string | undefined;
  term_start: string | undefined;
  term_end: string | undefined;
  add_course_start: string | undefined;
  add_course_end: string | undefined;
  withdraw_end: string | undefined;
};
function CreateTermPage(props: any) {
  useEffect(() => {
    setLoading(false);
  }, []);
  const [term_start, setTermStart] = useState<Dayjs | null>(dayjs());
  const [term_end, setTermEnd] = useState<Dayjs | null>(dayjs(new Date()));
  const [add_course_start, setAddCourseStart] = useState<Dayjs | null>(
    dayjs(new Date()),
  );
  const [add_course_end, setAddCourseEnd] = useState<Dayjs | null>(
    dayjs(new Date()),
  );
  const [withdraw_end, setWithDrawEnd] = useState<Dayjs | null>(dayjs());
  const validationSchema = Yup.object({
    name: Yup.string().required('Term name is required'),
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [isOpenError, setOpenError] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<TermForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
  });
  const history = useHistory();
  const watchAllFields = watch();
  const onSubmitClicked = (data: any) => {
    const createData = {
      name: data.name,
      term_start,
      term_end,
      add_course_start: add_course_start?.add(7, 'hour'),
      add_course_end: add_course_end?.add(7, 'hour'),
      withdraw_end: withdraw_end?.add(7, 'hour'),
    };
    setLoading(true);
    console.log('create term data', createData);
    axios
      .post('/api/v1/courses/terms', createData)
      .then((res) => {
        setLoading(false);
        history.push('/terms');
        console.log('create term', res.data.data);
      })
      .catch((error) => {
        setOpenError(true);
        console.log(error);
        setLoading(false);
      });
  };
  const onError = (errors: any, e: any) => console.log('error', errors, e);
  const onErrorCloseModal = () => {
    setOpenError(false);
  };
  return (
    <>
      <FlexBox
        style={{
          marginBottom: theme.spacing.base * 3,
          marginTop: theme.spacing.base * 3,
        }}>
        <FlexGrow>
          <h2 style={{ marginRight: 'auto', marginBottom: 0 }}>Create Term</h2>
        </FlexGrow>
      </FlexBox>
      <Card style={{ margin: '24px 0px' }}>
        {!loading ? (
          <form onSubmit={handleSubmit(onSubmitClicked, onError)}>
            <FlexBox
              alignItems="flex-start"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow>
                <h3>Term Information</h3>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Term Name
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('name')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="name"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox>
              <FlexGrow grow={1}>
                <FlexBox>
                  <FlexGrow>
                    <StyledText color={'#000000'} fontWeight={'bold'}>
                      Term Start Date
                    </StyledText>
                  </FlexGrow>
                  <FlexGrow>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        openTo="year"
                        views={['year', 'month', 'day']}
                        value={term_start}
                        onChange={(newValue) => {
                          setTermStart(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} helperText={null} />
                        )}
                      />
                    </LocalizationProvider>
                    <ErrorMessage
                      errors={errors}
                      name="term_start"
                      render={({ message }) => (
                        <p
                          style={{
                            marginTop: 0,
                            color: theme.color.danger,
                          }}>
                          {message}
                        </p>
                      )}
                    />
                  </FlexGrow>
                </FlexBox>
              </FlexGrow>
              <FlexGrow grow={1}>
                <FlexBox>
                  <FlexGrow>
                    <StyledText color={'#000000'} fontWeight={'bold'}>
                      Term End Date
                    </StyledText>
                  </FlexGrow>
                  <FlexGrow>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        openTo="year"
                        views={['year', 'month', 'day']}
                        value={term_end}
                        onChange={(newValue) => {
                          setTermEnd(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} helperText={null} />
                        )}
                      />
                    </LocalizationProvider>
                    <ErrorMessage
                      errors={errors}
                      name="term_end"
                      render={({ message }) => (
                        <p
                          style={{
                            marginTop: 0,
                            color: theme.color.danger,
                          }}>
                          {message}å
                        </p>
                      )}
                    />
                  </FlexGrow>
                </FlexBox>
              </FlexGrow>
            </FlexBox>
            <FlexBox>
              <FlexGrow>
                <FlexBox>
                  <FlexGrow>
                    <StyledText color={'#000000'} fontWeight={'bold'}>
                      Add Course Start Date
                    </StyledText>
                  </FlexGrow>
                  <FlexGrow>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        openTo="year"
                        value={add_course_start}
                        onChange={(newValue) => {
                          setAddCourseStart(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} helperText={null} />
                        )}
                      />
                    </LocalizationProvider>
                    <ErrorMessage
                      errors={errors}
                      name="add_course_start"
                      render={({ message }) => (
                        <p
                          style={{
                            marginTop: 0,
                            color: theme.color.danger,
                          }}>
                          {message}
                        </p>
                      )}
                    />
                  </FlexGrow>
                </FlexBox>
              </FlexGrow>
              <FlexGrow>
                <FlexBox>
                  <FlexGrow>
                    <StyledText color={'#000000'} fontWeight={'bold'}>
                      Add Course End Date
                    </StyledText>
                  </FlexGrow>
                  <FlexGrow>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        openTo="year"
                        value={add_course_end}
                        onChange={(newValue) => {
                          setAddCourseEnd(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} helperText={null} />
                        )}
                      />
                    </LocalizationProvider>
                    <ErrorMessage
                      errors={errors}
                      name="add_course_end"
                      render={({ message }) => (
                        <p
                          style={{
                            marginTop: 0,
                            color: theme.color.danger,
                          }}>
                          {message}
                        </p>
                      )}
                    />
                  </FlexGrow>
                </FlexBox>
              </FlexGrow>
            </FlexBox>
            <FlexBox>
              <FlexGrow>
                <FlexBox>
                  <FlexGrow>
                    <StyledText color={'#000000'} fontWeight={'bold'}>
                      Withdraw Course End Date
                    </StyledText>
                  </FlexGrow>
                  <FlexGrow>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        openTo="year"
                        value={withdraw_end}
                        onChange={(newValue) => {
                          setWithDrawEnd(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} helperText={null} />
                        )}
                      />
                    </LocalizationProvider>
                    <ErrorMessage
                      errors={errors}
                      name="withdraw_end"
                      render={({ message }) => (
                        <p
                          style={{
                            marginTop: 0,
                            color: theme.color.danger,
                          }}>
                          {message}
                        </p>
                      )}
                    />
                  </FlexGrow>
                </FlexBox>
              </FlexGrow>
              <FlexGrow>
                <FlexBox>
                  <FlexGrow></FlexGrow>
                  <FlexGrow></FlexGrow>
                </FlexBox>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              justify="flex-end"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow>
                <RightAlign>
                  <Button
                    onClick={() => {
                      history.push('/terms');
                    }}
                    isOutline
                    status={Status.primary}
                    style={{ padding: '4px 12px' }}>
                    <Icon name="x-s" mr={0.5} />
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    onClick={() => handleSubmit(onSubmitClicked)}
                    status={Status.primary}
                    backgroundColor="#CB092B"
                    style={{ padding: '4px 12px', marginLeft: '8px' }}>
                    <Icon name="floppy" mr={0.5} />
                    Save
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
          </form>
        ) : (
          <Icon mr={1} name="spinner" spin />
        )}
      </Card>
      <Modal
        isOpen={isOpenError}
        setOpen={setOpenError}
        onClose={onError}
        customStyle={{ content: { textAlign: 'center' } }}>
        <img
          src="/alert-warning.svg"
          alt="alert warning"
          style={{ margin: 'auto' }}
        />
        <h3>Cannot Create Term</h3>
        <p>
          An error occurred while creating the term.
          This error can occur if the same term name already exist.
          Please try again or contact us if the problem persists.
        </p>
        <Button status={Status.primary} onClick={onErrorCloseModal}>
          Done
        </Button>
      </Modal>
    </>
  );
}
export default CreateTermPage;
