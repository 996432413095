import axios from 'axios';
import React, { useState, useEffect } from 'react';

import { useScreenClass } from 'react-grid-system';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import colorName from '../styles/colors';
import theme from '../styles/theme';
import Status from '../types/enum/Status';
import Button from './Button';
import FlexBox, { FlexGrow } from './FlexBox';
import { ClickableIcon } from './Icon';
import Input from './Input';
import Modal from './Modal';
import NullStateTR from './NullStateTr';
import { Col, Row } from './StyledGridSystem';
import Table from './Table';
import { StyledText, TextStatus } from './Text';

const MAX_DESCRIPTION_LENGTH = 280;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StatusStyled = styled.div`
  display: flex;
  flex-flow: column;
`;

const StatsItemStyled = styled.div`
  display: flex;
  flex-flow: row;
  vertical-align: baseline;
`;

const BlockStlyed = styled.h6`
  padding-right: ${({ theme }) => theme.spacing.x3};
  width: 100px;
  margin: 0px;
`;
type TypeForm = {
  customType: string;
};
type TypeSelectModalProps = {
  isOpen: boolean;
  setOpen: (x: boolean) => void;
  onSelect: (programId: string) => void;
};
const TypeSelectModal = ({
  isOpen,
  setOpen,
  onSelect,
}: TypeSelectModalProps): JSX.Element => {
  const screenClass = useScreenClass();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm<TypeForm>({
    mode: 'onChange',
    defaultValues: {
      customType: '',
    },
  });
  useEffect(() => {
    reset();
  }, [isOpen]);
  const watchAllFields = watch();
  const [types, setTypes] = useState<any>([
    { label: 'Form', value: 'form' },
    { label: 'Academic Calendar', value: 'academic_calendar' },
  ]);
  return (
    <Modal isOpen={!!isOpen} setOpen={setOpen}>
      <FlexBox alignItems="flex-start" style={{ marginBottom: '12px' }}>
        <FlexGrow>
          <h3 style={{ margin: '0px' }}>Select Type</h3>
        </FlexGrow>
        <RightAlign>
          <ClickableIcon name="x-s" onClick={() => setOpen?.(false)} />
        </RightAlign>
      </FlexBox>
      <Table>
        <thead>
          <tr>
            <th style={{ width: '80%' }}>Predefined</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {types.map((type: any) => (
            <tr>
              <td>{type.label}</td>
              <td>
                <Button
                  isOutline
                  status={Status.primary}
                  onClick={() => {
                    onSelect(type.value);
                  }}>
                  Select
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <FlexBox style={{ padding: '30px 0' }}>
        <FlexGrow style={{ textAlign: 'center' }}>
          <StyledText>--- Or ---</StyledText>
        </FlexGrow>
      </FlexBox>
      <Table>
        <thead>
          <tr>
            <th style={{ width: '80%' }}>Custom</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Input {...register('customType')} />
            </td>
            <td>
              <Button
                isOutline
                disabled={
                  !watchAllFields.customType || watchAllFields.customType === ''
                }
                status={Status.primary}
                onClick={() => {
                  onSelect(watchAllFields.customType);
                }}>
                Select
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </Modal>
  );
};

export default TypeSelectModal;
