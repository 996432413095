import React, {
  HTMLProps,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { NavLink, useHistory } from 'react-router-dom';
import { useScreenClass } from 'react-grid-system';
import { StylesConfig } from 'react-select';
import axios from 'axios';
import { DateTime } from 'luxon';
import Breadcrumb from '../../components/Breadcrumb';
import Icon, { ClickableIcon } from '../../components/Icon';
import { Col, Row } from '../../components/StyledGridSystem';
import Status from '../../types/enum/Status';
import FlexBox, { FlexGrow } from '../../components/FlexBox';
import Card from '../../components/Card';
import Input, {
  InputContainer,
  InputIconContainer,
} from '../../components/Input';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import { StyledText, TextSize, TextStatus } from '../../components/Text';
import {
  StudentContent,
  SortOption,
  sortValue,
  StudentFilterValue,
} from '../../filter/StudentsFilterValue';
import useDebounce from '../../components/use-debounce';
import NullStateTR from '../../components/NullStateTr';
import theme from '../../styles/theme';
import Pill from '../../components/Pill';
import { UserContext } from '../../context/UserContext';
import { RightAlign } from '../../components/UtilsComponents';
import Button from '../../components/Button';
import MinimalSelect from '../../components/MinimalSelect';
import useQuery from '../../hooks/useQuery';
import SelectStudentsModal from '../../components/SelectStudentsModal';
import StudentDeleteModal from '../../components/StudentDeleteModal';
import SnackBar from '../../components/SnackBar';
import EditGrade from '../../components/EditGrade';

export interface Ioption {
  semester: any;
  program: any;
  time: any;
  day: any;
}
export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

const MinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x6 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
`;

const MultiMinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x4 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
  align-items: center;
`;

const FilterStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing.x2};
  margin-top: ${({ isMobile, theme }) => (isMobile ? theme.spacing.xs : 0)};
`;

const RowStyled = styled.div`
  display: flex;
  flex-flow: column;
`;
function SectionStudentsPage() {
  const history = useHistory();
  const sectionId = useQuery().get('section');
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const currentUser = useContext(UserContext).user;
  const style = {
    textDecoration: 'none',
    color: theme.color.textColor,
    textoverflow: 'clip',
    display: 'flex',
  };
  const test = [
    { name: 'Courses', link: '/admin/courses' },
    { name: 'Course Offerings', link: '/admin/courses/course/offerings' },
    { name: 'Students in Section', link: '/section/students' },
  ];
  const [students, setStudents] = useState<Array<any>>([]);
  const [currentSection, setCurrentSection] = useState<any>();
  const [totalPage, setTotalPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStudentModalOpen, setIsStudentModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isStudentConfirmOpen, setIsStudentConfirmOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [currentStudent, setCurrentStudent] = useState<StudentContent>({
    id: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    cmkl_email: '',
    address: {},
    secondary_contact: {},
    program: {
      id: '',
      name: '',
      status: '',
    },
  });
  const [loading, setLoading] = useState(true);
  const openModal = (data: any) => {
    if (data) {
      setCurrentStudent(data);
      setIsModalOpen(true);
    }
  };

  const ColumnName = (isMobile: boolean) => {
    if (isMobile) {
      return (
        <>
          <th style={{ width: '40%' }}></th>
          <th style={{ width: '40%' }}></th>
          <th></th>
        </>
      );
    }
    return (
      <>
        <th style={{ width: '40%' }}>CMKL Email</th>
        <th style={{ width: '40%' }}>Full Name</th>
        <th>Grade</th>
        <th></th>
      </>
    );
  };
  useEffect(() => {
    fetchStudentbyFilter();
    fetchSection();
  }, [sectionId]);

  const fetchStudentbyFilter = () => {
    setLoading(true);
    axios
      .get(`/api/v1/courses/sections/${sectionId}/students`)
      .then((res) => {
        if (res.data.data.students) {
          setStudents(res.data.data.students);
        } else {
          setStudents([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setStudents([]);
        setLoading(false);
      });
  };
  const fetchSection = () => {
    setLoading(true);
    axios
      .get(`/api/v1/courses/sections/${sectionId}`)
      .then((res) => {
        if (res.data.data.section) {
          setCurrentSection(res.data.data.section);
        } else {
          setCurrentSection({});
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setStudents([]);
        setLoading(false);
      });
  };
  const importGrades = (e: any) => {
    console.log(e);
    setLoading(true);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios
      .post(
        `/api/v1/courses/sections/${sectionId}/students/uploadGrade`,
        formData,
        config,
      )
      .then((res) => {
        setLoading(false);
        setSnackbarOpen(true);
        fetchStudentbyFilter();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setErrorSnackbarOpen(true);
      });
  };
  const importA1ceGrades = (e: any) => {
    console.log(e);
    setLoading(true);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios
        .post(
            `/api/v1/courses/sections/students/a1ce/uploadGrade`,
            formData,
            config,
        )
        .then((res) => {
          setLoading(false);
          setSnackbarOpen(true);
          fetchStudentbyFilter();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setErrorSnackbarOpen(true);
        });
  };
  const addStudent = () => {
    setIsStudentModalOpen(true);
  };
  const editGrade = () => {
    setIsEditModalOpen(true);
  };
  const removeStudent = (student: any) => {
    setCurrentStudent(student);
    setIsStudentConfirmOpen(true);
  };
  return (
    <>
      <SnackBar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        type="success"
        message="Grades uploaded successfully !"
      />
      <SnackBar
        isOpen={errorSnackbarOpen}
        setOpen={setErrorSnackbarOpen}
        type="error"
        message="Something went wrong. Please try again!"
      />
      <EditGrade
        sectionId={sectionId}
        isOpen={isEditModalOpen}
        setOpen={setIsEditModalOpen}
        currentStudent={students}
        onEditComplete={() => {
          fetchStudentbyFilter();
        }}
      />
      <SelectStudentsModal
        sectionId={sectionId}
        reloadParent={() => {
          fetchStudentbyFilter();
        }}
        isOpen={isStudentModalOpen}
        setOpen={setIsStudentModalOpen}
      />
      <StudentDeleteModal
        isOpen={isStudentConfirmOpen}
        setOpen={setIsStudentConfirmOpen}
        sectionId={sectionId}
        currentStudent={currentStudent}
        onDeleteComplete={fetchStudentbyFilter}
      />
      <Row>
        <Col>
          <Breadcrumb path={test} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FlexBox space={2}>
            <FlexGrow>
              <h2 style={{marginRight: 'auto', marginBottom: 0}}>
                Students in section {currentSection?.name}{' '}
              </h2>
            </FlexGrow>
            <label
                style={{
                  cursor: 'pointer',
                  color: '#CB092B',
                  border: '1px solid #CB092B',
                  padding: '4px 12px',
                  borderRadius: '4px',
                }}
                htmlFor="upload-button">
              <>
                <Icon mr={0.5} name="cloud-upload"></Icon>
                Import Grades
              </>
            </label>
            <input
                type="file"
                id="upload-button"
                accept=".csv"
                style={{display: 'none'}}
                // eslint-disable-next-line no-return-assign
                onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => ((e.target as HTMLInputElement).value = '')
                }
                onChange={importGrades}
            />
            <label
                style={{
                  cursor: 'pointer',
                  color: '#CB092B',
                  border: '1px solid #CB092B',
                  padding: '4px 12px',
                  borderRadius: '4px',
                }}
                htmlFor="upload-button1">
              <>
                <Icon mr={0.5} name="cloud-upload"></Icon>
                Import A1CE Grades
              </>
            </label>
            <input
                type="file"
                id="upload-button1"
                accept=".csv"
                style={{display: 'none'}}
                // eslint-disable-next-line no-return-assign
                onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => ((e.target as HTMLInputElement).value = '')
                }
                onChange={importA1ceGrades}
            />
            <Button
                onClick={() => editGrade()}
                isOutline
                status={Status.primary}
                style={{padding: '4px 12px'}}>
              <Icon name="pencil-edit" mr={0.5}/>
              Edit Students Grade
            </Button>
            <Button
                onClick={() => addStudent()}
                isOutline={false}
                status={Status.primary}
                style={{padding: '4px 12px'}}>
              <Icon name="plus-s" mr={0.5}/>
              Add Student
            </Button>
          </FlexBox>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{marginTop: '0px'}}>
            <Table hoverable>
              <thead>
              <tr>{ColumnName(isMobile)}</tr>
              </thead>
              <tbody>
              {!loading && students ? (
                  students.map((data) => {
                    if (!isMobile) {
                      return (
                          <tr
                              style={{cursor: 'default'}}
                              key={data.student_id}
                              onClick={() => openModal(data)}>
                            <td>
                              <StyledText textSize={TextSize.default}>
                                {data.cmkl_email}
                              </StyledText>
                            </td>
                            <td>
                            <StyledText textSize={TextSize.default}>
                              {data.first_name} {data.last_name}
                            </StyledText>
                          </td>
                          <td>{data.final_grade}</td>
                          <td
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            style={{ textAlign: 'center' }}>
                            <ClickableIcon
                              color="red"
                              onClick={() => {
                                removeStudent(data);
                              }}
                              name="trash-empty"
                            />
                          </td>
                        </tr>
                      );
                    }

                    return (
                      <tr key={data.student_id} onClick={() => openModal(data)}>
                        <td>
                          <StyledText textSize={TextSize.default}>
                            {data.first_name} {data.last_name}
                          </StyledText>
                        </td>
                        <td>
                          <StyledText textSize={TextSize.default}>
                            {data.final_grade}
                          </StyledText>
                        </td>
                        <td
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          style={{ textAlign: 'center' }}>
                          <ClickableIcon
                            color="red"
                            onClick={() => {}}
                            name="trash-empty"
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NullStateTR>
                    <Icon mr={1} name="spinner" spin />
                    Loading...
                  </NullStateTR>
                )}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default SectionStudentsPage;
