import React, {
  HTMLProps,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { NavLink, useHistory } from 'react-router-dom';
import { Row, useScreenClass } from 'react-grid-system';
import { StylesConfig } from 'react-select';
import axios from 'axios';
import { DateTime } from 'luxon';
import { Checkbox } from '@mui/material';
import { UserContext } from '../context/UserContext';
import theme from '../styles/theme';
import useDebounce from './use-debounce';
import {
  SortOption,
  sortValue,
  StudentContent,
} from '../filter/StudentsFilterValue';
import Modal from './Modal';
import Breadcrumb from './Breadcrumb';
import { Col } from './StyledGridSystem';
import FlexBox, { FlexGrow } from './FlexBox';
import Card from './Card';
import { RightAlign } from './UtilsComponents';
import Button from './Button';
import Status from '../types/enum/Status';
import Input, { InputContainer, InputIconContainer } from './Input';
import Icon from './Icon';
import { StyledText, TextSize, TextStatus } from './Text';
import MinimalSelect from './MinimalSelect';
import Table from './Table';
import Pill from './Pill';
import NullStateTR from './NullStateTr';

export interface Ioption {
  semester: any;
  program: any;
  time: any;
  day: any;
}
export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

const MinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x6 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
`;

const FilterStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing.x2};
  margin-top: ${({ isMobile, theme }) => (isMobile ? theme.spacing.xs : 0)};
`;

type SelectStudentsModalProps = {
  isOpen?: boolean;
  sectionId: string | null;
  setOpen?: (x: boolean) => void;
  reloadParent?: () => void;
};

const SelectStudentsModal = ({
  isOpen,
  setOpen,
  sectionId,
  reloadParent,
}: SelectStudentsModalProps): JSX.Element => {
  const history = useHistory();
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const [students, setStudents] = useState<Array<any>>([]);
  const [selectedStudents, setSelectedStudents] = useState<
    // eslint-disable-next-line camelcase
    Array<{ student_id: string }>
  >([]);
  const [offset, setOffSet] = useState(0);
  const [limit, setLimit] = useState(100);
  const [totalPage, setTotalPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStudent, setCurrentStudent] = useState<StudentContent>({
    id: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    cmkl_email: '',
    address: {},
    secondary_contact: {},
    program: {
      id: '',
      name: '',
      status: '',
    },
  });
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, 300);
  const [loading, setLoading] = useState(true);
  const [isOpenError, setOpenError] = useState<boolean>(false);
  const [options, setOptions] = useState({
    status: [{ label: 'All', value: 'All' }],
    class: [],
    program: [{ label: 'All', value: 'All' }],
  });
  const [filters, setfilters] = useState({
    status: [],
    program: [],
    class: '',
    sort: 'Name ascending',
  });
  const fetchOptions = () => {
    setLoading(true);
    const programVals: any = [];
    const statusVals: any = [];
    axios
      .get('/api/v1/students/status')
      .then((res) => {
        if (res.data.data.activity_types) {
          res.data.data.activity_types.forEach((status: any) => {
            statusVals.push({
              label: status.name,
              value: status.name,
              color: theme.color.warning,
              isFixed: true,
            });
          });
          console.log('status', options.status);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    axios
      .get('/api/v1/courses/programs')
      .then((res) => {
        if (res.data.data.programs) {
          res.data.data.programs.forEach((prog: any) => {
            programVals.push({ label: prog.name, value: prog.name });
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setOptions({ ...options, status: statusVals, program: programVals });
  };

  const handleChangeSort = (obj: any) => {
    console.log('in change sort', obj);
    setfilters({ ...filters, sort: obj.value });
  };

  const handleChangeProgram = (obj: any, action: any) => {
    console.log('obj in program', obj, action);
    const program: any = [];
    if (obj) {
      obj.forEach((element: any) => {
        program.push(element.value);
      });
      setfilters({ ...filters, program });
    } else {
      setfilters({ ...filters, program: [] });
    }
  };
  const handleChangeStatus = (obj: any) => {
    console.log('obj in status', obj);
    const status: any = [];
    if (obj) {
      obj.forEach((element: any) => {
        status.push(element.value);
      });
      setfilters({ ...filters, status });
    } else {
      setfilters({ ...filters, status: [] });
    }
  };

  const handleSearchChange = (text: string) => {
    setSearchValue(text);
  };
  const getHighlightedText = (text: string, highlight: string) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {' '}
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? { fontWeight: 'bold' }
                : {}
            }>
            {part}
          </span>
        ))}{' '}
      </span>
    );
  };

  const getDate = (strDate: string) => {
    const date = DateTime.fromISO(strDate);
    return date.toFormat('yyyy-MM-dd');
  };

  const openModal = (data: any) => {
    if (data) {
      setCurrentStudent(data);
      setIsModalOpen(true);
    }
  };

  const ColumnName = (isMobile: boolean) => {
    if (isMobile) {
      return (
        <>
          <th></th>
          <th></th>
        </>
      );
    }
    return (
      <>
        <th></th>
        <th>CMKL Email</th>
        <th>Full Name</th>
        <th>Program</th>
        <th>First Enrolled</th>
        <th>Status</th>
      </>
    );
  };
  useEffect(() => {
    fetchOptions();
    setSelectedStudents([]);
  }, [isOpen]);
  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchStudentbyFilter(offset, limit);
    } else {
      fetchStudentbyFilter(offset, limit);
    }
  }, [filters, debouncedSearchTerm]);

  const fetchStudentbyFilter = (offset: number, limit: number) => {
    console.log('filters', filters);
    // fetch data
    const params = {
      query: searchValue,
      filters,
      offSet: offset,
      perpage: limit,
    };
    console.log('parmas', params);
    setLoading(true);
    axios
      .get(`/api/v1/students/courses/sections/${sectionId}`, { params })
      .then((res) => {
        if (res.data.data.students) {
          setStudents(res.data.data.students);
          setTotalPage(res.data.data.total_pages);
          setOffSet(res.data.data.offset);
        } else {
          setStudents([]);
          setTotalPage(0);
          setOffSet(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        setOpenError(true);
        console.log(err);
        setLoading(false);
      });
  };
  const enrollStudents = () => {
    console.log('student', selectedStudents);
    setLoading(true);
    axios
      .post(`/api/v1/courses/sections/${sectionId}/students`, {
        students: selectedStudents,
      })
      .then(() => {
        setOpen?.(false);
        reloadParent?.();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    studentId: string,
  ) => {
    console.log(selectedStudents, event);
    if (event.target.checked) {
      setSelectedStudents([...selectedStudents, { student_id: studentId }]);
    }
    if (!event.target.checked) {
      setSelectedStudents(
        selectedStudents.filter((student) => student.student_id !== studentId),
      );
    }
  };
  const onErrorCloseModal = () => {
    setOpenError(false);
  };
  return (
    <>
      {console.log(selectedStudents)}
      <Modal
        isOpen={!!isOpen}
        setOpen={setOpen}
        customStyle={{
          content: {
            maxWidth: '650px',
            margin: '27px auto 0px',
            maxHeight: '80vh',
          },
        }}>
        <>
          <Row>
            <Col>
              <Card style={{ marginTop: '0px' }}>
                <FlexBox
                  style={{
                    alignItems: 'flex-start',
                    marginBottom: theme.spacing.base * 2,
                  }}>
                  <FlexGrow>
                    <h3>Search for Students</h3>
                  </FlexGrow>
                  <Button
                    onClick={() => enrollStudents()}
                    disabled={selectedStudents.length <= 0}
                    isOutline={false}
                    status={Status.primary}
                    style={{ padding: '4px 18px' }}>
                    Save
                  </Button>
                  <Button
                    onClick={() => setOpen?.(false)}
                    isOutline
                    status={Status.primary}
                    style={{ padding: '4px 18px' }}>
                    Cancel
                  </Button>
                </FlexBox>
                <InputContainer
                  as="form"
                  onSubmit={() => {}}
                  style={{ width: '100%' }}>
                  <Input
                    id="search-input"
                    name="searchInput"
                    // eslint-disable-next-line max-len
                    onKeyPress={(event) => ['\\', '[', ']'].includes(event.key) && event.preventDefault()}
                    // eslint-disable-next-line max-len
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchChange(e.target.value)
                    }
                    placeholder="Find students..."
                    value={searchValue}
                    style={{ width: 'inherit' }}
                  />
                  <InputIconContainer onClick={(e: any) => e.preventDefault()}>
                    <Icon name="search" status={Status.default} />
                  </InputIconContainer>
                </InputContainer>
                <FilterStyled isMobile={isMobile}>
                  <MinimalSelectStyled isMobile={isMobile}>
                    <StyledText status={TextStatus.default}>Status:</StyledText>
                    <MinimalSelect
                      multi
                      placeholder="All"
                      options={options.status}
                      // styles={colourStyles}
                      onChange={handleChangeStatus}
                    />
                  </MinimalSelectStyled>
                  <MinimalSelectStyled isMobile={isMobile}>
                    <StyledText status={TextStatus.default}>
                      Programs:
                    </StyledText>
                    <MinimalSelect
                      multi
                      placeholder="All"
                      options={options.program}
                      onChange={handleChangeProgram}
                    />
                  </MinimalSelectStyled>
                  <MinimalSelectStyled isMobile={isMobile}>
                    <StyledText status={TextStatus.default}>Sort:</StyledText>
                    <MinimalSelect
                      defaultValue={sortValue}
                      options={SortOption}
                      onChange={handleChangeSort}
                    />
                  </MinimalSelectStyled>
                </FilterStyled>
                <Table hoverable>
                  <thead>
                    <tr>{ColumnName(isMobile)}</tr>
                  </thead>
                  <tbody>
                    {!loading && students ? (
                      students.map((data) => {
                        if (!isMobile) {
                          return (
                            <tr
                              key={data.student_id}
                              onClick={() => openModal(data)}>
                              <td>
                                <Checkbox
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => handleChange(e, data.student_id)}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              </td>
                              <td>
                                {searchValue && searchValue !== '' ? (
                                  <StyledText textSize={TextSize.default}>
                                    {getHighlightedText(
                                      data.cmkl_email,
                                      searchValue,
                                    )}
                                  </StyledText>
                                ) : (
                                  <StyledText textSize={TextSize.default}>
                                    {data.cmkl_email}
                                  </StyledText>
                                )}
                              </td>
                              <td>
                                {searchValue && searchValue !== '' ? (
                                  <StyledText textSize={TextSize.default}>
                                    {getHighlightedText(
                                      data.first_name,
                                      searchValue,
                                    )}
                                    {getHighlightedText(
                                      data.last_name,
                                      searchValue,
                                    )}
                                  </StyledText>
                                ) : (
                                  <StyledText textSize={TextSize.default}>
                                    {data.first_name} {data.last_name}
                                  </StyledText>
                                )}
                              </td>
                              <td>{data.program?.name}</td>
                              <td>{getDate(data.enrolled_date)}</td>
                              <td>
                                <Pill
                                  style={{ textTransform: 'capitalize' }}
                                  status={Status.primary}>
                                  {data.program?.status}
                                </Pill>
                              </td>
                            </tr>
                          );
                        }

                        return (
                          <tr
                            key={data.student_id}
                            onClick={() => openModal(data)}>
                            <td>
                              {searchValue && searchValue !== '' ? (
                                <StyledText textSize={TextSize.default}>
                                  {getHighlightedText(
                                    data.first_name,
                                    searchValue,
                                  )}
                                  {getHighlightedText(
                                    data.last_name,
                                    searchValue,
                                  )}
                                </StyledText>
                              ) : (
                                <StyledText textSize={TextSize.default}>
                                  {data.first_name} {data.last_name}
                                </StyledText>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <NullStateTR>
                        <Icon mr={1} name="spinner" spin />
                        Loading...
                      </NullStateTR>
                    )}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </>
      </Modal>
      <Modal
        isOpen={isOpenError}
        setOpen={setOpenError}
        onClose={onErrorCloseModal}
        customStyle={{ content: { textAlign: 'center' } }}>
        <img
          src="/alert-warning.svg"
          alt="alert warning"
          style={{ margin: 'auto' }}
        />
        <h3>Cannot Enroll Student(s)</h3>
        <p>
          An error occurred while enrolling the students.
          Please try again or contact us if the problem persists.
        </p>
        <Button status={Status.primary} onClick={onErrorCloseModal}>
          Done
        </Button>
      </Modal>
    </>
  );
};

export default SelectStudentsModal;
