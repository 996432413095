import axios from 'axios';
import React, { useMemo, useState, useEffect } from 'react';

import { useScreenClass } from 'react-grid-system';
import styled from 'styled-components';
import theme from '../styles/theme';
import Status from '../types/enum/Status';
import Button from './Button';
import FlexBox, { FlexGrow } from './FlexBox';
import Icon, { ClickableIcon } from './Icon';
import Input from './Input';
import Modal from './Modal';
import { Col, Row } from './StyledGridSystem';
import { RightAlign } from './UtilsComponents';

type CourseDropModalProps = {
  isOpen?: boolean;
  setOpen: (x: boolean) => void;
  inDroppingPeriod: boolean;
  currentCourse: { [key: string]: any };
  showSections?: boolean;
  studentId: string;
  // handleDropCourse: (regId: string) => void
  onDropComplete?: () => void;
  onDropError?: (err: any) => void;
};

const wait = async (ms: number) => new Promise((resolve, reject) => {
  setTimeout(resolve, ms);
});

const CourseDropModal = ({
  isOpen,
  setOpen,
  currentCourse,
  inDroppingPeriod,
  studentId,
  // handleDropCourse,
  onDropError,
  onDropComplete,
}: CourseDropModalProps): JSX.Element => {
  const screenClass = useScreenClass();
  const [dropConfirmation, setDropconfirmation] = useState<boolean>(true);
  const [dropPasswordInput, setDropPasswordInput] = useState<string>('');
  const [isOpenComplete, setOpenComplete] = useState<boolean>(false);
  const [isOpenError, setOpenError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const handleDropCourse = async (regId: string) => {
    try {
      setLoading(true);
      // alert(`/api/v1/grading/dropCourse?course_reg_id=${regId}`);
      // await wait(800);
      // const res = await axios.delete(`/api/v1/grading/dropCourse?course_reg_id=${regId}`);
      const res = await axios.delete(
        `/api/v1/students/${studentId}/courses/${regId}`,
      );
      setOpenComplete(true);
    } catch (err) {
      setOpenError(true);
      console.error(err);
      setError(err);
    }
    setOpen(false);
    setLoading(false);
  };

  const onComplete = () => {
    setOpenComplete(false);
    onDropComplete?.();
  };

  const onError = () => {
    setOpenError(false);
    onDropError?.(error);
  };

  useEffect(() => {
    if (isOpen) {
      setDropPasswordInput('');
    }
  }, [isOpen]);

  const dropPassword = useMemo(() => {
    const verb = 'drop';
    return `${verb} ${currentCourse.code}`;
  }, [currentCourse, inDroppingPeriod]);

  return (
    <>
      <Modal isOpen={isOpen || false} setOpen={setOpen}>
        <FlexBox>
          <FlexGrow>
            <h3>Dropping Course</h3>
          </FlexGrow>
          <ClickableIcon name="x-s" onClick={() => setOpen(false)} />
        </FlexBox>
          <p>
            Are you sure you want to drop the course "
            <b>
              {currentCourse.code} {currentCourse.short_name}
            </b>
            "? This cannot be undone.
          </p>
        <p>
          To confirm, type "<b>{dropPassword}</b>" in this box.
        </p>
        <Input
          isFullWidth={true}
          value={dropPasswordInput}
          onInput={(e: any) => {
            setDropPasswordInput(e.target.value);
          }}
        />
        <RightAlign style={{ marginTop: theme.spacing.x2 }}>
          <Button
            isOutline
            onClick={() => {
              setOpen(false);
            }}
            status={Status.default}>
            Cancel
          </Button>
          <Button
            disabled={isLoading || dropPasswordInput !== dropPassword}
            status={Status.primary}
            onClick={() => handleDropCourse(currentCourse.id)}>
            {isLoading && (
              <>
                <Icon mr={1} name="spinner" spin />
                Dropping...
              </>
            )}
            {!isLoading && (
              <>
                <Icon mr={1} name="trash" />
                Drop course
              </>
            )}
          </Button>
        </RightAlign>
      </Modal>

      <Modal
        isOpen={isOpenComplete}
        setOpen={setOpenComplete}
        onClose={onComplete}
        customStyle={{ content: { textAlign: 'center' } }}>
        <img
          src="/alert-success-soft.svg"
          alt="alert warning"
          style={{ margin: 'auto' }}
        />
        <h3>Course {inDroppingPeriod ? 'Dropping' : 'Withrawal'} Complete</h3>
        {inDroppingPeriod ? (
          <p>
            The course "
            <b>
              {currentCourse.code} {currentCourse.short_name}
            </b>
            " has been dropped.
          </p>
        ) : (
          <p>
            The course "
            <b>
              {currentCourse.code} {currentCourse.short_name}
            </b>
            " has been withdrawn. This course will be marked as "W" on your
            transcript.
          </p>
        )}
        <Button status={Status.primary} onClick={onComplete}>
          Done
        </Button>
      </Modal>

      <Modal
        isOpen={isOpenError}
        setOpen={setOpenError}
        onClose={onError}
        customStyle={{ content: { textAlign: 'center' } }}>
        <img
          src="/alert-warning.svg"
          alt="alert warning"
          style={{ margin: 'auto' }}
        />
        <h3>Course {inDroppingPeriod ? 'Drop' : 'Withdrawal'} Error</h3>
        <p>
          An error occured while {inDroppingPeriod ? 'dropping' : 'withdrawing'}{' '}
          the course "
          <b>
            {currentCourse.code} {currentCourse.short_name}
          </b>
          ". Please try again or contact us if the problem persists.
        </p>
        <Button status={Status.primary} onClick={onError}>
          Done
        </Button>
      </Modal>
    </>
  );
};

export default CourseDropModal;
