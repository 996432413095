import React from 'react';
import styled from 'styled-components';
import Button from '../components/Button';
import Card from '../components/Card';
import { Col, Row } from '../components/StyledGridSystem';
import Status from '../types/enum/Status';
import Icon from '../components/Icon';
import FlexBox from '../components/FlexBox';

const Item = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: auto;
  align-items: center;
`;

const LogoSign = styled.img`
  /* padding-top: ${({ theme }) => theme.spacing.x4}; */
  height: 100px;
`;

const SignText = styled.div`
  font-size: 1rem;
  outline: none;
`;

const SignInCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing.x6};
  margin-top: ${({ theme }) => theme.spacing.x6};
`;

function SignInPage() {
  return (
    <>
      <Row>
        <Col>
          <SignInCard>
            <FlexBox direction="column" space={2}>
              <LogoSign src="/images/logo_color.png" alt="hub logo" />

              <SignText>Sign in to CMKL cube</SignText>

              <Button status={Status.primary} as="a" href="/oauth/login">
              {/* <Button status={Status.primary} as="a" href="https://hub-staging.cmkl.ac.th/oauth/login"> */}
                <Icon name="google" mr={1} />
                Sign in With GSuite
              </Button>
            </FlexBox>
          </SignInCard>
        </Col>
      </Row>
    </>
  );
}

export default SignInPage;
