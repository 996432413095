/* eslint-disable max-len */
import React, {
  useMemo, useState, useEffect, useContext,
} from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import axios from 'axios';
import { useScreenClass } from 'react-grid-system';

import _theme from '../styles/theme';

import Table from './Table';
import Icon, { ClickableIcon } from './Icon';
import Status from '../types/enum/Status';
import Card from './Card';
import {
  StyledText,
  Subtitle,
  TextSize,
  TextStatus,
  NullStateText,
} from './Text';
import { Col, Row } from './StyledGridSystem';
import Alert from './Alert';
import FlexBox, { FlexGrow } from './FlexBox';
import Button from './Button';
import NullStateTR from './NullStateTr';

import { CourseContent, EmptyCourseContent } from '../filter/FilterValue';
import CourseDropModal from './CourseDropModal';
import CourseInfoModal from './CourseInfoModal';
import CourseDropdown from './CourseDropdown';
import { StudentContext } from '../context/StudentBehalfContext';
import { UserContext } from '../context/UserContext';
import CourseWithdrawModal from './CourseWithdrawModal';

type EnrollCourseProps = {
  termID: number;
  termName: string;
};

const EnrollCourse = ({ termID, termName }: EnrollCourseProps) => {
  const screenClass = useScreenClass();
  const { student, isBehalf } = useContext(StudentContext);
  const { user } = useContext(UserContext);
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const [isNotified, setIsNotified] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isWithdrawOpen, setIsWithdrawOpen] = useState<boolean>(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  // Loading text on "Enrolled Courses"
  const [loading, setLoading] = useState<boolean>(true);
  // Enrolled Course Information for "Enrolled Courses"
  const [currCourse, setCurrCourse] = useState({
    report: [],
    term: '',
  });
  // Enrolled Course Information for CourseInfoModal
  const [currentCourse, setCurrentCourse] = useState<CourseContent>(EmptyCourseContent);
  // Drop Course Stages
  const [stages, setStages] = useState({
    addend: false,
    dropnoW: false,
    dropwithW: false,
  });
  // Add Course Period for "Registration" alert and "Add Course/(s)" button
  const [addCoursesPeriod, setAddCoursesPeriod] = useState({
    name: '',
    add_course_start: new Date(),
    add_course_end: new Date(),
  });

  useEffect(() => {
    // fetchStage();
    fetchAddCoursePeriod();
    fetchCurrCourse();
  }, [termID, student, user]);

  // Get add course period for "Add Course/(s)" button
  const fetchAddCoursePeriod = () => {
    axios
      .get(`/api/v1/courses/terms/${termID}`)
      .then((res) => {
        if (res.data.data.term) {
          setAddCoursesPeriod(res.data.data.term);
          setStages({
            addend: res.data.data.term.is_add_course_valid,
            dropnoW: res.data.data.term.is_drop_course_valid,
            dropwithW: res.data.data.term.is_with_draw_valid,
          });
        }
      })
      .catch((err) => {});
  };

  // Get enroll course by $termName
  const fetchCurrCourse = () => {
    if (user.role === 'admin' && isBehalf) {
      setLoading(true);
      axios
        .get(`/api/v1/students/${student.student_id}/courses`)
        .then((res) => {
          // setCurrCourse(res.data.data.courses.filter((term: any) => term.term === termName).pop());
          setCurrCourse({
            ...currCourse,
            report: res.data.data.courses.filter(
              (term: any) => term.term === termName,
            ),
            term: termName,
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else if (user.role === 'student') {
      setLoading(true);
      axios
        .get(`/api/v1/students/${user.student_id}/courses`)
        .then((res) => {
          // setCurrCourse(res.data.data.courses.filter((term: any) => term.term === termName).pop());
          setCurrCourse({
            ...currCourse,
            report: res.data.data.courses.filter(
              (term: any) => term.term === termName,
            ),
            term: termName,
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  // Course Info Modal
  const openInfoModal = (data: any) => {
    if (data) {
      setCurrentCourse(data);
      setIsInfoModalOpen(true);
    }
  };

  // Drop Course Modal
  const openDropModal = (course: any, e: MouseEvent) => {
    setCurrentCourse(course);
    setIsModalOpen(true);
    e.stopPropagation();
  };

  const openWithdrawModal = (course: any, e: MouseEvent) => {
    setCurrentCourse(course);
    setIsWithdrawOpen(true);
    e.stopPropagation();
  };

  // Redirect "Registration" alert and "Add Course/(s)" button to "Add Courses" Page
  const history = useHistory();
  const toggleAddCourse = () => {
    history.push({
      pathname: '/courses/add-courses',
      state: { termID, termName },
    });
  };

  // Stage for Drop Course
  // const fetchStage = () => {
  //   setLoading(true);
  //   axios.get('/api/v1/stages').then((res) => {
  //     // setStage(res.data[0]);
  //     setStages({
  //       addend: res.data[0].Add_course,
  //       dropnoW: res.data[0].Drop_course,
  //       dropwithW: res.data[0].Drop_course_with_w,
  //     });
  //     setLoading(false);
  //   }).catch((err) => {
  //     setLoading(false);
  //   });
  // };

  return (
    <>
      <CourseInfoModal
        isOpen={isInfoModalOpen}
        setOpen={setIsInfoModalOpen}
        currentCourse={currentCourse}
        showSections={false}
      />
      <CourseDropModal
        isOpen={isModalOpen}
        setOpen={setIsModalOpen}
        currentCourse={currentCourse}
        // handleDropCourse={handleDropCourse}
        inDroppingPeriod={stages.dropnoW}
        studentId={isBehalf ? student.student_id : user.student_id}
        onDropComplete={fetchCurrCourse}
        onDropError={() => {
          fetchCurrCourse();
        }}
      />
      <CourseWithdrawModal
        isOpen={isWithdrawOpen}
        setOpen={setIsWithdrawOpen}
        currentCourse={currentCourse}
        // handleDropCourse={handleDropCourse}
        inWithdrawPeriod={stages.dropwithW}
        studentId={isBehalf ? student.student_id : user.student_id}
        onDropComplete={fetchCurrCourse}
        onDropError={() => {
          fetchCurrCourse();
        }}
      />
      {/*
      This is incorrect:
        new Date((new Date().toLocaleString('th-TH', { timeZone: 'Asia/Bangkok' })))
      */}
      {((user.role === 'admin' && isBehalf)
        || (addCoursesPeriod
          && DateTime.fromJSDate(new Date(addCoursesPeriod.add_course_end))
            > DateTime.fromObject({ zone: 'Asia/Bangkok' }))) && (
        <Row onClick={toggleAddCourse} style={{ cursor: 'pointer' }}>
          <Col>
            <Alert
              style={{
                backgroundColor: '#e3f1fd',
                color: '#1c5f9d',
                border: '2px solid #1c5f9d',
              }}>
              {!isMobile && (
                <FlexBox>
                  <FlexGrow>
                    <Icon name="calendar-dates" />
                    <b>
                      {' '}
                      {addCoursesPeriod.name} course registration is now open{' '}
                    </b>
                    and will be&nbsp;
                    <b>
                      closed&nbsp;
                      {DateTime.fromJSDate(
                        new Date(addCoursesPeriod.add_course_end),
                      ).toRelative()}
                    </b>
                    &nbsp; (
                    {DateTime.fromJSDate(
                      new Date(addCoursesPeriod.add_course_end),
                    ).toLocaleString()}
                    )
                  </FlexGrow>
                  <a style={{ color: '#1c5f9d' }}>
                    Go to Course Registration{' '}
                    <Icon mr={1} name="chevron-right-s" />
                  </a>
                </FlexBox>
              )}
              {isMobile && (
                <div>
                  <div>
                    <Icon name="calendar-dates" />
                    <b>
                      {' '}
                      {addCoursesPeriod.name} course registration is now open{' '}
                    </b>
                    and will be&nbsp;
                    <b>
                      closed&nbsp;
                      {DateTime.fromJSDate(
                        new Date(addCoursesPeriod.add_course_end),
                      ).toRelative()}
                    </b>
                    &nbsp; (
                    {DateTime.fromJSDate(
                      new Date(addCoursesPeriod.add_course_end),
                    ).toLocaleString()}
                    )
                  </div>
                  <a style={{ color: '#1c5f9d' }}>
                    Go to Course Registration{' '}
                    <Icon mr={1} name="chevron-right-s" />
                  </a>
                </div>
              )}
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Card>
            <FlexBox
              alignItems="flex-start"
              style={{ marginBottom: _theme.spacing.x2 }}>
              <FlexGrow>
                <h3>{addCoursesPeriod.name}</h3>
                <Subtitle>Enrolled Courses</Subtitle>
              </FlexGrow>
              {((user.role === 'admin' && isBehalf)
                || (addCoursesPeriod
                  && DateTime.fromJSDate(
                    new Date(addCoursesPeriod.add_course_end),
                  ) > DateTime.fromObject({ zone: 'Asia/Bangkok' }))) && (
                <>
                  <FlexGrow
                    grow={0}
                    style={{ marginLeft: 'auto', textAlign: 'right' }}>
                    <FlexBox direction="column" alignItems="flex-end">
                      <Button onClick={toggleAddCourse} status={Status.primary}>
                        <Icon mr={1} name="plus-s" /> Enroll Course(s)
                      </Button>
                      <StyledText status={TextStatus.danger}>
                        Course add deadline&nbsp;
                        {DateTime.fromJSDate(
                          new Date(addCoursesPeriod.add_course_end),
                        ).toRelative()}
                        &nbsp; (
                        {DateTime.fromJSDate(
                          new Date(addCoursesPeriod.add_course_end),
                        ).toLocaleString()}
                        )
                      </StyledText>
                    </FlexBox>
                  </FlexGrow>
                </>
              )}
            </FlexBox>
            <Table hoverable>
              <thead>
                {!isMobile && (
                  <tr>
                    <th>Course/Section</th>
                    {!['xs', 'sm', 'md'].includes(screenClass) && (
                      <th>Course Name</th>
                    )}
                    {['md', 'sm'].includes(screenClass) ? (
                      <>
                        <th>Time / Location / Instructors</th>
                        <th>Units / Grading</th>
                      </>
                    ) : (
                      <>
                        <th>Time</th>
                        <th>Location</th>
                        <th>Instructors</th>
                      </>
                    )}
                    {!isMobile && !['md', 'sm'].includes(screenClass) && (
                      <th style={{ whiteSpace: 'nowrap' }}>Units / Grading</th>
                    )}
                    {/* <th>Go to Canvas</th>
                    <th></th> */}
                  </tr>
                )}
              </thead>
              <tbody>
                {!loading ? (
                  <>
                    {currCourse
                      && currCourse.report.map((course: any) => (
                        <>
                          <tr onClick={() => openInfoModal(course)}>
                            {isMobile && (
                              <>
                                <td>
                                  <StyledText textSize={TextSize.default}>
                                    <b>{course.short_name}</b>
                                    <br />
                                    <StyledText status={TextStatus.muted}>
                                      {course.code} /{' '}
                                      {course.sections
                                        ? course.sections.section
                                        : 'N/A'}
                                      <br />
                                      {course.units} Units · Letter Grade
                                    </StyledText>
                                  </StyledText>
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  <StyledText
                                    textSize={TextSize.default}
                                    status={TextStatus.muted}>
                                    {course.sections?.schedule !== null ? (
                                      <span style={{ whiteSpace: 'nowrap' }}>
                                        {course.sections
                                          && course.sections?.schedule?.map(
                                            (data: any) => (
                                              <div>
                                                {data.day.slice(0, 3)}{' '}
                                                {data.start}–{data.end}
                                                {/* {data.day.slice(0, 3)} {data.start
                                      .split(' ')[0]}–{data.end.split(' ')[0]} */}
                                              </div>
                                            ),
                                          )}
                                      </span>
                                    ) : (
                                      <p>-</p>
                                    )}
                                    {course.sections.map(
                                      (section: { room: { name: any } }) => (
                                        <>{section.room?.name}</>
                                      ),
                                    )}
                                    <br />
                                    {course.sections
                                      && course.sections?.lecturers
                                      && course.sections?.lecturers
                                        .filter(
                                          (elem: string, pos: string) => (course.sections?.lecturers).indexOf(
                                            elem,
                                          ) === pos,
                                        )
                                        .map((data: any) => (
                                          <div>{data?.name}</div>
                                        ))}
                                  </StyledText>
                                </td>
                              </>
                            )}
                            {['md'].includes(screenClass) && (
                              <>
                                <td>
                                  <div style={{ fontWeight: 'bold' }}>
                                    {course.short_name}
                                  </div>
                                  {course.code} /{' '}
                                  {course.sections && course.sections.length > 0
                                    ? course.sections[0].section
                                    : 'N/A'}
                                </td>
                                <td>
                                  {course.sections[0]?.schedule
                                    && course.sections[0]?.schedule.map(
                                      (data: any) => (
                                        <div>
                                          {data.day.slice(0, 3)}{' '}
                                          {data.start.split(' ')[0]}–
                                          {data.end.split(' ')[0]}
                                        </div>
                                      ),
                                    )}{' '}
                                  · {course.sections[0]?.room.name} ·{' '}
                                  {course.sections
                                    && course.sections[0]?.lecturers
                                    && course.sections[0].lecturers
                                      .filter(
                                        (elem: string, pos: string) => (course.sections[0]?.lecturers).indexOf(
                                          elem,
                                        ) === pos,
                                      )
                                      .map((data: any) => (
                                        <div>{data?.name}</div>
                                      ))}
                                </td>
                                <td>{course.units} Units · Letter Grade</td>
                              </>
                            )}
                            {!['xs', 'sm', 'md'].includes(screenClass) && (
                              <>
                                <td>
                                  {course.code} /{' '}
                                  {course.sections && course.sections.length > 0
                                    ? course.sections[0].section
                                    : 'N/A'}
                                </td>
                                <td>
                                  <b>{course.short_name}</b>
                                </td>
                                {course.sections[0]?.schedule
                                && course.sections[0]?.schedule.length > 0 ? (
                                  <td>
                                    {course.sections
                                      && course.sections[0]?.schedule.map(
                                        (data: any) => (
                                          <div>
                                            {data.day.slice(0, 3)}{' '}
                                            {data.start.split(' ')[0]}–
                                            {data.end.split(' ')[0]}
                                          </div>
                                        ),
                                      )}
                                  </td>
                                  ) : (
                                  <td>-</td>
                                  )}
                                <td>{course.sections[0]?.room.name}</td>
                                <td>
                                  {course.sections
                                    && course.sections[0]?.lecturers
                                    && course.sections[0]?.lecturers
                                      .filter(
                                        (elem: string, pos: string) => (course.sections[0]?.lecturers).indexOf(
                                          elem,
                                        ) === pos,
                                      )
                                      .map((data: any) => (
                                        <div>{data?.name}</div>
                                      ))}
                                </td>
                                <td>{course.units} Units · Letter Grade</td>
                              </>
                            )}
                            {['sm'].includes(screenClass) && (
                              <td>
                                <a
                                  href={course.url}
                                  target="_blank"
                                  rel="noreferrer">
                                  <Icon
                                    ml={0.5}
                                    size={2}
                                    name="canvas"
                                    style={{ opacity: course.url ? 1 : 0.2 }}
                                  />
                                </a>
                              </td>
                            )}
                            {/* {(!isMobile && !['sm'].includes(screenClass)) && (
                            <td>
                              {course.url
                                ? <a
                                href={course.url}
                                target="_blank"
                                rel="noreferrer"
                                style={{ whiteSpace: 'nowrap'
                              }}>
                                  Canvas <Icon ml={0.5} size={1.5} name="external-link-s" />
                                </a>
                                : <NullStateText>N/A</NullStateText>}
                            </td>
                          )} */}
                            {/* Last column */}
                            {!['xs'].includes(screenClass) ? (
                              <td
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                style={{ verticalAlign: 'middle' }}>
                                <CourseDropdown
                                  course={course}
                                  stages={stages}
                                  openInfoModal={openInfoModal}
                                  openDropModal={openDropModal}
                                  openWithdrawModal={openWithdrawModal}
                                />
                              </td>
                            ) : (
                              <td style={{ verticalAlign: 'middle' }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexFlow: 'column',
                                  }}>
                                  <FlexBox direction="column">
                                    {/* {course.url && ()} */}
                                    {/* <a href={course.url} target="_blank" rel="noreferrer">
                                    <ClickableIcon
                                    ml={0.5} size={2}
                                    name="canvas"
                                    style={{ opacity: course.url ? 1 : 0.2 }} />
                                  </a> */}
                                    <CourseDropdown
                                      course={course}
                                      stages={stages}
                                      openInfoModal={openInfoModal}
                                      openDropModal={openDropModal}
                                      openWithdrawModal={openWithdrawModal}
                                    />
                                  </FlexBox>
                                </div>
                              </td>
                            )}
                          </tr>
                        </>
                      ))}
                    {!currCourse && (
                      <NullStateTR>No enrolled courses.</NullStateTR>
                    )}
                  </>
                ) : (
                  <NullStateTR>
                    <Icon mr={1} name="spinner" spin />
                    Loading...
                  </NullStateTR>
                )}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EnrollCourse;
