import { useEffect, useMemo, useState, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useScreenClass } from "react-grid-system";
import Card from "../../components/Card";
import Button from "../../components/Button";
import GradeCard from "../../components/GradeCard";
import Breadcrumb from "../../components/Breadcrumb";
import Icon from "../../components/Icon";
import { Col, Row } from "../../components/StyledGridSystem";
import Status from "../../types/enum/Status";
import FlexBox, { FlexGrow } from "../../components/FlexBox";
import { NullStateText } from "../../components/Text";
import { StudentContext } from "../../context/StudentBehalfContext";
import Alert from "../../components/Alert";
import SnackBar from "../../components/SnackBar";

const ZeroCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing.x3};
  margin-top: ${({ theme }) => theme.spacing.x6};
  text-align: center;
`;

interface report {
  report: any;
  summary: any;
}

function CourseHistoryPage() {
  const { student, isBehalf } = useContext(StudentContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
    const [enrolled, setEnrolled] = useState<boolean>(false);

    const [programs, setPrograms] = useState<Program[]>([]);

  const screenClass = useScreenClass();
  const isMobile = useMemo(() => ["xs", "sm"].includes(screenClass), [
    screenClass,
  ]);
  const [history, setHistory] = useState<report>({
    report: [],
    summary: {},
  });
  // <-------- fetch data ---------->
  useEffect(() => {
    fetchGradeHistory(student.student_id);

      // Call the function to fetch programs and handbooks
      getProgramsAndHandbooks().then(data => {
          setPrograms(data)
      });
  }, [student.student_id]);
  const fetchGradeHistory = (stdid: string) => {
    setLoading(false);
    axios
      .get(`/api/v1/students/${stdid}/grades`)
      .then((res) => {
        setLoading(true);
        setHistory(res.data.data);
        if (res.data.data !== undefined && stdid) {
          setEnrolled(true);
        }
      })
      .catch((err) => {
        setLoading(true);
      });
  };

  const fetchUnofficialTranscript = () => {
    axios
      .get(`/api/v1/students/${student.student_id}/transcript/unofficial`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `unofficial_transcript_${student.first_name}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        setLoading(true);
      });
  };
  const fetchOfficialTranscript = () => {
    axios
      .get(`/api/v1/students/${student.student_id}/transcript/official`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `official_transcript_${student.first_name}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        setLoading(true);
      });
  };


    interface Program {
        id: string;
        name: string;
        program_name: string;
        handbook: Handbook;
    }

    interface Handbook {
        url: string;
        file_name: string;
        description: string;
    }

    async function fetchPrograms(): Promise<Program[]> {
        try {
            const res = await axios.get<{ data: { programs: Program[] } }>('/api/v1/courses/programs');
            return res.data.data.programs;
        } catch (error) {
            console.error('Error fetching programs:', error);
            return [];
        }
    }
    async function fetchUserProgramInfo(id: string): Promise<any> {
        try {
            const res = await axios.get<{ data: { program: any } }>(`/api/v1/students/${id}`);
            return res.data.data.program;
        } catch (error: any) {

            return Promise.reject('Failed to return fetchUserProgramInfo!');
        }

    };

    async function fetchHandbook(prog: string): Promise<Handbook> {
        try {
            const res = await axios.get<{ data: { document: Handbook } }>(`/api/v1/document/program/${prog}`);
            return res.data.data.document;
        } catch (error: any) {
            if (error.response) {
                setMessage(error.response.data.error.message || 'Failed to create competency!');
                setIsErrorOpen(true);
                console.error('Failed to find handbook:', prog, error.response.data.error.message);
            }
            setLoading(false);
            return Promise.reject(error.response.data.error.message || 'Failed to find handbook!');
        }
    }

    async function getProgramsAndHandbooks(): Promise<Program[]> {
        try {
            const studentProgram = await fetchUserProgramInfo(student.student_id);
            const programs = await fetchPrograms();
            let wantedPrograms = programs.filter((p:Program) => p.id === studentProgram.id);
            const programsWithHandbooks = await Promise.all(wantedPrograms.map(async (program) => {
                const handbook = await fetchHandbook(program.name);
                return {
                    ...program,
                    handbook
                };
            }));
            return programsWithHandbooks;
        } catch (error) {
            console.error('Error fetching programs or handbooks:', error);
            return Promise.reject('Error fetching programs or handbooks!');
        }
    }


  // <-------- mock data ---------->
  const test = [
    { name: "Courses", link: "/courses" },
    { name: "Enrollment History", link: "/courses/history" },
  ];
  return (
    <>
      <SnackBar
        isOpen={isErrorOpen}
        setOpen={setIsErrorOpen}
        message={message}
        type="error"
      />
      <Row>
        <Col>
          <Breadcrumb path={test} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FlexBox space={2}>
            <FlexGrow>
              <h2 style={{ marginRight: "auto", marginBottom: 0 }}>
                Enrollment History &amp; Grades
              </h2>
            </FlexGrow>
            {isBehalf ? (
              <>
                <Button
                  isOutline
                  status={Status.primary}
                  onClick={() => fetchOfficialTranscript()}
                  disabled={!enrolled}
                >
                  <Icon mr={1} name="download" /> Download Official Transcript
                  (.pdf)
                </Button>
              </>
            ) : (
              <></>
            )}
            {isMobile ? (
              <div>
                <br />
                <Button
                  isOutline
                  status={Status.primary}
                  onClick={() => fetchUnofficialTranscript()}
                  disabled={!enrolled}
                >
                  <Icon mr={1} name="download" /> Download Unofficial Transcript
                  (.pdf)
                </Button>
              </div>
            ) : (
              <Button
                isOutline
                status={Status.primary}
                style={{ marginLeft: "auto" }}
                onClick={() => fetchUnofficialTranscript()}
                disabled={!enrolled}
              >
                <Icon mr={1} name="download" /> Download Unofficial Transcript
                (.pdf)
              </Button>
            )}
          </FlexBox>
        </Col>
      </Row>
      <Row>
        <Col>
          <Alert
            style={{
              backgroundColor: "#e3f1fd",
              color: "#1c5f9d",
              border: "2px solid #1c5f9d",
            }}
          >
            {!isMobile && programs[0] && typeof programs[0] === 'object' &&(
              <FlexBox>
                <FlexGrow>
                    { programs[0]?.handbook.description }
                </FlexGrow>
                  <div>
                      <a
                          style={{color: "#1c5f9d"}}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={programs[0].handbook.url}
                      >
                          Download {programs[0].name} Handbook{" "}
                          <Icon mr={1} name="chevron-right-s"/>
                      </a>
                  </div>
              </FlexBox>
            )}
              {isMobile && programs[0] && typeof programs[0] === 'object' &&(
                  <div>
                      <div>
                          {programs[0]?.handbook.description}
                      </div>
                      <div>
                          <a
                              style={{color: "#1c5f9d"}}
                              target="_blank"
                              rel="noopener noreferrer"
                    href={ programs[0]?.handbook.url }
                  >
                      Download { programs[0]?.name } Handbook{" "}
                    <Icon mr={1} name="chevron-right-s" />
                  </a>
                </div>
              </div>
            )}
          </Alert>
        </Col>
      </Row>
      {enrolled ? (
        <>
          {history.report.map((data: any, index: number) => (
            <Row key={index}>
              <Col key={index}>
                {console.log("here", data)}
                <>
                  <GradeCard
                    key={index}
                    isMobile={isMobile}
                    termId={data.term_id}
                    semester={data.semester}
                    info={data.grades}
                    isBehalf={isBehalf}
                    summary={history.summary.summary[data.semester]}
                    studentId={student.student_id}
                    cumulative={history.summary.cumulative[data.semester]}
                  />
                </>
              </Col>
            </Row>
          ))}
        </>
      ) : (
        <Col>
          <ZeroCard>
            <NullStateText>No enrollment history.</NullStateText>
            {/* <FlexBox direction="column" space={2}>
              <h2>Sorry, you didn't enroll any course.</h2>
              <p>Please enroll the course to view the grades</p>
              <Link to="/courses/myCourses">Enroll now</Link>
            </FlexBox> */}
          </ZeroCard>
        </Col>
      )}
    </>
  );
}
export default CourseHistoryPage;
