import axios from 'axios';
import {
  HTMLProps, useEffect, useMemo, useState,
} from 'react';
import { useScreenClass } from 'react-grid-system';
import { NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  CourseContent, EmptyCourseContent, FilterValue, SectionContent,
} from '../filter/FilterValue';
import {
  SortOption,
  sortValue,
  StudentContent,
  StudentFilterValue,
} from '../filter/StudentsFilterValue';
import theme from '../styles/theme';
import Status from '../types/enum/Status';
import Breadcrumb from './Breadcrumb';
import Button from './Button';
import Card from './Card';
import FlexBox, { FlexGrow } from './FlexBox';
import Icon from './Icon';
import MinimalSelect from './MinimalSelect';
import NullStateTR from './NullStateTr';
import Pagination from './Pagination';
import Table from './Table';
import { StyledText, TextSize, TextStatus } from './Text';
import useDebounce from './use-debounce';
import { RightAlign } from './UtilsComponents';

export interface Ioption {
  semester: any;
  program: any;
  time: any;
  day: any;
  section: any;
}
const MinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x6 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
`;
const RowStyled = styled.div`
  display: flex;
  flex-flow: column;
`;
const FilterStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing.x2};
  margin-top: ${({ isMobile, theme }) => (isMobile ? theme.spacing.xs : 0)};
`;
const SubHeader = styled.div<{ isMobile?: boolean }>`
  font-size: ${({ isMobile }) => (isMobile ? '0.75rem' : '1rem')};
  display: flex;
  justify-content: flex-start;
`;
const Greytext = styled.div`
  color: ${({ theme }) => theme.color.textSecondary}!important;
  white-space: nowrap;
`;
const style = {
  textDecoration: 'none',
  color: theme.color.textColor,
  textoverflow: 'clip',
  display: 'flex',
};
type CourseStudentsInfoProps = {
  courseId: string;
};
const CourseStudentsInfo = ({ courseId, term }: any) => {
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const test = [{ name: 'Courses', link: '/admin/courses' }];

  const [posts, setPosts] = useState<Array<any>>([]);
  const [isItem, setIsItem] = useState(false);
  // const [offset, setOffSet] = useState(0);
  // const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allSections, setAllSections] = useState<Array<SectionContent>>([]);
  const [currentCourse, setCurrentCourse] = useState<CourseContent>(EmptyCourseContent);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, 300);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState({
    semester: [],
    program: [{ label: 'All', value: 'All' }],
    time: [],
    day: [],
    sections: [],
  });
  const [filters, setfilters] = useState({
    semester: '',
    program: [],
    time: [],
    day: [],
    sort: 'Course Code',
  });

  const [latestSemester, setLatestSemester] = useState<FilterValue>({
    label: '',
    value: '',
  });

  const [sections, setSections] = useState<FilterValue>({
    label: '',
    value: '',
  });

  let timeAndDay: any[] = [];
  timeAndDay = [...timeAndDay, ...options.day, ...options.time];
  const handleChangeSemester = (obj: any) => {
    setfilters({ ...filters, semester: obj.value });
  };

  const handleChangeSort = (obj: any) => {
    setfilters({ ...filters, sort: obj.value });
  };
  const fetchAllSections = () => {
    setLoading(true);
    axios
      .get(`/api/v1/courses/${courseId}/semesters/${term.value}/sections`)
      .then((res) => {
        if (res.data.data.sections) {
          setAllSections(res.data.data.sections);
          res.data.data.sections.map((ele: any, index: number) => {
            const updatedOption = { label: ele, value: ele };
          });
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const handleChangeProgram = (obj: any) => {
    const program: any = [];
    if (obj) {
      obj.forEach((element: any) => {
        program.push(element.value);
      });
      setfilters({ ...filters, program });
    } else {
      setfilters({ ...filters, program: [] });
    }
  };

  const handleChangeTime = (obj: any) => {
    if (obj) {
      const timelist: any = [];
      const daylist: any = [];
      obj.forEach((element: any) => {
        const ele = element.value;
        if (
          ele === 'Morning'
          || ele === 'Afternoon'
          || ele === 'Evening'
          || ele === 'Night'
        ) {
          timelist.push(ele);
        } else {
          daylist.push(ele);
        }
      });
      setfilters({ ...filters, time: timelist, day: daylist });
    } else {
      setfilters({ ...filters, time: [], day: [] });
    }
  };

  const handleSearchChange = (text: string) => {
    setSearchValue(text);
  };

  const openModal = (data: any) => {
    if (data) {
      setCurrentCourse(data);
      setIsModalOpen(true);
    }
  };

  const ColumnName = (isMobile: boolean) => {
    if (isMobile) {
      return (
        <>
          <th></th>
          <th></th>
        </>
      );
    }
    return (
      <>
        <th>Student Email</th>
        <th>Student Name</th>
        <th>Grade/Status</th>
        <th></th>
      </>
    );
  };
  const history = useHistory();
  const addCourse = () => {
    const path = '/admin/courses/add';
    history.push(path);
  };
  useEffect(() => {
    fetchAllSections();
    if (debouncedSearchTerm) {
      fetchPostbyPage();
    } else {
      fetchPostbyPage();
    }
  }, [filters, debouncedSearchTerm]);
  const sectionId = '9642e10a-a3ad-47b3-82a6-68f7e5a8de4a';
  const fetchPostbyPage = () => {
    setLoading(true);
    axios
      .get(`/api/v1/courses/${courseId}/sections/${sectionId}/students`)
      .then((res) => {
        // Object.keys(res.data.data.filter).forEach((key: string) => {
        //   const option: any[] = [];
        //   res.data.data.filter[key].map((ele: any, index: number) => {
        //     const updatedOption = { label: ele, value: ele };
        //     option.push(updatedOption);
        //     if (
        //       res.data.data.filter[key].length - 1 === index
        //       && key === 'semester'
        //       && filters.semester === ''
        //     ) {
        //       setfilters({ ...filters, semester: ele });
        //     }
        //     if (
        //       index === res.data.data.filter[key].length - 1
        //       && key === 'semester'
        //     ) {
        //       setLatestSemester(updatedOption);
        //     }
        //   });
        //   newOptions[key] = option;
        // });
        // setOptions(newOptions);
        // if (res.data.data.courses) {
        //   setPosts(res.data.data.courses);
        //   setTotalPage(res.data.data.total_pages);
        //   setOffSet(res.data.data.offset);
        // }

        // setIsItem(false);
        // if (!res.data.data.report) {
        //   setIsItem(true);
        // }
        setPosts(res.data.data.students);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <FlexBox>
        <FlexGrow>
          <Card style={{ marginTop: '0px' }}>
            <FlexBox
              style={{
                alignItems: 'flex-start',
                marginBottom: theme.spacing.base * 2,
              }}>
              <FlexGrow>
                <h3 style={{ marginBottom: 0 }}>Students</h3>
                <Greytext>
                  <SubHeader isMobile={isMobile}>30 students in this course</SubHeader>
                </Greytext>
              </FlexGrow>
              <FlexGrow ml-auto style={{ marginLeft: 'auto' }}>
                <RightAlign>
                  <Button
                    onClick={() => addCourse()}
                    isOutline
                    status={Status.primary}
                    style={{ padding: '4px 12px' }}>
                    <Icon name="plus-s" mr={0.5} />
                    Add Student
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
            <FlexBox>
              <FlexGrow>
              <StyledText fontWeight={'bold'} textSize={TextSize.small}>
                <Icon name="filter" mr={1} />
                Filter
              </StyledText>
              </FlexGrow>
            </FlexBox>
            <FilterStyled isMobile={isMobile}>
              <MinimalSelectStyled isMobile={isMobile}>
                <StyledText status={TextStatus.default}>
                  Sections:{' '}
                  {latestSemester.label !== '' ? (
                    <MinimalSelect
                      placeholder="All"
                      defaultValue={latestSemester}
                      options={options.sections}
                      onChange={handleChangeSemester}
                    />
                  ) : null}{' '}
                </StyledText>
              </MinimalSelectStyled>
              <MinimalSelectStyled isMobile={isMobile}>
                <StyledText status={TextStatus.default}>Sort:</StyledText>
                <MinimalSelect
                  defaultValue={sortValue}
                  options={SortOption}
                  onChange={handleChangeSort}
                />
              </MinimalSelectStyled>
            </FilterStyled>
            <Table hoverable>
              <thead>
                <tr>{ColumnName(isMobile)}</tr>
              </thead>
              <tbody>
                {!loading && posts ? (
                  posts.map((data) => {
                    if (!isMobile) {
                      return (
                        <tr key={data.id} onClick={() => openModal(data)}>
                          <td>
                            <StyledText
                              textSize={TextSize.default}
                              // status={TextStatus.disabled}
                            >
                              {data.cmkl_email}
                            </StyledText>
                          </td>
                          <td>{data.first_name}</td>
                          <td>{data.grade}</td>
                          <td>
                            <NavLink
                              exact
                              to={{
                                pathname: '/admin/courses/info',
                                state: { courseId: data.id },
                              }}
                              style={style}>
                              <Icon
                                name="chevron-right"
                                status={Status.primary}
                                style={{ alignSelf: 'center' }}
                              />
                            </NavLink>
                          </td>
                        </tr>
                      );
                    }

                    return (
                      <tr key={data.ID} onClick={() => openModal(data)}>
                        <td>
                          <RowStyled>
                            <StyledText style={{ fontWeight: 'bold' }}>
                              {data.short_name}
                            </StyledText>
                            <StyledText status={TextStatus.muted}>
                              {data.code}
                            </StyledText>
                            <StyledText status={TextStatus.muted}>
                              {data.units} Units ·{data.sections?.length}{' '}
                              Sections
                            </StyledText>
                          </RowStyled>
                        </td>
                        <td onClick={(e) => e.preventDefault()}>
                          <NavLink
                            exact
                            to={{
                              pathname: '/admin/courses/info',
                              state: { courseId: data.id },
                            }}
                            style={style}>
                            <Icon
                              name="chevron-right"
                              status={Status.primary}
                              style={{ alignSelf: 'center' }}
                            />
                          </NavLink>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <div></div>
                )}
                {isItem && !loading ? (
                  <NullStateTR>No courses found.</NullStateTR>
                ) : (
                  <div></div>
                )}
              </tbody>
            </Table>
            {!loading ? (
              <div></div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: theme.spacing.x2,
                }}>
                <Icon
                  name="spinner"
                  spin
                  size={4}
                  color={theme.color.textDisabled}
                />
                {/* <CircularProgress disableShrink /> */}
              </div>
            )}
            {/* {console.log(totalPage, offset, limit)} */}
            {/* <Pagination
              totalPages={totalPage}
              currentPage={Math.floor((offset + limit) / limit)}
              onPageChange={(goingToPage: number) => {
                fetchPostbyPage((goingToPage - 1) * limit, limit);
              }}
            /> */}
          </Card>
        </FlexGrow>
      </FlexBox>
    </>
  );
};
export default CourseStudentsInfo;
