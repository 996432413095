import axios from 'axios';
import React, { useMemo, useState, useEffect } from 'react';

import { useScreenClass } from 'react-grid-system';
import styled from 'styled-components';
import theme from '../styles/theme';
import Status from '../types/enum/Status';
import Button from './Button';
import FlexBox, { FlexGrow } from './FlexBox';
import Icon, { ClickableIcon } from './Icon';
import Modal from './Modal';
import { StyledText } from './Text';
import { RightAlign } from './UtilsComponents';

type ConfirmGradModalProps = {
  isOpen?: boolean;
  setOpen: (x: boolean) => void;
  confirmGraduation: (diplomaName: any) => void;
  diplomaName: any;
};

const ConfirmGradModal = ({
  isOpen,
  setOpen,
  confirmGraduation,
  diplomaName,
}: ConfirmGradModalProps): JSX.Element => {
  const screenClass = useScreenClass();
  const [isOpenComplete, setOpenComplete] = useState<boolean>(false);
  const [isOpenError, setOpenError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const onComplete = () => {
    setOpenComplete(false);
    // onDropComplete?.();
  };

  const onError = () => {
    setOpenError(false);
  };

  return (
    <>
      <Modal isOpen={isOpen || false} setOpen={setOpen}>
        <FlexBox>
          <FlexGrow>
            <h3>Confirm Graduation</h3>
          </FlexGrow>
          <ClickableIcon name="x-s" onClick={() => setOpen(false)} />
        </FlexBox>
        {/* <p style={{ textAlign: 'center' }}>
          <img src="/alert-warning.svg" alt="alert warning"/>
        </p> */}
        <p>This action is irreversible. Check th diploma name:</p>
        <p style={{ textAlign: 'center' }}>
          <StyledText fontWeight="bold">{diplomaName}</StyledText>
        </p>
        <p>Please click 'Confirm' for verification.</p>
        <RightAlign style={{ marginTop: theme.spacing.x2 }}>
          <Button
            isOutline
            onClick={() => {
              setOpen(false);
            }}
            style={{ marginRight: '8px' }}
            status={Status.danger}>
            Cancel
          </Button>
          <Button
            status={Status.primary}
            onClick={() => {
              confirmGraduation(diplomaName);
            }}>
            {isLoading && (
              <>
                <Icon mr={1} name="spinner" spin />
                Verifying...
              </>
            )}
            {!isLoading && <>Confirm</>}
          </Button>
        </RightAlign>
      </Modal>

      <Modal
        isOpen={isOpenComplete}
        setOpen={setOpenComplete}
        onClose={onComplete}
        customStyle={{ content: { textAlign: 'center' } }}>
        <img
          src="/alert-success-soft.svg"
          alt="alert warning"
          style={{ margin: 'auto' }}
        />
        <h3>Verification Complete</h3>
        <p>Success</p>
        <Button status={Status.primary} onClick={onComplete}>
          Done
        </Button>
      </Modal>

      <Modal
        isOpen={isOpenError}
        setOpen={setOpenError}
        onClose={onError}
        customStyle={{ content: { textAlign: 'center' } }}>
        <img
          src="/alert-warning.svg"
          alt="alert warning"
          style={{ margin: 'auto' }}
        />
        <h3>Verification Error</h3>
        <p>
          An error occured Please try again or contact us if the problem
          persists.
        </p>
        <Button status={Status.primary} onClick={onError}>
          Done
        </Button>
      </Modal>
    </>
  );
};

export default ConfirmGradModal;
