import typography from './typography';
import colors from './colors';
import getShadow from './shadows';

type ThemeType = {
  [key: string]: any;
};

const BASE_SPACING = 8;
const spacing: ThemeType = {
  base: BASE_SPACING,
  xxs: `${BASE_SPACING * 0.25}px`,
  xs: `${BASE_SPACING * 0.5}px`,
  x1: `${BASE_SPACING}px`,
  x2: `${BASE_SPACING * 2}px`,
  x3: `${BASE_SPACING * 3}px`,
  x4: `${BASE_SPACING * 4}px`,
  x6: `${BASE_SPACING * 6}px`,
  x8: `${BASE_SPACING * 8}px`,
  x10: `${BASE_SPACING * 10}px`,
};

const transitionTime: ThemeType = {
  default: '0.3s',
  quick: '0.15s',
  slow: '0.6s',
};

const theme: ThemeType = {
  radius: '4px',
  padding: '16px',
  navbarHeight: '60px',
  sidebarWidth: '240px',
  bottomPadding: `${BASE_SPACING * 8}px`,
  spacing,
  typography,
  transitionTime,
  color: colors,
  shadow: getShadow,
};

export default theme;
