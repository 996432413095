enum Status {
  default = 'default',
  primary = 'primary',
  secondary = 'secondary',
  success = 'success',
  info = 'info',
  danger = 'danger',
  warning = 'warning',
  blue = 'blue',
  // muted = 'muted',
  // disabled = 'disabled',
  light = 'light',
}

export default Status;
