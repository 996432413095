/* eslint-disable camelcase */
import axios from 'axios';
import moment from 'moment';
import React, { useMemo, useState, useEffect } from 'react';

import { useScreenClass } from 'react-grid-system';
import { useFieldArray, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { ErrorMessage } from '@hookform/error-message';
import theme from '../styles/theme';
import Status from '../types/enum/Status';
import Button from './Button';
import Card from './Card';
import ConfirmInvoiceModal from './ConfirmInvoiceModal';
import FlexBox, { FlexGrow } from './FlexBox';
import Icon, { ClickableIcon } from './Icon';
import Input from './Input';
import Modal from './Modal';
import StyledSnackbar from './StyledSnackbar';
import { Col, Row } from './StyledGridSystem';
import Table from './Table';
import { StyledText, TextSize } from './Text';
import { RightAlign } from './UtilsComponents';
import NullStateTR from './NullStateTr';
import formatDecimal from '../helpers/formatNumber';

type TemplateDetailModalProps = {
  isOpen?: boolean;
  setOpen: (x: boolean) => void;
  info: any;
  edit: boolean;
  refetch?: () => void;
};
type TemplateType = {
  total_balance: number;
  remaining_balance: number;
  statements: Array<any>;
  invoices: Array<any>;
};
interface InvoiceForm {
  invoice: {
    name: string;
    amount: number;
  }[];
}
const TemplateDetailModal = ({
  isOpen,
  setOpen,
  edit = false,
  info,
  refetch,
}: TemplateDetailModalProps): JSX.Element => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm<InvoiceForm>({
    reValidateMode: 'onChange',
    mode: 'onTouched',
  });
  const { fields, append, remove } = useFieldArray({
    name: 'invoice',
    control,
    keyName: 'key',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(edit);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [saveOpen, setSaveOpen] = useState<boolean>(false);
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const [invoice, setInvoice] = useState<any>({});
  const [plans, setPlans] = useState([
    { label: 'Semester Plan', value: 'semester_plan' },
    { label: 'Annual Plan', value: 'annual_plan' },
    { label: 'Program Plan', value: 'program_plan' },
  ]);
  const [filters, setfilters] = useState({
    plan: '',
  });
  const handleChangeSemester = (obj: any) => {
    setfilters({ ...filters, plan: obj.value });
  };
  useEffect(() => {
    fetchInvoice();
  }, []);
  const fetchInvoice = () => {
    setLoading(true);
    axios
      .get(`/api/v1/templates/${info.id}`)
      .then((res) => {
        setInvoice(res.data.data.template);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(true);
      });
  };
  const saveInvoice = () => {
    setLoading(true);
    setIsEdit(false);
    axios
      .get(`/api/v1/students/${info.student_id}/invoices/${info.id}`)
      .then((res) => {
        setInvoice(res.data.data.invoice);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(true);
      });
  };
  const confirmInvoice = () => {
    setLoading(true);
    axios
      .post(`/api/v1/students/${info.student_id}/invoices/${info.id}`)
      .then((res) => {
        setIsEdit(false);
        setMessage('Confirmed successfully');
        setShowMessage(true);
        refetch?.();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(true);
      });
  };
  const removeSchedule = (field: any, index: number) => {
    remove(index);
  };
  return (
    <>
      <StyledSnackbar
        isOpen={showMessage}
        setOpen={setShowMessage}
        message={message}
      />
      <Modal
        isOpen={isOpen || false}
        setOpen={setOpen}
        customStyle={{ content: { maxWidth: 550 } }}>
        <Row>
          <Col>
            <FlexBox>
              <FlexGrow>
                <h3 style={{ marginRight: 'auto', marginBottom: 0 }}>
                  Template Details
                </h3>
              </FlexGrow>
              <ClickableIcon name="x-s" onClick={() => setOpen(false)} />
            </FlexBox>
          </Col>
        </Row>
        {!loading ? (
          <>
            {!isMobile ? (
              <>
                <FlexBox
                  direction="column"
                  alignItems="start"
                  style={{ margin: '16px 0px 0px 0px' }}>
                  <FlexGrow style={{ flex: '0 0 100%', marginBottom: '5px' }}>
                    <StyledText fontWeight="700">Template Name</StyledText>
                  </FlexGrow>
                  <FlexGrow>
                    <StyledText>{invoice.name || '-'}</StyledText>
                  </FlexGrow>
                </FlexBox>
                <FlexBox
                  direction="column"
                  alignItems="start"
                  style={{ margin: '16px 0px 0px 0px' }}>
                  <FlexGrow style={{ flex: '0 0 100%', marginBottom: '5px' }}>
                    <StyledText fontWeight="700">Description</StyledText>
                  </FlexGrow>
                  <FlexGrow>
                    <StyledText>{invoice.description || '-'}</StyledText>
                  </FlexGrow>
                </FlexBox>
                <FlexBox
                  direction="column"
                  alignItems="start"
                  style={{ margin: '16px 0px 0px 0px' }}>
                  <FlexGrow style={{ flex: '0 0 100%', marginBottom: '5px' }}>
                    <StyledText fontWeight="700">Total Amount(THB)</StyledText>
                  </FlexGrow>
                  <FlexGrow>
                    <StyledText>
                      {formatDecimal(invoice.total_amount) || '-'}
                    </StyledText>
                  </FlexGrow>
                </FlexBox>
                <FlexBox
                  direction="column"
                  alignItems="start"
                  style={{ margin: '16px 0px 0px 0px' }}>
                  <FlexGrow style={{ flex: '0 0 100%', marginBottom: '5px' }}>
                    <StyledText fontWeight="700">Financial Plan</StyledText>
                  </FlexGrow>
                  <FlexGrow>
                    <StyledText>{invoice.financial_plan || '-'}</StyledText>
                  </FlexGrow>
                </FlexBox>
                <FlexBox
                  direction="column"
                  alignItems="start"
                  style={{ margin: '16px 0px 0px 0px' }}>
                  <FlexGrow style={{ flex: '0 0 100%', marginBottom: '5px' }}>
                    <StyledText fontWeight="700">Academic Year</StyledText>
                  </FlexGrow>
                  <FlexGrow>
                    <StyledText>{invoice.academic_year || '-'}</StyledText>
                  </FlexGrow>
                </FlexBox>
                <FlexBox
                  direction="column"
                  alignItems="start"
                  style={{ margin: '16px 0px 0px 0px' }}>
                  <FlexGrow style={{ flex: '0 0 100%', marginBottom: '5px' }}>
                    <StyledText fontWeight="700">Program</StyledText>
                  </FlexGrow>
                  <FlexGrow>
                    <StyledText>{invoice.program || '-'}</StyledText>
                  </FlexGrow>
                </FlexBox>
                <Table>
                  <thead>
                    <tr>
                      <th style={{ width: '30%' }}>Line Item Type</th>
                      <th style={{ width: '50%' }}>Line Item Name</th>
                      <th style={{ width: '20%' }}>Amount(THB)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoice?.items && invoice.items.length > 0 ? (
                      invoice.items.map((data: any) => (
                        <>
                          <tr>
                            <td style={{ border: 'none' }}>
                              {data.type || '-'}
                            </td>
                            <td style={{ border: 'none' }}>
                              {data.name || 'N/A'}
                            </td>
                            <td style={{ border: 'none' }}>
                              {formatDecimal(data.amount) || '-'}
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <NullStateTR></NullStateTR>
                    )}
                  </tbody>
                </Table>
              </>
            ) : (
              <>
                <Table>
                  <thead>
                    <tr>
                      <th>Item title</th>
                      <th>Amount(THB)</th>
                    </tr>
                  </thead>
                  {invoice.items.map((data: any) => (
                    <>
                      <tr>
                        <th colSpan={6}>{data.name}</th>
                      </tr>
                      <tr>
                        <td />
                        <td style={{ border: 'none' }}>
                          {data.amount.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          }) || 'N/A'}
                        </td>
                      </tr>
                    </>
                  ))}
                </Table>
              </>
            )}
          </>
        ) : (
          <></>
        )}
        <RightAlign style={{ marginTop: theme.spacing.x2 }}>
          {isEdit ? (
            <>
              <Button
                disabled={info.is_official}
                style={{ marginRight: 5 }}
                status={Status.primary}
                onClick={() => {
                  setIsEdit(false);
                }}>
                Cancel
              </Button>
              <Button
                disabled={
                  Object.keys(errors).length !== 0 || fields.length === 0
                }
                status={Status.success}
                onClick={() => {
                  setSaveOpen(true);
                }}>
                <>Save</>
              </Button>
            </>
          ) : (
            <>
              <Button
                disabled
                style={{ marginRight: 5 }}
                status={Status.default}
                onClick={() => {
                  setIsEdit(true);
                }}>
                Edit
              </Button>
              {info?.is_official ? (
                <Button disabled status={Status.primary}>
                  <>Confirmed</>
                </Button>
              ) : (
                <Button
                  disabled
                  status={Status.primary}
                  onClick={() => {
                    setConfirmOpen(true);
                  }}>
                  <>Confirm</>
                </Button>
              )}
            </>
          )}
        </RightAlign>
      </Modal>
    </>
  );
};

export default TemplateDetailModal;
