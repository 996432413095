/* eslint-disable camelcase */
import React, {
  useState, useEffect, useMemo, HTMLProps,
} from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ErrorMessage } from '@hookform/error-message';
import styled, { css } from 'styled-components';
import { useScreenClass } from 'react-grid-system';
import MinimalSelect from './MinimalSelect';
import FlexBox, { FlexGrow } from './FlexBox';
import theme from '../styles/theme';
import { StyledText } from './Text';
import Button from './Button';
import { RightAlign } from './UtilsComponents';
import Icon, { ClickableIcon } from './Icon';
import Status from '../types/enum/Status';
import Modal from './Modal';

const MinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x4 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
`;

type GradeForm = {
  grades: { id: string, grade: string }[];
};

type GradeItemProps = {
  // eslint-disable-next-line camelcase
  course_code: string,
  section: string,
  title: string,
  units: number,
  // eslint-disable-next-line camelcase
  final_score: string,
  points: string,
  semester: string
}

type GradeEditModalProps = {
  isOpen?: boolean;
  setOpen?: (x: boolean) => void;
  onEditComplete?: () => void;
  info: Array<GradeItemProps>,
  studentId: string,
};

const EditStudentGrade = ({
  isOpen,
  setOpen,
  onEditComplete,
  info,
  studentId,
}: GradeEditModalProps): JSX.Element => {
  console.log('grade info in edit page', info);
  const temp: { id: string, grade: string }[] = [];
  info.forEach((item: any) => {
    temp.push({
      id: item.id,
      grade: item.final_grade,
    });
  });
  const validationSchema = Yup.object({
  });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<GradeForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      grades: temp,
    },
  });
  useEffect(() => {
    fetchGrade();
  }, []);
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const [letter_grade, setLetterGrade] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const watchAllFields = watch();
  const history = useHistory();
  const fetchGrade = () => {
    setLoading(true);
    axios
      .get('/api/v1/grades')
      .then((res) => {
        if (res.data.data.grades) {
          const letterGrade: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.grades.forEach(
            (element: { letter_grade: string; }) => {
              const array = { label: '', value: '' };
              array.label = element.letter_grade;
              array.value = element.letter_grade;
              letterGrade.push(array);
            },
          );
          setLetterGrade(letterGrade);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const onSubmitClicked = (data: any) => {
    setLoading(true);
    console.log('value', data);
    axios
      .put(`/api/v1/students/${studentId}/grades`, data)
      .then((res) => {
        setLoading(false);
        onEditComplete?.();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    const path = '/courses/registration';
    history.push(path);
  };
  const onError = (errors: any, e: any) => console.log('error', errors, e);
  return (
    <>
      <Modal isOpen={!!isOpen} setOpen={setOpen}>
        <FlexBox
          style={{
            marginBottom: theme.spacing.base * 3,
          }}>
          <FlexGrow>
            <h3 style={{ marginRight: 'auto', marginBottom: 0 }}>
              Edit Student Grade
            </h3>
          </FlexGrow>
          <RightAlign>
            <ClickableIcon name="x-s" onClick={() => setOpen?.(false)} />
          </RightAlign>
        </FlexBox>
        {!loading ? (
          <form onSubmit={handleSubmit(onSubmitClicked, onError)}>
            {info?.map((grade, index) => (
              <FlexBox
                alignItems="center"
                style={{
                  marginBottom: theme.spacing.x2,
                }}>
                <FlexGrow grow={1}>
                  <StyledText color={'#000000'} fontWeight={'bold'}>
                    {grade.course_code}
                  </StyledText>
                </FlexGrow>
                <FlexGrow grow={3}>
                  <>
                    <Controller
                      control={control}
                      name={`grades.${index}.grade`}
                      defaultValue={grade.final_score as any}
                      render={({
                        field: {
                          onChange, onBlur, name, ref, value,
                        },
                      }) => (
                        <MinimalSelectStyled isMobile={isMobile}>
                          <MinimalSelect
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, width: '70px' }) }}
                            menuPosition={'absolute'}
                            name={name}
                            defaultValue={{ label: grade.final_score, value: grade.final_score }}
                            options={letter_grade}
                            onChange={(val: any) => onChange(val.value)}
                          />
                        </MinimalSelectStyled>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="code"
                      render={({ message }) => (
                        <p
                          style={{
                            marginTop: 0,
                            color: theme.color.danger,
                          }}>
                          {message}
                        </p>
                      )}
                    />
                  </>
                </FlexGrow>
              </FlexBox>
            ))}
            <FlexBox
              alignItems="center"
              justify="flex-end"
              style={{
                marginTop: theme.spacing.x4,
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow>
                <RightAlign>
                  <Button
                    onClick={() => { setOpen?.(false); onEditComplete?.(); }}
                    isOutline
                    status={Status.primary}
                    style={{ padding: '4px 12px' }}>
                    <Icon name="x-s" mr={0.5} />
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    // disabled={!isDirty}
                    onClick={() => handleSubmit(onSubmitClicked)}
                    status={Status.primary}
                    backgroundColor="#CB092B"
                    style={{ padding: '4px 12px', marginLeft: '8px' }}>
                    <Icon name="floppy" mr={0.5} />
                    Save
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
          </form>
        ) : (
          <Icon mr={1} name="spinner" spin />
        )}
      </Modal>
    </>
  );
};

export default EditStudentGrade;
