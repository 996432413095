import React, { useState } from 'react';
import styled from 'styled-components';

import Dropdown, { DropdownContainer, DropdownItem } from './Dropdown';
import Icon, { ClickableIcon } from './Icon';
import _theme from '../styles/theme';

const RemoveCourse = styled.div`
  color: ${({ theme }) => theme.color.primary};
`;

type CourseMasterDropdownProps = {
  course: { [key: string]: any };
  openAddSection?: (x: any) => void;
  openEditModal: (x: any) => void;
  openDropModal: (x: any, e?: any) => void;
};

const CourseMasterDropdown = ({
  course,
  openAddSection,
  openEditModal,
  openDropModal,
}: CourseMasterDropdownProps) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <DropdownContainer>
      <ClickableIcon
        ml={0.5}
        size={2}
        name="more-vertical"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!isOpen);
        }}
        style={{ color: _theme.color.primary }}
      />
      <Dropdown
        open={isOpen}
        style={{ whiteSpace: 'nowrap' }}
        setOpen={setOpen}
        align="right">
        {openAddSection && (
          <DropdownItem
            to="#"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
              openAddSection?.(course);
            }}>
            <Icon mr={1} name="plus-s" /> Add Section
          </DropdownItem>
        )}
        <DropdownItem
          to="#"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
            openEditModal(course);
          }}>
          <Icon mr={1} name="pencil-edit" /> Edit Course
        </DropdownItem>
        <DropdownItem
          to="#"
          onClick={(e: any) => {
            console.log('clicked');
            setOpen(false);
            openDropModal(course, e);
          }}>
          <RemoveCourse>
            <Icon mr={1} name="trash-empty" /> Delete course
          </RemoveCourse>
        </DropdownItem>
      </Dropdown>
    </DropdownContainer>
  );
};

export default CourseMasterDropdown;
