import { HTMLProps } from 'react';
import styled, { css } from 'styled-components';
import Status from '../types/enum/Status';

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.color.textSecondary};

  h1 + &, h2 + &, h3 + &, h4 + &, h5 + &, h6 + & {
    margin-top: -${({ theme }) => theme.spacing.x2};
  }
`;

export enum TextSize {
  default = 'default',
  paragraph = 'paragraph',
  small = 'small',
}

export enum TextStatus {
  default = 'textColor',
  muted = 'textSecondary',
  disabled = 'textDisabled',
  primary = 'primaryDark',
  secondary = 'secondaryDark',
  success = 'successDark',
  info = 'infoDark',
  danger = 'danger',
  warning = 'warningDark',
  light = 'light',
}

export type StyledTextProps = {
  status?: TextStatus;
  textSize?: TextSize;
  color?: string;
  fontWeight?: string;
} & HTMLProps<HTMLSpanElement>;

export const StyledText = styled.span<StyledTextProps>`
  &, * {
    ${({ theme, status }) => (status && status !== TextStatus.default) && css`
      color: ${theme.color[status]};
    `}

    ${({ color }) => color && css`
      color: ${color};
    `}
    ${({ fontWeight }) => fontWeight && css`
    font-weight: ${fontWeight};
    `}
    ${({ theme, textSize }) => textSize && css`
      font-size: ${theme.typography.sizes[textSize || TextSize.default]};
    `}
  }
`;

export const NullStateText = styled.span<StyledTextProps>`
  font-size: ${({ theme }) => theme.typography.sizes[TextSize.paragraph]};
  color: ${({ theme }) => theme.color.textDisabled};
  font-style: italic;
`;

const Text = {
  Subtitle,
};

export default Text;
