import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Student, { EmptyStudent } from '../types/Student';
import User, { EmptyUser } from '../types/User';

type Props = {
  children: React.ReactChild
}

type Input = {
  name: string,
  picture: string,
}

type StudentContextType = {
  student: Student;
  isBehalf: boolean;
  [key: string]: any;
}

export const StudentContext = React.createContext<StudentContextType>({
  student: EmptyStudent,
  isBehalf: false,
  setCurrentStudent: (stu: Student) => {},
  removeBehalf: () => {},
});

const StudentContextProvider = (props: Props) => {
  const [student, setStudent] = useState<Student>(EmptyStudent);
  const [isBehalf, setIsBehalf] = useState(false);

  const setCurrentStudent = (stu: Student) => {
    setStudent(stu);

    if (stu.first_name) {
      setIsBehalf(true);
    } else {
      setIsBehalf(false);
    }
  };

  const removeBehalf = () => {
    setIsBehalf(false);
    setStudent(EmptyStudent);
  };
  return (
    <StudentContext.Provider value={{
      student,
      isBehalf,
      setCurrentStudent,
      removeBehalf,
    }}>
      {props.children}
    </StudentContext.Provider>

  );
};

export default StudentContextProvider;
