import React, {
  HTMLProps, useEffect, useMemo, useState, useContext,
} from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { ScreenClassRender, useScreenClass } from 'react-grid-system';
import { StudentContext } from '../../context/StudentBehalfContext';
import { UserContext } from '../../context/UserContext';
import Breadcrumb from '../../components/Breadcrumb';
import Icon, { ClickableIcon } from '../../components/Icon';
import { Col, Row } from '../../components/StyledGridSystem';
import Status from '../../types/enum/Status';
import FlexBox from '../../components/FlexBox';
import Card from '../../components/Card';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import {
  StyledText,
  TextSize,
  TextStatus,
} from '../../components/Text';
import Modal from '../../components/Modal';
import {
  CourseContent,
  EmptyCourseContent,
} from '../../filter/FilterValue';
import useDebounce from '../../components/use-debounce';
import NullStateTR from '../../components/NullStateTr';
import theme from '../../styles/theme';
import CourseInfoModal from '../../components/CourseInfoModal';
import CourseCatalogModal from '../../components/CourseCatalogModal';

export interface Ioption {
  semester: any;
  program: any;
  time: any;
  day: any;
}

const MinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
  >`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x4 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
`;

const MultiMinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
  >`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x4 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
  align-items: center;
`;

const FilterStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
  >`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing.x2};
`;

const RowStyled = styled.div`
  display: flex;
  flex-flow: column;
`;

function CourseCatalogPage(this: any, props: any) {
  const location = useLocation();
  const currentUser = useContext(UserContext).user;
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const test = [
    { name: 'Courses', link: '/courses/myCourses' },
    { name: 'Course Catalog', link: '/courses/courseCatalog' },
  ];

  const [posts, setPosts] = useState<Array<CourseContent>>([]);
  const [isItem, setIsItem] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCourse, setCurrentCourse] = useState<CourseContent>(EmptyCourseContent);
  const studentBehalf = useContext(StudentContext);
  const { isBehalf } = useContext(StudentContext);
  console.log('currentUser', currentUser);
  console.log('student con', studentBehalf);
  const [loading, setLoading] = useState(true);
  const openModal = (data: any) => {
    if (data) {
      setCurrentCourse(data);
      setIsModalOpen(true);
    }
  };
  const ColumnName = (isMobile: boolean) => {
    if (isMobile) {
      return (
        <>
          <th></th>
          <th></th>
        </>
      );
    }
    return (
      <>
        <th>Course Code</th>
        <th>Course Name</th>
        <th>Units</th>
      </>
    );
  };
  const [programId, setProgramId] = useState('');
  useEffect(() => {
    if (isBehalf) {
      fetchUserInfo(studentBehalf.student.student_id);
    } else {
      fetchUserInfo(currentUser.student_id);
    }
  }, [programId, location]);

  const fetchUserInfo = (id: string) => {
    setLoading(true);
    axios
      .get(`/api/v1/students/${id}`)
      .then((res) => {
        setProgramId(res.data.data.program.id);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    axios
      .get(`/api/v1/courses/programs/${programId}`)
      .then((res) => {
        setPosts(res.data.data.courses);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <>
      <CourseCatalogModal
        isOpen={isModalOpen}
        setOpen={setIsModalOpen}
        currentCourse={currentCourse}
      />
      <Row>
        <Col>
          <Breadcrumb path={test} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FlexBox space={2}>
            <h2 style={{ marginRight: 'auto', marginBottom: 0 }}>
              Course Catalog
            </h2>
          </FlexBox>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ marginTop: '0px' }}>
            <Table hoverable>
              <thead>
                <tr>{ColumnName(isMobile)}</tr>
              </thead>
              <tbody>
                {!loading && posts ? (
                  posts.map((data) => {
                    if (!isMobile) {
                      return (
                        <tr key={data.id} onClick={() => openModal(data)}>
                          <td>
                            <StyledText
                              textSize={TextSize.default}
                            // status={TextStatus.disabled}
                            >
                              {data.code}
                            </StyledText>
                          </td>
                          <td>{data.short_name}</td>
                          <td>{data.units}</td>
                        </tr>
                      );
                    }

                    return (
                      <tr key={data.id} onClick={() => openModal(data)}>
                        <td>
                          <RowStyled>
                            <StyledText style={{ fontWeight: 'bold' }}>
                              {data.short_name}
                            </StyledText>
                            <StyledText status={TextStatus.muted}>
                              {data.code}
                            </StyledText>
                            <StyledText status={TextStatus.muted}>
                              {data.units}
                            </StyledText>
                          </RowStyled>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <></>
                )}
                {isItem && !loading ? (
                  <NullStateTR>No courses found.</NullStateTR>
                ) : (
                  <></>
                )}
              </tbody>
            </Table>
            {!loading ? (
              <></>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: theme.spacing.x2,
                }}>
                <Icon
                  name="spinner"
                  spin
                  size={4}
                  color={theme.color.textDisabled}
                />
                {/* <CircularProgress disableShrink /> */}
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default CourseCatalogPage;
