/* eslint-disable camelcase */
import React, {
  useState, useEffect, useMemo, HTMLProps,
} from 'react';
import ReactSelect from 'react-select';
import { useScreenClass } from 'react-grid-system';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ErrorMessage } from '@hookform/error-message';
import styled, { css } from 'styled-components';
import FlexBox, { FlexGrow } from '../../components/FlexBox';
import { StyledText } from '../../components/Text';
import { RightAlign } from '../../components/UtilsComponents';
import Status from '../../types/enum/Status';
import theme from '../../styles/theme';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Card from '../../components/Card';
import MinimalSelect from '../../components/MinimalSelect';
import Input from '../../components/Input';
import Modal from '../../components/Modal';

const sharedMinimalStyle = css`
  width: 100%;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 0px 12px;
  .react-select__menu {
    width: 100%;
    min-width: unset;
    margin-left: -12px;
  }
`;
const StyledMinimalSelect = styled(MinimalSelect)`
  ${sharedMinimalStyle}
`;
const StyledReactSelect = styled(ReactSelect) <{ isMobile?: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '86px' : '128px')};
`;
const MinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
  >`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x4 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
`;
type InstructorForm = {
  program_id: string;
  last_name: string;
  middle_name: string;
  first_name: string;
  cmkl_email: string;
  gender: string;
};
function CreateInstructorPage(props: any) {
  useEffect(() => {
    setLoading(false);
  }, []);
  const validationSchema = Yup.object({
    last_name: Yup.string().trim().required('Last name is required'),
    middle_name: Yup.string().trim(),
    first_name: Yup.string().trim().required('First name is required'),
    cmkl_email: Yup.string().email('Wrong email format').notRequired(),
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [isOpenError, setOpenError] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isDirty },
  } = useForm<InstructorForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      first_name: '',
      middle_name: '',
      last_name: '',
      cmkl_email: '',
    },
  });
  const GenderList = [
    {
      value: 'male',
      label: 'Male',
    },
    {
      value: 'female',
      label: 'Female',
    },
    {
      value: '',
      label: 'Custom',
    },
    {
      value: 'prefer_not_to_say',
      label: 'Prefer Not to Say',
    },
  ];
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const history = useHistory();
  const onSubmitClicked = (data: any) => {
    setLoading(true);
    axios
      .post('/api/v1/instructor', data)
      .then((res) => {
        history.push('/instructors');
      })
      .catch((error) => {
        setOpenError(true);
        console.log(error);
        setLoading(false);
      });
  };
  const onError = (errors: any, e: any) => console.log('error', errors, e);
  const onErrorCloseModal = () => {
    setOpenError(false);
  };
  return (
    <>
      <FlexBox
        style={{
          marginBottom: theme.spacing.base * 3,
          marginTop: theme.spacing.base * 3,
        }}>
        <FlexGrow>
          <h2 style={{ marginRight: 'auto', marginBottom: 0 }}>
            Create Instructor
          </h2>
        </FlexGrow>
      </FlexBox>
      <Card style={{ margin: '24px 0px' }}>
        {!loading ? (
          <form onSubmit={handleSubmit(onSubmitClicked, onError)}>
            <FlexBox
              alignItems="flex-start"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow>
                <h3>Instructor Information</h3>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  First Name
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('first_name')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="first_name"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Middle Name
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('middle_name')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="middle_name"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Last Name
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('last_name')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="last_name"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Gender
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Controller
                    control={control}
                    name={'gender'}
                    render={({
                      field: {
                        onChange, onBlur, name, ref, value,
                      },
                    }) => (
                      <MinimalSelectStyled isMobile={isMobile}>
                        <MinimalSelect
                          name={name}
                          options={GenderList}
                          onChange={(val: any) => onChange(val.value)}
                        />
                      </MinimalSelectStyled>
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="program_id"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  CMKL email
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('cmkl_email')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="cmkl_email"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              justify="flex-end"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow>
                <RightAlign>
                  <Button
                    onClick={() => {
                      history.push('/instructors');
                    }}
                    isOutline
                    status={Status.primary}
                    style={{ padding: '4px 12px' }}>
                    <Icon name="x-s" mr={0.5} />
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    onClick={() => handleSubmit(onSubmitClicked)}
                    status={Status.primary}
                    backgroundColor="#CB092B"
                    style={{ padding: '4px 12px', marginLeft: '8px' }}>
                    <Icon name="floppy" mr={0.5} />
                    Save
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
          </form>
        ) : (
          <Icon mr={1} name="spinner" spin />
        )}
      </Card>
      <Modal
        isOpen={isOpenError}
        setOpen={setOpenError}
        onClose={onError}
        customStyle={{ content: { textAlign: 'center' } }}>
        <img
          src="/alert-warning.svg"
          alt="alert warning"
          style={{ margin: 'auto' }}
        />
        <h3>Cannot Create Instructor</h3>
        <p>
          An error occurred while creating the instructor.
          This error can occur if the same cmkl email already exist.
          Please try again or contact us if the problem persists.
        </p>
        <Button status={Status.primary} onClick={onErrorCloseModal}>
          Done
        </Button>
      </Modal>
    </>
  );
}
export default CreateInstructorPage;
