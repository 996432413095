import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
} from 'react';

import {
  Redirect,
  Route,
  RouteProps,
  RouterProps,
  Switch,
  useLocation,
} from 'react-router-dom';
import { setConfiguration } from 'react-grid-system';
import { ThemeProvider } from 'styled-components';
import axios from 'axios';
import theme from './styles/theme';
import BasicLayout from './layouts/BasicLayout';
import HomePage from './pages/Home';
import DocumentsPage from './pages/documents/Documents';
import SignOutPage from './pages/SignOutPage';
import CourseHistoryPage from './pages/courses/History';
import SignInPage from './pages/SignInPage';
import MyCoursesPage from './pages/courses/MyCourses';
import AddCoursesPage from './pages/courses/AddCourses';
import { UserContext } from './context/UserContext';
import CourseCatalogPage from './pages/courses/CourseCatalogPage';
import * as apicall from './api/apicall';
import NotFoundPage from './pages/NotFoundPage';
import StudentsPage from './pages/students/Students';
import CoursesPage from './pages/courses/Courses';
import CourseInfoPage from './pages/courses/CourseInfo';
import CreateCoursePage from './pages/courses/CreateCourse';
import FinancePage from './pages/finance/Finance';
import FinanceCardPage from './pages/finance/FinanceCard';
import FinanceAdminPage from './pages/finance/FinanceAdmin';
import ReceiptPage from './pages/finance/Receipt';
import TemplatePage from './pages/finance/Template';

import CourseOfferingsPage from './pages/courses/CourseOfferingsPage';
import { StudentContext } from './context/StudentBehalfContext';
import CourseMasterPage from './pages/courses/CourseMasterPage';
import CourseSectionPage from './pages/courses/CourseSectionPage';
import CreateCourseMasterPage from './pages/courses/CreateCourseMaster';
import CreateCourseOffering from './pages/courses/CreateCourseOffering';
import TermsPage from './pages/Terms';
import CreateTermPage from './pages/CreateTermPage';
import ProgramsPage from './pages/Programs';
import CreateProgramPage from './pages/CreateProgramPage';
import CreateStudentPage from './pages/students/CreateStudent';
import SectionStudentsPage from './pages/courses/SectionStudentsPage';
import PersonalInfoPage from './pages/documents/Personalinfo';
import InstructorsPage from './pages/instructors/instructors';
import CreateInstructorPage from './pages/instructors/CreateInstructor';

setConfiguration({
  gutterWidth: theme.spacing.base * 3,
  containerWidths: [540, 740, 960, 1140, 1200],
  breakpoints: [576, 768, 992, 1200, 1600],
});

const PrivateRoute = (props: RouteProps) => {
  const userContext = useContext(UserContext);

  // return <Route {...props} />;
  return userContext.isSignedIn ? (
    <Route {...props} />
  ) : (
    <Redirect to="/signin" />
  );
};

function App() {
  const userContext = useContext(UserContext);
  const studentContext = useContext(StudentContext);
  useLayoutEffect(() => {
    axios
      .get('/api/v1/user/info')
      .then((res) => {
        if (res.data.role && res.data.role === 'student') {
          userContext.setCurrentUser(res.data);
          return studentContext.setCurrentStudent(res.data);
        }
        return userContext.setCurrentUser(res.data);
      })
      .catch((err) => console.error(err));
    const behalfInfo = JSON.parse(
      String(window.localStorage.getItem('behalfStudent')),
    );
    if (behalfInfo) {
      studentContext.setCurrentStudent(behalfInfo);
    }
    console.log(behalfInfo);
    // apicall.GetUserinfo().then((res) => console.log(res)).catch((err) => console.log(err));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // userContext.setCurrentUser(res.data))
  // FIXME : For fixed side bar;
  // userContext.isSignedIn = true;
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BasicLayout>
          <Switch>
            <PrivateRoute exact path="/" component={HomePage} />
            <PrivateRoute exact path="/courses" component={CourseCatalogPage} />
            <Redirect exact from="/admin/finance" to="/admin/finance/invoice" />
            <PrivateRoute
              exact
              path="/courses/myCourses"
              component={MyCoursesPage}
            />
            {/* <PrivateRoute exact path="/admin/courses" component={CoursesPage} /> */}
            <Redirect
              exact
              from="/admin/courses"
              to="/admin/courses/course/master"
            />
            <PrivateRoute
              exact
              path="/admin/courses/add"
              component={CreateCoursePage}
            />
            <PrivateRoute
              exact
              path="/admin/students/create"
              component={CreateStudentPage}
            />
            <PrivateRoute
              exact
              path="/admin/instructor/create"
              component={CreateInstructorPage}
            />
            <PrivateRoute
              exact
              path="/admin/courses/course/master/add"
              component={CreateCourseMasterPage}
            />
            <PrivateRoute
              path="/admin/courses/info"
              component={CourseInfoPage}
            />
            <PrivateRoute
              path="/admin/courses/course/offerings"
              component={CourseOfferingsPage}
            />
            <PrivateRoute
              exact
              path="/section/students"
              component={SectionStudentsPage}
            />
            <PrivateRoute
              path="/admin/courses/course/master"
              component={CourseMasterPage}
            />
            <PrivateRoute
              exact
              path="/admin/course/offerings/add"
              component={CreateCourseOffering}
            />
            <PrivateRoute
              path="/admin/courses/course/section"
              component={CourseSectionPage}
            />
            <PrivateRoute
              path="/courses/registration"
              component={CourseHistoryPage}
            />
            <PrivateRoute
              path="/courses/courseCatalog"
              component={CourseCatalogPage}
            />
            <PrivateRoute
              path="/courses/add-courses"
              component={AddCoursesPage}
            />
            <PrivateRoute
              path="/courses/calendar"
              component={() => {
                window.location.href = 'https://uploads-ssl.webflow.com/5da745a191bf587a2a8d8663/6136f78eb8e40cc3d4db4e3e_CMKL%20academic-calendar%20-%20(updated-07092021).pdf';
                return null;
              }}
            />
            <PrivateRoute
              path="/courses/history"
              component={CourseHistoryPage}
            />
            <PrivateRoute path="/students" component={StudentsPage} />
            <PrivateRoute path="/instructors" component={InstructorsPage} />
            <PrivateRoute exact path="/terms" component={TermsPage} />
            <PrivateRoute
              exact
              path="/terms/create"
              component={CreateTermPage}
            />
            <PrivateRoute exact path="/programs" component={ProgramsPage} />
            <PrivateRoute
              exact
              path="/programs/create"
              component={CreateProgramPage}
            />
            <PrivateRoute path="/documents" component={DocumentsPage} />
            <PrivateRoute path="/personalInfo" component={PersonalInfoPage} />
            <PrivateRoute path="/finance/detail" component={FinanceCardPage} />
            <PrivateRoute path="/finance" component={FinancePage} />
            <PrivateRoute
              path="/admin/finance/invoice"
              component={FinanceAdminPage}
            />
            <PrivateRoute
              path="/admin/finance/receipt"
              component={ReceiptPage}
            />
            <PrivateRoute
              path="/admin/finance/template"
              component={TemplatePage}
            />
            <Route path="/signout" component={SignOutPage} />
            <Route path="/oauth/login" />

            {userContext.isSignedIn ? (
              <Redirect from="/signin" to="/" />
            ) : (
              <Route path="/signin" component={SignInPage} />
            )}

            <Route path="/" component={NotFoundPage} />
            {/* TODO: add routes here */}
          </Switch>
        </BasicLayout>
      </ThemeProvider>
    </div>
  );
}

export default App;
