/* eslint-disable max-len */
import React, {
  useMemo, useEffect, useState, useRef, useContext,
} from 'react';
import axios from 'axios';
import styled from 'styled-components';

import { ScreenClassRender, useScreenClass } from 'react-grid-system';
import { UserContext } from '../context/UserContext';
import Card from '../components/Card';

import { Col, Row } from '../components/StyledGridSystem';
import QuickAccessIcon, {
  QuickAccessContainer,
} from '../components/QuickAccessIcon';
import Table from '../components/Table';

import TimeTableItem from '../components/TimeTableItem';
import { Subtitle, NullStateText } from '../components/Text';
import Icon from '../components/Icon';
import NullStateTR from '../components/NullStateTr';
import { StudentContext } from '../context/StudentBehalfContext';
import FlexBox, { FlexGrow } from '../components/FlexBox';
import Button from '../components/Button';
import Status from '../types/enum/Status';
import AnnouncementPage from './Announcement';
import ImportantDates from './ImportantDates';

const DayStyled = styled.td`
  font-weight: bold;
  background: ${({ theme }) => theme.color.defaultLight};
`;

const TextsecondayStyled = styled.td`
  color: ${({ theme }) => theme.color.textSecondary}!important;
  white-space: nowrap;
`;
const SubtitleStyled = styled.small`
  color: ${({ theme }) => theme.color.textSecondary}!important;
  /* margin-top: ${({ theme }) => theme.spacing.xs}; */
  display: block;
`;

const StyledCard = styled(Card)`
  margin-bottom: ${({ theme }) => theme.spacing.x3};
`;

const RowStyled = styled.tr`
  vertical-align: baseline;
`;

interface report {
  term: string;
  report: any;
  schedule: any;
}

type DataTimeTables = {
  [key: string]: any;
};

function HomePage() {
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const [timetable, setTimeTable] = useState([]);
  const [eachDay, setEachDay] = useState<any>({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
    TBA: [],
  });
  const [currentTerms, setCurrentTerms] = useState<any>([]);
  const { student, isBehalf } = useContext(StudentContext);
  const { user } = useContext(UserContext);
  const DataUpcomingDate = [
    {
      Date: 'August 23-29',
      Event: 'Registration period',
      term: '',
    },
    {
      Date: 'September 6-10',
      Event: 'TCI Registration period',
      term: '',
    },
    {
      Date: 'August 30',
      Event: 'Semester starts',
      term: '',
    },
    {
      Date: 'September 11',
      Event: 'TCI Semester starts',
      term: '',
    },
    {
      Date: 'September 13',
      Event: 'Add course deadline',
      term: '',
    },
    {
      Date: 'October 13',
      Event: 'Semester Course Drop Deadline',
      term: '',
    },
    {
      Date: 'October 14-15',
      Event: 'Midterm Exam',
      term: '',
    },
    {
      Date: 'October 18',
      Event: 'Mid Semester grades',
      term: '',
    },
    {
      Date: 'November 8',
      Event: 'Course Withdrawal Grade Deadline',
      term: '',
    },
    {
      Date: 'November 15-19',
      Event: 'Spring 2021 Registration Week',
      term: '',
    },
    {
      Date: 'November 22 - December 13',
      Event: 'Semester Faculty Course Evaluations',
      term: '',
    },
    {
      Date: 'December 3',
      Event: 'Semester last Day of Classes',
      term: '',
    },
    {
      Date: 'December 18',
      Event: 'TCI Semester last Day of Classes',
      term: '',
    },
    {
      Date: 'December 7-8/ 13-14 / 15',
      Event: 'Final Exam',
      term: '',
    },
    {
      Date: 'December 19-22',
      Event: 'TCI Final Exam',
      term: '',
    },
    {
      Date: 'December 16',
      Event: 'Makeup Final Exam',
      term: '',
    },
    {
      Date: 'December 23',
      Event: 'TCI Makeup Final Exam',
      term: '',
    },
    {
      Date: 'December 20',
      Event: 'Final grades',
      term: '',
    },
    {
      Date: 'December 24',
      Event: 'TCI Final grades',
      term: '',
    },
  ];

  const DataAnnoucements = [
    {
      title: 'Cube is now in beta for CMKL students!',
      date: '20/08/2021',
    },
    {
      title: 'University is closed due to COVID-19',
      date: '1/28/2021',
    },
  ];

  const AnnouncementsCard = (
    <StyledCard>
      <FlexBox style={{ marginBottom: '16px' }}>
        <FlexGrow grow={3}>
          <h3>Annoucements</h3>
        </FlexGrow>
        <FlexGrow>
          <Button status={Status.primary}>
            <Icon name="plus-s" mr={0.5} />
            Publish
          </Button>
        </FlexGrow>
      </FlexBox>
      <Table>
        <tbody>
          {DataAnnoucements.map((data, i) => (
            <RowStyled key={`announcementrow-${i}`}>
              <td>{data.title}</td>
              <TextsecondayStyled>{data.date}</TextsecondayStyled>
            </RowStyled>
          ))}
        </tbody>
      </Table>
    </StyledCard>
  );
  const ImportantDatesCard = (
    <StyledCard>
      <h3>Upcoming Important Dates</h3>
      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Event</th>
          </tr>
        </thead>
        <tbody>
          {DataUpcomingDate.map((data, i) => (
            <RowStyled key={`eventrow-${i}`}>
              <td>{data.Date}</td>
              <td>
                {data.Event}
                <SubtitleStyled>{data.term}</SubtitleStyled>
              </td>
            </RowStyled>
          ))}
        </tbody>
      </Table>
      <div style={{ textAlign: 'right', marginTop: '16px' }}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://uploads-ssl.webflow.com/5da745a191bf587a2a8d8663/6136f78eb8e40cc3d4db4e3e_CMKL%20academic-calendar%20-%20(updated-07092021).pdf">
          CMKL Academic Calendar 2021- 2022
          <Icon name="external-link" ml={0.5} />
        </a>
        <br />
      </div>
    </StyledCard>
  );

  useEffect(() => {
    fetchAddCoursePeriod();
  }, []);

  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else if (user.role === 'admin' && isBehalf) {
      axios
        .get(`/api/v1/students/${student.student_id}/courses`)
        .then((res) => {
          const data = res.data.data.courses.filter((term: any) => currentTerms.includes(term.term));
          const combinedTerms = data.reduce(
            (a: any, b: any) => a.concat(b),
            [],
          );
          setTimeTable(combinedTerms);
          const days: { [key: string]: any[] } = {
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
            Sunday: [],
            TBA: [],
          };

          for (const report of combinedTerms) {
            if (report.sections && report.sections.length > 0) {
              for (const section of report.sections) {
                if (section.schedule && section.schedule.length > 0) {
                  for (const time of section.schedule) {
                    const newObj = {
                      ...report,
                      time_start: time.start,
                      time_end: time.end,
                    };
                    days[time.day].push(newObj);
                  }
                } else {
                  const newObj = { ...report, time_start: '', time_end: '' };
                  days?.TBA?.push(newObj);
                }
              }
            } else {
              const newObj = { ...report, time_start: '', time_end: '' };
              days?.TBA?.push(newObj);
            }
          }
          setEachDay(days);
        })
        .catch((err) => console.log(err));
    } else if (user.role === 'student') {
      axios
        .get(`/api/v1/students/${user.student_id}/courses`)
        .then((res) => {
          // FIXME : REMOVE POP()
          // console.log(res.data);
          const data = res.data.data.courses.filter((term: any) => currentTerms.includes(term.term));
          const combinedTerms = data.reduce(
            (a: any, b: any) => a.concat(b),
            [],
          );
          setTimeTable(combinedTerms);
          const days: { [key: string]: any[] } = {
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
            Sunday: [],
            TBA: [],
          };

          for (const report of combinedTerms) {
            if (report.sections && report.sections.length > 0) {
              for (const section of report.sections) {
                if (section.schedule && section.schedule.length > 0) {
                  for (const time of section.schedule) {
                    const newObj = {
                      ...report,
                      time_start: time.start,
                      time_end: time.end,
                    };
                    days[time.day].push(newObj);
                  }
                } else {
                  const newObj = { ...report, time_start: '', time_end: '' };
                  days?.TBA?.push(newObj);
                }
              }
            } else {
              const newObj = { ...report, time_start: '', time_end: '' };
              days?.TBA?.push(newObj);
            }
          }
          setEachDay(days);
        })
        .catch((err) => console.log(err));
    }
  }, [currentTerms]);
  const fetchAddCoursePeriod = () => {
    axios.get('/api/v1/courses/terms/current').then((res) => {
      if (res.data.data) {
        setCurrentTerms(res.data.data.terms.map((term: any) => term.name));
      }
    });
  };
  /*
  const days = Object.keys(DataTimeTable.schedule).map((day: string, i: number) => (
    <>
      <tr key={`daygroup-${i}`}>
        <DayStyled colSpan={99}>{FULL_DAY[day]}</DayStyled>
      </tr>
      { DataTimeTable.schedule[day].map((ele: Days, j: number) => (
        <TimeTableItem key={`daygroup-${i}-${j}`} timestart={ele.time_start} timeend={ele.time_end} location={ele.location} courseName={ele.title} sec={ele.courseCode} instructor={ele.lecturer[0]} link={ele.canvas_url} isMobile={isMobile} />
      ))}
    </>
  ));
 */

  return (
    <>
      <Row>
        <Col>
          <h2>Quick Access</h2>
          <QuickAccessContainer isMobile={isMobile} isVerticalOnMobile={true}>
            <QuickAccessIcon to="/courses" icon="bookmark">
              My Courses
            </QuickAccessIcon>
            <QuickAccessIcon to="/courses/courseCatalog" icon="note-text">
              Courses Catalog
            </QuickAccessIcon>
            <QuickAccessIcon to="/courses/calendar" icon="calendar-dates">
              Academic Calendar
            </QuickAccessIcon>
            <QuickAccessIcon to="/courses/history" icon="time-history">
              Enrollment History
            </QuickAccessIcon>
          </QuickAccessContainer>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={8}>
          <StyledCard>
            {/* {console.log(timetable)} */}
            <h3>Course Timetable</h3>
            {currentTerms && currentTerms.length === 1 ? (
              <Subtitle>{currentTerms.term}</Subtitle>
            ) : (
              <Subtitle>{currentTerms.join('&')}</Subtitle>
            )}
            <Table>
              <thead>
                <tr>
                  <th>Day/Time</th>
                  {!isMobile && <th>Location</th>}
                  <th>Course Name</th>
                  {!isMobile && <th>Instructors</th>}
                  <th>{!isMobile && 'Go to Canvas'}</th>
                </tr>
              </thead>
              <tbody>
                {timetable
                  && timetable.length > 0
                  && Object.keys(eachDay).map((day: any, i: number) => (
                    <>
                      {eachDay[day].length > 0 ? (
                        <tr key={`daygroup-${i}`}>
                          <DayStyled colSpan={99}>{day}</DayStyled>
                        </tr>
                      ) : null}
                      {eachDay[day].map((ele: any, j: number) => (
                        <TimeTableItem
                          key={`daygroup-${i}-${j}`}
                          timestart={ele.time_start}
                          timeend={ele.time_end}
                          location={ele.sections}
                          courseName={ele.short_name}
                          sec={ele.code}
                          instructor={ele.sections}
                          link={ele.url}
                          isMobile={isMobile}
                        />
                      ))}
                    </>
                  ))}
                {timetable && !timetable.length && (
                  <NullStateTR>No enrolled courses.</NullStateTR>
                )}
              </tbody>
            </Table>
          </StyledCard>
        </Col>
        <Col md={4}>
          <AnnouncementPage />
          <ImportantDates />
        </Col>
      </Row>
    </>
  );
}
export default HomePage;
