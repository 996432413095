/* eslint-disable camelcase */
import axios from 'axios';
import { useMemo, useState, useEffect } from 'react';
import { useScreenClass } from 'react-grid-system';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import theme from '../styles/theme';
import Status from '../types/enum/Status';
import Button from './Button';
import FlexBox, { FlexGrow } from './FlexBox';
import { ClickableIcon } from './Icon';
import Input from './Input';
import Modal from './Modal';
import StyledSnackbar from './StyledSnackbar';
import { Col, Row } from './StyledGridSystem';
import { StyledText } from './Text';
import { RightAlign } from './UtilsComponents';
import Example from '../pages/finance/Receipt-Template.png';
import ConfirmInvoiceModal from './ConfirmInvoiceModal';
import getUrl from '../helpers/payslipUrl';

const StyledImage = styled.img`
  max-height: 500px;
`;
type ReceiptModalProps = {
  isOpen?: boolean;
  setOpen: (x: boolean) => void;
  info: any;
  edit: boolean;
  refetch?: () => void;
};
interface InvoiceForm {
  name: string;
}
const ReceiptModal = ({
  isOpen,
  setOpen,
  info,
  refetch,
}: ReceiptModalProps): JSX.Element => {
  const { register } = useForm<InvoiceForm>({
    reValidateMode: 'onChange',
    mode: 'onTouched',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [saveOpen, setSaveOpen] = useState<boolean>(false);
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const [invoice, setInvoice] = useState<any>({});
  const [payslips, setPayslips] = useState<any>([]);
  useEffect(() => {
    if (info && info?.id) {
      fetchSlips();
      fetchInvoice();
    }
  }, [info]);
  const fetchSlips = () => {
    const urls: any[] = [];
    setLoading(true);
    axios
      .get(`/api/v1/students/${info.student_id}/invoices/${info.id}/payslips`)
      .then((res) => {
        console.log(res);
        res.data.data.payslips.forEach((slip: any) => {
          getUrl(slip.url).then((data) => setPayslips((oldArray: any) => [...oldArray, data]));
        });
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(true);
      });
  };
  const fetchInvoice = () => {
    setLoading(true);
    axios
      .get(`/api/v1/students/${info.student_id}/invoices/${info.id}`)
      .then((res) => {
        // console.log('dataHistory : ', res.data);
        setInvoice(res.data.data.invoice);
        setLoading(false);
      })
      .catch(() => {
        setLoading(true);
      });
  };
  const saveInvoice = () => {
    setLoading(true);
    axios
      .post(
        `/api/v1/students/${info.student_id}/invoices/${info.id}/receipts`,
        {
          template_id: invoice.template.id,
          amount: invoice.template.total_amount,
        },
      )
      .then((res) => {
        refetch?.();
        setOpen(false);
        setLoading(false);
      })
      .catch(() => {
        setLoading(true);
      });
  };
  return (
    <>
      <StyledSnackbar
        isOpen={showMessage}
        setOpen={setShowMessage}
        message={message}
      />
      <Modal
        isOpen={isOpen || false}
        setOpen={setOpen}
        customStyle={{ content: { maxWidth: 600 } }}>
        <Row>
          <Col>
            <FlexBox>
              <FlexGrow>
                <h3 style={{ marginRight: 'auto', marginBottom: 0 }}>
                  Receipt
                </h3>
              </FlexGrow>
              <ClickableIcon name="x-s" onClick={() => setOpen(false)} />
            </FlexBox>
          </Col>
        </Row>
        {!loading ? (
          <>
            {!isMobile ? (
              <>
                <FlexBox
                  direction="column"
                  style={{
                    maxHeight: '500px',
                    flexWrap: 'nowrap',
                    overflowY: 'scroll',
                  }}>
                  {payslips && payslips.length > 0 ? (
                    <>
                      {payslips.map((item: string) => (
                        <FlexGrow>
                          <StyledImage src={item} alt="" />
                        </FlexGrow>
                      ))}
                    </>
                  ) : (
                    <FlexGrow>
                      <StyledText>No payslip</StyledText>
                    </FlexGrow>
                  )}
                </FlexBox>
                <FlexBox>
                  <FlexGrow style={{ width: '100%' }}>
                    <FlexBox>
                      <FlexGrow>
                        <StyledText>Name of receipt:</StyledText>
                      </FlexGrow>
                      <FlexGrow grow={3}>
                        <Input
                          isFullWidth
                          defaultValue="E-Check Payment"
                          {...register('name')}
                        />
                      </FlexGrow>
                    </FlexBox>
                  </FlexGrow>
                </FlexBox>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        <RightAlign style={{ marginTop: theme.spacing.x2 }}>
          <>
            <Button
              style={{ marginRight: 5 }}
              status={Status.primary}
              onClick={() => {
                setOpen(false);
              }}>
              Cancel
            </Button>
            <Button
              status={Status.success}
              onClick={() => {
                saveInvoice();
              }}>
              <>Confirm</>
            </Button>
          </>
        </RightAlign>
      </Modal>
    </>
  );
};

export default ReceiptModal;
