/* eslint-disable camelcase */
import React, {
  useMemo, useState, useEffect, useContext,
} from 'react';
import Select from 'react-select';
import axios from 'axios';
import { RowProps, useScreenClass } from 'react-grid-system';

import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ErrorMessage } from '@hookform/error-message';
import _theme from '../styles/theme';
import { Col, Row } from './StyledGridSystem';
import Card from './Card';
import FlexBox, { FlexBoxProps, FlexGrow } from './FlexBox';
import { RightAlign } from './UtilsComponents';
import Pill, { PillProps } from './Pill';
import Status from '../types/enum/Status';
import Button from './Button';
import Icon from './Icon';
import Table from './Table';
import NullStateTR from './NullStateTr';
import ALink from './ALink';
import CircleImage from './CircleImage';
import Input, { InputContainer, InputIconContainer } from './Input';
import { StudentContext } from '../context/StudentBehalfContext';
import { StyledText, TextStatus } from './Text';
import TextArea from './TextArea';
import Alert from './Alert';
import { CourseContent, EmptyCourseContent, ProgramContent } from '../filter/FilterValue';
import CourseDeleteModal from './CourseDeleteModal';
import MinimalSelect from './MinimalSelect';

interface ScreenProps {
  readonly isMobile: boolean;
}
const MAX_DESCRIPTION_LENGTH = 280;
const StyledProfileTag = styled.a`
  display: inline;
  align-items: center;
  vertical-align: middle;
  // TODO: fix vertical alignment
  /* line-height: 20px; */
  color: ${({ color, theme }) => color || theme.color.textColor};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
const StyledPill = styled(Pill)<
  { isMobile?: boolean } & React.HTMLProps<PillProps>
>`
  background-color: #aaaaaa;
  color: #ffffff;
  margin: 0px 16px;
  height: 16px;
  align-self: center;
  padding: ${({ isMobile }) => (isMobile ? '9px 12px' : '1px 6px')};
`;
const StyledRow = styled(Row)<ScreenProps>`
  flex-direction: ${(props) => (props.isMobile ? 'column-reverse' : '')};
`;
const StyledFlexBox = styled(FlexBox)<
  { isMobile?: boolean } & React.HTMLProps<FlexBoxProps>
>`
  align-items: center;
  margin: 0px 16px;
`;

const StyledTR = styled.tr<ScreenProps>`
  display: ${(props) => (props.isMobile ? 'inline-grid' : 'table-row')};
  width: 100%;
`;
const StyledTH = styled.th<ScreenProps>`
  padding: ${(props) => (props.isMobile ? '8px 16px 0px 16px !important' : '8px 16px !important')};
`;
const StyledTD = styled.td<ScreenProps>`
  padding: ${(props) => (props.isMobile ? '0px 16px 8px 16px !important' : '8px 16px !important')};
`;
type ViewMode = 'view' | 'edit';
type BasicCourseInfoForm = {
  description: string | undefined;
  units: number | undefined;
  prerequisites: string[] | undefined;
  co_requisites: string[] | undefined;
  programs: { id: string }[];
};
type BasicCourseInfoProps = {
  defViewMode: ViewMode;
  courseInfo: CourseContent;
  reloadParent: any;
};
const BasicCourseInfo = ({
  defViewMode,
  courseInfo,
  reloadParent,
}: BasicCourseInfoProps) => {
  const validationSchema = Yup.object({
    description: Yup.string(),
    units: Yup.number(),
    prerequisites: Yup.array().nullable(),
    co_requisites: Yup.array().nullable(),
    programs: Yup.array()
      .of(Yup.object({ id: Yup.string() }))
      .min(1, 'Please select at least 1 program')
      .required('Program is required'),
  });
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<BasicCourseInfoForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      description: courseInfo.description,
      units: courseInfo.units,
      prerequisites: courseInfo.condition.prerequisites,
      co_requisites: courseInfo.condition.co_requisites,
      programs: courseInfo.programs.length > 0 ? courseInfo.programs : [],
    },
  });
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  // Loading text on "Enrolled Courses"
  const [loading, setLoading] = useState<boolean>(true);
  const [viewMode, setViewMode] = useState<ViewMode>(defViewMode);
  const [isDescriptionExpand, setDescriptionExpand] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [basicCourseInfo, setBasicCourseInfo] = useState<CourseContent>(EmptyCourseContent);
  const [programs, setPrograms] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const onSubmitClicked = (data: any, e: any) => {
    if (isDirty && courseInfo.id && courseInfo.id !== '') {
      axios
        .put(`/api/v1/courses/${courseInfo.id}`, {
          code: courseInfo.code,
          full_name: courseInfo.full_name,
          short_name: courseInfo.short_name,
          description: data.description,
          units: data.units,
          prerequisites: data.prerequisites,
          co_requisites: data.co_requisites,
          programs: data.programs,
        })
        .then(() => {
          reloadParent();
          setViewMode('view');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const fetchPrograms = () => {
    setLoading(true);
    axios
      .get('/api/v1/courses/programs')
      .then((res) => {
        if (res.data.data.programs) {
          // eslint-disable-next-line max-len
          const programList: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.programs.forEach(
            (element: { name: string; id: string }) => {
              const array = { label: '', value: '' };
              array.label = element.name;
              array.value = element.id;
              programList.push(array);
            },
          );
          setPrograms(programList);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const onError = (errors: any, e: any) => console.log('error', errors, e);
  useEffect(() => {
    setBasicCourseInfo(courseInfo);
    fetchPrograms();
    setLoading(false);
  }, [courseInfo]);
  // Delete Course Modal
  const openDeleteModal = (course: any, e: MouseEvent) => {
    setIsModalOpen(true);
    e.stopPropagation();
  };
  const handleProgramChange = (program: any, dirty: boolean) => {
    const data: {
      id: string;
    }[] = [];
    if (program && program.length > 0) {
      program.forEach((lect: { value: any }) => data.push({ id: lect.value }));
    }
    setValue('programs', data, {
      shouldDirty: dirty,
    });
  };
  const history = useHistory();
  const toggleDeleteCourse = () => {
    const path = '/admin/courses';
    history.push(path);
  };
  return (
    <>
      <CourseDeleteModal
        isOpen={isModalOpen}
        setOpen={setIsModalOpen}
        currentCourse={basicCourseInfo}
        // handleDeleteCourse={handleDeleteCourse}
        onDeleteComplete={toggleDeleteCourse}
        onDeleteError={() => {
          toggleDeleteCourse();
        }}
      />
      <Card style={{ margin: '24px 0px' }}>
        <form onSubmit={handleSubmit(onSubmitClicked, onError)}>
          <FlexBox
            alignItems="flex-start"
            style={{
              marginBottom: _theme.spacing.x2,
            }}>
            <FlexGrow>
              <h3>Course Information</h3>
            </FlexGrow>
            <FlexGrow ml-auto style={{ marginLeft: 'auto' }}>
              <RightAlign>
                {viewMode === 'view' && (
                  <>
                    <Button
                      onClick={() => {
                        setViewMode('edit');
                      }}
                      isOutline
                      status={Status.primary}
                      style={{ padding: '4px 12px' }}>
                      <Icon name="pencil-edit" mr={0.5} />
                      Edit
                    </Button>
                  </>
                )}
              </RightAlign>
            </FlexGrow>
          </FlexBox>
          {viewMode === 'edit' && (
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: _theme.spacing.x2,
              }}>
              <FlexGrow grow={4}>
                <Alert
                  style={{
                    backgroundColor: '#FBECC8',
                    border: '2px solid #EFB829',
                  }}>
                  <FlexBox>
                    <FlexGrow>
                      <Icon
                        status={Status.warning}
                        name="alert-triangle"
                        style={{ marginRight: '5px', verticalAlign: 'middle' }}
                      />
                      <StyledText
                        status={TextStatus.warning}
                        style={{ verticalAlign: 'middle' }}>
                        Changes you make here will be applied to all offerings
                        of this course.
                      </StyledText>
                    </FlexGrow>
                  </FlexBox>
                </Alert>
              </FlexGrow>
            </FlexBox>
          )}
          <FlexBox
            alignItems="center"
            style={{
              marginBottom: _theme.spacing.x2,
            }}>
            <FlexGrow>
              <h5>Description</h5>
            </FlexGrow>
          </FlexBox>
          <FlexBox
            alignItems="center"
            style={{
              marginBottom: _theme.spacing.x2,
            }}>
            <FlexGrow>
              {viewMode === 'view' ? (
                <>
                  <StyledText>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {basicCourseInfo?.description.length
                    > MAX_DESCRIPTION_LENGTH ? (
                        !isDescriptionExpand ? (
                        <>
                          {basicCourseInfo?.description.substring(
                            0,
                            MAX_DESCRIPTION_LENGTH,
                          )}
                          ...{' '}
                          <a
                            href="#"
                            onClick={() => setDescriptionExpand(true)}>
                            Show More
                          </a>
                        </>
                        ) : (
                        <>
                          {basicCourseInfo?.description}{' '}
                          <a
                            href="#"
                            onClick={() => setDescriptionExpand(false)}>
                            Show less
                          </a>
                        </>
                        )
                      ) : (
                        basicCourseInfo?.description
                      )}
                  </StyledText>
                </>
              ) : (
                <>
                  <TextArea
                    {...register('description')}
                    isFullWidth
                    defaultValue={basicCourseInfo?.description}
                    wrap="hard"
                    rows={6}></TextArea>
                </>
              )}
            </FlexGrow>
          </FlexBox>
          <FlexBox
            alignItems="center"
            style={{
              marginBottom: _theme.spacing.x2,
            }}>
            <FlexGrow>
              <Row style={{ margin: '0 0px 8px 0' }}>
                <Col md={3} style={{ alignSelf: 'center', paddingLeft: 0 }}>
                  <StyledText color={'#000000'} fontWeight={'bold'}>
                    Units
                  </StyledText>
                </Col>

                {viewMode === 'view' ? (
                  <>
                    <Col md={3} style={{ alignSelf: 'center' }}>
                      <StyledText>{basicCourseInfo.units}</StyledText>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col md={9} style={{ paddingRight: 0 }}>
                      <Input
                        type="number"
                        isFullWidth
                        {...register('units')}
                        defaultValue={basicCourseInfo.units}></Input>
                    </Col>
                  </>
                )}
              </Row>
              <Row style={{ margin: '0 0px 8px 0' }}>
                <Col md={3} style={{ alignSelf: 'center', paddingLeft: 0 }}>
                  <StyledText color={'#000000'} fontWeight={'bold'}>
                    Prerequisites
                  </StyledText>
                </Col>
                {viewMode === 'view' ? (
                  <>
                    <Col md={3} style={{ alignSelf: 'center' }}>
                      {basicCourseInfo.condition.prerequisites ? (
                        <div style={{ fontSize: '16px' }}>
                          {' '}
                          {basicCourseInfo.condition.prerequisites}
                        </div>
                      ) : (
                        <StyledText
                          status={TextStatus.muted}
                          style={{ fontStyle: 'italic', fontSize: '16px' }}>
                          None
                        </StyledText>
                      )}
                    </Col>
                  </>
                ) : (
                  <>
                    <Col md={9} style={{ paddingRight: 0 }}>
                      <InputContainer
                        as="form"
                        onSubmit={() => {}}
                        style={{ width: '100%' }}>
                        <Input
                          id="search-input"
                          isFullWidth
                          {...register('prerequisites')}
                          // eslint-disable-next-line max-len
                          // onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchChange(e.target.value)
                          // }
                          placeholder="Find prerequisites..."
                          defaultValue={basicCourseInfo.condition.prerequisites}
                          style={{ width: 'inherit' }}
                        />
                        <InputIconContainer
                          onClick={(e: any) => e.preventDefault()}>
                          <Icon name="search" status={Status.default} />
                        </InputIconContainer>
                      </InputContainer>
                    </Col>
                  </>
                )}
              </Row>
              <Row style={{ margin: '0 0px 8px 0' }}>
                <Col md={3} style={{ alignSelf: 'center', paddingLeft: 0 }}>
                  <StyledText color={'#000000'} fontWeight={'bold'}>
                    Co-requisites
                  </StyledText>
                </Col>
                {viewMode === 'view' ? (
                  <>
                    <Col md={3} style={{ alignSelf: 'center' }}>
                      {basicCourseInfo.condition.co_requisites ? (
                        <div style={{ fontSize: '16px' }}>
                          {' '}
                          {basicCourseInfo.condition.co_requisites}
                        </div>
                      ) : (
                        <StyledText
                          status={TextStatus.muted}
                          style={{ fontStyle: 'italic', fontSize: '16px' }}>
                          None
                        </StyledText>
                      )}
                    </Col>
                  </>
                ) : (
                  <>
                    <Col md={9} style={{ paddingRight: 0 }}>
                      <InputContainer
                        as="form"
                        onSubmit={() => {}}
                        style={{ width: '100%' }}>
                        <Input
                          id="search-input"
                          {...register('co_requisites')}
                          isFullWidth
                          // eslint-disable-next-line max-len
                          // onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchChange(e.target.value)
                          // }
                          placeholder="Find co-requisites..."
                          defaultValue={basicCourseInfo.condition.co_requisites}
                          style={{ width: 'inherit' }}
                        />
                        <InputIconContainer
                          onClick={(e: any) => e.preventDefault()}>
                          <Icon name="search" status={Status.default} />
                        </InputIconContainer>
                      </InputContainer>
                    </Col>
                  </>
                )}
              </Row>
              <Row style={{ margin: '0 0px 8px 0' }}>
                <Col md={3} style={{ alignSelf: 'center', paddingLeft: 0 }}>
                  <StyledText color={'#000000'} fontWeight={'bold'}>
                    Programs
                  </StyledText>
                </Col>
                {viewMode === 'view' ? (
                  <>
                    <Col md={3} style={{ alignSelf: 'center' }}>
                      {basicCourseInfo.programs.map((program: any, index) => (
                        <span key={index} style={{ fontSize: '16px' }}>
                          {program.name}
                          {index !== basicCourseInfo.programs.length - 1 && ','}
                        </span>
                      ))}
                      {basicCourseInfo.programs.length === 0 && (
                        <StyledText
                          status={TextStatus.muted}
                          style={{ fontStyle: 'italic', fontSize: '16px' }}>
                          None
                        </StyledText>
                      )}
                    </Col>
                  </>
                ) : (
                  <>
                    <Col md={9} style={{ paddingRight: 0 }}>
                      <Controller
                        control={control}
                        defaultValue={courseInfo.programs}
                        name="programs"
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            isMulti
                            inputRef={ref}
                            classNamePrefix="addl-class"
                            options={programs}
                            // eslint-disable-next-line max-len
                            value={programs.filter((el) => value.some((f) => f.id === el.value))}
                            onChange={(e: any) => {
                              onChange(e);
                              handleProgramChange(e, true);
                            }}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="programs"
                        render={({ message }) => (
                          <p
                            style={{
                              marginTop: 0,
                              color: _theme.color.danger,
                            }}>
                            {message}
                          </p>
                        )}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </FlexGrow>
          </FlexBox>
          <FlexBox
            alignItems="center"
            justify="flex-end"
            style={{
              marginBottom: _theme.spacing.x2,
            }}>
            <FlexGrow>
              {viewMode === 'edit' && (
                <ALink
                  onClick={(e: any) => {
                    openDeleteModal(basicCourseInfo, e);
                  }}
                  // isOutline
                  // status={Status.primary}
                  style={{ textDecoration: 'underline' }}>
                  Delete Course
                </ALink>
              )}
            </FlexGrow>
            <FlexGrow grow={2}>
              <RightAlign>
                {viewMode === 'edit' && (
                  <>
                    <Button
                      onClick={() => {
                        setViewMode('view');
                      }}
                      isOutline
                      status={Status.primary}
                      style={{ padding: '4px 12px' }}>
                      <Icon name="x-s" mr={0.5} />
                      Discard Changes
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => handleSubmit(onSubmitClicked)}
                      status={Status.primary}
                      backgroundColor="#CB092B"
                      style={{ padding: '4px 12px', marginLeft: '8px' }}>
                      <Icon name="check-mark" mr={0.5} />
                      Save Changes
                    </Button>
                  </>
                )}
              </RightAlign>
            </FlexGrow>
          </FlexBox>
        </form>
      </Card>
    </>
  );
};

export default BasicCourseInfo;
