/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from 'react';
import ReactSelect from 'react-select';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ErrorMessage } from '@hookform/error-message';
import styled, { css } from 'styled-components';
import FlexBox, { FlexGrow } from '../components/FlexBox';
import { StyledText } from '../components/Text';
import { RightAlign } from '../components/UtilsComponents';
import Status from '../types/enum/Status';
import theme from '../styles/theme';
import Icon from '../components/Icon';
import Button from '../components/Button';
import Card from '../components/Card';
import MinimalSelect from '../components/MinimalSelect';
import Input from '../components/Input';

const sharedMinimalStyle = css`
  width: 100%;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 0px 12px;
  .react-select__menu {
    width: 100%;
    min-width: unset;
    margin-left: -12px;
  }
`;
const StyledMinimalSelect = styled(MinimalSelect)`
  ${sharedMinimalStyle}
`;
const StyledReactSelect = styled(ReactSelect) <{ isMobile?: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '86px' : '128px')};
`;
type ProgramForm = {
  name: string | undefined;
  program_name: string | undefined;
  degree_type: string | undefined;
  degree_abbr: string | undefined;
  program_abbr: string | undefined;
  description: string | undefined;
};
function CreateProgramPage(props: any) {
  useEffect(() => {
    setLoading(false);
  }, []);
  const validationSchema = Yup.object({
    name: Yup.string().required('Short name is required'),
    program_name: Yup.string().required('Full name is required'),
    degree_type: Yup.string().required('Degree Type is required'),
    degree_abbr: Yup.string().required('Degree Abbr is required'),
    program_abbr: Yup.string().required('Program Abbr is required'),
    description: Yup.string().required('Description is required'),
  });
  const [loading, setLoading] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm<ProgramForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      program_name: '',
      degree_type: '',
      degree_abbr: '',
      program_abbr: '',
      description: '',
    },
  });
  const history = useHistory();
  const watchAllFields = watch();
  const onSubmitClicked = (data: any) => {
    setLoading(true);
    console.log('create term data', data);
    axios
      .post('/api/v1/courses/programs', data)
      .then((res) => {
        setLoading(false);
        history.push('/programs');
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const onError = (errors: any, e: any) => console.log('error', errors, e);
  return (
    <>
      <FlexBox
        style={{
          marginBottom: theme.spacing.base * 3,
          marginTop: theme.spacing.base * 3,
        }}>
        <FlexGrow>
          <h2 style={{ marginRight: 'auto', marginBottom: 0 }}>
            Create Program
          </h2>
        </FlexGrow>
      </FlexBox>
      <Card style={{ margin: '24px 0px' }}>
        {!loading ? (
          <form onSubmit={handleSubmit(onSubmitClicked, onError)}>
            <FlexBox
              alignItems="flex-start"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow>
                <h3>Program Information</h3>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Short Name
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('name')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="name"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Full Name
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('program_name')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="program_name"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Degree Type
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('degree_type')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="degree_type"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Degree Abbr
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('degree_abbr')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="degree_abbr"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Program Abbr
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('program_abbr')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="program_abbr"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow grow={1}>
                <StyledText color={'#000000'} fontWeight={'bold'}>
                  Description
                </StyledText>
              </FlexGrow>
              <FlexGrow grow={3}>
                <>
                  <Input {...register('description')} isFullWidth />
                  <ErrorMessage
                    errors={errors}
                    name="description"
                    render={({ message }) => (
                      <p
                        style={{
                          marginTop: 0,
                          color: theme.color.danger,
                        }}>
                        {message}
                      </p>
                    )}
                  />
                </>
              </FlexGrow>
            </FlexBox>
            <FlexBox
              alignItems="center"
              justify="flex-end"
              style={{
                marginBottom: theme.spacing.x2,
              }}>
              <FlexGrow>
                <RightAlign>
                  <Button
                    onClick={() => {
                      history.push('/programs');
                    }}
                    isOutline
                    status={Status.primary}
                    style={{ padding: '4px 12px' }}>
                    <Icon name="x-s" mr={0.5} />
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    onClick={() => handleSubmit(onSubmitClicked)}
                    status={Status.primary}
                    backgroundColor="#CB092B"
                    style={{ padding: '4px 12px', marginLeft: '8px' }}>
                    <Icon name="floppy" mr={0.5} />
                    Save
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
          </form>
        ) : (
          <Icon mr={1} name="spinner" spin />
        )}
      </Card>
    </>
  );
}
export default CreateProgramPage;
