import React, { useState } from 'react';
import styled from 'styled-components';

import Dropdown, { DropdownContainer, DropdownItem } from './Dropdown';
import Icon, { ClickableIcon } from './Icon';
import _theme from '../styles/theme';

const RemoveCourse = styled.div`
  color: ${({ theme }) => theme.color.primary};
`;

type InvoiceDropdownProps = {
  invoice: { [key: string]: any };
  confirmInvoice: (x: any) => void;
  deleteinvoice: (x: any) => void;
  editModal: (x: any, e?: any) => void;
};

const InvoiceDropdown = ({
  invoice,
  confirmInvoice,
  deleteinvoice,
  editModal,
}: InvoiceDropdownProps) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <DropdownContainer>
      {/* open={() => setOpen(true)}> */}
      {/* isVisible.includes(course.code)}> */}
      <ClickableIcon
        ml={0.5}
        size={2}
        name="more-vertical"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!isOpen);
        }}
        style={{ color: _theme.color.primary }}
      />
      <Dropdown
        open={isOpen}
        style={{ whiteSpace: 'nowrap' }}
        setOpen={setOpen}
        align="right">
        {invoice?.is_official ? (
          <DropdownItem to="#">
            <RemoveCourse>
              <Icon mr={1} name="circle-checked" /> Confirmed
            </RemoveCourse>
          </DropdownItem>
        ) : (
          <>
            <DropdownItem
              to="#"
              onClick={() => {
                setOpen(false);
                editModal(invoice);
              }}>
              <Icon mr={1} name="pencil-s" /> Edit
            </DropdownItem>
            <DropdownItem
              to="#"
              onClick={() => {
                setOpen(false);
                confirmInvoice(invoice);
              }}>
              <Icon mr={1} name="check-mark" /> Confirm
            </DropdownItem>
            <DropdownItem
              to="#"
              onClick={(e: any) => {
                setOpen(false);
                deleteinvoice(invoice);
              }}>
              <Icon mr={1} name="trash" /> Delete
            </DropdownItem>
          </>
        )}
      </Dropdown>
    </DropdownContainer>
  );
};

export default InvoiceDropdown;
