import styled, { css } from 'styled-components';

type Props = {
  isOpen?: boolean;
  isBehalfActive?: boolean;
} & React.HTMLProps<HTMLDivElement>;

const Sidebar = styled.div<Props>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${({ theme }) => theme.sidebarWidth};
  background-color: ${({ theme }) => theme.color.white};
  transition: all ${({ theme }) => theme.transitionTime.default};
  z-index: 99;

  padding: ${({ theme }) => theme.spacing.x4};
  padding-right: ${({ theme }) => theme.spacing.x2};
  padding-top: ${({ theme }) => `calc(${theme.navbarHeight} + ${theme.spacing.x4})`};
  box-sizing: border-box;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  ${({ isBehalfActive }) => isBehalfActive
  && css`
    padding-top: ${({ theme }) => `calc(${theme.navbarHeight} + ${theme.spacing.x10})`};
  `}
  ${({ isOpen }) => !isOpen
    && css`
      transform: translateX(-100%);
    `}

  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;

  & > * {
    margin-bottom: ${({ theme }) => theme.spacing.x2};
  }
`;
// <Sidebar isOpen={true}>...</Sidebar>

export default Sidebar;
