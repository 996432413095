/* eslint-disable camelcase */
import internal from 'events';

export interface StudentContent {
  id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  cmkl_email: string;
  address: {};
  secondary_contact: {};
  program: {
    id: string;
    name: string;
    status: string;
  };
}
export const EmptyStudentContent: StudentContent = {
  id: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  cmkl_email: '',
  address: {},
  secondary_contact: {},
  program: {
    id: '',
    name: '',
    status: '',
  },
};

export interface StudentFilterValue {
  label: string;
  value: string;
}

export const semesterValue: StudentFilterValue = {
  label: 'Spring 2021',
  value: 'Spring 2021',
};

export const sortValue: StudentFilterValue = {
  label: 'Name (A-Z)',
  value: 'Name ascending',
};
export const CourseOptions: StudentFilterValue[] = [
  { label: 'ECE', value: 'ECE' },
  { label: 'EIC', value: 'EIC' },
];
export const TimeOptions: StudentFilterValue[] = [
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  { label: 'Saturday', value: 'Saturday' },
  { label: 'Sunday', value: 'Sunday' },
  { label: 'Morning', value: 'Morning' },
  { label: 'Afternoon', value: 'Afternoon' },
  { label: 'Evening', value: 'Evening' },
  { label: 'Night', value: 'Night' },
];

export const semesterOption: StudentFilterValue[] = [
  {
    label: 'Spring 2021',
    value: 'Spring 2021',
  },
  {
    label: 'Any',
    value: 'Any',
  },
];

export const SortOption: StudentFilterValue[] = [
  {
    label: 'Name (A-Z)',
    value: 'Name ascending',
  },
  {
    label: 'Name (Z-A)',
    value: 'Name descending',
  },
  {
    label: 'Enroll Date (ASC)',
    value: 'Enroll Date (ASC)',
  },
  {
    label: 'Enroll Date (DESC)',
    value: 'Enroll Date (DESC)',
  },
];
