/* eslint-disable camelcase */
import User from './User';

type Student = {
  student_id: string;
  first_name: string;
  last_name: string;
  expected_graduation_date: string;
  cmkl_email: string;
  email: string;
  program: {
    id: string;
    name: string;
  };
} & User;

export const EmptyStudent: Student = {
  student_id: '',
  cmkl_email: '',
  email: '',
  user_id: '',
  first_name: '',
  last_name: '',
  name: '',
  picture: '',
  role: '',
  program: {
    id: '',
    name: '',
  },
  expected_graduation_date: '',
};
export default Student;
