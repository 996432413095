import { useMemo, useState, useEffect } from 'react';

import theme from '../styles/theme';
import Status from '../types/enum/Status';
import Button from './Button';
import FlexBox, { FlexGrow } from './FlexBox';
import Icon, { ClickableIcon } from './Icon';
import Input from './Input';
import Modal from './Modal';
import { RightAlign } from './UtilsComponents';

type ConfirmInvoiceModalProps = {
  isOpen?: boolean;
  setOpen: (x: boolean) => void;
  currentInvoice: { [key: string]: any };
  operation: string;
  header?: string;
  save: () => void;
  onSaveError?: (err: any) => void;
};

const ConfirmInvoiceModal = ({
  isOpen,
  setOpen,
  currentInvoice,
  operation,
  header,
  save,
}: ConfirmInvoiceModalProps): JSX.Element => {
  const [deleteConfirmation, setDeleteconfirmation] = useState<boolean>(true);
  const [deletePasswordInput, setDeletePasswordInput] = useState<string>('');
  const [isOpenComplete, setOpenComplete] = useState<boolean>(false);
  const [isOpenError, setOpenError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    if (isOpen) {
      setDeletePasswordInput('');
    }
  }, [isOpen]);

  const deletePassword = useMemo(
    () => `${operation} ${currentInvoice.invoice_number}`,
    [currentInvoice],
  );

  return (
    <>
      <Modal
        isOpen={isOpen || false}
        setOpen={setOpen}
        customStyle={{ overlay: { zIndex: 101 } }}>
        <FlexBox>
          <FlexGrow>
            <h3 style={{ textTransform: 'capitalize' }}>
              {operation} {header || 'Invoice'}
            </h3>
          </FlexGrow>
          <ClickableIcon name="x-s" onClick={() => setOpen(false)} />
        </FlexBox>
        {/* <p style={{ textAlign: 'center' }}>
          <img src="/alert-warning.svg" alt="alert warning"/>
        </p> */}
        <p>
          Are you sure you want to {operation} the {header || 'invoice'} "
          <b>{currentInvoice.invoice_number}</b>"? This cannot be undone.
        </p>
        <p>
          To confirm, type "<b>{deletePassword}</b>" in this box.
        </p>
        <Input
          isFullWidth={true}
          value={deletePasswordInput}
          onInput={(e: any) => {
            setDeletePasswordInput(e.target.value);
          }}
        />
        <RightAlign style={{ marginTop: theme.spacing.x2 }}>
          <Button
            isOutline
            onClick={() => {
              setOpen(false);
            }}
            status={Status.default}>
            Cancel
          </Button>
          <Button
            disabled={isLoading || deletePasswordInput !== deletePassword}
            status={Status.primary}
            style={{ textTransform: 'capitalize' }}
            onClick={() => {
              setOpen(false);
              save();
            }}>
            {isLoading && (
              <>
                <Icon mr={1} name="spinner" spin />
                {operation}...
              </>
            )}
            {!isLoading && <>{operation}</>}
          </Button>
        </RightAlign>
      </Modal>
    </>
  );
};
export default ConfirmInvoiceModal;
