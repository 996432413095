/* eslint-disable camelcase */
import {
  useEffect, useMemo, useState, useContext, HTMLProps, Key,
} from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useScreenClass } from 'react-grid-system';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import Card from '../../components/Card';
import Button from '../../components/Button';
import Breadcrumb from '../../components/Breadcrumb';
import Icon from '../../components/Icon';
import { Col, Row } from '../../components/StyledGridSystem';
import Status from '../../types/enum/Status';
import FlexBox, { FlexGrow } from '../../components/FlexBox';
import {
  NullStateText,
  StyledText,
  TextSize,
  TextStatus,
} from '../../components/Text';
import { StudentContext } from '../../context/StudentBehalfContext';
import Alert from '../../components/Alert';
import FinanceCard from './FinanceCard';
import Table from '../../components/Table';
import NullStateTR from '../../components/NullStateTr';
import Pagination from '../../components/Pagination';
import theme from '../../styles/theme';
import Pill from '../../components/Pill';
import MinimalSelect from '../../components/MinimalSelect';
import { UserContext } from '../../context/UserContext';
import formatDecimal from '../../helpers/formatNumber';

const ZeroCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing.x3};
  margin-top: ${({ theme }) => theme.spacing.x6};
  text-align: center;
`;
type FinanceType = {
  total_balance: number;
  remaining_balance: number;
  statements: Array<any>;
  invoices: Array<any>;
};
interface Finance {
  id: string;
  invoice_number: string;
  name: string;
  status: string;
  issued_date: string;
  due_date: any;
}
const style = {
  textDecoration: 'none',
  color: theme.color.textColor,
  textoverflow: 'clip',
  display: 'flex',
};
const FilterStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing.x2};
`;
const MinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x4 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
`;
function FinancePage(this: any, props: any) {
  const test = [{ name: 'Finance', link: '/finance' }];
  const { student, isBehalf } = useContext(StudentContext);
  const { user } = useContext(UserContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [enrolled, setEnrolled] = useState<boolean>(true);
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const [financeData, setFinanceData] = useState<FinanceType>({
    total_balance: 0,
    remaining_balance: 0,
    statements: [],
    invoices: [],
  });
  const [plans, setPlans] = useState([
    { label: 'Semester Plan', value: 'semester_plan' },
    { label: 'Annual Plan', value: 'annual_plan' },
    { label: 'Program Plan', value: 'program_plan' },
  ]);
  const [filters, setfilters] = useState({
    plan: '',
  });
  const handleChangeSemester = (obj: any) => {
    setfilters({ ...filters, plan: obj.value });
  };
  // <-------- fetch data ---------->
  useEffect(() => {
    fetchFinances();
  }, [student, user]);
  const fetchFinances = () => {
    setLoading(true);
    axios
      .get(
        `/api/v1/students/${
          isBehalf ? student.student_id : user.student_id
        }/finances`,
      )
      .then((res) => {
        setLoading(false);
        setFinanceData(res.data.data.finance);
        filterData(res.data.data.finance);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const filterData = (data: any) => {
    const newInvoices = data?.invoices.filter((inv: any) => inv.is_official);
    setFinanceData((prevState: any) => ({
      ...prevState,
      invoices: newInvoices,
    }));
    const unique: string[] = [
      ...new Set<string>(data?.statements?.map((item: any) => item.term)),
    ];
    // const newData: {[key:string]: string} = {};
    const newDataArray: Array<any> = [];
    for (const uitem of unique) {
      const item: { name: string; items: [] } = {
        name: '',
        items: [],
      };
      const filteredInvoices = data?.statements?.filter(
        (item: any) => item.term === uitem,
      );
      item.name = uitem;
      item.items = filteredInvoices;
      newDataArray.push(item);
    }
    setFinanceData((prevState: any) => ({
      ...prevState,
      statements: newDataArray,
    }));
    setLoading(false);
  };
  const ColumnName = (isMobile: boolean) => {
    if (isMobile) {
      return (
        <>
          <th></th>
          <th></th>
        </>
      );
    }
    return (
      <>
        <th style={{ width: '35%' }}>Invoice No.</th>
        <th style={{ width: '25%' }}>Due Date</th>
        <th style={{ width: '15%' }}>Status</th>
        <th style={{ width: '15%' }}></th>
      </>
    );
  };

  return (
    <>
      <Row>
        <Col>
          <Breadcrumb path={test} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FlexBox space={2}>
            <FlexGrow>
              <h2 style={{ marginRight: 'auto', marginBottom: 0 }}>
                Student Account Statement
              </h2>
            </FlexGrow>
            <FlexBox direction="column" alignItems="flex-end">
              <FlexGrow>
                <StyledText style={{ verticalAlign: 'top' }}>
                  Balance:
                </StyledText>
              </FlexGrow>
            </FlexBox>
            <FlexBox direction="column" alignItems="flex-start">
              <FlexGrow>
                <StyledText style={{ verticalAlign: 'top' }}>
                  {`${formatDecimal(financeData?.remaining_balance)} THB` || 'N/A'}
                </StyledText>
              </FlexGrow>
            </FlexBox>
          </FlexBox>
        </Col>
      </Row>
      {!loading ? (
        <>
          {financeData.statements && financeData.invoices?.length > 0 ? (
            financeData.statements.map((statement, index) => (
              <Row key={index}>
                <Col>
                  <Card>
                    <FlexBox
                      alignItems="flex-start"
                      style={{ marginBottom: theme.spacing.x2 }}>
                      <FlexGrow>
                        <h3 style={{ textTransform: 'capitalize' }}>
                          {statement.name}
                        </h3>
                        {/* <Subtitle>Enrolled Courses</Subtitle> */}
                      </FlexGrow>
                    </FlexBox>
                    <Table hoverable>
                      <thead>
                        {!isMobile && (
                          <tr>
                            <th style={{ width: '20%' }}>DATE</th>
                            <th style={{ width: '60%' }}>DESCRIPTION</th>
                            <th style={{ width: '20%' }}>AMOUNT</th>
                          </tr>
                        )}
                      </thead>
                      <tbody>
                        {statement && statement.items?.map((sItem: any, index: any) => (
                          <tr key={index}>
                            <td>
                              <StyledText textSize={TextSize.default}>
                                {moment(sItem.date).format('DD/MM/YYYY')}
                              </StyledText>
                            </td>
                            <td>
                              <StyledText textSize={TextSize.default}>
                                {sItem.name}
                              </StyledText>
                            </td>
                            <td>
                              <StyledText textSize={TextSize.default}>
                                {sItem.unit}{' '}
                                {formatDecimal(sItem.amount) || 'N/A'}
                              </StyledText>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td></td>
                          <td style={{ textAlign: 'end' }}>Balance</td>
                          <td>
                            {`${formatDecimal(financeData?.remaining_balance)} THB` || 'N/A'}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card>
                </Col>
              </Row>
            ))
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      <Row>
        <Col>
          <Card style={{ marginTop: '0px' }}>
            {/* <FilterStyled isMobile={isMobile}>
              <MinimalSelectStyled isMobile={isMobile}>
                <StyledText status={TextStatus.default}>
                  Financial Plans:{' '}
                  {plans[0].label !== '' ? (
                    <MinimalSelect
                      defaultValue={plans[0]}
                      options={plans}
                      onChange={handleChangeSemester}
                    />
                  ) : null}{' '}
                </StyledText>
              </MinimalSelectStyled>
            </FilterStyled> */}
            <Table hoverable>
              <thead>
                <tr>{ColumnName(isMobile)}</tr>
              </thead>
              <tbody>
                {financeData.invoices && financeData.invoices.length > 0 ? (
                  financeData.invoices.map((data) => {
                    if (!isMobile) {
                      return (
                        <>
                          <tr key={data.id}>
                            <td>
                              <StyledText textSize={TextSize.default}>
                                {data.invoice_number}
                              </StyledText>
                            </td>
                            <td>
                              <StyledText textSize={TextSize.default}>
                                {moment(data.due_date).format('DD/MM/YYYY')}
                              </StyledText>
                            </td>
                            <td>
                              {data.status === 'completed' && (
                                <>
                                  <Pill
                                    style={{ textTransform: 'capitalize' }}
                                    status={Status.success}>
                                    {data.status}
                                  </Pill>
                                </>
                              )}
                              {data.status === 'pending' && (
                                <>
                                  <Pill
                                    style={{ textTransform: 'capitalize' }}
                                    status={Status.primary}>
                                    {data.status}
                                  </Pill>
                                </>
                              )}
                            </td>
                            <td>
                              <NavLink
                                exact
                                to={{
                                  pathname: '/finance/detail',
                                  state: {
                                    info: data.id,
                                    isMobile,
                                    semester: 'New',
                                  },
                                }}
                                style={style}>
                                <StyledText
                                  textSize={TextSize.default}
                                  status={TextStatus.primary}>
                                  Statement Detail
                                </StyledText>
                                <Icon
                                  name="chevron-right"
                                  status={Status.primary}
                                  style={{ alignSelf: 'center' }}
                                />
                              </NavLink>
                            </td>
                          </tr>
                        </>
                      );
                    }

                    return (
                      <tr>
                        <td>
                          <StyledText textSize={TextSize.default}>
                            {data.name}
                          </StyledText>
                        </td>
                        <td>
                          <NavLink
                            to={{
                              pathname: '/finance/detail',
                              state: {
                                info: data.id,
                                isMobile,
                              },
                            }}
                            style={style}>
                            <StyledText
                              textSize={TextSize.default}
                              status={TextStatus.primary}>
                              Invoice Detail
                            </StyledText>
                            <Icon
                              name="chevron-right"
                              status={Status.primary}
                              style={{ alignSelf: 'center' }}
                            />
                          </NavLink>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NullStateTR>No Data</NullStateTR>
                )}
              </tbody>
            </Table>
            <Pagination
              totalPages={1}
              currentPage={1}
              onPageChange={(goingToPage: number) => {
                // fetchStudentbyFilter((goingToPage - 1) * limit, limit);
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default FinancePage;
