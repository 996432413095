import styled from 'styled-components';
import Status from '../types/enum/Status';
import theme from '../styles/theme';

export type PillProps = {
  theme: typeof theme;
  children?: React.ReactNode;
  status?: Status;
}

type PillFullProps = {
  backgroundColor?: string;
  fontColor?: string;
} & PillProps;

const Pill = styled.span.attrs(({
  theme,
  status = Status.default,
}: PillProps) => {
  let backgroundColor: string;
  let fontColor: string;
  if (status === Status.default) {
    backgroundColor = theme.color.lightBackground;
    fontColor = theme.color.textColor;
  } else {
    backgroundColor = theme.color[status];
    fontColor = theme.color.white;
  }
  return ({
    backgroundColor,
    fontColor,
  });
}) <PillFullProps>`
  display: inline-flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.xs};
  border-radius: ${({ theme }) => theme.radius};
  padding: 1px 6px;
  color: ${({
    fontColor,
  }) => fontColor};
  background-color: ${({
    backgroundColor,
  }) => backgroundColor};
  ${({
    theme,
  }) => theme.typography.UITextSmall}

  i {
    font-style: normal;
    margin-right: 5px;
  }
`;

export default Pill;
