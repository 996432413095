import React from 'react';
import ReactSelect, { components, Props } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styled, { css } from 'styled-components';
import Icon from './Icon';
import Pill from './Pill';

// FIXME: why didn't overriding isMulti work?
export type MinimalSelectProps = Props & {
  isMulti?: boolean;
};

const sharedStyle = css`

  display: inline-block;

  .react-select__indicators {
    gap: ${({ theme }) => theme.spacing.xs};
  }

  .react-select__indicator{
    &.react-select__dropdown-indicator {
      color: ${({ theme }) => theme.color.textSecondary};
      padding: 0;
    }
    &.react-select__clear-indicator {
      color: ${({ theme }) => theme.color.textSecondary};
      padding: 0;
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__value-container {
    padding: 0;
  }

  .react-select__control {
    padding: 0;
    border: none;
    outline: none;
    box-shadow: none;
    min-height: none;
    cursor: pointer;
  }

  .react-select__placeholder {
    margin: 0; // ${({ theme }) => theme.spacing.x1};
    position: relative;
    transform: none;
    color: ${({ theme }) => theme.color.textMuted};
  }


  .react-select__control {
    border: none;
    background: transparent;
  }

  .react-select__input {
    width: unset;
    margin: 0;
  }

  .react-select__menu {
    min-width: ${({ theme }) => theme.spacing.base * 20}px;
    margin: 0;
  }
  
  .react-select__value-container {
    padding: 0 ${({ theme }) => theme.spacing.xs};
  }
  
  .react-select__single-value {
    font-weight: bold;
    position: relative;
    transform: none;
    max-width: none;
    margin: 0;
  }

  .react-select__multi-value {
    margin: 0;
    padding: 1px 6px;
    border-radius: 4px;
  }

  .react-select__single-value__label {
    color: unset;
    margin: 0;
  }

  .react-select__multi-value__label {
    color: inherit;
    padding: 0;
    font-size: inherit;
  }

  .react-select__multi-value__remove {
    color: inherit;
    padding: 0;
    font-size: inherit;
    
    &:hover {
      background: transparent;
      color: ${({ theme }) => theme.color.danger};
    }
  }
`;

const StyledReactSelect = styled(ReactSelect)`
  ${sharedStyle}
`;

const StyledCreatableSelect = styled(CreatableSelect)`
  ${sharedStyle}
`;

const MultiValueContainer = (props: any) => (
  <Pill style={{ padding: 0 }}>
    <components.MultiValueContainer {...props} />
  </Pill>
);

const MultiValueRemove = (props: any) => (
  <components.MultiValueRemove {...props}>
    <Icon name="x-s" size={1.5} ml={1} />
  </components.MultiValueRemove>
);

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <Icon name="chevron-bottom-s" />
  </components.DropdownIndicator>
);

const ClearIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <Icon name="x-s" />
  </components.DropdownIndicator>
);

const MinimalSelect = ({
  multi, styles, options, defaultValue, placeholder,
}: any) => (
  <StyledReactSelect
    closeMenuOnSelect={true}
    classNamePrefix="react-select"
    isMulti={multi}
    styles={styles}
    options={options}
    defaultValue={defaultValue}
    placeholder={placeholder}
    components={{
      MultiValueContainer,
      MultiValueRemove,
      DropdownIndicator,
      ClearIndicator,
    }}
  />
);

export const MinimalSelectCreatable = ({ multi, ...props }: MinimalSelectProps) => (
  <StyledCreatableSelect
    classNamePrefix="react-select"
    components={{
      MultiValueContainer,
      MultiValueRemove,
      DropdownIndicator,
      ClearIndicator,
    }}
    isMulti={multi}
    {...props}
  />
);
export default MinimalSelect;
