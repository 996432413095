/* eslint-disable no-nested-ternary */
import React, {
  HTMLProps,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useScreenClass } from 'react-grid-system';
import { StylesConfig } from 'react-select';
import axios from 'axios';
import Breadcrumb from '../../components/Breadcrumb';
import Icon from '../../components/Icon';
import { Col, Row } from '../../components/StyledGridSystem';
import Status from '../../types/enum/Status';
import FlexBox, { FlexGrow } from '../../components/FlexBox';
import Card from '../../components/Card';
import Input, {
  InputContainer,
  InputIconContainer,
} from '../../components/Input';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import { StyledText, TextSize, TextStatus } from '../../components/Text';
import {
  SortOption,
  sortValue,
  StudentFilterValue,
} from '../../filter/StudentsFilterValue';
import useDebounce from '../../components/use-debounce';
import NullStateTR from '../../components/NullStateTr';
import theme from '../../styles/theme';
import MinimalSelect from '../../components/MinimalSelectColored';
import { UserContext } from '../../context/UserContext';
import Button from '../../components/Button';
import ConfirmInvoiceModal from '../../components/ConfirmInvoiceModal';
import StyledSnackbar from '../../components/StyledSnackbar';
import TemplateInfoModal from '../../components/TemplateInfoModal';
import { RightAlign } from '../../components/UtilsComponents';
import AddTemplateModal from '../../components/AddTemplateModal';
import TemplateDetailModal from '../../components/TemplateDetailModal';
import formatDecimal from '../../helpers/formatNumber';

export interface Ioption {
  semester: any;
  program: any;
  time: any;
  day: any;
}
export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

const MinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x6 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
`;

const MultiMinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x4 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
  align-items: center;
`;

const FilterStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing.x2};
  margin-top: ${({ isMobile, theme }) => (isMobile ? theme.spacing.xs : 0)};
`;

const RowStyled = styled.div`
  display: flex;
  flex-flow: column;
`;

function TemplatePage(this: any, props: any) {
  const history = useHistory();
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const currentUser = useContext(UserContext).user;
  const style = {
    textDecoration: 'none',
    color: theme.color.textColor,
    textoverflow: 'clip',
    display: 'flex',
  };
  const test = [{ name: 'Finance', link: '/admin/finance' }];
  const colourStyles: StylesConfig<ColourOption, true> = {
    control: (styles: any) => ({ ...styles, backgroundColor: 'white' }),
    multiValue: (styles: any, { data }: any) => ({
      ...styles,
      backgroundColor: data.color,
    }),
    multiValueLabel: (styles: any, { data }: any) => ({
      ...styles,
      color: '#ffffff !important',
    }),
    multiValueRemove: (styles: any, { data }: any) => ({
      ...styles,
      color: '#ffffff !important',
      ':hover': {
        backgroundColor: data.color,
        color: '#ffffff !important',
      },
    }),
  };
  const [templates, setTemplates] = useState<Array<any>>([]);
  const [isItem, setIsItem] = useState(false);
  const [offset, setOffSet] = useState(0);
  const [limit, setLimit] = useState(100);
  const [totalPage, setTotalPage] = useState(0);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editInvoice, setEditInvoice] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [isInfoOpen, setIsInfoOpen] = useState<boolean>(false);
  const [currentInvoice, setCurrentInvoice] = useState<any>();
  const [currentTemplate, setCurrentTemplate] = useState<string>('');
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, 300);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState({
    status: [
      {
        label: 'Enrolled',
        value: 'Enrolled',
        // color: '#d9600a',
        color: theme.color.primary,
        isFixed: true,
      },
      {
        label: 'Incoming Students',
        value: 'Incoming Students',
        color: theme.color.warning,
        isFixed: true,
      },
    ],
    class: [],
    program: [],
  });
  const [filters, setfilters] = useState({
    status: [],
    program: [],
    class: '',
    sort: 'Name',
  });

  const [latestSemester, setLatestSemester] = useState<StudentFilterValue>({
    label: '',
    value: '',
  });

  const handleChangeClass = (obj: any) => {
    setfilters({ ...filters, class: obj.value });
  };

  const handleChangeSort = (obj: any) => {
    setfilters({ ...filters, sort: obj.value });
  };

  const handleChangeProgram = (obj: any) => {
    const program: any = [];
    if (obj) {
      obj.forEach((element: any) => {
        program.push(element.value);
      });
      setfilters({ ...filters, program });
    } else {
      setfilters({ ...filters, program: [] });
    }
  };
  const handleChangeStatus = (obj: any) => {
    const status: any = [];
    if (obj) {
      obj.forEach((element: any) => {
        status.push(element.value);
      });
      setfilters({ ...filters, status });
    } else {
      setfilters({ ...filters, status: [] });
    }
  };

  const handleSearchChange = (text: string) => {
    setSearchValue(text);
  };
  const getHighlightedText = (text: string, highlight: string) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? { fontWeight: 'bold' }
                : {}
            }>
            {part}
          </span>
        ))}
      </span>
    );
  };

  const openModal = (data: any) => {
    if (data) {
      setCurrentInvoice(data);
      setIsModalOpen(true);
    }
  };
  const templateInfo = (tempNumber: any) => {
    if (tempNumber) {
      setCurrentTemplate(tempNumber);
      setIsInfoOpen(true);
    }
  };

  const ColumnName = (isMobile: boolean) => {
    if (isMobile) {
      return (
        <>
          <th></th>
          <th></th>
        </>
      );
    }
    return (
      <>
        <th>Template #</th>
        <th>Template Name</th>
        <th>Total Amount</th>
        <th>Program</th>
        <th>Academic Year</th>
        <th></th>
      </>
    );
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchStudentbyFilter(offset, limit);
    } else {
      fetchStudentbyFilter(offset, limit);
    }
  }, [filters, debouncedSearchTerm]);

  const fetchStudentbyFilter = (offset: number, limit: number) => {
    setIsModalOpen(false);
    const newOptions: any = {};
    // fetch data
    const params = {
      query: searchValue,
      filters,
      offSet: offset,
      perpage: limit,
    };
    setLoading(true);
    axios
      .get('/api/v1/templates', { params })
      .then((res) => {
        // Object.keys(res.data.data.filter).forEach((key: string) => {
        //   const option: any[] = [];
        //   res.data.data.filter[key].map((ele: any, index: number) => {
        //     const updatedOption = { label: ele, value: ele };
        //     option.push(updatedOption);
        //     if (
        //       res.data.data.filter[key].length - 1 === index
        //       && key === 'semester'
        //       && filters.semester === ''
        //     ) {
        //       setfilters({ ...filters, semester: ele });
        //     }
        //     if (
        //       index === res.data.data.filter[key].length - 1
        //       && key === 'semester'
        //     ) {
        //       setLatestSemester(updatedOption);
        //     }
        //   });
        //   newOptions[key] = option;
        // });
        // setOptions(newOptions);
        if (res.data.data.templates) {
          setTemplates(res.data.data.templates);
          setTotalPage(res.data.data?.total_pages);
          setOffSet(res.data.data?.offset);
        }
        setIsItem(false);
        if (!res.data.data.report) {
          setIsItem(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const confirmInvoice = () => {
    setLoading(true);
    axios
      .post(
        `/api/v1/students/${currentInvoice.student_id}/templates/${currentInvoice.id}`,
      )
      .then((res) => {
        setMessage('Confirmed successfully');
        setShowMessage(true);
        fetchStudentbyFilter(offset, limit);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(true);
      });
  };
  const addTemplate = () => {
    // const path = '/admin/courses/add';
    // history.push(path);
    setIsAddOpen(true);
  };
  return (
    <>
      <StyledSnackbar
        isOpen={showMessage}
        setOpen={setShowMessage}
        message={message}
      />
      {isAddOpen && (
        <AddTemplateModal
          isOpen={isAddOpen}
          setOpen={setIsAddOpen}
          onCreateComplete={() => {
            fetchStudentbyFilter(offset, limit);
          }}
        />
      )}
      {isInfoOpen && (
        <TemplateInfoModal
          isOpen={isInfoOpen}
          setOpen={setIsInfoOpen}
          currentTemplate={currentTemplate}
        />
      )}
      {confirmOpen && (
        <ConfirmInvoiceModal
          isOpen={confirmOpen}
          operation="confirm"
          setOpen={setConfirmOpen}
          save={confirmInvoice}
          currentInvoice={currentInvoice}
        />
      )}
      {isModalOpen && (
        <TemplateDetailModal
          setOpen={setIsModalOpen}
          isOpen={isModalOpen}
          edit={editInvoice}
          info={currentInvoice}
          refetch={() => fetchStudentbyFilter(offset, limit)}
        />
      )}
      <Row>
        <Col>
          <Breadcrumb path={test} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FlexBox
            style={{
              alignItems: 'flex-start',
              marginBottom: theme.spacing.base * 2,
            }}>
            <FlexGrow>
              <h2 style={{ marginRight: 'auto', marginBottom: 0 }}>
                Templates
              </h2>
            </FlexGrow>
          </FlexBox>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ marginTop: '0px' }}>
            <FlexBox
              style={{
                alignItems: 'flex-start',
                marginBottom: theme.spacing.base * 2,
              }}>
              <FlexGrow>
                <h3 style={{ marginRight: 'auto', marginBottom: 0 }}>
                  Search for Templates
                </h3>
              </FlexGrow>
              <FlexGrow ml-auto style={{ marginLeft: 'auto' }}>
                <RightAlign>
                  <Button
                    onClick={() => addTemplate()}
                    isOutline
                    status={Status.primary}
                    style={{ padding: '4px 12px' }}>
                    <Icon name="plus-s" mr={0.5} />
                    Create Template
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
            <InputContainer
              as="form"
              onSubmit={() => {}}
              style={{ width: '100%' }}>
              <Input
                id="search-input"
                name="searchInput"
                // eslint-disable-next-line max-len
                onKeyPress={(event) => ['\\', '[', ']'].includes(event.key) && event.preventDefault()}
                // eslint-disable-next-line max-len
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchChange(e.target.value)
                }
                placeholder="Find students..."
                value={searchValue}
                style={{ width: 'inherit' }}
              />
              <InputIconContainer onClick={(e: any) => e.preventDefault()}>
                <Icon name="search" status={Status.default} />
              </InputIconContainer>
            </InputContainer>
            <FilterStyled isMobile={isMobile}>
              <MinimalSelectStyled isMobile={isMobile}>
                <StyledText status={TextStatus.default}>Status:</StyledText>
                <MinimalSelect
                  multi
                  placeholder="All"
                  options={options.status}
                  styles={colourStyles}
                  onChange={handleChangeStatus}
                />
              </MinimalSelectStyled>
              <MinimalSelectStyled isMobile={isMobile}>
                <StyledText status={TextStatus.default}>Programs:</StyledText>
                <MinimalSelect
                  multi
                  placeholder="All"
                  options={options.program}
                  onChange={handleChangeProgram}
                />
              </MinimalSelectStyled>
              <MultiMinimalSelectStyled isMobile={isMobile}>
                <StyledText status={TextStatus.default}>Class of:</StyledText>

                <MinimalSelect
                  multi
                  placeholder="All Years"
                  options={options.class}
                  onChange={handleChangeClass}
                />
              </MultiMinimalSelectStyled>
              <MinimalSelectStyled isMobile={isMobile}>
                <StyledText status={TextStatus.default}>Sort:</StyledText>
                <MinimalSelect
                  defaultValue={sortValue}
                  options={SortOption}
                  onChange={handleChangeSort}
                />
              </MinimalSelectStyled>
            </FilterStyled>
            <Table hoverable>
              <thead>
                <tr>{ColumnName(isMobile)}</tr>
              </thead>
              <tbody>
                {!loading && templates ? (
                  templates.map((data) => {
                    if (!isMobile) {
                      return (
                        <tr key={data.id} onClick={() => openModal(data)}>
                          <td>
                            <StyledText textSize={TextSize.default}>
                              {data.template_number}
                            </StyledText>
                          </td>
                          <td>
                            {searchValue && searchValue !== '' ? (
                              <StyledText textSize={TextSize.default}>
                                {getHighlightedText(data.name, searchValue)}
                              </StyledText>
                            ) : (
                              <StyledText textSize={TextSize.default}>
                                {data.name}
                              </StyledText>
                            )}
                          </td>
                          <td>{formatDecimal(data.total_amount)}</td>
                          <td>{data.program}</td>
                          <td>
                            <StyledText textSize={TextSize.default}>
                              {data.academic_year}
                            </StyledText>
                          </td>
                          <td
                            style={{ textAlign: 'end' }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}>
                            <Button
                              disabled
                              style={{ marginRight: 5 }}
                              status={Status.default}
                              onClick={() => {
                                setEditInvoice(true);
                                setCurrentInvoice(data);
                                setIsModalOpen(true);
                              }}>
                              <>Edit</>
                            </Button>
                          </td>
                        </tr>
                      );
                    }

                    return (
                      <tr key={data.student_id} onClick={() => openModal(data)}>
                        <td>
                          {searchValue && searchValue !== '' ? (
                            <StyledText textSize={TextSize.default}>
                              {getHighlightedText(data.first_name, searchValue)}
                              {getHighlightedText(data.last_name, searchValue)}
                            </StyledText>
                          ) : (
                            <StyledText textSize={TextSize.default}>
                              {data.first_name} {data.last_name}
                            </StyledText>
                          )}
                        </td>
                        <td>
                          <Button status={Status.primary} onClick={() => {}}>
                            <>Edit</>
                          </Button>
                          <Button status={Status.default} onClick={() => {}}>
                            <>Confirm</>
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NullStateTR>
                    <Icon mr={1} name="spinner" spin />
                    Loading...
                  </NullStateTR>
                )}
              </tbody>
            </Table>
            <Pagination
              totalPages={totalPage}
              currentPage={Math.floor((offset + limit) / limit)}
              onPageChange={(goingToPage: number) => {
                fetchStudentbyFilter((goingToPage - 1) * limit, limit);
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default TemplatePage;
