import React, {
  HTMLProps, useEffect, useMemo, useState,
} from 'react';
import styled from 'styled-components';

import axios from 'axios';
import { ScreenClassRender, useScreenClass } from 'react-grid-system';
import Breadcrumb from '../../components/Breadcrumb';
import Icon, { ClickableIcon } from '../../components/Icon';
import { Col, Row } from '../../components/StyledGridSystem';
import FlexBox, { FlexGrow } from '../../components/FlexBox';
import Card from '../../components/Card';
import Table from '../../components/Table';
import {
  StyledText,
  Subtitle,
  TextSize,
  TextStatus,
} from '../../components/Text';
import NullStateTR from '../../components/NullStateTr';
import theme from '../../styles/theme';

export interface Ioption {
  semester: any;
  program: any;
  time: any;
  day: any;
}

const MinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x4 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
`;

const MultiMinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x4 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
  align-items: center;
`;

const RowStyled = styled.div`
  display: flex;
  flex-flow: column;
`;

function CourseSectionPage() {
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const test = [
    { name: 'Courses', link: '/courses/myCourses' },
    { name: 'Course Sections', link: '/courses/course/sections' },
  ];

  const [posts, setPosts] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);
  const ColumnName = (isMobile: boolean) => {
    if (isMobile) {
      return (
        <>
          <th style={{ width: '45%' }}></th>
          <th style={{ width: '55%' }}></th>
        </>
      );
    }
    return (
      <>
        <th style={{ width: '15%' }}>Course Code</th>
        <th style={{ width: '45%' }}>Course Name</th>
        <th style={{ width: '40%' }}>Sections</th>
      </>
    );
  };

  useEffect(() => {
    fetchPostbyPage();
  }, []);

  const fetchPostbyPage = () => {
    // fetch data
    setLoading(true);
    axios
      .get('/api/v1/courses/sections')
      .then((res) => {
        setPosts(res.data.data.courses);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPosts([]);
        setLoading(false);
      });
  };

  return (
    <>
      <Row>
        <Col>
          <Breadcrumb path={test} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FlexBox space={2}>
            <h2 style={{ marginRight: 'auto', marginBottom: 0 }}>
              Course Section
            </h2>
          </FlexBox>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ marginTop: '0px' }}>
            <Table hoverable>
              <thead>
                <tr>{ColumnName(isMobile)}</tr>
              </thead>
              <tbody>
                {!loading && posts ? (
                  posts.map((data) => {
                    if (!isMobile) {
                      return (
                        <tr key={data.id}>
                          <td>
                            <StyledText
                              textSize={TextSize.default}
                              // status={TextStatus.disabled}
                            >
                              {data.code}
                            </StyledText>
                          </td>
                          <td>{data.short_name}</td>
                          <td>
                            {data.sections
                              && data.sections.map((section: any) => (
                                <FlexBox>
                                  <FlexGrow>Section Name: {section.name}</FlexGrow>
                                  <FlexGrow>
                                    Capacity: {section.capacity}
                                  </FlexGrow>
                                </FlexBox>
                              ))}
                          </td>
                        </tr>
                      );
                    }

                    return (
                      <tr key={data.id}>
                        <td>
                          <RowStyled>
                            <StyledText style={{ fontWeight: 'bold' }}>
                              {data.short_name}
                            </StyledText>
                            <StyledText status={TextStatus.muted}>
                              {data.code}
                            </StyledText>
                          </RowStyled>
                        </td>
                        <td>
                          {data.sections
                            && data.sections.map((section: any) => (
                              <FlexBox
                                style={{
                                  borderBottom:
                                    data.sections.length > 1
                                      ? 'red dashed 1px' : 'unset',
                                }}>
                                <FlexGrow style={{ flexBasis: '100%' }}>
                                  Section Name: {section.name}
                                </FlexGrow>
                                <FlexGrow style={{ flexBasis: '100%' }}>
                                  Section Capacity: {section.capacity}
                                </FlexGrow>
                              </FlexBox>
                            ))}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <div>
                    <NullStateTR>No courses found.</NullStateTR>
                  </div>
                )}
              </tbody>
            </Table>
            {!loading ? (
              <div></div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: theme.spacing.x2,
                }}>
                <Icon
                  name="spinner"
                  spin
                  size={4}
                  color={theme.color.textDisabled}
                />
                {/* <CircularProgress disableShrink /> */}
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default CourseSectionPage;
