/* eslint-disable camelcase */
import axios from 'axios';
import moment from 'moment';
import React, { useMemo, useState, useEffect } from 'react';

import { useScreenClass } from 'react-grid-system';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { ErrorMessage } from '@hookform/error-message';
import Select from 'react-select';
import theme from '../styles/theme';
import Status from '../types/enum/Status';
import Button from './Button';
import Card from './Card';
import ConfirmInvoiceModal from './ConfirmInvoiceModal';
import FlexBox, { FlexGrow } from './FlexBox';
import Icon, { ClickableIcon } from './Icon';
import Input from './Input';
import Modal from './Modal';
import StyledSnackbar from './StyledSnackbar';
import { Col, Row } from './StyledGridSystem';
import Table from './Table';
import { RightAlign } from './UtilsComponents';
import formatDecimal from '../helpers/formatNumber';
import { StyledText } from './Text';
import Hr from './Hr';

type FinanceDetailModalProps = {
  isOpen?: boolean;
  setOpen: (x: boolean) => void;
  info: any;
  edit: boolean;
  refetch?: () => void;
};
interface InvoiceForm {
  template: {
    id: string;
  };
  additional_items: {
    name: string;
    amount: number;
    type: string;
  }[];
}
const FinanceDetailModal = ({
  isOpen,
  setOpen,
  edit = false,
  info,
  refetch,
}: FinanceDetailModalProps): JSX.Element => {
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<InvoiceForm>({
    reValidateMode: 'onChange',
    mode: 'onTouched',
  });
  const { fields, append, remove } = useFieldArray({
    name: 'additional_items',
    control,
    keyName: 'key',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(edit);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const [invoice, setInvoice] = useState<{
    additional_items?: any;
    confirmation_admin?: string;
    confirmation_date?: string;
    due_date?: string;
    financial_evidence?: string;
    id?: string;
    invoice_number?: string;
    issued_date?: string;
    items?: Array<any>;
    status?: string;
    template?: { id: string };
    total?: Number;
  }>({});
  const [templates, setTemplates] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);
  const [plans, setPlans] = useState([
    { label: 'Semester Plan', value: 'semester_plan' },
    { label: 'Annual Plan', value: 'annual_plan' },
    { label: 'Program Plan', value: 'program_plan' },
  ]);
  const [filters, setfilters] = useState({
    plan: '',
  });
  useEffect(() => {
    if (info && info?.id) {
      fetchInvoice();
      fetchTemplates();
    }
  }, [info]);
  const fetchTemplates = () => {
    setLoading(true);
    axios
      .get('/api/v1/templates')
      .then((res) => {
        if (res.data.data.templates) {
          // eslint-disable-next-line max-len
          const programList: React.SetStateAction<
            { label: string; value: string }[]
          > = [];
          res.data.data.templates.forEach(
            (element: { name: string; id: string }) => {
              const array = { label: '', value: '' };
              array.label = element.name;
              array.value = element.id;
              programList.push(array);
            },
          );
          setTemplates(programList);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const fetchInvoice = () => {
    setLoading(true);
    axios
      .get(`/api/v1/students/${info.student_id}/invoices/${info.id}`)
      .then((res) => {
        setInvoice(res.data.data.invoice);
        setValue('template.id', res.data.data.invoice.template.id);
        if (
          res.data.data.invoice.additional_items
          && res.data.data.invoice.additional_items.length > 0
        ) {
          const temp: { name: string; amount: number; type: string }[] = [];
          res.data.data.invoice.additional_items.forEach((item: any) => {
            temp.push({
              name: item.name,
              amount: item.amount,
              type: item.type,
            });
          });
          setValue('additional_items', temp);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(true);
      });
  };
  const saveInvoice = (data: any) => {
    setLoading(true);
    const payload: {
      additional_items: Array<any>;
      template: any;
    } = {
      additional_items: [],
      template: {},
    };
    data.additional_items.forEach((item2: any) => {
      payload.additional_items.push({
        amount: Number(item2.amount),
        name: item2.name,
        type: item2.type,
      });
    });
    payload.template = data.template;
    axios
      .put(`/api/v1/invoices/${info.id}`, payload)
      .then((res) => {
        fetchInvoice();
        setIsEdit(false);
        setLoading(false);
      })
      .catch(() => {
        setLoading(true);
      });
  };
  const getTemplate = (id: string) => {
    axios
      .get(`/api/v1/templates/${id}`)
      .then((res) => {
        if (res.data.data.template) {
          setInvoice({
            ...invoice,
            template: res.data.data.template,
            items: res.data.data.template.items,
          });
          setValue('additional_items', []);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const confirmInvoice = () => {
    setLoading(true);
    axios
      .post(`/api/v1/students/${info.student_id}/invoices/${info.id}`)
      .then(() => {
        setIsEdit(false);
        setMessage('Confirmed successfully');
        setShowMessage(true);
        refetch?.();
        setLoading(false);
      })
      .catch(() => {
        setLoading(true);
      });
  };
  const removeSchedule = (field: any, index: number) => {
    remove(index);
  };
  const cancel = () => {
    setIsEdit(false);
  };
  return (
    <>
      <StyledSnackbar
        isOpen={showMessage}
        setOpen={setShowMessage}
        message={message}
      />
      <Modal
        isOpen={isOpen || false}
        setOpen={setOpen}
        customStyle={{ content: { maxWidth: 800 } }}>
        <form onSubmit={handleSubmit(saveInvoice)}>
          <Row>
            <Col>
              <FlexBox>
                <FlexGrow>
                  <h3 style={{ marginRight: 'auto', marginBottom: 0 }}>
                    Invoice Details
                  </h3>
                </FlexGrow>
                <ClickableIcon name="x-s" onClick={() => setOpen(false)} />
              </FlexBox>
            </Col>
          </Row>
          <FlexBox style={{ margin: '16px 0px 16px 0px' }}>
            <FlexGrow style={{ flex: '0 0 100%', marginBottom: '5px' }}>
              <StyledText fontWeight="700">Template</StyledText>
              {/* <Select
              isMulti={false}
              {...register('template.id', {
                required: 'Template is required',
              })}
              defaultValue={
                templates.filter(
                  (x) => x.value === invoice?.template?.id,
                )[0] as any
              }
              options={templates}
              onChange={(e: any) => {
                setValue('template.id', e.value);
                getTemplate(e.value);
              }}
            /> */}
              {invoice && templates && (
                <Controller
                  control={control}
                  name={'template.id' as const}
                  defaultValue={invoice?.template?.id}
                  render={({
                    field: {
                      onBlur, onChange, name, ref, value,
                    },
                  }) => (
                    <Select
                      name={name}
                      inputRef={ref}
                      value={templates.find((c) => c.value === value)}
                      onChange={(val: any) => {
                        onChange(val.value);
                        setValue('template.id', val.value);
                        getTemplate(val.value);
                      }}
                      onBlur={onBlur}
                      options={templates}
                    />
                  )}
                />
              )}
              <ErrorMessage
                errors={errors}
                name="template.id"
                render={({ message }) => (
                  <p
                    style={{
                      marginTop: 0,
                      color: theme.color.danger,
                    }}>
                    {message}
                  </p>
                )}
              />
            </FlexGrow>
          </FlexBox>
          {!loading ? (
            <>
              {!isMobile ? (
                <>
                  <Table>
                    <thead>
                      <tr>
                        <th style={{ width: '30%' }}>Line Item Type</th>
                        <th style={{ width: '50%' }}>Item title</th>
                        <th style={{ width: '20%' }}>Amount(THB)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoice?.items
                        && invoice.items.map((data: any) => (
                          <>
                            <tr>
                              <td style={{ border: 'none' }}>
                                <StyledText>{data.type || '-'}</StyledText>
                              </td>
                              <td style={{ border: 'none' }}>
                                {data.name || 'N/A'}
                              </td>
                              <td style={{ border: 'none' }}>
                                {data.amount.toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                }) || 'N/A'}
                              </td>
                            </tr>
                          </>
                        ))}
                      {!isEdit && invoice?.additional_items && (
                        <>
                          <tr
                            style={{ borderTop: '1.5px dashed #CB092B' }}></tr>
                          {invoice.additional_items.map((data: any) => (
                            <>
                              <tr>
                                <td style={{ border: 'none' }}>
                                  <StyledText>{data.type || '-'}</StyledText>
                                </td>
                                <td style={{ border: 'none' }}>
                                  {data.name || 'N/A'}
                                </td>
                                <td style={{ border: 'none' }}>
                                  {data.amount.toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                  }) || 'N/A'}
                                </td>
                              </tr>
                            </>
                          ))}
                        </>
                      )}
                      {isEdit && (
                        <>
                          {fields.map((field, index) => (
                            <>
                              <tr>
                                <td style={{ border: 'none' }}>
                                  <Input
                                    isFullWidth
                                    {...register(
                                      `additional_items.${index}.type` as const,
                                      {
                                        required: 'Line Item type is required',
                                      },
                                    )}></Input>
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: theme.color.danger,
                                    }}>
                                    {
                                      errors?.additional_items?.[index]?.type}
                                  </p>
                                </td>
                                <td style={{ border: 'none' }}>
                                  <Input
                                    isFullWidth
                                    {...register(
                                      `additional_items.${index}.name` as const,
                                      {
                                        required:
                                          'Additional Line Item name is required',
                                      },
                                    )}></Input>
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: theme.color.danger,
                                    }}>
                                    {
                                      errors?.additional_items?.[index]?.name
                                        ?.message
                                    }
                                  </p>
                                </td>
                                <td style={{ border: 'none' }}>
                                  <FlexBox style={{ flexWrap: 'nowrap' }}>
                                    <FlexGrow style={{ flexGrow: 0 }}>
                                      <Input
                                        isFullWidth={false}
                                        {...register(
                                          `additional_items.${index}.amount` as const,
                                          {
                                            required:
                                              'Additional Line Item amount is required',
                                          },
                                        )}></Input>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          color: theme.color.danger,
                                        }}>
                                        {
                                          errors?.additional_items?.[index]
                                            ?.amount?.message
                                        }
                                      </p>
                                    </FlexGrow>
                                    <RightAlign>
                                      <ClickableIcon
                                        type="button"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          removeSchedule(field, index);
                                        }}
                                        name="x-s"
                                        style={{
                                          color: '#7E7E7E',
                                          verticalAlign: 'middle',
                                        }}></ClickableIcon>
                                    </RightAlign>
                                  </FlexBox>
                                </td>
                              </tr>
                            </>
                          ))}
                          <FlexBox style={{ marginTop: '5px' }}>
                            <FlexGrow>
                              <ClickableIcon
                                type="button"
                                title="Add Item"
                                style={{
                                  alignItems: 'center',
                                  color: theme.color.primary,
                                }}
                                status={Status.primary}
                                name="plus-s"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  append({
                                    type: 'Fee',
                                    name: '',
                                    amount: 0,
                                  });
                                }}
                              />
                            </FlexGrow>
                          </FlexBox>
                        </>
                      )}
                    </tbody>
                    {!isEdit && (
                      <tfoot>
                        <tr>
                          <td style={{ borderTop: '1px solid #C1C7CF' }}></td>
                          <td
                            style={{
                              textAlign: 'end',
                              borderTop: '1px solid #C1C7CF',
                            }}>
                            {'Amount:'}
                          </td>
                          <td style={{ borderTop: '1px solid #C1C7CF' }}>
                            {formatDecimal(invoice?.total)}
                          </td>
                        </tr>
                      </tfoot>
                    )}
                  </Table>
                </>
              ) : (
                <>
                  <Table>
                    <thead>
                      <tr>
                        <th>Item title</th>
                        <th>Amount(THB)</th>
                      </tr>
                    </thead>
                    {invoice?.items?.map((data: any) => (
                      <>
                        <tr>
                          <th colSpan={6}>{data.name}</th>
                        </tr>
                        <tr>
                          <td />
                          <td style={{ border: 'none' }}>
                            {data.amount.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            }) || 'N/A'}
                          </td>
                        </tr>
                      </>
                    ))}
                  </Table>
                </>
              )}
            </>
          ) : (
            <></>
          )}
          <RightAlign style={{ marginTop: theme.spacing.x2 }}>
            {isEdit ? (
              <>
                <Button
                  disabled={info.is_official}
                  style={{ marginRight: 5 }}
                  status={Status.primary}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    cancel();
                  }}>
                  Cancel
                </Button>
                <Button
                  disabled={Object.keys(errors).length !== 0}
                  status={Status.success}
                  type="submit">
                  <>Save</>
                </Button>
              </>
            ) : (
              <>
                <Button
                  disabled={info.is_official}
                  style={{ marginRight: 5 }}
                  status={Status.default}
                  onClick={() => {
                    setIsEdit(true);
                  }}>
                  Edit
                </Button>
                {info?.is_official ? (
                  <Button disabled status={Status.primary}>
                    <>Confirmed</>
                  </Button>
                ) : (
                  <Button
                    status={Status.primary}
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      confirmInvoice();
                    }}>
                    <>Confirm</>
                  </Button>
                )}
              </>
            )}
          </RightAlign>
        </form>
      </Modal>
    </>
  );
};

export default FinanceDetailModal;
