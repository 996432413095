/* eslint-disable camelcase */
type User = {
  name: string;
  // lastname: string;
  picture: string;
  role: string;
  student_id: string;
  user_id: string;
};

// export const DummyUser: User = {
//   name: 'Visarut',
//   lastname: 'Techatanachai
//   picture: 'https://picsum.photos/200',
// };

export const EmptyUser:User = {
  name: '',
  picture: '',
  role: '',
  student_id: '',
  user_id: '',
};

export default User;
