import React, {
  HTMLProps,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { NavLink, useHistory } from 'react-router-dom';
import { useScreenClass } from 'react-grid-system';
import { StylesConfig } from 'react-select';
import axios from 'axios';
import { DateTime } from 'luxon';
import Breadcrumb from '../../components/Breadcrumb';
import Icon from '../../components/Icon';
import { Col, Row } from '../../components/StyledGridSystem';
import Status from '../../types/enum/Status';
import FlexBox, { FlexGrow } from '../../components/FlexBox';
import Card from '../../components/Card';
import Input, {
  InputContainer,
  InputIconContainer,
} from '../../components/Input';
import Table from '../../components/Table';
import { StyledText, TextSize, TextStatus } from '../../components/Text';
import useDebounce from '../../components/use-debounce';
import NullStateTR from '../../components/NullStateTr';
import theme from '../../styles/theme';
import Pill from '../../components/Pill';
import { UserContext } from '../../context/UserContext';
import { RightAlign } from '../../components/UtilsComponents';
import Button from '../../components/Button';

export interface Ioption {
  semester: any;
  program: any;
  time: any;
  day: any;
}
export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

const MinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x6 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
`;

const MultiMinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x4 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
  align-items: center;
`;

const FilterStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing.x2};
  margin-top: ${({ isMobile, theme }) => (isMobile ? theme.spacing.xs : 0)};
`;

const RowStyled = styled.div`
  display: flex;
  flex-flow: column;
`;

function InstructorsPage() {
  const history = useHistory();
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const currentUser = useContext(UserContext).user;

  const test = [{ name: 'Instructors', link: '/instructors' }];

  const [instructors, setInstructors] = useState<Array<any>>([]);
  const [isItem, setIsItem] = useState(false);
  const [loading, setLoading] = useState(true);

  const ColumnName = (isMobile: boolean) => {
    if (isMobile) {
      return (
        <>
          <th></th>
          <th></th>
        </>
      );
    }
    return (
      <>
        <th>Full Name</th>
        <th>CMKL Email</th>
        <th></th>
      </>
    );
  };
  useEffect(() => {
    fetchInstructors();
  }, []);
  const fetchInstructors = () => {
    setLoading(true);
    axios
      .get('/api/v1/courses/instructors')
      .then((res) => {
        if (res.data.data.instructors) {
          setInstructors(res.data.data.instructors);
          setLoading(false);
        } else {
          setInstructors([]);
        }
        setIsItem(false);
        if (!res.data.data.report) {
          setIsItem(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const createInstructor = () => {
    const path = '/admin/instructor/create';
    history.push(path);
  };
  return (
    <>
      <Row>
        <Col>
          <Breadcrumb path={test} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FlexBox space={2}>
            <h2 style={{ marginRight: 'auto', marginBottom: 0 }}>Instructors</h2>
          </FlexBox>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ marginTop: '0px' }}>
            <FlexBox
              style={{
                alignItems: 'flex-start',
                marginBottom: theme.spacing.base * 2,
              }}>
              <FlexGrow ml-auto style={{ marginLeft: 'auto' }}>
                <RightAlign>
                  <Button
                    onClick={() => createInstructor()}
                    isOutline
                    status={Status.primary}
                    style={{ padding: '4px 12px' }}>
                    <Icon name="plus-s" mr={0.5} />
                    Create Instructor
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
            <Table hoverable>
              <thead>
                <tr>{ColumnName(isMobile)}</tr>
              </thead>
              <tbody>
                {!loading && instructors ? (
                  instructors.map((data) => {
                    if (!isMobile) {
                      return (
                        <tr
                          key={data.id}
                        // onClick={() => openModal(data)}
                        >
                          <td>
                            <StyledText textSize={TextSize.default}>
                              {data?.first_name} {data?.last_name}
                            </StyledText>
                          </td>
                          <td>
                            <StyledText textSize={TextSize.default}>
                              {data?.cmkl_email ? data.cmkl_email : '-'}
                            </StyledText>
                          </td>
                          <td
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            style={{ verticalAlign: 'middle' }}>
                          </td>
                        </tr>
                      );
                    }

                    return (
                      <tr key={data.id}
                      // onClick={() => openModal(data)}
                      >
                        <td>
                          <StyledText textSize={TextSize.default}>
                            {data.first_name} {data.last_name}
                          </StyledText>
                        </td>
                        <td
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          style={{ verticalAlign: 'middle' }}>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NullStateTR>
                    <Icon mr={1} name="spinner" spin />
                    Loading...
                  </NullStateTR>
                )}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default InstructorsPage;
