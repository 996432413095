import React, { useState, useEffect } from 'react';

import { useScreenClass } from 'react-grid-system';
import styled from 'styled-components';
import FlexBox, { FlexGrow } from './FlexBox';
import { ClickableIcon } from './Icon';
import Modal from './Modal';
import NullStateTR from './NullStateTr';
import { Col, Row } from './StyledGridSystem';
import Table from './Table';
import { StyledText, TextStatus } from './Text';

const MAX_DESCRIPTION_LENGTH = 280;

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StatusStyled = styled.div`
  display: flex;
  flex-flow: column;
`;

const StatsItemStyled = styled.div`
  display: flex;
  flex-flow: row;
  vertical-align: baseline;
`;

const BlockStlyed = styled.h6`
  padding-right: ${({ theme }) => theme.spacing.x3};
  width: 100px;
  margin: 0px;
`;

type TemplateInfoModalProps = {
  isOpen?: boolean;
  setOpen?: (x: boolean) => void;
  currentTemplate: string;
};

const TemplateInfoModal = ({
  isOpen,
  setOpen,
  currentTemplate,
}: TemplateInfoModalProps): JSX.Element => {
  const screenClass = useScreenClass();

  return (
    <Modal isOpen={!!isOpen} setOpen={setOpen}>
      <FlexBox alignItems="flex-start">
        <FlexGrow>
          <StyledText status={TextStatus.muted}>Template Code</StyledText>
          <h3 style={{ margin: '0px' }}>Name</h3>
        </FlexGrow>
        <RightAlign>
          <ClickableIcon name="x-s" onClick={() => setOpen?.(false)} />
        </RightAlign>
      </FlexBox>
      <p>Description</p>
      <StatusStyled>
        <StatsItemStyled>
          <BlockStlyed>Label</BlockStlyed>
          <div style={{ fontSize: '16px' }}>Data</div>
        </StatsItemStyled>
        <StatsItemStyled>
          <BlockStlyed>Label</BlockStlyed>
          <div style={{ fontSize: '16px' }}>Data</div>
        </StatsItemStyled>
        <StatsItemStyled>
          <BlockStlyed>label</BlockStlyed>
          <StyledText
            status={TextStatus.muted}
            style={{ fontStyle: 'italic', fontSize: '16px' }}>
            None
          </StyledText>
        </StatsItemStyled>
      </StatusStyled>
    </Modal>
  );
};

export default TemplateInfoModal;
