import React, { useState } from 'react';
import styled from 'styled-components';

import Dropdown, { DropdownContainer, DropdownItem } from './Dropdown';
import Icon, { ClickableIcon } from './Icon';
import _theme from '../styles/theme';
import User from '../types/User';

const RemoveCourse = styled.div`
  color: ${({ theme }) => theme.color.primary};
`;

type DocumentDropdownProps = {
  user: User;
  downloadDocument: () => void;
  editDocument: () => void;
  deleteDocument: () => void;
};

const DocumentDropdown = ({
  user,
  editDocument,
  downloadDocument,
  deleteDocument,
}: DocumentDropdownProps) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <DropdownContainer>
      <ClickableIcon
        ml={0.5}
        size={2}
        name="more-vertical"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!isOpen);
        }}
        style={{ color: _theme.color.primary }}
      />
      <Dropdown
        open={isOpen}
        style={{ whiteSpace: 'nowrap' }}
        setOpen={setOpen}
        align="right">
        <DropdownItem
          to="#"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
            downloadDocument();
          }}>
          <Icon mr={1} name="download" />
          Download
        </DropdownItem>
        {user.role === 'admin' && (
          <>
            <DropdownItem
              to="#"
              onClick={(e: any) => {
                setOpen(false);
                editDocument();
              }}>
              <Icon mr={1} name="pencil-s" /> Edit
            </DropdownItem>
            <DropdownItem
              to="#"
              onClick={(e: any) => {
                setOpen(false);
                deleteDocument();
              }}>
              <RemoveCourse>
                <Icon mr={1} name="trash" /> Delete
              </RemoveCourse>
            </DropdownItem>
          </>
        )}
      </Dropdown>
    </DropdownContainer>
  );
};

export default DocumentDropdown;
