import React, {
  HTMLProps,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { NavLink, useHistory } from 'react-router-dom';
import { useScreenClass } from 'react-grid-system';
import { StylesConfig } from 'react-select';
import axios from 'axios';
import { DateTime } from 'luxon';
import Breadcrumb from '../../components/Breadcrumb';
import Icon from '../../components/Icon';
import { Col, Row } from '../../components/StyledGridSystem';
import Status from '../../types/enum/Status';
import FlexBox, { FlexGrow } from '../../components/FlexBox';
import Card from '../../components/Card';
import Input, {
  InputContainer,
  InputIconContainer,
} from '../../components/Input';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import { StyledText, TextSize, TextStatus } from '../../components/Text';
import {
  StudentContent,
  SortOption,
  sortValue,
  StudentFilterValue,
  EmptyStudentContent,
} from '../../filter/StudentsFilterValue';
import useDebounce from '../../components/use-debounce';
import NullStateTR from '../../components/NullStateTr';
import theme from '../../styles/theme';
import Pill from '../../components/Pill';
import { UserContext } from '../../context/UserContext';
import { RightAlign } from '../../components/UtilsComponents';
import Button from '../../components/Button';
import MinimalSelect from '../../components/MinimalSelect';
import StudentDeleteModal from './StudentDeleteModal';
import StudentDropDown from './StudentDropdown';

export interface Ioption {
  semester: any;
  program: any;
  time: any;
  day: any;
}
export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

const MinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x6 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
`;

const MultiMinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x4 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
  align-items: center;
`;

const FilterStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing.x2};
  margin-top: ${({ isMobile, theme }) => (isMobile ? theme.spacing.xs : 0)};
`;

const RowStyled = styled.div`
  display: flex;
  flex-flow: column;
`;

function StudentsPage() {
  const history = useHistory();
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const currentUser = useContext(UserContext).user;
  const style = {
    textDecoration: 'none',
    color: theme.color.textColor,
    textoverflow: 'clip',
    display: 'flex',
  };
  const test = [{ name: 'Students', link: '/students' }];
  const colourStyles: StylesConfig<ColourOption, true> = {
    control: (styles: any) => ({ ...styles, backgroundColor: 'white' }),
    multiValue: (styles: any, { data }: any) => ({
      ...styles,
      backgroundColor: data.color,
    }),
    multiValueLabel: (styles: any, { data }: any) => ({
      ...styles,
      color: '#ffffff !important',
    }),
    multiValueRemove: (styles: any, { data }: any) => ({
      ...styles,
      color: '#ffffff !important',
      ':hover': {
        backgroundColor: data.color,
        color: '#ffffff !important',
      },
    }),
  };
  const [students, setStudents] = useState<Array<any>>([]);
  const [isItem, setIsItem] = useState(false);
  const [offset, setOffSet] = useState(0);
  const [limit, setLimit] = useState(100);
  const [totalPage, setTotalPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, 300);
  const [loading, setLoading] = useState(true);
  const [activityTypes, setActivityTypes] = useState([
    {
      label: '',
      value: '',
      color: theme.color.warning,
      isFixed: true,
    },
  ]);
  const [options, setOptions] = useState({
    status: [{ label: 'All', value: 'All' }],
    class: [],
    program: [{ label: 'All', value: 'All' }],
  });
  const [filters, setfilters] = useState({
    status: [],
    program: [],
    class: '',
    sort: 'Name ascending',
  });
  const fetchOptions = () => {
    setLoading(true);
    const programVals: any = [];
    const statusVals: any = [];
    axios
      .get('/api/v1/students/status')
      .then((res) => {
        if (res.data.data.activity_types) {
          res.data.data.activity_types.forEach((status: any) => {
            statusVals.push({
              label: status.name,
              value: status.name,
              color: theme.color.warning,
              isFixed: true,
            });
          });
          console.log('status', options.status);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    axios
      .get('/api/v1/courses/programs')
      .then((res) => {
        if (res.data.data.programs) {
          res.data.data.programs.forEach((prog: any) => {
            programVals.push({ label: prog.name, value: prog.name });
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setOptions({ ...options, status: statusVals, program: programVals });
  };

  const handleChangeSort = (obj: any) => {
    console.log('in change sort', obj);
    setfilters({ ...filters, sort: obj.value });
  };

  const handleChangeProgram = (obj: any, action: any) => {
    console.log('obj in program', obj, action);
    const program: any = [];
    if (obj) {
      obj.forEach((element: any) => {
        program.push(element.value);
      });
      setfilters({ ...filters, program });
    } else {
      setfilters({ ...filters, program: [] });
    }
  };
  const handleChangeStatus = (obj: any) => {
    console.log('obj in status', obj);
    const status: any = [];
    if (obj) {
      obj.forEach((element: any) => {
        status.push(element.value);
      });
      setfilters({ ...filters, status });
    } else {
      setfilters({ ...filters, status: [] });
    }
  };

  const handleSearchChange = (text: string) => {
    setSearchValue(text);
  };
  const getHighlightedText = (text: string, highlight: string) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {' '}
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? { fontWeight: 'bold' }
                : {}
            }>
            {part}
          </span>
        ))}{' '}
      </span>
    );
  };

  const getDate = (strDate: string) => {
    const date = DateTime.fromISO(strDate);
    return date.toFormat('yyyy-MM-dd');
  };
  const redirectUser = (userId: string, role: string) => {
    history.push({
      pathname: '/personalInfo',
      state: { userId, role },
    });
  };

  const openModal = (data: any) => {
    if (data) {
      setCurrentStudent(data);
      setIsModalOpen(true);
    }
  };

  const ColumnName = (isMobile: boolean) => {
    if (isMobile) {
      return (
        <>
          <th></th>
          <th></th>
        </>
      );
    }
    return (
      <>
        <th>CMKL Email</th>
        <th>Full Name</th>
        <th>Program</th>
        <th>First Enrolled</th>
        <th>Status</th>
        <th></th>
      </>
    );
  };
  useEffect(() => {
    fetchOptions();
  }, []);
  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchStudentbyFilter(offset, limit);
    } else {
      fetchStudentbyFilter(offset, limit);
    }
  }, [filters, debouncedSearchTerm]);
  const fetchStudentbyFilter = (offset: number, limit: number) => {
    console.log('filters', filters);
    // fetch data
    const params = {
      query: searchValue,
      filters,
      offSet: offset,
      perpage: limit,
    };
    setLoading(true);
    axios
      .get('/api/v1/students', { params })
      .then((res) => {
        if (res.data.data.students) {
          setStudents(res.data.data.students);
          setTotalPage(res.data.data.total_pages);
          setOffSet(res.data.data.offset);
          setLimit(res.data.data.limit);
          setLoading(false);
        } else {
          setStudents([]);
          setTotalPage(0);
          setOffSet(0);
        }
        setIsItem(false);
        if (!res.data.data.report) {
          setIsItem(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const createStudent = () => {
    const path = '/admin/students/create';
    history.push(path);
  };
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [currentStudent, setCurrentStudent] = useState<StudentContent>(EmptyStudentContent);
  const openDeleteModal = (student: any, e: MouseEvent) => {
    setCurrentStudent(student);
    setIsDeleteOpen(true);
    e.stopPropagation();
  };
  return (
    <>
      <StudentDeleteModal
        isOpen={isDeleteOpen}
        setOpen={setIsDeleteOpen}
        student={currentStudent}
        // handleDeleteCourse={handleDeleteCourse}
        onDeleteComplete={() => {
          fetchStudentbyFilter(offset, limit);
        }}
        onDeleteError={() => {
          fetchStudentbyFilter(offset, limit);
        }}
      />
      <Row>
        <Col>
          <Breadcrumb path={test} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FlexBox space={2}>
            <h2 style={{ marginRight: 'auto', marginBottom: 0 }}>Students</h2>
          </FlexBox>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ marginTop: '0px' }}>
            <FlexBox
              style={{
                alignItems: 'flex-start',
                marginBottom: theme.spacing.base * 2,
              }}>
              <FlexGrow>
                <h3>Search for Students</h3>
              </FlexGrow>
              <FlexGrow ml-auto style={{ marginLeft: 'auto' }}>
                <RightAlign>
                  <Button
                    onClick={() => createStudent()}
                    isOutline
                    status={Status.primary}
                    style={{ padding: '4px 12px' }}>
                    <Icon name="plus-s" mr={0.5} />
                    Create Student
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
            <InputContainer
              as="form"
              onSubmit={() => {}}
              style={{ width: '100%' }}>
              <Input
                id="search-input"
                name="searchInput"
                // eslint-disable-next-line max-len
                onKeyPress={(event) => ['\\', '[', ']'].includes(event.key) && event.preventDefault()
                }
                // eslint-disable-next-line max-len
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchChange(e.target.value)
                }
                placeholder="Find students..."
                value={searchValue}
                style={{ width: 'inherit' }}
              />
              <InputIconContainer onClick={(e: any) => e.preventDefault()}>
                <Icon name="search" status={Status.default} />
              </InputIconContainer>
            </InputContainer>
            <FilterStyled isMobile={isMobile}>
              <MinimalSelectStyled isMobile={isMobile}>
                <StyledText status={TextStatus.default}>Status:</StyledText>
                <MinimalSelect
                  multi
                  placeholder="All"
                  options={options.status}
                  // styles={colourStyles}
                  onChange={handleChangeStatus}
                />
              </MinimalSelectStyled>
              <MinimalSelectStyled isMobile={isMobile}>
                <StyledText status={TextStatus.default}>Programs:</StyledText>
                <MinimalSelect
                  multi
                  placeholder="All"
                  options={options.program}
                  onChange={handleChangeProgram}
                />
              </MinimalSelectStyled>
              <MinimalSelectStyled isMobile={isMobile}>
                <StyledText status={TextStatus.default}>Sort:</StyledText>
                <MinimalSelect
                  defaultValue={sortValue}
                  options={SortOption}
                  onChange={handleChangeSort}
                />
              </MinimalSelectStyled>
            </FilterStyled>
            <Table hoverable>
              <thead>
                <tr>{ColumnName(isMobile)}</tr>
              </thead>
              <tbody>
                {!loading && students ? (
                  students.map((data) => {
                    if (!isMobile) {
                      return (
                        <tr
                          key={data.student_id}
                          onClick={() => openModal(data)}>
                          <td>
                            {searchValue && searchValue !== '' ? (
                              <StyledText textSize={TextSize.default}>
                                {getHighlightedText(
                                  data.cmkl_email,
                                  searchValue,
                                )}
                              </StyledText>
                            ) : (
                              <StyledText textSize={TextSize.default}>
                                {data.cmkl_email}
                              </StyledText>
                            )}
                          </td>
                          <td>
                            {searchValue && searchValue !== '' ? (
                              <StyledText textSize={TextSize.default}>
                                {getHighlightedText(
                                  data.first_name,
                                  searchValue,
                                )}
                                {getHighlightedText(
                                  data.last_name,
                                  searchValue,
                                )}
                              </StyledText>
                            ) : (
                              <StyledText textSize={TextSize.default}>
                                {data.first_name} {data.last_name}
                              </StyledText>
                            )}
                          </td>
                          <td>{data.program?.name}</td>
                          <td>{getDate(data.enrolled_date)}</td>
                          <td>
                            <Pill
                              style={{ textTransform: 'capitalize' }}
                              status={Status.primary}>
                              {data.program?.status}
                            </Pill>
                          </td>
                          <td
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            style={{ verticalAlign: 'middle' }}>
                            <StudentDropDown
                              currentUserRole={
                                currentUser.role && currentUser.role !== ''
                                  ? currentUser.role
                                  : 'student'
                              }
                              student={data}
                              openDeleteModal={openDeleteModal}
                            />
                          </td>
                        </tr>
                      );
                    }

                    return (
                      <tr key={data.student_id} onClick={() => openModal(data)}>
                        <td>
                          {searchValue && searchValue !== '' ? (
                            <StyledText textSize={TextSize.default}>
                              {getHighlightedText(data.first_name, searchValue)}
                              {getHighlightedText(data.last_name, searchValue)}
                            </StyledText>
                          ) : (
                            <StyledText textSize={TextSize.default}>
                              {data.first_name} {data.last_name}
                            </StyledText>
                          )}
                        </td>
                        <td
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          style={{ verticalAlign: 'middle' }}>
                          <StudentDropDown
                            currentUserRole={
                              currentUser.role && currentUser.role !== ''
                                ? currentUser.role
                                : 'student'
                            }
                            student={data}
                            openDeleteModal={openDeleteModal}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NullStateTR>
                    <Icon mr={1} name="spinner" spin />
                    Loading...
                  </NullStateTR>
                )}
              </tbody>
            </Table>
            <Pagination
              totalPages={totalPage}
              currentPage={Math.floor((offset + limit) / limit)}
              onPageChange={(goingToPage: number) => {
                fetchStudentbyFilter((goingToPage - 1) * limit, limit);
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default StudentsPage;
