import { darken } from 'polished';
import styled, { css } from 'styled-components';
import theme from '../styles/theme';
import Status from '../types/enum/Status';

export type InputProps = {
  isFullWidth?: boolean,
  disabled?: boolean,
  status?: Status
} & React.HTMLProps<HTMLInputElement>;

const Input = styled.input<InputProps>`
  box-sizing: border-box;
  padding: 5px 12px;
  height: ${({
    theme,
  }) => `${theme.spacing.base * 4}px`};
  width: ${({ theme, isFullWidth }) => (isFullWidth ? '100%' : `${theme.spacing.base * 25}px`)};
  border-radius: ${({ theme }) => theme.radius};
  border: 1px solid ${({ theme, status }) => (status === Status.default ? theme.color.borderColor : theme.color[status || Status.default])};
  
  ::placeholder {
    color: ${({ theme }) => theme.color.textMuted};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.color.lightBackground};
  }

  &:hover {
    border-color: ${({ theme }) => theme.color.primaryLight};
  }

  &:focus {
    outline-color: ${({ theme }) => theme.color.primaryLight};
    outline-width: 2px;
  }
`;

Input.defaultProps = {
  status: Status.default,
  isFullWidth: false,
};

export type InputContainerProps = {
  theme?: typeof theme;
  isFullWidth?: boolean;
} & React.HTMLProps<HTMLDivElement>;

export const InputContainer = styled.div<InputContainerProps>`
  position: relative;
  display: inline-flex;
  ${({ isFullWidth }) => isFullWidth && css`width: 100%;`}
`;

export const InputIconContainer = styled.button<InputContainerProps>`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.color.primary};
  width: ${({ theme, isFullWidth }) => (isFullWidth ? '100%' : `${theme.spacing.base * 20}px`)};
  display: flex;
  padding: 0;
  width: ${({ theme }) => theme.spacing.x3};

  align-items: center;
  justify-content: center;
  position: absolute;
  right: ${({ theme }) => theme.spacing.x1};
  top: ${({ theme }) => theme.spacing.xs};
  bottom: ${({ theme }) => theme.spacing.xs};
  transition: 0.2s all;
  border-radius: ${({ theme }) => theme.radius};
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: ${({ theme }) => theme.color.lightBackground};
    border-radius: 50%;
  }

  &:active {
    background-color: ${({ theme }) => darken(0.05, theme.color.lightBackground)};
  }
`;

export default Input;
